.profile-overview-mobile {
  padding-left: 16px;
  padding-right: 16px;
}

.profile-overview-mobile__about-wrapper {
  margin-top: 12px;
}

.profile-overview-mobile__interaction-wrapper {
  margin-top: 12px;
}

.profile-overview-mobile__photos-wrapper {
  margin-top: 12px;
}

.profile-overview-mobile__videos-wrapper {
  margin-top: 12px;
}

.profile-overview-mobile__events-wrapper {
  margin-top: 12px;
}

.profile-overview-mobile__pages-wrapper {
  margin-top: 12px;
}

.profile-overview-mobile__claim-wrapper {
  margin-top: 12px;
}
