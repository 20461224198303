.event-standings-summary-mobile {
  /* padding: 12px; */
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.event-standings-summary-mobile__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */
  padding: 12px;
  color: var(--dark-4);
}

.event-standings-summary-mobile__table {
}

.event-standings-summary-mobile__table .row {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border-bottom: solid 1px var(--light-2);
  /* height: 32px; */
  /* padding-left: 8px; */
  /* padding-right: 8px; */
  padding-left: 12px;
  padding-right: 12px;
  height: 32px;
}

.event-standings-summary-mobile__table .row.even {
  /* background: var(--blue-subtlest); */
}

.event-standings-summary-mobile__table__list {
}

.event-standings-summary-mobile__table__list .td {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  /* padding-right: 8px; */
  /* padding-left: 8px; */
}

.event-standings-summary-mobile__table__list .td .logo {
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-right: 8px;
  overflow: hidden;
}

.event-standings-summary-mobile__table__list .td .logo img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.event-standings-summary-mobile__table__list .td.number {
  justify-content: flex-end;
}

.event-standings-summary-mobile__table__list__header {
  /* border-bottom: solid 2px var(--light-2); */
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-2);
}

.event-standings-summary-mobile__table__list__body {
}

.event-standings-summary-mobile__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-weight: 600;
  color: var(--dark-4);
}
