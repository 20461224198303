.fixture-summary {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 32px;
}

.fixture-summary .left-column {
  grid-column-start: 1;
  grid-column-end: 4;
}

.fixture-summary .middle-column {
  grid-column-start: 4;
  grid-column-end: 10;
}

.fixture-summary .right-column {
  grid-column-start: 10;
  grid-column-end: 13;
}

.fixture-summary .app-card {
  /* margin-top: 32px; */
}

.fixture-summary .match-details-card {
  padding: 16px;
}

.fixture-summary .match-details-card .title {
  font-weight: 700;
  color: var(--dark-4);
  margin-bottom: 16px;
  text-align: center;
}

.fixture-summary .match-details-card .info-map {
  height: 160px;
}

.fixture-summary .match-details-card .info-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px var(--light-2);
}

.match-details-card__info-item__label {
  margin-top: 16px;
  font-size: 10px;
  font-weight: 700;
  color: var(--dark-4);
}

.fixture-summary .match-details-card .info-item:last-child {
  border-bottom: none;
  padding-bottom: 8px;
}

.fixture-summary .match-details-card .info-item .icon {
  /* stroke: var(--dark-3); */
  height: 16px;
  width: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.fixture-summary .match-details-card .info-item .info-item-text {
  /* font-family: "Inter", sans-serif; */
  font-size: 12px;
}

.fixture-summary .match-details-card .info-item .info-item-text.link {
  color: var(--blue-main);
}

.fixture-summary .match-details-card .info-item .info-item-edit-btn {
  margin-left: auto;
  height: 24px;
  width: 24px;
  background: var(--orange-subtle);
  border-radius: 8px;
  box-shadow: var(--elevation-1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* margin-left: 16px; */
}

.fixture-summary
  .match-details-card
  .info-item
  .info-item-edit-btn
  .info-item-edit-btn__icon {
  fill: var(--orange-main);
  height: 14px;
}

.fixture-summary .match-details-card .info-item .info-item-confirm-btn {
  margin-left: 12px;
  height: 24px;
  width: 24px;
  background: var(--green-main);
  border-radius: 8px;
  box-shadow: var(--elevation-1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* margin-left: 16px; */
}

.fixture-summary
  .match-details-card
  .info-item
  .info-item-confirm-btn
  .info-item-confirm-btn__icon {
  fill: var(--light-1);
  height: 14px;
}

.fixture-summary .match-details-card .info-item .info-item-cancel-btn {
  margin-left: 12px;
  height: 24px;
  width: 24px;
  background: var(--light-3);
  border-radius: 8px;
  box-shadow: var(--elevation-1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* margin-left: 16px; */
}

.fixture-summary
  .match-details-card
  .info-item
  .info-item-cancel-btn
  .info-item-cancel-btn__icon {
  stroke: var(--dark-4);
  height: 14px;
}

.fixture-summary .match-details-card .info-item .info-item-loader {
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}
