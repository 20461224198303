.app-button {
  background: white;
  font-weight: 700;
  font-family: var(--nhg);
  /* letter-spacing: 0.2px; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: all 0.2s ease-out;
  border: none;
}

.app-button:hover {
  cursor: pointer;
}

.app-button.blue {
  background: var(--blue-main);
  color: var(--blue-main);
  border-color: var(--blue-main);
}

.app-button.blue:hover {
  background: var(--blue-lighter);
  border-color: var(--blue-lighter);
}

.app-button.blue:active {
  background: var(--blue-darker);
  border-color: var(--blue-darker);
}

.app-button.green {
  background: var(--green-main);
  color: var(--green-main);
  border-color: var(--green-main);
}

.app-button.green:hover {
  background: var(--green-lighter);
  border-color: var(--green-lighter);
}

.app-button.green:active {
  background: var(--green-darker);
  border-color: var(--green-darker);
}

.app-button.red {
  background: var(--red-main);
  color: var(--red-main);
  border-color: var(--red-main);
}

.app-button.red:hover {
  background: var(--red-lighter);
  border-color: var(--red-lighter);
}

.app-button.red:active {
  background: var(--red-darker);
  border-color: var(--red-darker);
}

.app-button.orange {
  background: var(--orange-main);
  color: var(--orange-main);
  border-color: var(--orange-main);
}

.app-button.orange:hover {
  background: var(--orange-lighter);
  border-color: var(--orange-lighter);
}

.app-button.orange:active {
  background: var(--orange-darker);
  border-color: var(--orange-darker);
}

.app-button.small {
  height: 24px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  font-size: 10px;
  /* line-height: 24px; */
}

.app-button.medium {
  height: 32px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  font-size: 12px;
}

.app-button.large {
  height: 40px;
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 8px;
  font-size: 14px;
}

.app-button.primary {
  color: white;
}

.app-button.primary.blue {
  /* box-shadow: 0 2px 4px 0px var(--blue-shadow); */
}

.app-button.primary.blue:hover {
  /* box-shadow: 0 4px 8px 0px var(--blue-shadow); */
}

.app-button.primary.blue:active {
  /* box-shadow: 0 0.5px 2px 0px var(--blue-shadow); */
}

.app-button.primary.red {
  /* box-shadow: 0 2px 4px 0px var(--red-shadow); */
}

.app-button.primary.red:hover {
  /* box-shadow: 0 4px 8px 0px var(--red-shadow); */
}

.app-button.primary.red:active {
  /* box-shadow: 0 0.5px 2px 0px var(--red-shadow); */
}

.app-button.primary.green {
  /* box-shadow: 0 2px 4px 0px var(--green-shadow); */
}

.app-button.primary.green:hover {
  /* box-shadow: 0 4px 8px 0px var(--green-shadow); */
}

.app-button.primary.green:active {
  /* box-shadow: 0 0.5px 2px 0px var(--green-shadow); */
}

.app-button.secondary {
  background: white !important;
  border: solid 1px #dae2e7;
  /* box-shadow: var(--elevation-2); */
}

.app-button.secondary:hover {
  /* box-shadow: var(--elevation-3); */
}

.app-button.secondary:active {
  /* box-shadow: var(--elevation-1); */
}

.app-button.tertiary {
  background: white !important;
  border-radius: 16px;
  /* box-shadow: var(--elevation-3); */
}

.app-button.tertiary:hover {
  /* box-shadow: var(--elevation-4); */
}

.app-button.tertiary:active {
  /* box-shadow: var(--elevation-2); */
}

.app-button.tertiary.large {
  border-radius: 20px;
}

.app-button.round {
  background: white !important;
  /* box-shadow: var(--elevation-3); */
  padding: 0;
}

.app-button.round:hover {
  /* box-shadow: var(--elevation-4); */
}

.app-button.round:active {
  /* box-shadow: var(--elevation-2); */
}

.app-button.round.small {
  border-radius: 24px;
  width: 24px;
}

.app-button.round.medium {
  border-radius: 32px;
  width: 32px;
}

.app-button.round.large {
  border-radius: 40px;
  width: 40px;
}

.app-button.neutral {
  background: white;
  border: solid 1px var(--light-3);
  color: var(--dark-2);
  /* box-shadow: var(--elevation-2); */
}

.app-button.neutral:hover {
  /* box-shadow: var(--elevation-3); */
}

.app-button.neutral:active {
  /* box-shadow: var(--elevation-1); */
}

.app-button.disabled {
  background: var(--light-2);
  color: var(--light-4);
  pointer-events: none;
}

.app-button:disabled {
  opacity: 0.5;
  pointer-events: none;
}
