.score-football {
  height: 100%;
  width: 100%;
  position: relative;
}

.score-football .header {
  height: 48px;
  position: absolute;
  top: 0;
  width: 100%;
  background: var(--blue-darker);
  box-shadow: var(--elevation-4);
  z-index: 3;
  display: grid;
  grid-template-columns: 24% 52% 24%;
  grid-template-rows: 100%;
}

.score-football .header .close-scoring-btn {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  padding-left: 16px;
  cursor: pointer;
}

.score-football .header .close-scoring-btn .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-football .header .close-scoring-btn .text {
  margin-left: 8px;
  font-weight: 700;
}

.score-football .header .time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.score-football .header .time .start {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-weight: 700;
  height: 32px;
  background: var(--blue-darkest);
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 16px;
  cursor: pointer;
}

.score-football .header .time .start .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-football .header .time .pause {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-weight: 700;
  height: 32px;
  background: var(--blue-darkest);
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 16px;
  cursor: pointer;
}

.score-football .header .time .pause .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-football .header .time .end-of-period {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-weight: 700;
  background: var(--blue-darkest);
  height: 32px;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 16px;
}

.score-football .header .time .end-of-period .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-football .header .time .selected-period-selector {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-darkest);
  padding-left: 16px;
  padding-right: 8px;
  border-radius: 8px;
  color: white;
  font-weight: 700;
  margin-left: 16px;
  cursor: pointer;
}

.score-football .header .time .selected-period-selector-menu {
  background: var(--light-1);
  width: 100%;
  margin-top: 4px;
  border-radius: 8px;
  box-shadow: var(--elevation-5);
  /* z-index: 100; */
  position: relative;
  overflow: hidden;
  z-index: 5;
}

.score-football .header .time .selected-period-selector-menu__item {
  text-align: center;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: solid 1px var(--light-2);
  cursor: pointer;
}

.score-football .header .time .selected-period-selector-menu__item:hover {
  background: var(--light-2);
}

.score-football .header .time .selected-period-selector-menu__item:last-child {
  border: none;
}

.score-football
  .header
  .time
  .selected-period-selector
  .selected-period-selector__icon {
  stroke: white;
  height: 14px;
  margin-left: 8px;
}

.score-football .header .time .current-time {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-darkest);
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  border-radius: 8px;
  color: white;
  font-weight: 700;
  margin-left: 16px;
}

.score-football .header .time .current-time .option {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  stroke: white;
  cursor: pointer;
}

.score-football .header .time .current-time .time {
  padding-left: 8px;
  padding-right: 8px;
}

.score-football .header .settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
  cursor: pointer;
}

.score-football .header .settings .edit-lineups-btn {
  height: 28px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  border: solid 1px white;
}

.score-football .header .settings .publish-stats-btn {
  background: var(--green-darker);
  height: 28px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
}

.score-football .header .settings .settings-btn {
}

.score-football .header.expanded {
  height: 100%;
}

.score-football .body {
  display: grid;
  grid-template-columns: 24% 52% 24%;
  grid-template-rows: 100%;
  background: var(--light-2);
  height: 100%;
  padding-top: 48px;
  position: relative;
}

.score-football .select-period-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 48px;
  z-index: 2;
  background: white;
  transition: all 0.2s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: var(--light-1);
  /* padding-left: 32px; */
  /* padding-right: 32px; */
  /* border-radius: 8px; */
  /* margin: 16px; */
  /* margin-left: 16px; */
  /* padding-top: 32px; */
}

.score-football .left-column {
  grid-column-start: 1;
  grid-column-end: 2;
  height: 100%;
  overflow: auto;
  z-index: 1;
}

.score-football .middle-column {
  grid-column-start: 2;
  grid-column-end: 3;
  height: 100%;
  background: white;
  box-shadow: var(--elevation-3);
  /* padding-left: 24px; */
  /* padding-right: 24px; */
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.score-football .middle-column .match-participants {
  display: flex;
  flex-direction: row;
  height: 88px;
  /* border-bottom: solid 1px var(--light-2); */
  flex-shrink: 0;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  background: var(--blue-darkest);
  color: var(--light-2);
  box-shadow: var(--elevation-1);
  z-index: 3;
}

.score-football .middle-column .match-participants .home-participant {
  display: flex;
  flex-direction: row;
  width: 33.33%;
  align-items: center;
}

.score-football .middle-column .match-participants .away-participant {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 33.33%;
  text-align: right;
}

.score-football .middle-column .match-participants .logo {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 8px;
  overflow: hidden;
  /* margin-right: 16px; */
  /* margin-right: 16px; */
  /* margin-left: 16px; */
}

.score-football .middle-column .match-participants .logo img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.score-football .middle-column .match-participants .home-participant .logo {
  margin-right: 16px;
}

.score-football .middle-column .match-participants .away-participant .logo {
  margin-left: 16px;
}

.score-football .middle-column .match-participants .name {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  font-family: var(--nhg-display);
  /* margin-bottom: 24px; */
  /* color: var(--dark-2); */
}

.score-football .middle-column .match-participants .score {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  /* margin-bottom: 24px; */
  /* color: var(--dark-2); */
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-football .right-column {
  grid-column-start: 3;
  grid-column-end: 4;
  height: 100%;
  overflow: auto;
  z-index: 1;
}

.score-football .stat-type-group {
  padding: 0px;
  margin-bottom: 24px;

  /* margin-bottom: 32px; */
  /* padding-bottom: 0; */
  /* border-bottom: solid 1px var(--light-3); */
}

.score-football .stat-type-group .title {
  font-weight: 700;
  background: var(--blue-subtle);
  color: var(--blue-darkest);
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  letter-spacing: 0.4px;
  /* text-align: center; */
}

.score-football .stat-type-group .items {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.score-football .stat-type-group .stat-type-item {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  height: 40px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease-out;
  font-weight: 600;
  color: var(--dark-4);
  /* height: 64px; */
}

/* .score-football .stat-type-group .stat-type-item.span-2 {
  grid-column-span: 2;
  -ms-grid-column-span: 2;
} */

.score-football .stat-type-group .stat-type-item.disabled {
  cursor: default;
}

.score-football .stat-type-group .stat-type-item:hover {
  box-shadow: var(--elevation-3);
}

.score-football .stat-overlay {
  height: 100%;
  width: 100%;
  z-index: 6;
  background: rgba(46, 52, 56, 0.84);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-football .stat-input-form {
  background: white;
  width: 32%;
  height: 80%;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.score-football .stat-input-form__title {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--blue-darker);
  color: white;
  font-weight: 600;
  padding-left: 16px;
  padding-right: 16px;
  flex-shrink: 0;
}

.score-football .stat-input-form__title .dismiss-btn {
  margin-left: auto;
  cursor: pointer;
}

.score-football .stat-input-form .timestamp-editor {
  height: 40px;
  border-bottom: solid 1px var(--light-2);
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
  flex-shrink: 0;
}

.score-football .stat-input-form .timestamp-editor .label {
  font-weight: 600;
  color: var(--dark-4);
}

.score-football .stat-input-form .timestamp-editor .input {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--light-4);
  border-radius: 4px;
  margin-left: auto;
  overflow: hidden;
}

.score-football .stat-input-form .timestamp-editor .input .icon {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-2);
  height: 100%;
  cursor: pointer;
}

.score-football .stat-input-form .timestamp-editor .input .value {
  width: 32px;
  border-left: solid 1px var(--light-4);
  border-right: solid 1px var(--light-4);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-football .stat-input-form .timestamp-editor .input select {
  border: none;
  padding-left: 4px;
  padding-right: 4px;
  outline: none;
}

.score-football .stat-input-form .select-player {
  border-top: solid 1px var(--light-2);
}

.score-football .stat-input-form .select-player .select-player__title {
  padding: 16px;
  padding-bottom: 8px;
  font-weight: 600;
  color: var(--dark-4);
}

.score-football .stat-input-form .player-list .select-player__item {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.score-football .stat-input-form .player-list {
  flex-grow: 1;
  overflow: auto;
}

.score-football .stat-input-form .player-list .select-player__item:hover {
  background: var(--blue-subtlest);
}

.score-football .stat-input-form .player-list .select-player__item .img {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.score-football .stat-input-form .player-list .select-player__item .img img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.score-football
  .stat-input-form
  .player-list
  .select-player__item
  .name__primary {
  font-weight: 700;
  color: var(--dark-2);
}

.score-football
  .stat-input-form
  .player-list
  .select-player__item
  .name__secondary {
  color: var(--dark-4);
}

.score-football
  .stat-input-form
  .player-list
  .select-player__item
  .num-container {
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
}

.score-football
  .stat-input-form
  .player-list
  .select-player__item
  .num-container
  .num-val {
  font-size: 12px;
  color: var(--dark-4);
}

.score-football .stat-input-form .select-player .team-selector {
  display: flex;
  flex-direction: row;
  background: var(--light-2);
}

.score-football
  .stat-input-form
  .select-player
  .team-selector
  .team-selector__item {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
}

.score-football
  .stat-input-form
  .select-player
  .team-selector
  .team-selector__item.active {
  border-bottom: solid 2px var(--blue-main);
}

.score-football .stat-input-form .saved-stats {
  padding-left: 16px;
  padding-right: 16px;
}

.score-football .stat-input-form .saved-stats .saved-stat-item {
  border: dashed 1px var(--blue-lighter);
  border-radius: 4px;
  margin-top: 16px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-football .stat-input-form .saved-stats .saved-stat-item .timestamp {
  margin-right: 8px;
}

.score-football .stat-input-form .saved-stats .saved-stat-item .edit-btn {
  margin-left: auto;
  font-weight: 600;
  color: var(--orange-lighter);
  cursor: pointer;
}

.score-football .stat-input-form .associated-stat-title {
  background: var(--light-2);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 16px;
  color: var(--dark-4);
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-football .stat-input-form .associated-stat-title .skip-associated-btn {
  margin-left: auto;
  cursor: pointer;
}

.score-football .stats-list {
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 48px;
  padding-top: 16px;
  background: var(--light-2);
}

.score-football .stats-list .stats-list__period {
  background: var(--blue-subtle);
  color: var(--blue-darkest);
  font-weight: 600;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin-top: 16px;
}

.score-football .stats-list .stat-item-container.away {
  justify-content: flex-end;
}

.score-football .stats-list .stat-item-container .stat-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 60%; */
  border: solid 1px var(--light-2);
  border-radius: 4px;
  overflow: hidden;
}

.score-football .stats-list .stat-item-container .stat-item.away {
  justify-content: flex-end;
}

.score-football .stats-list .stat-item-container .stat-item .img {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
}

.score-football .stats-list .stat-item-container .stat-item .timestamp {
  height: 32px;
  width: 48px;
  border: solid 1px var(--light-2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-football .stats-list .stat-item-container .stat-item .statement {
  margin-left: 16px;
  margin-right: 16px;
}

.score-football .stats-list .stat-item-container .stat-item.home .statement {
  margin-right: 32px;
}

.score-football .stats-list .stat-item-container .stat-item.away .statement {
  margin-left: 32px;
}

.score-football .stats-list .stat-item-container .stat-item .options {
  display: flex;
  flex-direction: row;
}

.score-football .stats-list .stat-item-container .stat-item .options .option {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--light-2);
}

.score-football .football-settings {
  position: absolute;
  padding-top: 48px;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  background: var(--blue-darkest);
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.score-football .football-settings .football-settings-header {
  position: absolute;
  height: 48px;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--blue-darker);
  display: grid;
  display: grid;
  grid-template-columns: 24% 52% 24%;
  grid-template-rows: 100%;
}

.score-football .football-settings .football-settings-header .dismiss {
  font-weight: 700;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
  margin-right: 24px;
}

.score-football .football-settings .football-settings-header__btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 24px;
}

.score-football
  .football-settings
  .football-settings-header
  .close-scoring-btn {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  padding-left: 16px;
  cursor: pointer;
}

.score-football
  .football-settings
  .football-settings-header
  .close-scoring-btn
  .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-football
  .football-settings
  .football-settings-header
  .close-scoring-btn
  .text {
  margin-left: 8px;
  font-weight: 700;
}

.score-football .football-settings .settings-title {
  color: white;
  font-size: 16px;
  font-weight: 700;
  font-family: var(--nhg-display);
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 32px; */
}

.score-football .football-settings .settings-form {
  margin-top: 48px;
  width: 400px;
  /* padding-bottom: 32px; */
}

.score-football .football-settings .settings-form .form-group {
  background: #044c7c;
  /* border-bottom: solid 1px var(--light-4); */
  padding: 16px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 8px;
  margin-bottom: 32px;
  padding-bottom: 24px;
}

.score-football .football-settings .settings-form .form-group.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-football .football-settings .settings-form .form-group .label {
  color: white;
  font-weight: 700;
  /* font-size: 14px; */
  text-align: center;
  display: flex;
  flex-direction: row;
  /* margin-bottom: 16px; */
}

.score-football
  .football-settings
  .settings-form
  .form-group
  .label
  .primary-label {
  flex-grow: 1;
  text-align: center;
}

.score-football .football-settings .settings-form .form-group .label .duration {
  /* margin-left: auto; */
}

.score-football .football-settings .settings-form .form-group .value {
}

.score-football
  .football-settings
  .settings-form
  .form-group
  .value
  .innings-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  height: 32px;
  border: solid 1px var(--light-2);
  border-radius: 8px;
  overflow: hidden;
  /* margin-left: 40px; */
}

.score-football
  .football-settings
  .settings-form
  .form-group
  .value
  .innings-selector
  .minus {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: solid 1px var(--light-2);
}
.score-football
  .football-settings
  .settings-form
  .form-group
  .value
  .innings-selector
  .plus {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: solid 1px var(--light-2);
}
.score-football
  .football-settings
  .settings-form
  .form-group
  .value
  .innings-selector
  .num {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-2);
  font-weight: 700;
}

.score-football
  .football-settings
  .settings-form
  .form-group
  .value
  .settings-dropdown-container {
  position: relative;
}

.score-football
  .football-settings
  .settings-form
  .form-group
  .value
  .dropdown-input {
  height: 32px;
  border: solid 1px var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  padding-left: 16px;
  margin-top: 12px;
  padding-right: 8px;
  color: white;
}
.score-football
  .football-settings
  .settings-form
  .form-group
  .value
  .dropdown-menu {
  background: var(--light-2);
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;

  /* width: 100%; */
}

.score-football
  .football-settings
  .settings-form
  .form-group
  .value
  .dropdown-menu
  .menu-item {
  padding: 24px;
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: solid 1px var(--light-3);
}

.score-football
  .football-settings
  .settings-form
  .form-group
  .value
  .dropdown-menu
  .menu-item:last-child {
  border: none;
}

.score-football
  .football-settings
  .settings-form
  .form-group
  .value
  .innings-list {
}

.score-football
  .football-settings
  .settings-form
  .form-group
  .value
  .innings-list
  .inning-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.score-football .period__options {
  display: flex;
  flex-direction: row;
}

.score-football .period__options__option {
  display: flex;
  flex-direction: row;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.score-football .period__options__option__icon {
  height: 14px;
}

.score-football .period__options__option__icon.delete {
  stroke: white;
}

.score-football .period__options__option__icon.edit {
  fill: white;
}

.score-football
  .football-settings
  .settings-form
  .form-group
  .value
  .innings-list
  .inning {
  height: 32px;
  font-size: 12px;
  border: solid 1px var(--light-2);
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  border-radius: 8px;
  /* margin-right: 16px; */
}

.score-football
  .football-settings
  .settings-form
  .form-group
  .value
  .innings-list
  .add-inning {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--green-lighter);
  font-size: 10px;
  font-weight: 700;
  margin-top: 16px;
  cursor: pointer;
}

.score-football .lineups-page {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: var(--blue-darkest);
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding-top: 48px;
}

.score-football .lineups-page .lineups-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
  width: 100%;
  background: var(--blue-darker);
}

.score-football .lineups-page .lineups-header .dismiss-btn {
  position: absolute;
  right: 24px;
  cursor: pointer;
}

.score-football .lineups-page .lineup-editor {
  width: 400px;
  height: 80%;
  background: #044c7c;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.score-football .lineups-page .lineup-editor .team-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--light-2);
  font-weight: 700;
  height: 40px;
  flex-shrink: 0;
}

.score-football
  .lineups-page
  .lineup-editor
  .team-selector
  .team-selector-item {
  height: 40px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.score-football
  .lineups-page
  .lineup-editor
  .team-selector
  .team-selector-item.active {
  border-bottom: solid 1px var(--green-main);
  color: var(--green-main);
}

.score-football .lineups-page .lineup-editor .lineup-list {
  background: var(--light-2);
  flex-grow: 1;
  overflow: auto;
}

.score-football .lineups-page .lineup-editor .lineup-list .lineup-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  height: 40px;
  border-bottom: solid 1px var(--light-3);
}

.score-football
  .lineups-page
  .lineup-editor
  .lineup-list
  .lineup-list-item
  .image {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  border-radius: 100%;
  margin-right: 16px;
}

.score-football
  .lineups-page
  .lineup-editor
  .lineup-list
  .lineup-list-item
  .name {
}

.score-football
  .lineups-page
  .lineup-editor
  .lineup-list
  .lineup-list-item
  .checkbox {
  margin-left: auto;
}

.score-football .period-form {
  display: flex;
  flex-direction: row;
}

.score-football .period-row {
  display: grid;
  grid-template-columns: 160px 96px 80px;
  grid-template-rows: 32px;
  grid-column-gap: 16px;
  margin-top: 16px;
}

.score-football .period-row .th {
  color: white;
  font-weight: 700;
  text-align: center;
}

.score-football .period-row__options {
  display: flex;
  flex-direction: row;
  /* margin-left: 10px; */
}

.score-football .period-row__options__option {
  height: 32px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
}

.score-football .period-row__options__option.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.score-football .period-row__options__option .icon {
  height: 14px;
}

.score-football .period-row__options__option.submit .icon {
  fill: var(--green-main);
}

.score-football .period-row__options__option.cancel .icon {
  stroke: var(--red-main);
}

.score-football .period-form input {
  /* border-radius: 8px;
  border: none;
  padding-left: 12px;
  outline: none; */
}

.score-football .period-form input.label-input {
}

.score-football .period-form input.duration-input {
}

.score-football .period-form input.submit-input {
  padding: 0;
  background: none;
  color: var(--green-main);
  font-weight: 700;
}

.score-football .stats-list .stat-item-container {
  margin-top: 16px;
  /* display: flex; */
  /* flex-direction: row; */
  display: grid;
  grid-template-columns: 45% 1fr 45%;
  grid-column-gap: 16px;
}

.score-football .stats-list .stat-item-container .home-stat-item {
  /* border: solid 1px var(--light-3); */
  /* background: white; */
  display: flex;
  flex-direction: row;
}

.score-football .stats-list .stat-item-container .statements {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  padding-left: 8px;
  padding-right: 8px;
  /* text-align: center; */
}

.score-football .stats-list .stat-item-container .statements .statement {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.score-football .stats-list .stat-item-container .options {
  display: flex;
  flex-direction: row;
}

.score-football .stats-list .stat-item-container .home-stat-item .options {
  margin-right: 8px;
}

.score-football .stats-list .stat-item-container .away-stat-item .options {
  margin-left: 8px;
}

.score-football .stats-list .stat-item-container .options .option {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.score-football .stats-list .stat-item-container .timestamp {
  /* border: solid 1px var(--light-3); */
  background: var(--blue-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 36px;
  font-weight: 700;
  color: var(--blue-darkest);
}

.score-football .stats-list .stat-item-container .away-stat-item {
  /* border: solid 1px var(--light-3); */
  /* background: white; */
  display: flex;
  flex-direction: row;
}

.score-football .stats-list .stat-item-container .away-stat-item .options {
}

.score-football .publish-stats {
  position: absolute;
  padding-top: 48px;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  background: var(--blue-darkest);
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.score-football .publish-stats__header {
  position: absolute;
  height: 48px;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--blue-darker);
  display: grid;
  display: grid;
  grid-template-columns: 24% 52% 24%;
  grid-template-rows: 100%;
}

.score-football .publish-stats__header .close-scoring-btn {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  padding-left: 16px;
  cursor: pointer;
}

.score-football .publish-stats__header .close-scoring-btn .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-football .publish-stats__header .close-scoring-btn .text {
  margin-left: 8px;
  font-weight: 700;
}

.score-football .publish-stats__header .publish-stats-title {
  color: white;
  font-size: 16px;
  font-weight: 700;
  font-family: var(--nhg-display);
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 32px; */
}

.score-football .publish-stats__header .publish-stats-btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.score-football .publish-stats__header .publish-stats-btn {
  background: var(--green-darker);
  height: 28px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  margin-left: auto;
}

.score-football .publish-stats__body {
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, 50%);
}

.score-football .publish-stats__body .stats-table-container {
  padding: 24px;
}

.score-football .publish-stats__body .stats-table {
  /* height: 200%; */
  background: var(--blue-darker);
  border-radius: 8px;
  overflow: hidden;
}

.score-football .publish-stats__body .stats-table__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.score-football .publish-stats__body .stats-table__header__title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-football .publish-stats__body .stats-table__header__title__logo {
  height: 32px;
  width: 32px;
  background: var(--blue-main);
  border-radius: 8px;
  overflow: hidden;
}

.score-football .publish-stats__body .stats-table__header__title__logo img {
  width: 100%;
}

.score-football .publish-stats__body .stats-table__header__title__text {
  margin-left: 12px;
  color: white;
  font-size: 14px;
  font-weight: 700;
}

.score-football .publish-stats__body .stats-table__header__value-selector {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
}

.score-football
  .publish-stats__body
  .stats-table__header__value-selector__icon {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-main);
  cursor: pointer;
}

.score-football
  .publish-stats__body
  .stats-table__header__value-selector__icon
  .icon {
  stroke: white;
  height: 14px;
}

.score-football .publish-stats__body .stats-table__header__value-selector__val {
  height: 32px;
  min-width: 32px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
}

.score-football .publish-stats__body .stats-table__body {
  background: white;
  display: grid;
  grid-template-columns: 168px 1fr;
  position: relative;
}

.score-football .publish-stats__body .stats-table__body__overlay {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-left: 30%;
  padding-right: 30%;
  padding-top: 64px;
}

.score-football .publish-stats__body .stats-table__body .column {
  border-right: solid 1px var(--light-3);
}

.score-football .publish-stats__body .stats-table__body .column.stats {
  overflow-x: auto;
}

.score-football .publish-stats__body .stats-table__body .column__header {
}

.score-football .publish-stats__body .stats-table__body .column__row {
  display: flex;
  flex-direction: row;
  /* border-bottom: solid 1px var(--light-2); */
}

.score-football .publish-stats__body .stats-table__body .column__row__data {
  height: 32px;
  width: 144px;
  display: flex;
  flex-direction: row;
  align-items: center;

  border-right: solid 1px var(--light-2);
  border-bottom: solid 1px var(--light-2);
  flex-shrink: 0;
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column__row__data__value {
  width: 100%;

  padding-left: 24px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column__row__data__value.hover:hover {
  background: var(--light-2);
  cursor: pointer;
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column__row__data.highlight {
  background: var(--blue-subtle);
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column__row__data__form {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  min-width: 0;
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column__row__data__form__input {
  flex-grow: 1;
  width: calc(100% - 64px);
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column__row__data__form__input
  input {
  height: 100%;
  border: none;
  width: 100%;
  padding-left: 24px;
  outline: none;
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column__row__data__form
  button {
  height: 32px;
  width: 32px;
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column__row__data__form
  button {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column__row__data__form
  button
  .icon {
  height: 14px;
  fill: black;
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column__row__data__form
  button
  .icon.submit {
  fill: var(--green-main);
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column__row__data__form
  button
  .icon.cancel {
  stroke: var(--light-4);
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column__row__data.bold {
  font-weight: 700;
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column__row__data.light {
  color: var(--light-4);
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column.players
  .column__row {
  width: 100%;
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column.players
  .column__row__data {
  width: 100%;
}

.score-football
  .publish-stats__body
  .stats-table__body
  .column
  .column__header
  .column__row__data {
  font-weight: 700;
}

.score-football .publish-stats .confirm-publish-stats {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(46, 52, 56, 0.88);
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-football .publish-stats .confirm-publish-stats__form {
  background: white;
  width: 30%;
  border-radius: 8px;
  overflow: hidden;
}

.score-football .publish-stats .confirm-publish-stats__form__text {
  padding: 16px;
  padding-top: 0;
}

.score-football .publish-stats .confirm-publish-stats__form__header {
  background: var(--blue-darker);
  font-weight: 700;
  height: 32px;
  display: flex;
  align-items: center;
  color: white;
  padding: 16px;
}

.score-football .publish-stats .confirm-publish-stats__form__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  padding-top: 8px;
}

.score-football
  .publish-stats
  .confirm-publish-stats__form__options
  .app-button {
  margin-left: 16px;
}

.score-football .confirm-delete-stats {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(46, 52, 56, 0.88);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
}

.score-football .confirm-delete-stats__form {
  background: white;
  width: 30%;
  border-radius: 8px;
  overflow: hidden;
}

.score-football .confirm-delete-stats__form__text {
  padding: 16px;
  /* padding-top: 0; */
  display: flex;
  flex-direction: row;
}

.score-football .confirm-delete-stats__form__text__timestamp {
  background: var(--blue-subtle);
  width: 36px;
  min-height: 36px;
  color: var(--blue-darker);
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding-top: 11px;
  border-radius: 8px;
}

.score-football .confirm-delete-stats__form__text__statements .statement {
  height: 36px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.score-football .confirm-delete-stats__form__header {
  background: var(--blue-darker);
  font-weight: 700;
  height: 32px;
  display: flex;
  align-items: center;
  color: white;
  padding: 16px;
}

.score-football .confirm-delete-stats__form__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  /* padding-top: 8px; */
  border-top: solid 1px var(--light-2);
}

.score-football .confirm-delete-stats__form__options .app-button {
  margin-left: 16px;
}

.score-football .penalty-shootout-card__rows__row {
  display: flex;
  flex-direction: row;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  border-bottom: solid 1px var(--light-2);
  height: 40px;
}

.score-football .penalty-shootout-card__rows__row__section {
  display: flex;
  flex-direction: row;
}

.score-football .penalty-shootout-card__rows__row__section:first-child {
}

.score-football .penalty-shootout-card__rows__row__section.away {
  flex-direction: row-reverse;
}

.score-football .penalty-shootout-card__rows__row__options {
  border-right: solid 1px var(--light-2);
  border-left: solid 1px var(--light-2);
  width: 40px;
}

.score-football .penalty-shootout-card__rows__row__options__option {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.score-football .penalty-shootout-card__rows__row__options__option:hover {
  background: var(--light-2);
}

.score-football .penalty-shootout-card__rows__row__body {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-football .penalty-shootout-card__rows__row__body__option {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: var(--light-4);
  /* text-decoration: underline; */
  cursor: pointer;
}

.score-football .penalty-shootout-card__rows__row__body__option:hover {
  color: var(--blue-lighter);
}

.score-football .penalty-shootout-card__rows__row__body__statement {
  font-weight: 700;
}

.score-football .penalty-shootout-card__rows__row__indicator {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: solid 1px var(--light-2);
  border-left: solid 1px var(--light-2);
}

.score-football .penalty-shootout-card__rows__row__indicator__icon {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: var(--light-2);
}

.score-football .penalty-shootout-card__rows__row__indicator__icon.green {
  background: var(--green-main);
}

.score-football .penalty-shootout-card__rows__row__indicator__icon.red {
  background: var(--red-main);
}
