.event-fixtures-mobile {
  padding: 16px;
}

.event-part-mobile {
  background: white;
  box-shadow: var(--elevation-mobile);
  border-radius: 8px;
  margin-bottom: 16px;
}

.event-part-mobile__title {
  padding: 8px;
  font-weight: 700;
  color: var(--dark-4);
  font-family: var(--nhg-display);
}
