.event-item-card {
  padding: 16px;
  margin-bottom: 24px;
}

.event-item-card .head {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.event-item-card .head .image {
  height: 44px;
  width: 44px;
  border-radius: 8px;
  background: var(--blue-subtle);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.event-item-card .head .image.black {
  background: black;
}

.event-item-card .head .image img {
  width: 100%;
}

.event-item-card .head .date-wrapper {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  background: var(--blue-subtle);
  height: 44px;
}

.event-item-card .head .date {
  height: 44px;
  width: 32px;

  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.event-item-card .head .date .month {
  font-weight: 400;
  letter-spacing: 0.6px;
  font-size: 8px;
  line-height: 10px;
  color: var(--blue-lighter);
}

.event-item-card .head .date .calendar-placeholder-icon {
  stroke: var(--light-4);
  height: 14px;
  margin-top: 4px;
}

.event-item-card .head .date .day {
  font-size: 14px;
  font-weight: 600;
  /* color: var(--dark-1); */
  line-height: 16px;
  margin-top: 2px;
  color: var(--blue-lighter);
  /* margin-bottom: 2px; */
}

.event-item-card .head .date .day.placeholder {
  color: var(--light-4);
  font-size: 8px;
}

.event-item-card .head .meta {
  padding-left: 16px;
}

.event-item-card .head .meta .primary {
  font-size: 16px;
  font-weight: 700;
  color: var(--dark-2);
  line-height: 22px;
  font-family: var(--nhg-display);
  /* margin-right: 40px; */
  /* overflow: hidden; */
  /* flex-grow: 1; */
  /* padding-right: 16px; */
}

.event-item-card .head .options {
  margin-left: auto;
}

.event-item-card .head .meta .secondary {
  font-size: 12px;
  font-weight: 400;
  color: var(--dark-4);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  /* justify-content: center; */
  /* line-height: 22px; */
}

.event-item-card .head .meta .secondary .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  margin-right: 4px;
}

.event-item-card .head .meta .secondary .icon .meta-icon {
  stroke: var(--light-4);
  height: 14px;
}

.event-item-card .event-item-card-body {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.event-item-card .event-item-card-body .image {
  height: 160px;
  background: var(--blue-subtle);
  border-radius: 8px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.event-item-card .event-item-card-body .image img {
  width: 100%;
}

.event-item-card .event-item-card-body .image .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #022e4b;
  z-index: 0;
  opacity: 0.01;
}

.event-item-card .event-item-card-body .blurb {
  margin-top: 16px;
  /* font-family: "Inter", sans-serif; */
}

.event-item-card .foot {
  margin-top: 16px;
  padding-top: 16px;
  border-top: solid 1px var(--light-2);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.event-item-card .foot .followers {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.event-item-card .foot .followers .follower {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: var(--blue-darkest);
  font-size: 10px;
  cursor: pointer;
  color: var(--blue-lighter);
  overflow: hidden;
}

.event-item-card .foot .followers .more {
  height: 32px;
  background: var(--blue-subtle);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 10px;
  font-weight: 700;
  margin-right: 16px;
  cursor: pointer;

  color: var(--blue-lighter);
}

.event-item-card .foot .organizedBy {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: auto;
  height: 32px;
  -ms-align-items: center;
  align-items: center;
  cursor: pointer;
}

.event-item-card .foot .organizedBy .desc {
  text-align: right;
  color: var(--dark-3);
  font-size: 10px;
}

.event-item-card .foot .organizedBy .desc .primary {
  font-weight: 700;
}

.event-item-card .foot .organizedBy .desc .secondary {
  font-weight: 100;
}

.event-item-card .foot .organizedBy .image {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.event-item-card .foot .organizedBy .image.black {
  background: black;
}

.event-item-card .foot .organizedBy .image img {
  width: 100%;
}
