.event-description-card {
  grid-column-start: 1;
  grid-column-end: 8;
  /* padding: 16px; */
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  /* padding: 0; */
}

.event-description-card .meta {
  display: flex;
}

.event-description-card .event-details {
  width: 60%;
  padding: 16px;
}

.event-description-card .event-details .blurb {
  margin-bottom: 24px;
}

.event-description-card .event-details .info-group {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.event-description-card .event-details .info-group .info-group-key {
  margin-right: auto;
  font-weight: 600;
  /* color: var(--dark-2); */
}

.event-description-card .event-details .info-group .info-group-value {
}

.event-description-card .event-venue {
  border-left: solid 1px var(--light-2);
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 16px;
}

.event-description-card .event-venue .map {
  flex-grow: 1;
  background: var(--blue-subtle);
  border-radius: 8px;
  margin-bottom: 16px;
}

.event-description-card .event-venue .org {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.event-description-card .event-venue .org .image {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-right: 16px;
}

.event-description-card .event-venue .org .desc {
}

.event-description-card .event-venue .org .desc .primary-text {
  font-weight: 600;
}

.event-description-card .event-venue .org .desc .secondary-text {
  color: var(--dark-4);
}

.event-description-card .event-roles {
  height: 104px;
  background: var(--blue-subtle);
}
