.notifications-list-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  /* margin-bottom: 16px; */
  /* padding-bottom: 16px; */
  border-bottom: solid 1px var(--light-2);
}

.notifications-list-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.notifications-list-item .image {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-right: 16px;
  flex-shrink: 0;
  overflow: hidden;
}

.notifications-list-item .text .primary-text {
  line-height: 16px;
}

.notifications-list-item .text .date {
  font-size: 10px;
  color: var(--dark-4);
  /* margin-top: 4px; */
}

.notifications-list-item .bold {
  font-weight: 700;
}
