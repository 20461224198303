.manage-event-details {
  padding-left: 48px;
  padding-right: 48px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 32px;
  padding-bottom: 48px;
}

.manage-event-details .app-card {
  margin-top: 32px;
  padding: 16px;
}

.manage-event-details .app-card .card-title {
  font-weight: 600;
  color: var(--dark-4);
}
