.manage-event-info {
}

.manage-event-info .info-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  /* font-size: 12px; */
}

.manage-event-info .info-group .info-group-key {
  font-weight: 600;
  color: var(--dark-1);
  margin-right: auto;
}

.manage-event-info .info-group .info-group-value {
}

.manage-event-info .info-group .info-group-edit-btn {
  height: 24px;
  width: 24px;
  background: var(--orange-subtle);
  border-radius: 8px;
  box-shadow: var(--elevation-1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 16px;
}

.manage-event-info .info-group .info-group-edit-btn .icon {
  fill: var(--orange-main);
  height: 14px;
}

.manage-event-info .info-group-form {
  margin-top: 16px;
}

.manage-event-info .info-group-form .info-group-form-body {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.manage-event-info .info-group-form .info-group-form-label {
  font-weight: 600;
  font-size: 10px;
}

.manage-event-info .info-group-form .info-group-form-input {
  flex-grow: 1;
}

.manage-event-info .info-group-form .info-group-form-input input {
  width: 100%;
  height: 24px;
}

.manage-event-info .info-group-form .info-group-form-input textarea {
  width: 100%;
  height: 80px;
}

.manage-event-info .info-group-form .options {
  display: flex;
  flex-direction: row;
}

.manage-event-info .info-group-form .options button {
  height: 24px;
  width: 24px;
  border: none;
  outline: none;
  margin-left: 8px;
  padding: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.manage-event-info .info-group-form .options button.submit {
  background: var(--green-main);
}

.manage-event-info .info-group-form .options button.cancel {
  background: var(--light-2);
  stroke: var(--dark-4);
}

.manage-event-info .info-group-form .options button .icon {
  height: 12px;
}
