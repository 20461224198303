.app-activity-container {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.app-activity-container .days {
  padding-top: 16px;
  width: 24px;
  font-size: 8px;
}

.app-activity-container .days .day {
  height: 8px;
  margin-bottom: 4px;
}

.app-activity-container .weeks {
  flex-grow: 1;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  overflow: auto;
}

.app-activity-container .weeks .column {
  margin-right: 4px;
}

.app-activity-container .weeks .column .month {
  height: 16px;
  font-size: 8px;
  width: 8px;
  overflow: visible;
}

.app-activity-container .weeks .column .cell {
  height: 8px;
  width: 8px;
  background: var(--blue-subtlest);
  margin-bottom: 4px;
}
