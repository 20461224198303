.badge {
  height: 20px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.badge .badge-label {
  font-size: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 800;
  letter-spacing: 0.2px;
}

.badge.info {
  background: var(--blue-subtle);
  color: var(--blue-darker);
}

.badge.success {
  background: var(--green-subtle);
  color: var(--green-darker);
}

.badge.warning {
  background: var(--orange-subtle);
  color: var(--orange-darker);
}

.badge.danger {
  background: var(--red-subtle);
  color: var(--red-darker);
}

.badge.reminder {
  background: var(--yellow-subtle);
  color: var(--yellow-darker);
}

.badge.misc {
  background: var(--purple-subtle);
  color: var(--purple-darker);
}

.badge.unavailable {
  background: var(--light-2);
  color: var(--dark-4);
}

.badge.offline {
  background: var(--light-1);
  border: solid 1px var(--light-4);
  color: var(--dark-2);
}

.badge.primary {
  background: var(--blue-subtle);
  color: var(--blue-main);
}
