.delete-video-form {
}

.delete-video-form__desc {
}

.delete-video-form__options {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.delete-video-form__options__option {
  margin-right: 16px;
  font-weight: 700;
  font-size: 10px;
  background: var(--light-2);
  border-radius: 4px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.delete-video-form__options__option.delete {
  padding-left: 8px;
  padding-right: 8px;
  background: var(--red-main);
  color: white;
}
