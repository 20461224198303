.app-header .search {
  /* width: 320px; */
  grid-column-start: 2;
  grid-column-end: 3;
  position: relative;
  /* z-index: 10; */
  /* display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center; */
  /* flex-grow: 1; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* position: absolute; */
  /* width: 50%; */
  /* margin-right: auto; */
}

.app-header .search .search-wrapper {
  /* width: 100%; */
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  position: relative;
  /* overflow-y: auto; */
}

.app-header .search .search-wrapper .app-input {
  width: 100%;
  /* background: var(--blue-subtlest); */
  border-radius: 8px;
}

.search .input-wrapper {
  box-shadow: none;
  border: solid 1px var(--light-3);
  /* box-sizing: border-box; */
  height: 32px;
  /* border-radius: 4px; */
}

.app-header .search .app-search-menu {
  /* width: 100%; */
  width: 100%;
  box-shadow: var(--elevation-5);
  margin-top: -4px;
  font-size: 12px;
  max-height: 640px;
  overflow-y: auto;
  z-index: 20;
  position: relative;
}

.app-header .search .app-search-menu .search-results-section {
  /* padding: 16px;
    padding-top: 8px;
    padding-bottom: 8px; */
  padding-top: 8px;
}

.app-header .search .app-search-menu .search-results-section__title {
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: var(--dark-4);
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.app-header
  .search
  .app-search-menu
  .search-results-section__title
  .search-results-section__title__show-more {
  color: var(--blue-lighter);
  margin-left: auto;
  cursor: pointer;
}

.app-header .search .app-search-menu .search-results-section__items {
}

.app-header .search .app-search-menu .search-results-section__items__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 16px; */
  transition: all 0.2s ease-out;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  color: var(--dark-1);
  /* font-weight: 700; */
}

.app-header
  .search
  .app-search-menu
  .search-results-section__items__item:hover {
  background: var(--light-2);
}

.app-header
  .search
  .app-search-menu
  .search-results-section__items__item__image {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.app-header
  .search
  .app-search-menu
  .search-results-section__items__item__image
  img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.app-header
  .search
  .app-search-menu
  .search-results-section__items__item__desc {
}

.app-header
  .search
  .app-search-menu
  .search-results-section__items__item__desc__primary {
  font-weight: 600;
  color: var(--dark-2);
}

.app-header
  .search
  .app-search-menu
  .search-results-section__items__item__desc__secondary {
  color: var(--dark-3);
  font-size: 10px;
}

.app-header .search .app-search-menu .paged-view-wrapper__tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  padding-left: 16px;
}

.app-header .search .app-search-menu .paged-view-wrapper__tabs__tab {
  height: 24px;
  margin-right: 16px;
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: var(--dark-4);
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}

.app-header .search .app-search-menu .paged-view-wrapper__tabs__tab:hover {
  color: var(--dark-3);
}

.app-header .search .app-search-menu .paged-view-wrapper__tabs__tab.active {
  color: var(--blue-main);
  border-bottom: solid 1px var(--blue-main);
}

.app-header
  .search
  .app-search-menu
  .search-results-section__footer__show-more {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-top: solid 1px var(--light-2);
  font-size: 10px;
  font-weight: 700;
  color: var(--light-4);
  cursor: pointer;
}

.app-header
  .search
  .app-search-menu
  .search-results-section__footer__show-more:hover {
  color: var(--dark-4);
}

.es-search-placeholder-text {
  font-size: 12px;
  font-family: var(--nhg);
  color: var(--dark-4);
}

.es-app-search-menu-wrapper {
  margin-top: -40px;
}
