.account-registrations {
  height: 100%;
  overflow: auto;
}

.account-registrations__item {
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.account-registrations__item .image {
  height: 64px;
  width: 64px;
  border-radius: 4px;
  background-color: var(--blue-subtle);
}

.account-registrations__item .text {
  padding-left: 16px;
}

.account-registrations__item .text .primary-text {
  font-weight: 700;
  font-family: var(--nhg-display);
  font-size: 16px;
  margin-bottom: 4px;
}

.account-registrations__item .text .secondary-text {
  color: var(--dark-3);
  margin-bottom: 4px;
}

.account-registrations__item .text .date {
  font-size: 10px;
  color: var(--dark-4);
}
