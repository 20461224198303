.create-menu {
  background: white;
  margin-top: 10px;
  box-shadow: var(--elevation-6);
  border-radius: 8px;
  /* border-top-right-radius: 0; */
  overflow: hidden;
  width: 400px;
  z-index: 5;
  position: relative;
  /* padding: 16px; */
}

.create-menu .create-menu-item {
  /* margin-bottom: 16px; */
  padding: 16px;
  border-bottom: solid 1px var(--light-2);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.create-menu .create-menu-item .icon {
  height: 56px;
  width: 56px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  margin-right: 16px;
}

.create-menu .create-menu-item .text {
}

.create-menu .create-menu-item .text .primary {
  /* font-family: var(--nhg-display); */
  font-weight: 700;
  color: var(--dark-1);
}

.create-menu .create-menu-item .text .secondary {
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  color: var(--dark-2);
}
