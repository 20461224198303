.directory-card {
  padding: 0;
  overflow: hidden;
}

.directory-card .header {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  /* margin-bottom: 16px; */
  padding: 16px;
  padding-bottom: 12px;
  padding-left: 24px;
  /* padding-top: 20px; */
  /* padding-right: 24px; */
}

.directory-card .header .text {
  font-size: 20px;
  font-weight: 700;
  font-family: var(--nhg-display);
  color: var(--dark-2);
  margin-left: 16px;
}

.directory-card .info {
  margin-left: 24px;
  margin-right: 40px;
  /* font-size: 12px; */
  color: var(--dark-3);
  /* padding-bottom: 8px; */
}

.directory-card .info p {
  margin: 0;
  margin-bottom: 16px;
  line-height: 19px;
}

.directory-card .user-pages {
  padding-top: 16px;
  padding-bottom: 24px;
  border-top: solid 1px var(--light-2);
}

.directory-card .user-pages .user-pages-title {
  padding-left: 24px;
  padding-right: 24px;
  font-weight: 700;
  color: var(--dark-4);
  font-family: var(--nhg-display);
}

.directory-card .user-pages .user-page {
  padding-left: 24px;
  padding-right: 24px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-top: 16px;
}

.directory-card .user-pages .user-page .image {
  height: 48px;
  width: 48px;
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
}

.directory-card .user-pages .user-page .image.black {
  background: black;
}

.directory-card .user-pages .user-page .image img {
  width: 100%;
}

.directory-card .user-pages .user-page .meta {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  /* font-family: "Inter", sans-serif; */
}

.directory-card .user-pages .user-page .meta .primary {
  font-weight: 700;
  margin-bottom: 4px;
  color: var(--dark-1);
}

.directory-card .user-pages .user-page .meta .secondary {
  color: var(--dark-2);
}
