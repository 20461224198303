.edit-profile-name__wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-profile-name {
  width: 320px;
}

.edit-profile-name__header {
  font-weight: 600;
  font-family: var(--nhg-display);
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: var(--dark-2);
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px var(--light-2);
  padding-left: 16px;
  padding-right: 16px;
}

.edit-profile-name__header__title {
}

.edit-profile-name__header__dismiss {
  stroke: var(--dark-4);
  cursor: pointer;
  margin-left: auto;
}

.edit-profile-name__header__dismiss__icon {
  height: 14px;
}

.edit-profile-name__form {
}

.edit-profile-name__form__group {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.edit-profile-name__form__group__label {
  font-size: 10px;
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-2);
  margin-bottom: 4px;
}

.edit-profile-name__form__group__input {
  height: 32px;
  width: 100%;
  outline: none;
  border: solid 1px var(--light-3);
  border-radius: 4px;
  padding-left: 8px;
}

.edit-profile-name__form__group__input:focus {
  border-color: var(--blue-main);
}

.edit-profile-name__form__submit {
  padding: 16px;
  padding-top: 12px;
}

.edit-profile-name__form__submit__btn {
  border: none;
  width: 100%;
}
