.event-results-summary-mobile {
  /* padding: 12px; */
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.event-results-summary-mobile__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
  padding: 12px;
}

.event-results-summary-mobile__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-weight: 600;
  color: var(--dark-4);
  border-top: solid 1px var(--light-2);
}
