.home-page-mobile__profile-card__profile-requests {
  border-left: solid 4px var(--orange-main);
  background: var(--orange-subtle);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  /* margin-bottom: 16px; */
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 8px;
}

.home-page-mobile__profile-card__profile-requests__text {
  font-family: var(--nhg-display);
  color: var(--orange-darker);
  font-weight: 700;
}

.home-page-mobile__profile-card__profile-requests__link {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--orange-main);
  border: solid 1px var(--orange-main);
  border-radius: 8px;
  margin-left: auto;
  font-family: var(--nhg-display);
  font-weight: 600;
  padding-left: 24px;
  padding-right: 24px;
}
