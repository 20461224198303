.fixture-timeline {
  padding: 16px;
  grid-column-start: 1;
  grid-column-end: 13;
  margin-top: 32px;
}

.fixture-timeline__home {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-timeline__home .logo {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.fixture-timeline__home .logo img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.fixture-timeline__home .desc {
  font-weight: 700;
  color: var(--dark-2);
}

.fixture-timeline__away {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-timeline__away .logo {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.fixture-timeline__away .logo img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.fixture-timeline__away .desc {
  font-weight: 700;
  color: var(--dark-2);
}

.fixture-timeline__timeline {
  margin-top: 32px;
  margin-bottom: 32px;
}

.fixture-timeline__timeline__indicators {
}

.fixture-timeline__timeline__timestamps {
  border-radius: 8px;
  /* overflow: hidden; */
  display: flex;
  flex-direction: row;
  position: relative;
}

.fixture-timeline__timeline__timestamps__period {
  height: 14px;
  background: var(--green-main);
  /* position: relative; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.fixture-timeline__timeline__timestamps__period .period-end-marker {
  background: var(--green-darker);
  color: white;
  border-radius: 100%;
  font-size: 8px;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: translateX(12px); */
  /* margin-right: 1px; */
}

.fixture-timeline__timeline__timestamps .stamp {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 14px;
  font-size: 8px;
  color: white;
}

.fixture-timeline__timeline__timestamps .stamp .stamp-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixture-timeline .tooltip-stats {
  width: 128px;
  overflow: hidden;
}

.fixture-timeline .tooltip-stats__item {
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.fixture-timeline .tooltip-stats__item__meta {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-timeline .tooltip-stats__item__meta__timestamp {
  color: var(--dark-4);
  margin-right: 8px;
}

.fixture-timeline .tooltip-stats__item__meta__stat-type {
}

.fixture-timeline .tooltip-stats__item__player {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
