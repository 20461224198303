.organization-events-mobile {
  padding-left: 16px;
  padding-right: 16px;
}

.organization-events-mobile .show-more-container {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
