.app-events-calendar-header {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 16px;
  border-bottom: solid 1px var(--light-2);
  padding-bottom: 16px;
  -ms-align-items: center;
  align-items: center;
  font-family: var(--nhg-display);
}

.app-events-calendar-header .app-events-calendar-title {
  font-weight: 700;
  color: var(--dark-4);
}

.app-events-calendar-header .app-events-calendar-scroll {
  margin-left: auto;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.app-events-calendar-header .app-events-calendar-scroll .prev {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.app-events-calendar-header .app-events-calendar-scroll .current {
  font-weight: 700;
  margin-left: 8px;
  margin-right: 8px;
}

.app-events-calendar-header .app-events-calendar-scroll .next {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.app-events-calendar-calendar {
  border-bottom: solid 1px var(--light-2);
  /* margin-bottom: 16px; */
}

.app-events-calendar-calendar .calendar-header {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

  margin-bottom: 16px;
}

.app-events-calendar-calendar .calendar-header .calendar-header-item {
  width: 14.2%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  color: var(--dark-4);
}

.app-events-calendar-calendar .calendar-body {
}

.app-events-calendar-calendar .calendar-body .calendar-row {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 12px;
}

.app-events-calendar-calendar .calendar-body .calendar-row .calendar-row-item {
  width: 14.2%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.app-events-calendar-calendar
  .calendar-body
  .calendar-row
  .calendar-row-item
  .calendar-row-item-data {
  height: 24px;
  width: 24px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
}

.app-events-calendar-calendar
  .calendar-body
  .calendar-row
  .calendar-row-item
  .calendar-row-item-data.has-event {
  /* border: solid 1px var(--blue-lighter); */
}

.app-events-calendar-calendar
  .calendar-body
  .calendar-row
  .calendar-row-item
  .calendar-row-item-data.highlighted {
  background: var(--green-subtle);
}

.app-events-calendar-calendar
  .calendar-body
  .calendar-row
  .calendar-row-item
  .calendar-row-item-data.selected {
  background: var(--blue-main);
  border-radius: 8px;
  color: white;
  font-weight: 700;
}

.app-events-calendar-calendar
  .calendar-body
  .calendar-row
  .calendar-row-item
  .calendar-row-item-data
  .event-indicator {
  position: absolute;
  top: -1.5px;
  right: -1.5px;
  width: 0;
  height: 0;
  /* border-style: solid; */
  /* border-width: 0 6px 6px 0; */
  /* border-color: transparent var(--green-main) transparent transparent; */
  height: 3px;
  width: 3px;
  border-radius: 100%;
  background: var(--green-main);
}
