.fixture-summary .match-highlights-card {
  padding: 16px;
  margin-bottom: 32px;
}

.fixture-summary .match-highlights-card .video {
  min-height: 320px;
  background: var(--dark-1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixture-summary .match-highlights-card .video video {
  width: 100%;
  max-height: 100%;
  /* width: auto; */
  height: auto;
}
