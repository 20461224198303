.onboarding-prompt {
  height: 120px;
  background: var(--light-1);
  border-bottom: solid 1px var(--light-2);
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  /* color: var(--light-1); */
  /* font-weight: 600; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  /* justify-content: center; */
  font-size: 12px;
  /* text-decoration: underline; */
  box-shadow: var(--elevation-2);
  padding-left: 48px;
  padding-right: 48px;
  z-index: 4;
  /* padding-right: 48px; */
}

.onboarding-prompt__meta {
}

.onboarding-prompt__options {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboarding-prompt__title {
  font-weight: 700;
  margin-bottom: 8px;
  font-size: 20px;
  font-family: var(--nhg-display);
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--dark-2);
}

.onboarding-prompt__title__progress {
  /* margin-left: auto; */
  margin-top: auto;
}

.onboarding-prompt__title__progress__container {
  width: 320px;
  background: var(--light-3);
  height: 4px;
  /* border-radius: 8px; */
  position: relative;
  overflow: hidden;
}

.onboarding-prompt__title__progress__container__fill {
  position: absolute;
  height: 100%;
  left: 0;
  width: 90%;
  /* border-radius: 8px; */
  background: var(--green-main);
}

.onboarding-prompt__title__progress__container__text {
  font-size: 8px;
  position: absolute;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* letter-spacing: 0.4px; */
  color: var(--light-1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.onboarding-prompt__desc {
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.onboarding-prompt__desc__line {
  margin-bottom: 4px;
}

.onboarding-prompt__desc__line__link {
  color: var(--blue-main);
}

.onboarding-prompt__cta {
  background: var(--blue-main);
  border-radius: 8px;
  margin-left: auto;
  height: 40px;
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  overflow: hidden;
  position: relative;
}

.onboarding-prompt__cta.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.onboarding-prompt__cta__progress {
  position: absolute;
  height: 100%;
  /* width: %; */
  left: 0%;
  background: var(--blue-darker);
  transition: all 1.5s ease-out;
}

.onboarding-prompt__cta__text {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.onboarding-prompt__cta__primary {
  font-weight: 700;
  /* margin-bottom: 4px; */
  /* font-size: 20px; */
  font-family: var(--nhg-display);
  letter-spacing: 0.4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-bottom: auto; */
  /* flex-grow: 1; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}

.onboarding-prompt__cta__secondary {
  /* font-size: 10px; */
  /* font-weight: 400; */
  /* letter-spacing: 0.4px; */
  letter-spacing: 0.4px;
  font-size: 8px;
  font-weight: 400;
  font-family: var(--nhg);
  margin-top: 4px;
}

.onboarding-prompt__estimate {
  margin-top: 12px;
  font-size: 10px;
  color: var(--dark-3);
}

.onboarding-prompt__title__icon {
  stroke: var(--blue-main);
  height: 26px;
  margin-right: 12px;
}
