.organization-overview .description-card {
  margin-top: 32px;
  padding: 16px;
  /* display: flex; */
  /* flex-direction: column; */
}

.organization-overview .description-card .description-card-title {
  font-weight: 600;
  color: var(--dark-3);
  margin-bottom: 16px;
}

.organization-overview .description-card .description-card-types {
  display: flex;
  flex-direction: row;
  /* margin-bottom: 16px; */
  flex-wrap: wrap;
}

.organization-overview .description-card .description-card-types .app-chip {
  margin-right: 12px;
  background: var(--blue-subtle);
  color: var(--blue-main);
  margin-bottom: 12px;
}

.organization-overview
  .description-card
  .description-card-types
  .app-chip.editable {
  padding-right: 0;
  cursor: pointer;
}

.organization-overview
  .description-card
  .description-card-types
  .app-chip.edit-type {
  background: var(--orange-subtle);
  color: var(--orange-main);
}

.organization-overview
  .description-card
  .description-card-types
  .app-chip.add-type {
  background: white;
  color: var(--blue-main);
  border: solid 1px var(--blue-main);
}

.organization-overview .description-card .edit-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.organization-overview .description-card .edit-org-button {
  height: 24px;
  background: var(--orange-subtle);
  color: var(--orange-main);
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 10px;
}

.organization-overview .description-card .description-card-content {
  /* font-family: "Inter", sans-serif; */
  line-height: 19px;
  font-weight: 400;
  margin-top: 8px;
}

.organization-overview .description-card .update-organization-description-form {
}

.organization-overview
  .description-card
  .update-organization-description-form
  textarea {
  width: 100%;
  font-family: var(--nhg);
  height: 80px;
  border: solid 1px var(--light-3);
  border-radius: 8px;
  padding: 8px;
  outline: none;
  font-size: 12px;
}

.organization-overview
  .description-card
  .update-organization-description-form
  textarea:focus {
  border: solid 1px var(--blue-main);
}

.organization-overview
  .description-card
  .update-organization-description-form
  .options {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.organization-overview
  .description-card
  .update-organization-description-form
  .options
  .char-count {
  margin-right: auto;
  font-size: 10px;
  font-weight: 700;
  color: var(--dark-4);
}

.organization-overview
  .description-card
  .update-organization-description-form
  .options
  .char-count.error {
  color: var(--red-main);
}

.organization-overview .description-card .update-types-container {
  margin-bottom: 16px;
}

.organization-overview
  .description-card
  .update-types-container
  .update-types-form {
  display: flex;
  flex-direction: row;
}

.update-types-form .dismiss-btn {
  margin-left: 16px;
}
