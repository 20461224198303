.directory-page {
  display: grid;
  padding-left: 48px;
  padding-right: 48px;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 32px;
  padding-top: 32px;
}

.directory-page .sticky-wrapper {
  position: -webkit-sticky;
  position: sticky;
  padding-bottom: 32px;
}

.directory-page .sticky-wrapper.sticky-top {
  top: 32px;
}

.directory-page .sticky-wrapper.sticky-bottom {
  bottom: 0;
}

.directory-page .left-column {
  grid-column-start: 1;
  grid-column-end: 4;
  /* position: relative; */
  /* height: fit-content; */
}

.directory-page .left-column.sticky-bottom {
  display: flex;
  flex-direction: column-reverse;
}

.directory-page .middle-column {
  grid-column-start: 4;
  grid-column-end: 10;
}

.directory-page .right-column {
  grid-column-start: 10;
  grid-column-end: 13;
  border-left: solid 1px var(--light-3);
  padding-left: 28px;
}

.directory-page .right-column.sticky-bottom {
  display: flex;
  flex-direction: column-reverse;
}

.directory-page .card-section .card-section-title {
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  margin-bottom: 16px;
  font-weight: 700;
  color: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  border-bottom: solid 1px var(--light-4);
  padding-bottom: 12px;
}

.directory-page .card-section .card-section-title .reset {
  margin-left: auto;
  color: var(--red-lighter);
}

.directory-page .card-section .app-card {
  /* margin-bottom: 24px; */
}

.directory-page .card-section .app-card:last-child {
  margin-bottom: 0px;
}

.directory-page .profile-card {
  padding-left: 0;
  padding-right: 0;
}

.directory-page .profile-card .profile-link {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  /* margin-bottom: 16px; */
}

.directory-page .profile-card .image {
  height: 48px;
  width: 48px;
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-right: 16px;
}

.directory-page .profile-card .meta {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  font-family: "Inter", sans-serif;
}

.directory-page .profile-card .meta .primary {
  font-weight: 700;
  margin-bottom: 4px;
}

.directory-page .profile-card .meta .secondary {
  color: var(--dark-2);
}

.directory-page .search-card {
  padding: 0;
  padding-left: 16px;
  padding-right: 16px;
  height: 40px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 20px;
}

.directory-page .filter-group {
  margin-bottom: 24px;
}

.directory-page .filter-group .filter-group-title {
  font-weight: 700;
  color: var(--dark-2);
  margin-bottom: 8px;
}

.directory-page .filter-group .filter-group-input {
}

.directory-page .filter-group .filter-group-input .app-input .input-wrapper {
  background: white;
}

.directory-page .filter-group .filter-group-applied {
  /* padding-top: 16px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.directory-page .filter-group .filter-group-applied .app-chip {
  margin-right: 12px;
  margin-top: 12px;
  cursor: pointer;
  background: var(--blue-main);
  color: var(--light-1);
  padding-right: 4px;
}

.directory-page .show-more-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
}

.directory-page .location-input-wrapper .input-wrapper {
  height: 32px;
  box-shadow: var(--elevation-1);
  border: solid 1px var(--light-2);
  background: white;
}

.directory-page .location-input-wrapper .input-wrapper input {
  padding-left: 16px;
}

/* .directory-page .page */
