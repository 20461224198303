.fixture-awards-card {
  padding: 16px;
  margin-top: 32px;
}

.fixture-awards-card__title {
  font-weight: 700;
  color: var(--dark-2);
}

.fixture-awards-card__award-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.fixture-awards-card__award-type__img {
  height: 48px;
  width: 48px;
  background: var(--blue-subtle);
  border-radius: 4px;
  overflow: hidden;
}

.fixture-awards-card__award-type__img img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.fixture-awards-card__award-type__desc {
  padding-left: 16px;
  flex-grow: 1;
}

.fixture-awards-card__award-type__desc__primary {
  margin-bottom: 2px;
  font-size: 10px;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-4);
  /* letter-spacing: 0.4px; */
}

.fixture-awards-card__award-type__desc__secondary {
  font-weight: 700;
  color: var(--dark-2);
}

.fixture-awards-card__award-type__desc__tertiary {
  color: var(--dark-4);
}

.fixture-awards-card__award-type__option__icon {
  stroke: var(--red-main);
  height: 14px;
}

.fixture-awards-card .add-event-award-form {
  margin-bottom: 0;
}
