.auth-form {
  width: 100%;
  padding-right: 16.33%;
}

.auth-form .form-group {
  margin-top: 40px;
  position: relative;
}

.auth-form .form-group .input {
  height: 40px;
  font-size: 16px;
  border: none;
  border-bottom: solid 2px var(--light-4);
  width: 100%;
  outline: none;
  padding-left: 32px;
  /* font-family:var(---nhg) ; */
}
.auth-form .form-group .input:focus {
  border-bottom-color: var(--blue-main);
}

.auth-form .form-group label {
  position: absolute;
  left: 32px;
  bottom: 12px;
  font-size: 16px;
  color: var(--dark-4);
  transition: all 0.2s ease-out;
  pointer-events: none;
}

.auth-form .form-group .input:focus + label {
  font-size: 10px;
  bottom: 38px;
  left: 2px;
}

.auth-form .form-group .input:active + label,
.auth-form .form-group .input.hasValue + label {
  font-size: 10px;
  bottom: 38px;
  left: 2px;
}

.auth-form .form-group .input.invalid {
  border-bottom-color: var(--red-main);
}

.auth-form .form-group .static-icon {
  position: absolute;
  left: 0;
  bottom: 11px;
  color: var(--dark-4);
  transform: all 0.4s ease-out;
  pointer-events: none;
}

.auth-form .form-group .static-icon-right {
  position: absolute;
  right: 0;
  bottom: 11px;
  color: var(--dark-4);
  transform: all 0.4s ease-out;
  /* pointer-events: none; */
  cursor: pointer;
}

.auth-form .form-group .assistive {
  position: absolute;
  left: 0;
  top: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.auth-form .form-group .assistive .assistive-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-form .form-group .assistive .assistive-text {
  font-size: 10px;
  color: var(--red-main);
  font-weight: 600;
  margin-left: 8px;
}

.auth-form .submit {
  border: none;
  margin-top: 40px;
  width: 100%;
  /* height: 40px;
  font-size: 16px;
  margin-top: 40px;
  border-radius: 8px;
  border: none;
  background: var(--green-main);
  color: white;
  font-weight: 800;
  font-family: var(--nhg-display);
  width: 100%; */
  transition: all 0.2s ease-out;
}

.auth-form .terms {
  margin-top: 40px;
  font-size: 14px;
  color: var(--dark-4);
}
