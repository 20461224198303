.organization-overview .organization-overview-posts {
  margin-top: 32px;
}

.organization-overview .organization-overview-posts .app-post-card {
  margin-bottom: 32px;
}

.organization-overview-posts .show-more-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 32px; */
  padding-bottom: 32px;
}

.organization-overview-posts .show-more-container .show-more-button {
  width: 104px;
}
