.profile-stat-percentage-summary {
  grid-column-start: 1;
  grid-column-end: 5;
  margin-top: 32px;

  /* padding: 16px; */
}

.profile-stat-percentage-summary__row {
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px var(--light-2);
}

.profile-stat-percentage-summary__row__section {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 33%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 16px;
  /* border-right: solid 1px var(--light-2); */
}

.profile-stat-percentage-summary__row__section:last-child {
  border: none;
}

.profile-stat-percentage-summary__row__section__value {
  font-size: 24px;
  line-height: 20px;
  font-family: var(--din);
  font-weight: 600;
  color: var(--dark-2);
  margin-right: 8px;
}

.profile-stat-percentage-summary__row__section__label {
  font-family: var(--nhg-display);
  color: var(--blue-lighter);
  font-weight: 700;
}
