.ScoringApp-badminton__scoring__box-score {
  display: flex;
  flex-direction: row;
  background: white;
  border-radius: 8px;
  margin-top: 12px;
  overflow: hidden;
}

.ScoringApp-badminton__scoring__box-score__column {
}

.ScoringApp-badminton__scoring__box-score__column__header {
  height: 32px;
  background: var(--blue-darker);
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-weight: 600;
}

.ScoringApp-badminton__scoring__box-score__column__row {
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  min-width: 80px;
  border-bottom: solid 1px var(--light-2);
}

.ScoringApp-badminton__scoring__box-score__column__row__img {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  border-radius: 4px;
  overflow: hidden;
  margin-right: 8px;
}
