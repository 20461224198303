.app-header .account {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-left: 16px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  color: var(--blue-main);
}

.app-header .account img {
  width: 100%;
}

.app-header .account-button-menu {
  background: white;
  box-shadow: var(--elevation-6);
  margin-top: 10px;
  border-radius: 8px;
  overflow: hidden;
  z-index: 5;
  position: relative;
}

.app-header .account-button-menu .account-button-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-3);
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.app-header .account-button-menu .account-button-menu-item:hover {
  background: var(--light-2);
}

.app-header .account-button-menu .account-button-menu-item .icon {
  margin-right: 12px;
}

.app-header .account-button-menu .account-button-menu-item .desc {
}

.app-header
  .account-button-menu
  .account-button-menu-item
  .icon
  .account-button-menu-icon {
  stroke: var(--light-4);
  height: 20px;
  margin-top: 2px;
}

.app-header .account-button-menu .account-button-menu-item.disabled {
  background: var(--light-2);
  color: var(--light-4);
  /* cursor: default; */
  pointer-events: none;
}
