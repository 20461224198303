.account-page {
  display: grid;
  padding-left: 48px;
  padding-right: 48px;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 32px;
  padding-top: 32px;
  height: 100%;
  overflow: hidden;
}

.account-page__nav {
  grid-column-start: 1;
  grid-column-end: 4;
}

.account-page__nav__card {
}

.account-page__nav__card__profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom: solid 1px var(--light-2);
}

.account-page__nav__card__profile__img {
  height: 48px;
  width: 48px;
  border-radius: 8px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  overflow: hidden;
}

.account-page__nav__card__profile__name {
  padding-left: 16px;
}

.account-page__nav__card__profile__name__primary {
  font-size: 16px;
  font-weight: 700;
}

.account-page__nav__card__profile__name__secondary {
  margin-top: 4px;
  margin-bottom: 4px;
  color: var(--dark-4);
}

.account-page__nav__card__menu {
}

.account-page__nav__card__menu__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  /* transition: all 0.2s ease-out; */
  color: var(--dark-3);
}

.account-page__nav__card__menu__item:hover {
  color: var(--blue-main);
}

.account-page__nav__card__menu__item.selected {
  font-weight: 700;
  color: var(--blue-main);
  border-right: solid 4px var(--blue-main);
}

.account-page__content {
  grid-column-start: 4;
  grid-column-end: 13;
  height: 100%;
  overflow: hidden;
}
