.account-saved-posts {
  height: 100%;
  overflow: auto;
}

.account-saved-posts__wrapper {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.account-saved-posts .app-post-card {
  margin-bottom: 24px;
  grid-column-start: 1;
  grid-column-end: 6;
}
