.app-tab-selector {
  height: 32px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border: solid 1px var(--blue-subtle);
  border-radius: 8px;
  /* margin-bottom: 32px; */
  overflow: hidden;
}

.app-tab-selector .tab-selector-option {
  width: 50%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  color: var(--dark-4);
  height: 100%;
  font-family: var(--nhg-display);
  font-weight: 700;
  transition: all 0.2s ease-out;
}

.app-tab-selector .tab-selector-option.selected {
  background: var(--blue-main);
  color: white;
}
