.fixture-page-mobile {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding-top: 48px;
}

.fixture-page-mobile__nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 48px;
  background: white;
  /* box-shadow: 0px 0px 3px rgba(40, 41, 61, 0.08); */
  box-shadow: 0px 0px 4px 1px rgba(40, 41, 61, 0.08);
  /* padding-left: 16px; */
  padding-right: 16px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-page-mobile__nav__back-btn {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixture-page-mobile__event {
  background: white;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--nhg-display);
  color: var(--dark-4);
  font-size: 10px;
  font-weight: 600;
  border-bottom: solid 1px var(--light-2);
}

.fixture-page-mobile__header {
  background: var(--light-1);
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 8px;
  z-index: 5;
  position: relative;
}

.fixture-page-mobile__header__participant {
  display: flex;
  align-items: center;
}

.fixture-page-mobile__header__participant.home {
  flex-direction: row;
}

.fixture-page-mobile__header__participant.away {
  flex-direction: row-reverse;
}

.fixture-page-mobile__header__participant__meta {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.fixture-page-mobile__header__participant.away
  .fixture-page-mobile__header__participant__meta {
  align-items: flex-end;
}

.fixture-page-mobile__header__participant__meta__image {
  height: 44px;
  width: 44px;
  border-radius: 8px;
  background: var(--blue-subtle);
  overflow: hidden;
}

.fixture-page-mobile__header__participant__meta__image img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.fixture-page-mobile__header__participant__meta__name {
  margin-top: 12px;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-2);
}

.fixture-page-mobile__header__participant__result {
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-2);
  font-size: 20px;
  margin-bottom: 25px;
}

.fixture-page-mobile__header__seperator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: 700;
  color: var(--dark-4);
  margin-bottom: 25px;
}

.fixture-page-mobile__highlights {
  padding: 16px;
  background: var(--light-1);
  border-top: solid 1px var(--light-2);
  z-index: 3;
  position: relative;
}

.fixture-page-mobile__highlights__row {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 8px;
  margin-bottom: 4px;
}

.fixture-page-mobile__highlights__row__meta {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-page-mobile__highlights__row__meta.away {
  flex-direction: row-reverse;
}

.fixture-page-mobile__highlights__row__meta__icon {
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixture-page-mobile__highlights__row__meta.home
  .fixture-page-mobile__highlights__row__meta__icon {
  margin-right: 8px;
}

.fixture-page-mobile__highlights__row__meta.away
  .fixture-page-mobile__highlights__row__meta__icon {
  margin-left: 8px;
}

.fixture-page-mobile__highlights__row__meta__icon__svg {
  fill: var(--dark-4);
  height: 14px;
}

.fixture-page-mobile__highlights__row__meta__name {
  font-size: 8px;
}

.fixture-page-mobile__highlights__row__timestamp {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--nhg-display);
  font-weight: 600;
  font-size: 8px;
  color: var(--dark-4);
}

.fixture-page-mobile__tabs {
  display: flex;
  flex-direction: row;
  height: 40px;
  background: var(--light-1);
  border-top: solid 1px var(--light-2);
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 16px;
  /* box-shadow: var(--elevation-mobile); */
  box-shadow: 0px 0px 4px 1px rgba(40, 41, 61, 0.08);
  z-index: 2;
  position: relative;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: -2px;
}

.fixture-page-mobile__tabs__tab {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--dark-4);
  margin-right: 24px;
  font-size: 10px;
}

.fixture-page-mobile__tabs__tab.active {
  font-weight: 600;
  color: var(--dark-2);
  border-bottom: solid 2px var(--blue-main);
}
