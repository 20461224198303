.onboard-page-mobile {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 48px;
  background: white;
}

.onboard-page-mobile__nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 48px;
  background: white;
  /* box-shadow: 0px 0px 3px rgba(40, 41, 61, 0.08); */
  box-shadow: 0px 0px 4px 1px rgba(40, 41, 61, 0.08);
  /* padding-left: 16px; */
  padding-right: 16px;
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.onboard-page-mobile__nav__back-btn {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.onboarding-form-mobile__graphic {
}

.onboarding-form-mobile__logo-bottom {
}

.onboard-page-mobile__categories__subtitle {
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-3);
}

.onboard-page-mobile__categories__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.onboard-page-mobile__categories__list__item {
  box-shadow: var(--elevation-2);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  font-size: 10px;
  color: var(--dark-4);
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 16px;
  margin-right: 8px;
  border-radius: 8px;
  font-weight: 600;
}

.onboard-page-mobile__categories__list__item.selected {
  background: var(--blue-main);
  color: white;
}

.onboard-page-mobile__sports__list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 16px;
}

.onboard-page-mobile__sports__list__item {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: var(--elevation-2);
  color: var(--dark-2);
  font-family: var(--nhg-display);
  font-weight: 600;
  border-radius: 8px;
}

.onboard-page-mobile__sports__list__item.selected {
  background: var(--blue-main);
  color: white;
}

.onboard-page-mobile__sports__list__item .sports-icon-svg {
  fill: var(--blue-lightest);
  height: 32px;
}
