.fixture-lineups {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 32px;
  /* padding-left: 48px; */
  /* padding-right: 48px; */
}

.fixture-lineups .left-column {
  grid-column-start: 1;
  grid-column-end: 2;
  /* padding-left: 48px; */
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 32px;
  padding-top: 32px;
}

.fixture-lineups .left-column .meta-column {
  grid-column-start: 1;
  grid-column-end: 6;
}

.fixture-lineups .left-column .starters {
  grid-column-start: 6;
  grid-column-end: 13;
}

.fixture-lineups .right-column {
  grid-column-start: 2;
  grid-column-end: 3;
  /* padding-right: 48px; */
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 32px;
  padding-top: 32px;
}

.fixture-lineups .right-column .meta-column {
  grid-column-start: 8;
  grid-column-end: 13;
}

.fixture-lineups .right-column .starters {
  grid-column-start: 1;
  grid-column-end: 8;
}

.fixture-lineups .top-players-card {
  /* margin-top: 16px; */
}

.fixture-lineups .pitch-card {
  /* height: 560px; */
}

.fixture-lineups .details-card {
  background: var(--blue-darker);
  padding: 16px;
}

.fixture-lineups .details-card .formation {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  align-items: center;
}

.fixture-lineups .details-card .formation .logo {
  background: var(--blue-lighter);
  height: 24px;
  width: 24px;
  border-radius: 4px;
}

.fixture-lineups .details-card .formation .text {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.fixture-lineups .left-column .details-card .formation .text {
  margin-left: auto;
}

.fixture-lineups .right-column .details-card .formation .text {
  margin-right: auto;
}

.fixture-lineups .details-card .key-individuals {
  /* display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 16px; */
  margin-top: 16px;
}

.fixture-lineups .details-card .key-individuals .key-individual {
  /* height: 104px; */
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-direction: row;
}

.fixture-lineups .details-card .key-individuals .key-individual .image {
  height: 48px;
  width: 48px;
  /* width: 48px; */
  background: var(--blue-subtle);
  border-radius: 4px;
}

.fixture-lineups .details-card .key-individuals .key-individual__info {
  padding-left: 12px;
}

.fixture-lineups .details-card .key-individuals .key-individual .name {
  /* text-align: center; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  margin-top: 8px;
}

.fixture-lineups .details-card .key-individuals .key-individual .desc {
  /* text-align: center; */
  font-family: var(--din);
  font-size: 10px;
  color: var(--dark-4);
  margin-top: 4px;
}

.fixture-lineups .starters {
  display: flex;
  flex-direction: column;
}

.fixture-lineups .starters .card-title {
  border-bottom: solid 1px var(--light-3);
  width: 100%;
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  font-weight: 700;
  color: var(--dark-3);
}

.fixture-lineups .starters__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-3);
  width: 100%;
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: var(--light-2);
  height: 32px;
}

.fixture-lineups .starters__title__text {
  font-weight: 700;
  color: var(--dark-4);
  display: flex;
  flex-direction: row;
}

.fixture-lineups .starters__title__text__icon {
  stroke: var(--dark-4);
  height: 14px;
}

.fixture-lineups .starters__title__selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.fixture-lineups .starters__title__selection__option {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
  cursor: pointer;
}

.fixture-lineups .starters__title__selection__option__icon {
  fill: var(--dark-4);
}

.fixture-lineups
  .starters__title__selection__option.selected
  .starters__title__selection__option__icon {
  fill: var(--blue-main);
}

.fixture-lineups .select-formation-menu {
  width: 140px;
  max-height: 300px;
  overflow: auto;
}

.fixture-lineups .select-formation-menu__list__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  cursor: pointer;
}

.fixture-lineups .select-formation-menu__list__item:hover {
  background: var(--light-2);
}

.starters__body {
  flex-grow: 1;
  min-height: 548px;
}

.subs__body {
  flex-grow: 1;
  min-height: 248px;
}

.starters__body__list {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.starters__body__list__items {
  flex-grow: 1;
  overflow: auto;
}

.starters__body__list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: solid 1px var(--light-2);
}

.starters__body__list__item__image {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.starters__body__list__item__image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.starters__body__list__item__name {
}

.starters__body__list__item__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.starters__body__list__item__option {
  font-size: 10px;
  cursor: pointer;
  /* width: 64px; */
  margin-left: 8px;
  text-align: right;
}

.starters__body__list__item__option.placeholder {
  border-bottom: dashed 1px var(--light-3);
  color: var(--dark-4);
}

input.starters__body__list__item__option {
  border: dashed 1px var(--light-3);
  width: 32px;
  outline: none;
  height: 20px;
}

.starters__body__list__item__option.error {
  border-color: var(--red-main);
}

.starters__body__list__item__option__icon {
  stroke: var(--red-lighter);
  height: 14px;
}

.starters__body__list__footer {
  padding: 16px;
  border-top: solid 1px var(--light-3);
}

.fixture-lineups-list-container {
  background: var(--light-2);
  max-height: 80%;
  width: 32%;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.fixture-lineups-list-container__header {
  background: var(--blue-darker);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  color: white;
  font-weight: 700;
}

.fixture-lineups-list-container__header .dismiss-btn {
  margin-left: auto;
  cursor: pointer;
}

.fixture-lineups-list-container .lineup-list {
  flex-grow: 1;
  overflow: auto;
  background: white;
}

.fixture-lineups-list-container .lineup-list .lineup-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  /* height: 40px; */
  border-bottom: solid 1px var(--light-3);
  position: relative;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
}

.fixture-lineups-list-container .lineup-list .lineup-list-item:hover {
  background: var(--light-2);
}

.fixture-lineups-list-container .lineup-list .lineup-list-item .disabled {
  /* opacity: 0.1;
  pointer-events: none; */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: var(--light-1);
  opacity: 0.3;
  z-index: 2;
  pointer-events: none;
}

.fixture-lineups-list-container .lineup-list .lineup-list-item.disabled {
  pointer-events: none;
}

.fixture-lineups-list-container .lineup-list .lineup-list-item.disabled:hover {
  background: none;
}

.fixture-lineups-list-container .lineup-list .lineup-list-item .image {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.fixture-lineups-list-container .lineup-list .lineup-list-item .image img {
  width: 100%;
}

.fixture-lineups-list-container .lineup-list .lineup-list-item .name__primary {
  font-weight: 700;
}

.fixture-lineups-list-container
  .lineup-list
  .lineup-list-item
  .name__secondary {
  color: var(--dark-3);
}

.fixture-lineups-list-container .lineup-list .lineup-list-item .num {
  margin-left: auto;
}

.fixture-lineups-list-container .lineup-list .lineup-list-item .checkbox {
  /* margin-left: auto; */
}

.fixture-lineups-list-container .lineup-list .lineup-list-item.disabled .name {
  opacity: 0.4;
}

.fixture-lineups-list-container
  .lineup-list
  .lineup-list-item.disabled
  .checkbox {
  opacity: 0.1;
}
