.event-standings-summary {
  grid-column-start: 7;
  grid-column-end: 13;
  height: inherit;
}

.event-standings-summary .card-title {
  font-weight: 600;
  color: var(--dark-4);
  padding: 16px;
}

.event-standings-summary .standings-table .standings-list {
  /* margin-top: 16px; */
  padding-bottom: 16px;
}

.event-standings-summary .standings-table .standings-list .row {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 32px;
  padding-left: 8px;
  padding-right: 8px;
}

.event-standings-summary .standings-table .standings-list .table-header {
  border-bottom: solid 2px var(--light-2);
}

.event-standings-summary .standings-table .standings-list .table-body {
}

.event-standings-summary .standings-table .standings-list .table-body .row {
  height: 40px;
  border-bottom: solid 1px var(--light-2);
}

.event-standings-summary
  .standings-table
  .standings-list
  .table-body
  .row.even {
  background: var(--blue-subtlest);
}

.event-standings-summary .standings-table .standings-list .td {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding-right: 8px;
  padding-left: 8px;
}

.event-standings-summary .standings-table .standings-list .td .logo {
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-right: 8px;
  overflow: hidden;
}

.event-standings-summary .standings-table .standings-list .td .logo img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.event-standings-summary .standings-table .standings-list .td.number {
  justify-content: flex-end;
}

.event-standings-summary .standings-table .standings-list .table-header .td {
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-2);
}

.event-standings-summary .standings-table .standings-list .table-body .td {
}

.event-standings-summary .event-standings-summary-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-weight: 600;
  color: var(--dark-4);
}
