.app-input {
}

.app-input .label {
  font-weight: 500;
  color: var(--dark-3);
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 4px;
  padding-left: 12px;
}

.app-input .assistive {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  color: var(--dark-3);
  margin-top: 4px;
  padding-left: 12px;
}

.app-input .assistive .assistive-icon {
  margin-right: 8px;
}

.app-input .input-wrapper {
  height: 32px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: inset 0 0.5px 4px var(--light-3);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  border: solid 1px transparent;
  /* transition: all 0.2s ease-out; */
}

.app-input .input-wrapper .input-icon-left {
  margin-left: 12px;
  margin-right: 8px;
  margin-top: 2px;
}

.app-input .input-wrapper .input-icon-right {
  margin-right: 12px;
  margin-left: 8px;
}

.app-input .input-wrapper input {
  border: none;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  background: transparent;
  flex: 1;

  outline: none;
  /* padding-left: 12px; */
  font-size: 12px;
  font-family: var(--nhg);
}

.app-input div.input-wrapper.focused {
  border: solid 1px var(--blue-main);
}

.app-input div.input-wrapper.error {
  border: solid 1px var(--red-main);
}

.app-input div.input-wrapper.valid {
  border: solid 1px var(--green-main);
}

.app-input div.input-wrapper.disabled {
  background: var(--light-2);
  color: var(--light-4);
  pointer-events: none;
}

.app-input div.input-wrapper.disabled input {
  color: var(--dark-4);
}
