.organization-record-mobile {
  margin: 16px;
  /* display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr)); */
  display: flex;
  flex-direction: row;
  /* grid-column-gap: 12px; */
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.organization-record-mobile__meta {
  /* grid-column-start: 1;
  grid-column-end: 2; */
  width: 50%;
  display: flex;
  flex-direction: column;
  border-right: solid 1px var(--light-2);
}

.organization-record-mobile__meta__title {
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
}

.organization-record-mobile__meta__highlight {
  padding: 16px;
  margin-top: auto;
}

.organization-record-mobile__meta__highlight__value {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-1);
}

.organization-record-mobile__meta__highlight__label {
  font-family: var(--nhg-display);
  font-weight: 600;
  font-size: 12px;
  color: var(--dark-4);
  margin-top: 8px;
}

.organization-record-mobile__items {
  width: 50%;
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 16px;
}

.organization-record-mobile__items__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.organization-record-mobile__items__item__value {
  font-family: var(--nhg-display);
  font-weight: 700;
  /* font-size: 24px; */
  color: var(--dark-1);
  margin-bottom: 8px;
}

.organization-record-mobile__items__item__graph {
  height: 120px;
}

.organization-record-mobile__items__item__graph__track {
  height: 100%;
  width: 8px;
  background: var(--light-3);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.organization-record-mobile__items__item__graph__fill {
  width: 8px;
  height: 50%;
  border-radius: 4px;
  background: linear-gradient(75.26deg, #78c9fc 18.6%, #63ea47 100%);
}

.organization-record-mobile__items__item__label {
  font-family: var(--nhg-display);
  font-weight: 600;
  font-size: 12px;
  color: var(--dark-4);
  margin-top: 8px;
}

.organization-form-mobile {
  margin: 16px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  /* display: flex;
  flex-direction: row; */
  grid-column-gap: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.organization-form-mobile__title {
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
  grid-column-start: 1;
  grid-column-end: 5;
}

.organization-form-mobile__items {
  grid-column-start: 1;
  grid-column-end: 5;
}

.organization-form-mobile__items__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.organization-form-mobile__items__item__meta {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  padding-left: 16px;
}

.organization-form-mobile__items__item__meta__image {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.organization-form-mobile__items__item__meta__image img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.organization-form-mobile__items__item__meta__text {
}

.organization-form-mobile__items__item__meta__text__primary {
  font-weight: 700;
  color: var(--dark-1);
}

.organization-form-mobile__items__item__meta__text__secondary {
  font-weight: 400;
  color: var(--dark-4);
  font-size: 10px;
  margin-top: 2px;
}

.organization-form-mobile__items__item__result {
  width: 25%;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: var(--nhg-display);
}

.organization-form-mobile__items__item__result__text {
  font-weight: 700;
}

.organization-form-mobile__items__item__result__score {
}

.organization-form-mobile__items__item__result__text.green {
  color: var(--green-main);
}

.organization-form-mobile__items__item__result__text.orange {
  color: var(--orange-main);
}

.organization-form-mobile__items__item__result__text.red {
  color: var(--red-main);
}
