.profile-page {
}

.profile-page .cover {
  height: 240px;
  background: var(--blue-subtle);
  background: var(--blue-main);
  background: rgb(185, 227, 254);
  background: linear-gradient(
    90deg,
    rgba(185, 227, 254, 1) 0%,
    rgba(235, 247, 255, 1) 100%
  );
  /* background: linear-gradient(
    90deg,
    rgba(120, 201, 252, 1) 0%,
    rgba(185, 227, 254, 1) 100%
  ); */
  /* position: relative; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.profile-page .cover img {
  /* position: absolute; */
  width: 100%;
  z-index: -1;
  position: relative;
}

.profile-page .cover .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #022e4b;
  z-index: 0;
  opacity: 0.01;
  cursor: pointer;
}

.profile-page .cover .edit-cover-btn {
  height: 24px;
  /* width: 32px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: var(--orange-lighter);
  fill: var(--light-2);
  position: absolute;
  right: 48px;
  top: 24px;
  cursor: pointer;
  padding-left: 4px;
  padding-right: 12px;
  font-size: 10px;
  color: white;
  font-weight: 700;
  transition: all 0.2s ease-out;
}

.profile-page .cover .edit-cover-btn:hover {
  background: var(--orange-main);
}

.profile-page .edit-profile-photo-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: var(--orange-lighter);
  fill: var(--light-2);
  position: absolute;
  bottom: 10px;
  right: 16px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.profile-page .edit-profile-photo-btn:hover {
  background: var(--orange-main);
}

.profile-page .edit-profile-photo-btn .icon {
  height: 14px;
}

.profile-page .cover .edit-cover-btn .icon {
  height: 12px;
}

.profile-page .profile-name {
  padding-left: 368px;
  font-size: 28px;
  line-height: 28px;
  font-weight: 600;
  /* margin-bottom: 24px; */
  color: var(--dark-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  border-bottom: solid 1px var(--light-2);
  height: 72px;
}

.profile-page .tabs {
  height: 40px;
  background: white;
  padding-left: 368px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.profile-page .tabs .tab-item {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-right: 32px;
  color: var(--dark-4);
  font-weight: 400;
  border-bottom: solid 2px transparent;
  padding-top: 2px;
}

.profile-page .tabs .tab-item.active {
  color: var(--dark-2);
  /* font-weight: 600; */
  border-bottom: solid 2px var(--blue-main);
}

.profile-page .body {
  padding-left: 48px;
  padding-right: 48px;
  display: grid;
  grid-template-columns: 320px 1fr;
}

.profile-page .body .sidebar {
  padding-right: 48px;
}

.profile-page .profile-summary-card {
  /* height: 240px; */
  margin-top: -176px;
  padding: 24px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  position: relative;
}

.profile-page .profile-summary-card__image-wrapper {
  position: relative;
}

.profile-page .profile-summary-card__handle {
  font-size: 16px;
  font-family: var(--nhg-display);
  font-weight: 100;
  margin-top: 16px;
  color: var(--dark-2);
}

.profile-page .profile-summary-card .image {
  height: 160px;
  width: 160px;
  background: var(--blue-subtle);
  border-radius: 100%;
  border: solid 4px var(--blue-subtlest);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.profile-page .profile-summary-card .image img {
  height: 100%;
}

.profile-page .profile-summary-card .image .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #022e4b;
  z-index: 0;
  opacity: 0;
  cursor: pointer;
}

.profile-page .profile-summary-card .stats {
  margin-top: 16px;
  margin-bottom: 24px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
}

.profile-page .profile-summary-card .stats .stat-item {
  width: 33%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  border-right: solid 1px var(--light-2);
  /* flex-grow: 1; */
  cursor: pointer;
}

.profile-page .profile-summary-card .stats .stat-item .value {
  font-size: 16px;
  font-weight: 600;
}

.profile-page .profile-summary-card .stats .stat-item .key {
  /* font-family: "Inter", sans-serif; */
  color: var(--dark-4);
  font-size: 10px;
  margin-top: 4px;
  letter-spacing: 0.4px;
}

.profile-page .profile-summary-card .stats .stat-item:last-child {
  border: none;
}

.profile-page .profile-summary-card .follow-button {
  width: 100%;
}

.profile-page .user-bio-card {
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 24px;
}

.profile-page .user-bio-card .location-search-input {
  width: 100%;
  margin-top: 12px;
  font-family: var(--nhg);
  font-size: 12px;
  height: 24px;
  border-radius: 4px;
  border: solid 1px var(--light-4);
  padding-left: 8px;
  outline: none;
}

.profile-page .user-bio-card .autocomplete-dropdown-container .suggestion-item,
.profile-page
  .user-bio-card
  .autocomplete-dropdown-container
  .suggestion-item--active {
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-page .user-bio-card .location-search-input:focus {
  border-color: var(--blue-lighter);
}

.profile-page .user-bio-card .edit-bio-btn {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--orange-main);
  margin-left: 16px;
  background: var(--orange-subtle);
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.profile-page .user-bio-card .edit-bio-btn .icon {
  height: 10px;
}

.profile-page .user-bio-card .edit-bio-btn:hover {
  cursor: pointer;
  background: var(--orange-main);
  fill: white;
}

.profile-page .user-bio-card .edit-bio-form {
  margin-bottom: 16px;
}

.profile-page .user-bio-card .edit-bio-form .edit-bio-form-label {
  margin-bottom: 4px;
  font-size: 10px;
  font-weight: 600;
  color: var(--dark-4);
}

.profile-page .user-bio-card .edit-bio-form .edit-bio-form-body {
  display: flex;
  flex-direction: row;
}

.profile-page .user-bio-card .edit-bio-form input {
  height: 24px;
  flex-grow: 1;
  border: solid 1px var(--light-3);
  padding-left: 8px;
  border-radius: 4px;
  outline: none;
}

.profile-page .user-bio-card .edit-bio-form input:focus {
  border-color: var(--blue-main);
}

.profile-page .user-bio-card .edit-bio-form button {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
}

.profile-page .user-bio-card .edit-bio-form button.submit {
  background: var(--green-main);
  border: none;
  fill: white;
  border-radius: 4px;
}

.profile-page .user-bio-card .edit-bio-form button.submit.disabled {
  pointer-events: none;
  background: var(--light-2);
}

.profile-page .user-bio-card .edit-bio-form button.cancel {
  border: none;
  stroke: var(--dark-4);
  background: var(--light-2);
  border-radius: 4px;
}

.profile-page .user-bio-card .info-group {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 16px;
}

.profile-page .user-bio-card .info-group:last-child {
  margin-bottom: 0;
}

.profile-page .user-bio-card .info-group.row {
  align-items: center;
}

.profile-page .user-bio-card .info-group.vertical {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.profile-page .user-bio-card .info-group .key {
  font-size: 12px;
  color: var(--dark-2);
  font-weight: 600;
}

.profile-page .user-bio-card .info-group.vertical .key {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-page .user-bio-card .info-group.vertical .key .edit-bio-btn {
  margin-left: auto;
}

.profile-page .user-bio-card .info-group.vertical .key .edit-bio-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-bottom: 0;
}

.profile-page .user-bio-card .info-group .map {
  height: 120px;
  background: var(--blue-subtle);
  margin-top: 12px;
}

.profile-page .user-bio-card .info-group .value {
  margin-left: auto;
  /* font-family: "Inter", sans-serif; */
  /* font-weight: 600; */
}

.profile-page .user-bio-card .info-group.vertical .value {
  margin-left: 0;
  /* margin-top: 12px; */
}

.profile-page .user-bio-card .info-group .value.row {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
}

.profile-page .user-bio-card .info-group .value.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 16px;
}

.profile-page .user-bio-card .info-group .value .interest {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  /* border: solid 1px var(--light-3); */
  box-shadow: var(--elevation-2);
  border-radius: 4px;
  overflow: hidden;
}

.profile-page .user-bio-card .info-group .value .interest.inactive {
  /* background: var(--light-2); */
  border: solid 1px var(--blue-subtle);
  box-shadow: var(--elevation-3);
}

.profile-page .user-bio-card .info-group .value .interest .icon {
  height: 24px;
  width: 24px;
  background: var(--blue-main);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-page .user-bio-card .info-group .value .interest.inactive .icon {
  background: none;
  border-right: solid 1px var(--blue-subtle);
}

.profile-page
  .user-bio-card
  .info-group
  .value
  .interest
  .icon
  .sports-icon-svg {
  fill: white;
  height: 16px;
}

.profile-page
  .user-bio-card
  .info-group
  .value
  .interest.inactive
  .icon
  .sports-icon-svg {
  fill: var(--dark-4);
}

.profile-page .user-bio-card .info-group .value .interest .desc {
  flex-grow: 1;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  color: var(--dark-3);
}

.profile-page .user-bio-card .info-group .value .interest.inactive .desc {
  color: var(--dark-4);
}

.profile-page .user-bio-card .info-group .value .icon-value {
  height: 56px;
  width: 100%;
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-right: 16px;
  margin-top: 16px;
}

.profile-page .user-bio-card .user-page {
  /* padding-left: 24px; */
  /* padding-right: 24px; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-top: 16px;
}

.profile-page .user-bio-card .user-page .image {
  height: 48px;
  width: 48px;
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-right: 16px;
}

.profile-page .user-bio-card .user-page .meta {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  /* font-family: "Inter", sans-serif; */
}

.profile-page .user-bio-card .user-page .meta .primary {
  font-weight: 700;
  margin-bottom: 4px;
}

.profile-page .user-bio-card .user-page .meta .secondary {
  color: var(--dark-2);
}

.select-profile-photo-wrapper {
  width: 40%;
  max-height: 80%;
}

.select-profile-photo {
  display: flex;

  flex-direction: column;
}

.select-profile-photo__header {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
  padding-left: 24px;
  padding-right: 24px;
  font-size: 16px;
  font-family: var(--nhg-display);
  font-weight: 700;
}

.select-profile-photo__header__title {
}

.select-profile-photo__header__link {
  color: var(--blue-main);
  margin-left: auto;
}

.select-profile-photo__images {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 8px;
  flex-grow: 1;
  overflow: auto;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.select-profile-photo-list-options {
  padding: 24px;
  padding-top: 16px;
}

.select-profile-photo__images__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--light-2);
  margin-top: 24px;
  position: relative;
  cursor: pointer;
}

.select-profile-photo__images__item__select {
  position: absolute;
  top: 4px;
  right: 4px;
  background: var(--blue-main);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  opacity: 0;
  transition: all 0.2s ease-out;
  /* position: relative; */
}

.select-profile-photo__images__item__select.selected {
  opacity: 1;
}

.select-profile-photo__images__item__wrapper:hover
  .select-profile-photo__images__item__select {
  opacity: 1;
}

.select-profile-photo__images__item__select__icon {
  height: 12px;
  fill: white;
  position: absolute;
}

.select-profile-photo__images__item__select__icon-delete {
  height: 12px;
  stroke: white;
  position: absolute;
  display: none;
}

.select-profile-photo__images__item__wrapper:hover
  .select-profile-photo__images__item__select.selected {
  background: var(--red-main);
}

.select-profile-photo__images__item__wrapper:hover
  .select-profile-photo__images__item__select.selected
  .select-profile-photo__images__item__select__icon {
  display: none;
}

.select-profile-photo__images__item__wrapper:hover
  .select-profile-photo__images__item__select.selected
  .select-profile-photo__images__item__select__icon-delete {
  display: block;
}

.select-profile-photo__images__item img {
  width: 100%;
}

.profile-page .user-bio-card .edit-bio-form button.submit.disabled {
  pointer-events: none;
  background: var(--light-3);
}

.select-profile-photo__images__item__options {
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.select-profile-photo__images__item__options__icon {
}

.select-profile-photo__images__item__options__icon__delete {
  stroke: var(--red-main);
  height: 14px;
}
