.App {
  background: var(--blue-subtlest);
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding-top: 48px;
  position: relative;
}

.App.onboarding {
  padding-top: 168px;
}

.App .App-content {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.App .bold {
  font-weight: 700;
}

.App .App-modal {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.App .App-modal .App-modal-background {
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
}

.App .App-modal.open {
  pointer-events: all;
  z-index: 10;
}

.App .App-modal.open .App-modal-background {
  background: rgba(0, 24, 39, 0.9);
}

.App .no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column-start: 1;
  grid-column-end: 4;
  font-size: 12px;
  color: var(--dark-4);
  line-height: 20px;
  height: 120px;
  text-align: center;
}

.App .no-data .link {
  color: var(--blue-lighter);
  cursor: pointer;
}
