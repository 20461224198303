.organization-overview-services-card {
  padding: 16px;
  padding-bottom: 0;
}

.organization-overview-services-card .card-title {
  font-weight: 600;
  color: var(--dark-4);
}

.organization-overview-services-card .organization-overview-services-list {
  margin-top: 16px;
}

.organization-overview-services-card
  .organization-overview-services-list
  .organization-overview-service-card {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.organization-overview-services-card
  .organization-overview-services-list
  .organization-overview-service-card
  .image {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-right: 16px;
  flex-shrink: 0;
}

.organization-overview-services-card
  .organization-overview-services-list
  .organization-overview-service-card
  .text {
}

.organization-overview-services-card
  .organization-overview-services-list
  .organization-overview-service-card
  .text
  .primary-text {
  font-weight: 600;
}

.organization-overview-services-card
  .organization-overview-services-list
  .organization-overview-service-card
  .text
  .secondary-text {
  color: var(--dark-4);
}

.organization-overview-services-card
  .organization-overview-services-card-footer {
  /* margin-top: 16px; */
  padding: 8px;
  border-top: solid 1px var(--light-2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--dark-4);
  cursor: pointer;
}
