.app-header {
  height: 48px;
  background: white;
  box-shadow: var(--elevation-2);
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  /* display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center; */
  padding-left: 48px;
  padding-right: 48px;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  box-sizing: border-box;
  z-index: 5;
}

.App.onboarding .app-header {
  /* top: 24px; */
  box-shadow: var(--elevation-1);
  border-bottom: solid 1px var(--light-2);
}

.app-header .logo {
  /* margin-right: auto; */
  grid-column-start: 1;
  grid-column-end: 2;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}

.app-header .options {
  grid-column-start: 3;
  grid-column-end: 4;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  justify-content: flex-end;

  /* margin-right: 16px; */
  height: 100%;
}

.app-header .options .option {
  margin-left: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 28px;
  border-bottom: solid 2px transparent;
  stroke: var(--dark-4);
  transition: all 0.2s ease-out;
  height: 48px;
}

.app-header .options .option:hover {
  cursor: pointer;
  stroke: var(--blue-lighter);
}

.app-header .options .option.active {
  border-bottom: solid 2px var(--blue-main);
  stroke: var(--blue-main);
}

.app-header .primary.create-button {
  box-shadow: none;
  padding-left: 8px;
  padding-right: 12px;
  margin-left: 16px;
  z-index: 5;
  position: relative;
}

.app-header .primary.create-button .icon {
  margin-right: 4px;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  stroke: var(--light-1);
}

.app-header .primary.create-button .icon img {
  height: 14px;
}
