.app-party-list {
  background: white;
  width: 480px;
  border-radius: 8px;
  height: 70%;
  display: flex;
  flex-direction: column;
}

.app-party-list__header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: solid 1px var(--light-3);
  flex-shrink: 0;
}

.app-party-list__header__title {
  font-weight: 600;
  font-family: var(--nhg-display);
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  color: var(--dark-2);
}

.app-party-list__header__dismiss {
  position: absolute;
  right: 8px;
  stroke: var(--dark-4);
  cursor: pointer;
}

.app-party-list__header__dismiss__icon {
  height: 14px;
}

.app-party-list__body {
  flex-grow: 1;
  overflow: auto;
}

.app-party-list__body__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom: solid 1px var(--light-2);
}

.app-party-list__body__item__image {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--light-2);
  overflow: hidden;
}

.app-party-list__body__item__image img {
  width: 100%;
}

.app-party-list__body__item__desc {
  margin-right: auto;
  color: var(--dark-2);
}

.app-party-list__body__item__desc__primary {
  font-weight: 600;
}

.app-party-list__body__item__desc__secondary {
  color: var(--dark-4);
}

.app-party-list__body__item__options {
}

.app-party-list__body__item--show-more {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-3);
  cursor: pointer;
}
