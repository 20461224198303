.organization-posts {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 32px;
}

.organization-posts .card-section {
  margin-top: 32px;
}

.organization-posts .card-section .card-section-title {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 12px;
  font-weight: 700;
  color: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.organization-posts .left-column {
  grid-column-start: 1;
  grid-column-end: 9;
}

.organization-posts .profile-activity-card {
  margin-top: 32px;
  padding: 16px;
  /* padding-bottom: 4px; */
}

.organization-posts .organization-posts-list {
  margin-top: 32px;
}

.organization-posts .organization-posts-list .feed-item-card {
  margin-top: 24px;
}

.organization-posts .organization-posts-list .app-post-card {
  margin-top: 32px;
}

.organization-posts .organization-posts-list .create-post-card {
  margin-top: 32px;
}

.organization-posts .feed-item-card {
  font-family: "Inter", sans-serif;
  padding: 16px;
}

.organization-posts .feed-item-card .head {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 16px;
}

.organization-posts .feed-item-card .head .avatar {
  height: 36px;
  width: 36px;
  background: var(--blue-subtle);
  margin-right: 16px;
  border-radius: 8px;
}

.organization-posts .feed-item-card .head .meta {
  flex-grow: 1;
}

.organization-posts .feed-item-card .head .meta .primary {
  font-weight: 700;
  color: var(--dark-1);
}

.organization-posts .feed-item-card .head .meta .secondary {
  color: var(--dark-4);
}

.organization-posts .feed-item-card .head .feed-item-options-button {
}

.organization-posts .feed-item-card .text {
  line-height: 19px;
}

.organization-posts .feed-item-card .media {
  margin-top: 16px;
}

.organization-posts .feed-item-card .foot {
  border-top: solid 1px var(--light-2);
  margin-top: 16px;
  padding-top: 16px;
  /* padding-bottom: 8px; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.organization-posts .feed-item-card .foot .option {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.organization-posts .feed-item-card .foot .option .icon {
  /* stroke: var(--dark-2); */
}

.organization-posts .feed-item-card .foot .option .value {
  margin-left: 8px;
}

.organization-posts .feed-item-card .foot .options-right {
  margin-left: auto;
}

.organization-posts .feed-item-card .media .image-placeholder {
  width: 100%;
  height: 400px;
  background: var(--blue-subtle);
  border-radius: 8px;
}

.organization-posts .right-column {
  grid-column-start: 9;
  grid-column-end: 13;
}

.organization-posts .right-column .app-card {
  margin-top: 32px;
  padding: 16px;
}

.organization-posts .show-more-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  /* padding-bottom: 32px; */
}

.organization-posts .show-more-container .show-more-button {
  width: 104px;
}
