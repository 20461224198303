.organization-services {
}

.organization-services .no-services-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 32px;
}

.organization-services .no-services-container .text {
  font-size: 16px;
  color: var(--dark-4);
}

.organization-services .no-services-container .app-button {
  width: 240px;
  margin-top: 32px;
}

.organization-services .organization-services-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 32px;
}

.organization-services .organization-services-list {
  /* margin-top: 32px; */
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 32px;
}

.organization-services .organization-service-card {
  margin-top: 32px;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.organization-services .organization-service-card .image {
  height: 160px;
  background: var(--blue-subtle);
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.organization-services .organization-service-card .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #022e4b;
  z-index: 0;
  opacity: 0.4;
}

.organization-services .organization-service-card .image img {
  width: 100%;
}

.organization-services .organization-service-card .primary-text {
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
  /* margin-bottom: 8px; */
  position: relative;
  margin-bottom: 16px;
}

.organization-services .organization-service-card .primary-text input {
  height: 32px;
  border: solid 1px var(--light-4);
  width: 100%;
  border-radius: 4px;
  padding-left: 8px;
  font-size: 12px;
  outline: none;
}

.organization-services .organization-service-card .primary-text input:focus {
  border: solid 1px var(--blue-main);
}

.organization-services .organization-service-card .secondary-text {
  color: var(--dark-4);
  position: relative;
  margin-bottom: 16px;
}

.organization-services .organization-service-card .secondary-text.link {
  color: var(--blue-lighter);
  cursor: pointer;
}

.organization-services .organization-service-card .secondary-text textarea {
  width: 100%;
  /* margin-top: 8px; */

  height: 80px;
  border: solid 1px var(--light-4);
  border-radius: 4px;
  padding-left: 8px;
  padding-top: 8px;
  font-size: 12px;
  font-family: var(--nhg);
  outline: none;
}

.organization-services
  .organization-service-card
  .secondary-text
  textarea:focus {
  border: solid 1px var(--blue-main);
}
.organization-services .organization-service-card .character-count-label {
  position: absolute;
  font-size: 8px;
  color: var(--dark-4);
  background: white;
  top: -4px;
  right: 4px;
  font-weight: 700;
  padding-left: 4px;
  padding-right: 4px;
}

.organization-services .organization-service-card .character-count-label.error {
  color: var(--red-lighter);
}

.organization-services .organization-service-card .enquire-button {
  margin-top: 16px;
}

.organization-services .organization-service-card .submit-button {
  width: 100%;
  outline: none;
  border: none;
  margin-bottom: 16px;
}

.organization-services .organization-service-card .delete-button {
  margin-bottom: 16px;
  margin-top: 16px;
  width: 100%;
  outline: none;
  border: none;
}

.organization-service-card__options {
  /* margin-top: 8px; */
  margin-top: auto;
}

.organization-service-card__options__option {
  cursor: pointer;
  margin-top: 8px;
  font-weight: 700;
}

.organization-service-card__options__option.edit {
  color: var(--blue-lighter);
}

.organization-service-card__options__option.delete {
  color: var(--red-lighter);
}

.organization-service-card__image__edit-btn {
  height: 32px;
  width: 32px;
  background: var(--orange-subtle);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 8px;
  bottom: 8px;
  cursor: pointer;
}
