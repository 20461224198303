.video-list-item {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 24px;
}

.video-list-item .video-wrapper {
  grid-column-start: 1;
  grid-column-end: 9;
  padding: 16px;
}

.video-list-item .video-wrapper .video {
  background: black;
  /* height: 320px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-list-item .video-wrapper .video video {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.video-list-item .video-info {
  /* border-left: solid 1px var(--light-2); */
  grid-column-start: 9;
  grid-column-end: 13;
}

.video-list-item .video-info__src {
  /* padding: 16px; */
  padding-left: 0;
  padding-right: 0;
  /* margin-left: 16px; */
  /* margin-right: 16px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-3);
}

.video-list-item .video-info__src__img {
  height: 36px;
  width: 36px;
  border-radius: 8px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-list-item .video-info__src__img img {
  width: 100%;
}

.video-list-item .video-info__src__text {
  flex-grow: 1;
}

.video-list-item .video-info__src__text__primary {
  font-weight: 700;
  margin-bottom: 4px;
}

.video-list-item .video-info__src__text__secondary {
  color: var(--dark-4);
}

.video-list-item .video-info__desc {
  padding-top: 16px;
  /* padding-left: 16px; */
  padding-right: 16px;
  line-height: 18px;
}

.video-info .edit-description-btn {
  font-weight: 700;
  color: var(--orange-main);
  margin-top: 8px;
}

.video-info .delete-video-btn {
  font-weight: 700;
  color: var(--red-main);
  margin-top: 4px;
}

.video-info__source {
  padding-bottom: 16px;
  border-bottom: solid 1px var(--light-3);
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  margin-right: 16px;
  color: var(--dark-1);
}

.video-info__source__image {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  margin-right: 16px;
  /* margin-left: 16px; */
}

.video-info__source__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.video-info__source__text__primary {
  font-weight: 700;
}

.video-info__source__text__secondary {
  color: var(--dark-4);
}

.video-info__source__fixture {
  /* display: grid;
  grid-template-columns: 45% 10% 45%; */

  width: 100%;
}

.video-info__source__fixture__team {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.video-info__source__fixture__team.away {
  justify-content: flex-end;
}

.video-info__source__fixture__team__image {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  margin-right: 16px;
  overflow: hidden;
  /* margin-left: 16px; */
}

.video-info__source__fixture__team__image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.video-info__source__fixture__team__title {
  font-weight: 700;
  flex-grow: 1;
}

.video-info__source__fixture__team.away
  .video-info__source__fixture__team__title {
  text-align: right;
}

.video-info__source__fixture__team__score {
  font-weight: 700;
}

.video-info__source__fixture__seperator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: 700;
  color: var(--dark-4);
  letter-spacing: 1px;
}

.video-card-fixture-source {
  margin-top: 0;
  width: 100%;
  box-shadow: none;
}

.video-card-fixture-source .participants {
  padding: 0;
}

.video-card-fixture-source .participants .participant {
  padding: 0;
  margin-bottom: 12px;
}

.video-card-fixture-source .participants .participant:last-child {
  margin-bottom: 0;
}

.video-card-fixture-source .score {
  padding: 0;
}

.video-card-fixture-source .score__box-score-period__item {
  padding: 0;
  margin-bottom: 12px;
  margin-left: 12px;
}

.video-card-fixture-source .score__box-score-period__item:last-child {
  margin-bottom: 0;
}
