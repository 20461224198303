.ScoringApp-football__publish__table__section-title {
  background: var(--blue-main);
  padding: 4px;
  padding-left: 24px;
  font-size: 10px;
  font-weight: 700;
  color: var(--light-2);
  text-align: center;
}

.ScoringApp-football__publish__table__header__seperator {
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 700;
  color: var(--light-2);
  font-size: 16px;
}
