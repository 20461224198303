.photos-summary-mobile {
  padding: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.photos-summary-mobile__header {
  display: flex;
  flex-direction: row;
}

.photos-summary-mobile__header__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.photos-summary-mobile__header__link {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--blue-main);
  margin-left: auto;
}

.photos-summary-mobile__body {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 4px;
  margin-top: 12px;
}

.photos-summary-mobile__body__image {
  background: var(--dark-1);
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.photos-summary-mobile__body__image img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
