.landing-page-new {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: repeat(32, minmax(0, 1fr));
  grid-column-gap: 12px;
}

.landing-page-new__bg {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.landing-page-new__bg img {
  height: 100%;
  width: auto;
}

.landing-page-new__blur {
  position: absolute;
  left: -200px;
  top: -200px;
  z-index: 2;
  /* display: none; */
}

.landing-page-new__logo {
  position: absolute;
  top: 8.33%;
  left: 6.25%;
  width: 6.25%;
  z-index: 3;
}

.landing-page-new__logo img {
  width: 100%;
}

.landing-page-new__banner {
  grid-column-start: 21;
  grid-column-end: 33;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  z-index: 3;
}

.landing-page-new__banner img {
  width: 100%;
  /* height: 100%; */
  /* min-width: 100%; */

  /* width: auto; */
}

.landing-page-new__hero {
  grid-column-start: 15;
  grid-column-end: 28;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  z-index: 4;
  /* margin-left: 40px; */
}

.landing-page-new__hero img {
  width: 100%;

  /* width: auto; */
}

.landing-page-new__text {
  grid-column-start: 3;
  grid-column-end: 14;
  position: absolute;
  bottom: 9.33%;
  z-index: 3;
  color: #333333;
}

.landing-page-new__text__secondary {
  font-size: 4.5vw;
  line-height: 1em;
  font-family: allotrope, sans-serif;
  font-weight: 900;
  font-style: normal;
}

.landing-page-new__text__primary {
  font-size: 8vw;
  line-height: 1em;
  font-family: allotrope, sans-serif;
  font-weight: 900;
  font-style: normal;
}

.landing-page-new__text__tertiary {
  font-size: 16px;
  font-family: allotrope, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-top: 12px;
  color: #626161;
  line-height: 1.5em;
}

.landing-page-new__text__link {
  font-size: 16px;
  font-family: allotrope, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: var(--blue-main);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
}

.landing-page-new__text__link__icon {
  fill: var(--blue-main);
  height: 16px;
}

.landing-page-new__text__link:hover {
  text-decoration: underline;
}

.landing-page-new__cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffd23f;
  color: #333333;
  height: 40px;
  position: absolute;
  right: 6.25%;
  top: 8.33%;
  z-index: 5;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 8px;
  box-shadow: 0 8px 16px -4px rgba(255, 210, 63, 0.58);

  border: none;
  cursor: pointer;
  /* letter-spacing: 0.4px; */
}

.landing-page-new__cta__section {
  width: 71px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  font-family: allotrope, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
}

.landing-page-new__cta__seperator {
  width: 2px;
  font-family: allotrope, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  margin-left: -3px;
}
