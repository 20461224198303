.latest-highlights-card {
  grid-column-start: 8;
  grid-column-end: 13;
  margin-top: 32px;
  height: fit-content;
}

.latest-highlights-card .card-title {
  font-weight: 600;
  color: var(--dark-4);
  padding: 16px;
  padding-bottom: 0;
}

.latest-highlights-card .video-wrapper {
  padding: 16px;
}

.latest-highlights-card .video-wrapper .video-container {
  background: var(--dark-2);
  /* height: 240px; */
}

.latest-highlights-card .video-wrapper .video-container video {
  width: 100%;
  max-height: 100%;
}

.latest-highlights-card .latest-highlights-card-footer {
  border-top: solid 1px var(--light-2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: var(--dark-4);
  font-weight: 600;
  /* margin-top: 8px; */
}
