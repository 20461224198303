.event-standings {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 32px;
}

.event-standings__tables {
  grid-column-start: 1;
  grid-column-end: 8;
}

.event-standings .standings-table {
  /* grid-column-start: 1;
  grid-column-end: 8; */
  margin-top: 32px;
  padding: 16px;
  width: 100%;
}

.event-standings .standings-table .card-title {
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-4);
}

.event-standings .standings-table .standings-list {
  margin-top: 16px;
}

.event-standings .standings-table .standings-list .row {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 32px;
  padding-left: 8px;
  padding-right: 8px;
}

.event-standings .standings-table .standings-list .table-header {
  border-bottom: solid 2px var(--light-2);
}

.event-standings .standings-table .standings-list .table-body {
}

.event-standings .standings-table .standings-list .table-body .row {
  height: 40px;
  border-bottom: solid 1px var(--light-2);
}

.event-standings .standings-table .standings-list .table-body .row.even {
  background: var(--blue-subtlest);
}

.event-standings .standings-table .standings-list .td {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding-right: 8px;
  padding-left: 8px;
}

.event-standings .standings-table .standings-list .td .logo {
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-right: 8px;
  overflow: hidden;
}

.event-standings .standings-table .standings-list .td .logo img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.event-standings .standings-table .standings-list .td.number {
  justify-content: flex-end;
}

.event-standings .standings-table .standings-list .table-header .td {
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-2);
  white-space: nowrap;
}

.event-standings .standings-table .standings-list .table-body .td {
}

.event-standings .standings-evolution {
  grid-column-start: 8;
  grid-column-end: 13;
  margin-top: 32px;
}

.event-standings .standings-table__legend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 8px;
}

.event-standings .standings-table__legend__item {
  font-size: 10px;
  font-weight: 500;
  color: var(--dark-4);
  margin-left: 16px;
  margin-top: 8px;
  /* font-family: var(--nhg-display); */
}
