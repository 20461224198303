.events-page-mobile__list__item {
  background: var(--light-1);
  margin-top: 16px;
  box-shadow: var(--elevation-mobile);
  border-radius: 8px;
  overflow: hidden;
  display: block;
}

.events-page-mobile__list__item__cover {
  height: 16.66vw;
  background: var(--blue-subtle);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.events-page-mobile__list__item__cover img {
  width: 100%;
}

.events-page-mobile__list__item__text {
  padding: 12px;
}

.events-page-mobile__list__item__text__primary {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 700;
  font-size: 14px;
  /* margin-top: 12px; */
  /* line-height: 22px; */
  /* or 183% */

  /* display: flex; */
  /* align-items: center; */

  color: var(--dark-1);
}

.events-page-mobile__list__item__text__secondary {
  margin-top: 6px;
  font-family: var(--nhg);
  /* font-style: normal; */
  font-weight: 400;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* line-height: 22px; */
  /* or 220% */

  /* display: flex; */
  /* align-items: center; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.events-page-mobile__list__item__text__secondary__icon {
  height: 12px;
  width: 12px;
  stroke: var(--dark-4);
  margin-top: 1px;
  flex-shrink: 0;
  margin-right: 8px;
}
