.profile-attack-stats-mobile {
  margin: 16px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.profile-attack-stats-mobile__title {
  grid-column-start: 1;
  grid-column-end: 5;
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
}

.profile-attack-stats-mobile__items {
  grid-column-start: 1;
  grid-column-end: 4;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;
  /* flex-shrink: 0; */
}

.profile-attack-stats-mobile__items__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-attack-stats-mobile__items__item__value {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-1);
}

.profile-attack-stats-mobile__items__item__label {
  font-family: var(--nhg-display);
  font-weight: 600;
  font-size: 12px;
  color: var(--dark-4);
  margin-top: 8px;
  text-align: center;
}

.profile-attack-stats-mobile__graphic {
  grid-column-start: 4;
  grid-column-end: 5;
  position: relative;
  /* font-size: 76px;
  letter-spacing: -6px;
  font-weight: 700;
  font-style: italic; */
}

.profile-attack-stats-mobile__graphic__blue {
  color: rgba(19, 160, 250, 0.48);

  height: 76px;
  position: absolute;
  left: 69px;
  bottom: 8px;
  transform-origin: 0 76px;
  transform: rotateZ(-90deg);
}

.profile-attack-stats-mobile__graphic__green {
  color: rgba(99, 234, 71, 0.24);
  height: 76px;
  position: absolute;
  left: 73px;
  bottom: 5px;
  transform-origin: 0 76px;
  transform: rotateZ(-90deg);
}

.profile-shooting-stats-mobile {
  margin: 16px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  padding-bottom: 16px;
}

.profile-shooting-stats-mobile__title {
  grid-column-start: 1;
  grid-column-end: 5;
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
}

.profile-shooting-stats-mobile__items {
  padding-left: 16px;
  padding-right: 16px;
  grid-column-start: 1;
  grid-column-end: 5;
}

.profile-shooting-stats-mobile__items__item {
  margin-bottom: 24px;
}

.profile-shooting-stats-mobile__items__item__meta {
  display: flex;
  flex-direction: row;
  font-family: var(--nhg-display);
}

.profile-shooting-stats-mobile__items__item__meta__key {
  font-weight: 600;
  color: var(--dark-4);
}

.profile-shooting-stats-mobile__items__item__meta__value {
  font-weight: 700;
  color: var(--dark-1);
  margin-left: auto;
  margin-right: 8px;
}

.profile-shooting-stats-mobile__items__item__graph {
  margin-top: 12px;
}

.profile-shooting-stats-mobile__items__item__graph__track {
  height: 8px;
  border-radius: 4px;
  background: var(--light-3);
  overflow: hidden;
}

.profile-shooting-stats-mobile__items__item__graph__fill {
  height: 100%;
  border-radius: 4px;
  background: linear-gradient(75.26deg, #78c9fc 18.6%, #63ea47 100%);
}

.profile-shooting-stats-mobile__doughnut {
  grid-column: span 2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  padding-top: 0;
  /* -ms-grid-column-span: 2; */
}

.profile-shooting-stats-mobile__doughnut__meta {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.profile-shooting-stats-mobile__doughnut__meta__value {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-1);
}

.profile-shooting-stats-mobile__doughnut__meta__key {
  font-weight: 600;
  color: var(--dark-4);
  font-size: 10px;
  text-align: center;
}

.profile-shooting-stats-mobile__doughnut__bg {
  height: calc(100% + 0px);
  width: calc(100% + 0px);
  position: absolute;
  left: 3px;
  top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  padding-top: 0;
  z-index: 0;
  /* margin-top: 8px; */
  /* margin-right: 8px; */
}

.profile-passing-stats-mobile {
  margin: 16px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.profile-passing-stats-mobile__title {
  grid-column-start: 1;
  grid-column-end: 5;
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
}

.profile-passing-stats-mobile__items {
  padding-left: 16px;
  padding-right: 16px;
  grid-column-start: 1;
  grid-column-end: 5;
}

.profile-passing-stats-mobile__items__item {
}

.profile-passing-stats-mobile__items__item__meta {
  display: flex;
  flex-direction: row;
  font-family: var(--nhg-display);
}

.profile-passing-stats-mobile__items__item__meta__key {
  font-weight: 600;
  color: var(--dark-4);
}

.profile-passing-stats-mobile__items__item__meta__value {
  font-weight: 700;
  color: var(--dark-1);
  margin-left: auto;
  margin-right: 8px;
}

.profile-passing-stats-mobile__items__item__graph {
  margin-top: 12px;
  margin-bottom: 12px;
}

.profile-passing-stats-mobile__items__item__graph__track {
  height: 8px;
  border-radius: 4px;
  background: var(--light-3);
  overflow: hidden;
}

.profile-passing-stats-mobile__items__item__graph__fill {
  height: 100%;
  border-radius: 4px;
  background: linear-gradient(75.26deg, #78c9fc 18.6%, #63ea47 100%);
}

.profile-passing-stats-mobile__doughnut {
  grid-column: span 3;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  padding-top: 0;
  margin-top: 32px;
  margin-bottom: 32px;
}

.profile-passing-stats-mobile__doughnut__bg {
  height: calc(100% + 0px);
  width: calc(100% + 0px);
  position: absolute;
  left: 3px;
  top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  padding-top: 0;
  z-index: 0;
}

.profile-passing-stats-mobile__doughnut__meta {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.profile-passing-stats-mobile__doughnut__meta__value {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-1);
}

.profile-passing-stats-mobile__doughnut__meta__key {
  font-weight: 600;
  color: var(--dark-4);
  font-size: 10px;
  text-align: center;
}

.profile-passing-stats-mobile__graphic {
  grid-column-start: 4;
  grid-column-end: 5;
  position: relative;
  /* font-size: 64px; */
  /* letter-spacing: -6px; */
  /* font-weight: 700; */
  /* font-style: italic; */
}

.profile-passing-stats-mobile__graphic__blue {
  color: rgba(19, 160, 250, 0.48);

  height: 64px;
  position: absolute;
  left: 36px;
  bottom: -32px;
  transform-origin: 0 32px;
  transform: rotateZ(-90deg);
}

.profile-passing-stats-mobile__graphic__green {
  color: rgba(99, 234, 71, 0.24);
  height: 64px;
  position: absolute;
  left: 40px;
  bottom: -40px;
  transform-origin: 0 32px;
  transform: rotateZ(-90deg);
}

.profile-positions-played-mobile {
  margin: 16px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  padding-bottom: 16px;
}

.profile-positions-played-mobile__title {
  grid-column-start: 1;
  grid-column-end: 5;
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
}

.profile-positions-played-mobile__pitch {
  padding-left: 16px;
  padding-right: 16px;
  grid-column-start: 1;
  grid-column-end: 5;
}

.profile-participation-stats-mobile {
  margin: 16px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  margin-left: 0;
  margin-right: 0;
}

.profile-participation-stats-mobile__title {
  grid-column-start: 1;
  grid-column-end: 5;
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
}

.profile-participation-stats-mobile__table {
  grid-column-start: 1;
  grid-column-end: 5;
  display: flex;
  flex-direction: row;
}

.profile-participation-stats-mobile__table__column {
}

.profile-participation-stats-mobile__table__column__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: solid 1px var(--light-2);
  border-bottom: solid 1px var(--light-2);
  height: 24px;
  font-size: 10px;
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-4);
  padding-right: 12px;
}

.profile-participation-stats-mobile__table__column__header.with-image {
  justify-content: flex-start;
  padding-left: 16px;
}

.profile-participation-stats-mobile__table__column__rows {
}

.profile-participation-stats-mobile__table__td {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 44px;
  padding-right: 12px;
  color: var(--dark-1);
}

.profile-participation-stats-mobile__table__td.even {
  background: var(--light-2);
}

.profile-participation-stats-mobile__table__td.with-image {
  justify-content: flex-start;
  padding-left: 16px;
}

.profile-participation-stats-mobile__table__td__image {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-right: 4px;
  flex-shrink: 0;
}

.profile-participation-stats-mobile__table__td__meta {
  font-size: 10px;
  flex-grow: 1;
  overflow: hidden;
}

.profile-participation-stats-mobile__table__td__meta__primary {
  font-weight: 700;
  color: var(--dark-1);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-participation-stats-mobile__table__td__meta__secondary {
  font-weight: 400;
  color: var(--dark-3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-awards-stats-mobile {
  margin: 16px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.profile-awards-stats-mobile__title {
  grid-column-start: 1;
  grid-column-end: 5;
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
  padding-bottom: 0;
}

.profile-awards-stats-mobile__items {
  grid-column-start: 1;
  grid-column-end: 5;
}

.profile-awards-stats-mobile__items__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px;
}

.profile-awards-stats-mobile__items__item__img {
  height: 40px;
  width: 40px;
  background-color: var(--blue-subtle);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-awards-stats-mobile__items__item__desc {
  padding-left: 16px;
}

.profile-awards-stats-mobile__items__item__desc__primary {
  font-weight: 700;
  margin-bottom: 4px;
  color: var(--dark-1);
}

.profile-awards-stats-mobile__items__item__desc__secondary {
  color: var(--dark-3);
}
