.app-match-report {
  background: white;
  /* overflow: hidden; */
  margin-bottom: 32px;
  overflow: hidden;
  border-top-left-radius: 0px;
}

.app-match-report__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  height: 24px;
  font-family: var(--nhg-display);
  font-size: 10px;
  font-weight: 600;
  color: var(--dark-4);
  border-bottom: solid 1px var(--light-2);
  position: relative;
}

.app-match-report__header__title {
  z-index: 1;
  color: white;
}

.app-match-report__header__highlight {
  position: absolute;
  left: 0;
  top: 0;
  height: 24px;
  width: 104px;
  z-index: 0;
  transform: scaleY(-1);
}

.app-match-report__header__highlight__item {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-bottom: 24px solid;
  /* border-left: 12px solid transparent; */
  border-right: 8px solid transparent;
  height: 0;
}

.app-match-report__header__highlight__item.blue {
  border-bottom-color: var(--blue-darker);
  width: 92%;
}

.app-match-report__header__highlight__item.green {
  width: 100%;
  border-bottom-color: var(--green-main);

  /* width: 100px; */
}

.app-match-report__header__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.app-match-report__header__options__option {
  color: var(--dark-4);
  margin-left: 16px;
  cursor: pointer;
}

.app-match-report__header__options__option.edit {
  color: var(--orange-main);
}

.app-match-report__header__options__option.delete {
  color: var(--red-main);
}

.app-match-report__fixture {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: solid 1px var(--light-2);
}

.app-match-report__fixture__team {
  width: 47.5%;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
}

.app-match-report__fixture__team__image {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  border-radius: 8px;
  flex-shrink: 0;
  overflow: hidden;
}

.app-match-report__fixture__team__name {
  margin-left: 12px;
  margin-right: 12px;
  font-family: var(--nhg-display);
  font-size: 14px;
  font-weight: 600;
  color: var(--dark-1);
}

.app-match-report__fixture__team__score {
  font-family: var(--nhg-display);
  font-size: 14px;
  font-weight: 700;
  color: var(--dark-1);
}

.app-match-report__fixture__team.home .app-match-report__fixture__team__score {
  margin-left: auto;
}

.app-match-report__fixture__seperator {
  width: 5%;
  font-family: var(--nhg-display);
  font-size: 8px;
  font-weight: 600;
  color: var(--dark-4);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.app-match-report__fixture__team.away {
}

.app-match-report__fixture__team.away .app-match-report__fixture__team__image {
}

.app-match-report__fixture__team.away .app-match-report__fixture__team__name {
  margin-left: auto;
  text-align: right;
}

.app-match-report__fixture__team.away .app-match-report__fixture__team__score {
}

.app-match-report__image {
}

.app-match-report__image img {
}

.app-match-report__headline {
  font-family: var(--nhg-display);
  font-size: 20px;
  font-weight: 700;
  color: var(--dark-1);
  padding: 16px;
  padding-bottom: 8px;
}

.app-match-report__content {
  color: var(--dark-2);
}

.app-match-report__content__truncate-btn {
  padding-left: 16px;
  padding-right: 16px;
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--blue-darker);
}

.app-match-report__footer {
  border-top: solid 1px var(--light-2);
  margin-top: 16px;
  /* padding-top: 16px; */
  /* padding-bottom: 8px; */
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.app-match-report__footer__option {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.app-match-report__footer__options.right {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.app-match-report__footer__options.right .app-match-report__footer__option {
  margin-left: 8px;
}

.app-match-report__footer__option .clap-count {
  margin-left: 0px;
  font-weight: 700;
  color: var(--dark-4);
}

/* .app-match-report .ql-container {
  border: none;
} */

.app-match-report .ql-editor {
  /* padding: 0; */
  font-family: var(--nhg);
  line-height: 20px;
  font-size: 12px;
}
