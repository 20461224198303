.auth-page {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  background: white;
  overflow: auto;
}

.auth-page .form-container {
  grid-column-start: 1;
  grid-column-end: 2;
  height: 100%;
  padding-left: 16.66%;
  padding-right: 16.66%;
  padding-top: 48px;
  padding-bottom: 48px;
}

.auth-page .form-container .logo {
  height: 32px;
}

.auth-page .form-container .logo img {
  height: 100%;
}

.auth-page .form-container .auth-page-title {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 32px;
  /* margin-top: 48px; */
  display: flex;
  flex-direction: row;
  align-items: center;

  /* justify-content: center; */
  /* line-height: 44px; */
}

.auth-page .form-container .auth-page-title .logo {
  height: 24px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-page .form-container .auth-page-title .logo img {
  height: 24px;
}

.auth-page .form-container .auth-page-secondary-title {
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
  color: var(--dark-4);
}

.auth-page .text-link {
  text-decoration: underline;
}

.auth-page .text-link:hover {
  color: var(--blue-main);
}

.auth-page .auth-carousel {
  grid-column-start: 2;
  grid-column-end: 3;
  height: 100%;
  /* padding: 8.33%; */
  /* padding-left: 0; */
  /* border-radius: 8px; */
  /* overflow: hidden; */
}

.auth-page .auth-carousel .carousel-content-wrapper {
  height: 100%;
  background: var(--blue-lighter);
  /* border-radius: 16px; */
}
