.fixture-stats-mobile__football {
  padding-bottom: 528px;
}

.fixture-stats-mobile__football__match-stats {
  padding: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  margin-top: 12px;
}

.fixture-stats-mobile__football__match-stats__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 700;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
  text-align: center;
}

.fixture-stats-mobile__football__match-stats__items {
  border: solid 1px var(--light-3);
  border-radius: 8px;
  margin-top: 8px;
  padding-bottom: 12px;
}

.fixture-stats-mobile__football__match-stats__possession-graph {
  background: var(--light-2);
  margin: 4px;
  padding: 4px;
  padding-bottom: 12px;
}

.fixture-stats-mobile__football__match-stats__possession-graph__meta {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  /* padding-left: 12px;
  padding-right: 12px; */
  padding: 8px;
}

.fixture-stats-mobile__football__match-stats__possession-graph__meta__team {
  /* height: 24px; */
  /* width: 24px; */

  display: flex;
  flex-direction: row;
}

.fixture-stats-mobile__football__match-stats__possession-graph__meta__team__logo {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
}

.fixture-stats-mobile__football__match-stats__possession-graph__meta__team__logo
  img {
  height: auto;
  width: auto;
  max-height: 100%;
  min-height: 100%;
}

.fixture-stats-mobile__football__match-stats__possession-graph__meta__label {
  text-align: center;
  font-weight: 700;
  font-family: var(--nhg-display);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark-2);
}

.fixture-stats-mobile__football__match-stats__possession-graph__meta__team.away {
  justify-content: flex-end;
}

.fixture-stats-mobile__football__match-stats__possession-graph__graph {
  height: 24px;
  background: var(--light-3);
  /* margin-top: 8px; */
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow: hidden;
  /* padding: 2px; */
}

.fixture-stats-mobile__football__match-stats__possession-graph__graph__value {
  height: 20px;
  width: 32px;
  border-radius: 20px;
  background: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 10px;
  z-index: 1;
}

.fixture-stats-mobile__football__match-stats__possession-graph__graph__value.home {
  left: 2px;
  color: var(--blue-main);
}

.fixture-stats-mobile__football__match-stats__possession-graph__graph__value.away {
  right: 2px;
  color: var(--green-darker);
}

.fixture-stats-mobile__football__match-stats__possession-graph__graph__track {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  position: relative;
  height: 24px;
}

.fixture-stats-mobile__football__match-stats__possession-graph__graph__track__section {
  display: flex;
  flex-direction: row;
}

.fixture-stats-mobile__football__match-stats__possession-graph__graph__track__section.home {
  border-right: solid 1px var(--light-1);
  justify-content: flex-end;
}

.fixture-stats-mobile__football__match-stats__possession-graph__graph__track__section.away {
  border-left: solid 1px var(--light-1);
}

.fixture-stats-mobile__football__match-stats__possession-graph__graph__track__icon {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: white;
  top: 2px;
  left: calc(50% - 10px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixture-stats-mobile__football__match-stats__possession-graph__graph__track__section__fill {
  /* width: 90%; */
  height: 24px;
}

.fixture-stats-mobile__football__match-stats__possession-graph__graph__track__section.home
  .fixture-stats-mobile__football__match-stats__possession-graph__graph__track__section__fill {
  background: var(--blue-main);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.fixture-stats-mobile__football__match-stats__possession-graph__graph__track__section.away
  .fixture-stats-mobile__football__match-stats__possession-graph__graph__track__section__fill {
  background: var(--green-main);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.match-stats-item-mobile {
  padding: 12px;
}

.match-stats-item-mobile__meta {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  /* margin-bottom: 8px; */
  padding: 8px;
}

.match-stats-item-mobile__meta__value {
  font-weight: 700;
  color: var(--dark-2);
}

.match-stats-item-mobile__meta__value.home {
}

.match-stats-item-mobile__meta__value.away {
  text-align: right;
}

.match-stats-item-mobile__meta__label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.match-stats-item-mobile__graph {
  width: 100%;
  height: 8px;
  background-color: var(--light-2);
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  overflow: hidden;
}

.match-stats-item-mobile__graph__section {
  display: flex;
  flex-direction: row;
}

.match-stats-item-mobile__graph__section.home {
  justify-content: flex-end;
}

.match-stats-item-mobile__graph__section.away {
}

.match-stats-item-mobile__graph__section__fill {
  height: 8px;
  /* width: 50%; */
}

.match-stats-item-mobile__graph__section.home
  .match-stats-item-mobile__graph__section__fill {
  background: var(--blue-main);
}

.match-stats-item-mobile__graph__section.away
  .match-stats-item-mobile__graph__section__fill {
  background: var(--green-main);
}

.match-player-stats-mobile {
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  margin-top: 12px;
}

.match-player-stats-mobile__header {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 700;
  font-size: 12px;
  /* line-height: 14px; */
  position: sticky;
  top: 40px;
  /* Dark / Dark 4 */
  z-index: 3;
  background: white;

  color: var(--dark-1);
  text-align: center;
  border-bottom: solid 1px var(--green-main);
  box-shadow: var(--elevation-mobile);

  /* padding-left: 12px; */
}

.match-player-stats-mobile__header__wrapper {
  /* padding: 12px; */
  height: 48px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 2px var(--blue-main);
}

.match-player-stats-mobile__header__team-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--dark-1);
}

.match-player-stats-mobile__header__team-selector__image {
  height: 20px;
  width: 20px;
  background: var(--blue-subtle);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 8px;
}

.match-player-stats-mobile__header__team-selector__image img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.match-player-stats-mobile__header__category-selector {
  margin-left: auto;
}

.match-player-stats-mobile__table {
  display: flex;
  flex-direction: row;
  position: relative;
  /* position: relative; */
  /* border-top: solid 2px var(--green-main); */
}

.match-player-stats-mobile__table__fixed-columns {
  width: 50%;
  display: flex;
  flex-direction: row;
  z-index: 1;
  border-right: solid 1px var(--light-2);

  /* box-shadow: var(--elevation-mobile); */
  /* background: white; */
}

.match-player-stats-mobile__table__scroll-columns {
  width: 50%;
  /* position: absolute; */
  /* left: 50%; */
  /* top: 0; */

  display: flex;
  flex-direction: row;
  overflow-x: auto;
  background: white;
  /* flex-wrap: nowrap; */
}

.match-player-stats-mobile__table__scroll-columns.inset {
  box-shadow: inset 2px 0 9px -7px rgba(0, 0, 0, 0.4);
}

.match-player-stats-mobile__table__fixed-columns.inset {
  border-color: var(--light-3);
}

.match-player-stats-mobile__table__column {
  min-width: 42%;
  flex-shrink: 0;
  border-right: solid 1px var(--light-2);
}

.match-player-stats-mobile__table__fixed-columns
  .match-player-stats-mobile__table__column {
  width: 100%;
}

/* .match-player-stats-mobile__table__scroll-columns
  .match-player-stats-mobile__table__column {
  width: 50%;
  border-left: solid 1px var(--light-2);
} */

.match-player-stats-mobile__table__header {
  padding-left: 12px;
  padding-right: 12px;
  font-size: 8px;
  font-family: var(--nhg-display);
  letter-spacing: 0.4px;
  height: 24px;
  display: flex;
  align-items: center;
  color: var(--dark-4);
  background: white;
  z-index: 2;
  font-weight: 600;
  /* width: 100%; */
  /* position: sticky; */
  /* top: 88px; */

  /* justify-content: center; */
  /* border-bottom: solid 1px var(--light-2); */
}

.match-player-stats-mobile__table__header.fixed {
  position: fixed;
  top: 136px;
}

.match-player-stats-mobile__table__rows {
}

.match-player-stats-mobile__table__rows__row {
  display: flex;
  flex-direction: row;
}

.match-player-stats-mobile__table__rows__row__td {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  font-weight: 600;
}

.match-player-stats-mobile__table__rows__row__td.odd {
  background: var(--light-2);
}

.match-player-stats-mobile__table__rows__row__td.even {
  background: var(--light-1);
}

.match-player-stats-mobile__table__scroll-columns
  .match-player-stats-mobile__table__rows__row__td {
  justify-content: flex-end;
}
.match-player-stats-mobile__table__scroll-columns
  .match-player-stats-mobile__table__header {
  justify-content: flex-end;
  /* position: sticky;
  top: 0px; */
}

.match-player-stats-mobile__table__rows__row__td__image {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: var(--blue-subtle);
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.match-player-stats-mobile__table__rows__row__td__image img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.match-player-stats-mobile__table__rows__row__td__text {
}
