.manage-event-participants {
  padding: 16px;
}

.manage-event-participants .card-title {
  font-weight: 600;
  color: var(--dark-4);
}

.manage-event-participants .participants-list {
}

.manage-event-participants .event-participant {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.manage-event-participants .event-participant .image {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-right: 16px;
  overflow: hidden;
}

.manage-event-participants .event-participant .image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.manage-event-participants .event-participant .primary-text {
  font-weight: 600;
  margin-bottom: 4px;
}

.manage-event-participants .event-participant .secondary-text.green {
  color: var(--green-darker);
}

.manage-event-participants .event-participant .secondary-text.red {
  color: var(--orange-main);
}

.manage-event-participants .event-participant .options {
  margin-left: auto;
}

.manage-event-participants .event-participant .options .btn {
  background: var(--light-2);
  height: 24px;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  color: var(--dark-4);
  cursor: pointer;
}

.manage-event-participants .event-participant .options .btn.success {
  background: var(--green-main);
  color: white;
}

.participant-type-form {
  display: flex;
  flex-direction: row;
}

.participant-type-form .form-group-input {
  flex-grow: 1;
}

.participant-type-form .options {
  display: flex;
  flex-direction: row;
}

.participant-type-form .options .btn {
  height: 32px;
  width: 32px;
  border: none;
  outline: none;
  border-radius: 4px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.participant-type-form .options .btn .icon {
  height: 14px;
}

.participant-type-form .options .btn.submit {
  fill: white;
  background: var(--green-main);
}

.participant-type-form .options .btn.cancel {
  background: none;
  stroke: var(--red-main);
}

.manage-event-parties .participant-type-card {
  /* margin-top: 32px; */
  padding: 16px;
  margin-bottom: 32px;
}

.manage-event-parties .participant-type-card .info-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-event-parties .participant-type-card .info-group .info-group-label {
  font-weight: 600;
  color: var(--dark-3);
}

.manage-event-parties .participant-type-card .info-group .info-group-value {
  margin-left: auto;
}

.manage-event-parties .participant-type-card .info-group .edit-btn {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--orange-subtle);
  fill: var(--orange-main);
  margin-left: 16px;
  cursor: pointer;
}

.manage-event-parties .participant-type-card .info-group .edit-btn .icon {
  height: 14px;
}

.manage-event-participants .suggested-participants-list {
  border-top: solid 1px var(--light-3);
  margin-top: 16px;
}

.participant-type-card__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  border-top: solid 1px var(--light-2);
  padding-top: 16px;
  color: var(--dark-3);
}

.participant-type-card__info__icon {
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-event-participants__info {
  color: var(--dark-3);
  margin-top: 16px;
  padding-top: 16px;
  border-top: solid 1px var(--light-2);
}

.manage-event-participants__info__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  font-weight: 700;
}

.manage-event-participants__roles {
  margin-top: 32px;
}

.manage-event-participants__roles .card-title {
  font-weight: 600;
  color: var(--dark-4);
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-event-participants__roles__add-btn {
  margin-left: auto;
  cursor: pointer;
  color: var(--blue-main);
}

.manage-event-participants__roles__list {
  /* padding: 16px; */
  padding-top: 0;
}

.manage-event-participants__roles__list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.manage-event-participants__roles__list__item__image {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  border-radius: 8px;
  /* margin-bottom: 8px; */
  margin-right: 16px;
  overflow: hidden;
}

.manage-event-participants__roles__list__item__content {
  flex-grow: 1;
}

.manage-event-participants__roles__list__item__content__primary {
  font-weight: 600;
  margin-bottom: 4px;
}

.manage-event-participants__roles__list__item__content__secondary {
  color: var(--dark-4);
  margin-bottom: 4px;
  font-size: 10px;
}

.manage-event-participants__roles__list__item__options {
}

.manage-event-participants__roles__list__item__options__delete {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.manage-event-participants__roles__list__item__options__delete:hover {
  background: var(--light-2);
  border-radius: 4px;
}

.manage-event-participants__roles__list__group {
}

.manage-event-participants__roles__list__group__title {
  background: var(--light-2);
  color: var(--dark-4);
  font-size: 10px;
  font-weight: 700;
  padding-left: 16px;
  padding-right: 16px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-event-participants__roles__list__group__items {
  padding: 16px;
  padding-top: 0;
}
