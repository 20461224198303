.event-overview-mobile {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 40px;
}

.event-overview-mobile__about-wrapper {
  margin-top: 12px;
}

.event-overview-mobile__placements-wrapper {
  margin-top: 12px;
}

.event-overview-mobile__highlights-wrapper {
  margin-top: 12px;
}

.event-overview-mobile__results-wrapper {
  margin-top: 12px;
}

.event-overview-mobile__standings-wrapper {
  margin-top: 12px;
}

.event-overview-mobile__roles-wrapper {
  margin-top: 12px;
}

.event-overview-mobile__awards-wrapper {
  margin-top: 12px;
}
