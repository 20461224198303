.event-placements-card {
  margin-top: 32px;
  position: relative;
  padding: 16px;
}

.event-placements-card__title {
  font-weight: 600;
  color: var(--dark-4);
  /* padding: 16px;
  padding-bottom: 0; */
}

.event-placements-card__item {
  /* position: absolute; */

  display: flex;
  flex-direction: row;
  align-items: center;
  /* background: red; */
  /* width: 18%; */
  cursor: pointer;
  margin-top: 16px;
  margin-bottom: 8px;
  /* transform-origin: 50%; */
}

.event-placements-card__item .event-placements-card__item__img {
  /* height: 80px; */
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  /* opacity: 50%; */
  border-radius: 8px;
  overflow: hidden;
}

.event-placements-card__item .event-placements-card__item__img img {
  width: 100%;
}

.event-placements-card__item__text {
  padding-left: 16px;
}

.event-placements-card__item .event-placements-card__item__text__primary {
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 4px;
  /* text-align: center; */
  /* margin-top: 8px; */
}

.event-placements-card__item .event-placements-card__item__text__secondary {
  color: var(--dark-4);
  font-weight: 100;
}

.event-placements-card__item .event-placements-card__item__icon {
  margin-left: auto;
}
