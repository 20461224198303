.ScoringApp-football__scoring__period__list__penalty-row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  border-bottom: solid 1px var(--light-2);
  height: 40px;
  background: white;
}

.ScoringApp-football__scoring__period__list__penalty-row__section {
  display: flex;
  flex-direction: row;
}

.ScoringApp-football__scoring__period__list__penalty-row__section.away {
  flex-direction: row-reverse;
}

.ScoringApp-football__scoring__period__list__penalty-row__section__options {
  border-right: solid 1px var(--light-2);
  border-left: solid 1px var(--light-2);
  width: 40px px;
}

.ScoringApp-football__scoring__period__list__penalty-row__section__options__option {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ScoringApp-football__scoring__period__list__penalty-row__section__body {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScoringApp-football__scoring__period__list__penalty-row__section__body__statement {
  font-weight: 700;
}

.ScoringApp-football__scoring__period__list__penalty-row__section__body__option {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: var(--light-4);
  /* text-decoration: underline; */
  cursor: pointer;
}

.ScoringApp-football__scoring__period__list__penalty-row__indicator {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: solid 1px var(--light-2);
  border-left: solid 1px var(--light-2);
}

.ScoringApp-football__scoring__period__list__penalty-row__indicator__icon {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: var(--light-2);
}

.ScoringApp-football__scoring__period__list__penalty-row__indicator__icon.green {
  background: var(--green-main);
}

.ScoringApp-football__scoring__period__list__penalty-row__indicator__icon.red {
  background: var(--red-main);
}
