.notifications-menu {
  background: white;
  margin-top: 2px;
  box-shadow: var(--elevation-5);
  border-radius: 8px;
  /* border-top-right-radius: 0; */
  overflow: hidden;
  width: 400px;
  z-index: 5;
  position: relative;
}

.notifications-menu .notifications-menu-header {
  padding: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-2);
  border-bottom: solid 1px var(--light-2);
}

.notifications-menu .notifications-list {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

.notifications-menu .notifications-list .notifications-list-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px var(--light-2);
}

.notifications-menu .notifications-list .notifications-list-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.notifications-menu .notifications-list .notifications-list-item .image {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-right: 16px;
  flex-shrink: 0;
  overflow: hidden;
}

.notifications-menu
  .notifications-list
  .notifications-list-item
  .text
  .primary-text {
  line-height: 16px;
}

.notifications-menu .notifications-list .notifications-list-item .text .date {
  font-size: 10px;
  color: var(--dark-4);
  /* margin-top: 4px; */
}

.notifications-menu .notifications-menu-footer {
  border-top: solid 1px var(--light-2);
  padding: 12px;
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 12px;

  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.notifications-menu .notifications-menu-footer a {
  color: var(--blue-lighter);
}
