.ScoringApp-football__scoring {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 24% 52% 24%;
  grid-template-rows: 100%;
  background: var(--blue-darkest);
  position: relative;
}

.ScoringApp-football__scoring__header {
  height: 48px;
  width: 100%;
  background: var(--blue-darker);
  color: white;
  font-weight: 700;
}

.ScoringApp-football__scoring__left-column {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ScoringApp-football__scoring__middle-column {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ScoringApp-football__scoring__right-column {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ScoringApp-football__scoring__header {
  flex-shrink: 0;
}

.ScoringApp-football__scoring__left-column
  .ScoringApp-football__scoring__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
}

.ScoringApp-football__scoring__middle-column
  .ScoringApp-football__scoring__header {
  display: flex;
  flex-direction: row;
}

.ScoringApp-football__scoring__header__team {
  display: flex;
  align-items: center;
  width: 40%;
  padding-left: 12px;
  padding-right: 12px;
}

.ScoringApp-football__scoring__header__team__img {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: var(--blue-subtle);
  overflow: hidden;
  /* margin-left: 16px; */
  /* margin-right: 16px; */
}

.ScoringApp-football__scoring__header__team__name {
  margin-left: 16px;
  margin-right: 16px;
}

.ScoringApp-football__scoring__header__team.away {
  flex-direction: row-reverse;
}

.ScoringApp-football__scoring__header__score {
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.ScoringApp-football__scoring__header__score__seperator {
  margin-left: 8px;
  margin-right: 8px;
  padding-bottom: 4px;
}

.ScoringApp-football__scoring__right-column
  .ScoringApp-football__scoring__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
}

.ScoringApp-football__scoring__stat-types {
  flex-grow: 1;
  overflow: auto;
  /* border-right: solid 1px var(--light-2);
  border-left: solid 1px var(--light-2); */
  /* background: white; */
  padding: 12px;
  transition: all 0.2s ease-out;
  will-change: opacity;
}

.ScoringApp-football__scoring__stat-types.hidden {
  opacity: 0;
}

.ScoringApp-football__scoring__stat-types__groups {
  border-radius: 8px;
  overflow: hidden;
}

.ScoringApp-football__scoring__stat-types__group {
}

.ScoringApp-football__scoring__stat-types__group__label {
  background: var(--blue-subtle);
  font-size: 8px;
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--blue-darkest);
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
}

.ScoringApp-football__scoring__stat-types__group__types {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  background: white;
}

.ScoringApp-football__scoring__stat-types__group__types__type {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  font-family: var(--nhg-display);
  border-right: solid 1px var(--light-2);
  border-bottom: solid 1px var(--light-2);
  cursor: pointer;
  color: var(--dark-3);
  transition: all 0.2s ease-out;
}

.ScoringApp-football__scoring__stat-types__group__types__type:hover {
  background: var(--light-3);
}

.ScoringApp-football__scoring__period {
  margin-top: 12px;
  flex-grow: 1;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ScoringApp-football__scoring__period__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-bottom: solid 1px var(--light-2);
  flex-shrink: 0;
  background: var(--blue-darker);
}
.ScoringApp-football__scoring__period__header__selected-period {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding-left: 12px;
  padding-right: 4px;
  border-radius: 4px;
  background: var(--blue-darkest);
  color: white;
  font-weight: 600;
}
.ScoringApp-football__scoring__period__header__selected-period__icon {
  fill: var(--light-4);
  height: 16px;
}
.ScoringApp-football__scoring__period__header__selected-period__menu {
  overflow: hidden;
}
.ScoringApp-football__scoring__period__header__selected-period__menu__item {
  text-align: center;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: solid 1px var(--light-2);
  cursor: pointer;
}

.ScoringApp-football__scoring__period__header__selected-period__menu__item:hover {
  background: var(--light-2);
}

.ScoringApp-football__scoring__period__header__timing {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.ScoringApp-football__scoring__period__header__timing__current {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-darkest);
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  border-radius: 4px;
  /* color: white; */
  /* font-weight: 700; */
  margin-left: 16px;
  color: white;
  font-weight: 600;
}

.ScoringApp-football__scoring__period__header__timing__current__option {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
}

.ScoringApp-football__scoring__period__header__timing__current__option__icon {
  height: 14px;
  fill: var(--light-2);
}

.ScoringApp-football__scoring__period__header__timing__current__time {
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScoringApp-football__scoring__period__header__timing__current__time__input {
  width: 32px;
  padding: 0;
  border: none;
  height: 20px;
  padding-left: 8px;
  border-radius: 2px;
  outline: none;
  background: var(--light-2);
  font-family: var(--nhg);
  font-size: 12px;
}

/* Chrome, Safari, Edge, Opera */
.ScoringApp-football__scoring__period__header__timing__current__time__input::-webkit-outer-spin-button,
.ScoringApp-football__scoring__period__header__timing__current__time__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.ScoringApp-football__scoring__period__header__timing__current__time__input[type="number"] {
  -moz-appearance: textfield;
}

.ScoringApp-football__scoring__period__header__timing__current__time__seperator {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 2px;
}

.ScoringApp-football__scoring__period__header__timing__opt {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-weight: 700;
  height: 32px;
  background: var(--blue-darkest);
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 12px;
  cursor: pointer;
}

.ScoringApp-football__scoring__period__header__timing__opt__icon {
  height: 14px;
  fill: var(--light-2);
}

.ScoringApp-football__scoring__period__list {
  flex-grow: 1;
  overflow: auto;
  background: var(--light-2);
  padding-bottom: 40px;
}

.ScoringApp-football__scoring__period__list__show-more {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 40px;
}

.ScoringApp-football__scoring__period__list__show-more__btn {
  cursor: pointer;
  font-weight: 600;
  color: var(--blue-darkest);
  background: var(--blue-subtle);
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.ScoringApp-football__scoring__period__list__item {
  margin-top: 16px;
  /* display: flex; */
  /* flex-direction: row; */
  display: grid;
  grid-template-columns: 45% 1fr 45%;
  grid-column-gap: 16px;
}

.ScoringApp-football__scoring__period__list__item__section {
  display: flex;
  flex-direction: row;
}

.ScoringApp-football__scoring__period__list__item__section__options {
  margin-right: 8px;
  margin-left: 8px;
}

.ScoringApp-football__scoring__period__list__item__section__options__option {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}

.ScoringApp-football__scoring__period__list__item__section__statements {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  padding-left: 8px;
  padding-right: 8px;
}

.ScoringApp-football__scoring__period__list__item__section__timestamp {
  background: var(--blue-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 36px;
  font-weight: 700;
  color: var(--blue-darkest);
}

.ScoringApp-football__scoring__period__list__item__section__statements__statement {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--dark-2);
}

.ScoringApp-football__scoring__period__list__item__section__statements__statement
  .bold {
  font-weight: 700;
}

.ScoringApp-football__scoring__overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
