.event-placements-mobile {
  padding: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  padding-bottom: 16px;
}

.event-placements-mobile__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.event-placements-mobile__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background: red; */
  /* width: 18%; */
  cursor: pointer;
  margin-top: 12px;
  /* margin-bottom: 8px; */
}

.event-placements-mobile__item__img {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  /* opacity: 50%; */
  border-radius: 8px;
  overflow: hidden;
}

.event-placements-mobile__item__img img {
  width: 100%;
}

.event-placements-mobile__item__text {
  padding-left: 16px;
}

.event-placements-mobile__item__text__primary {
  font-weight: 700;
  /* font-size: 12px; */
  margin-bottom: 4px;
}

.event-placements-mobile__item__text__secondary {
  color: var(--dark-4);
  font-weight: 100;
}

.event-placements-mobile__item__icon {
  margin-left: auto;
}
