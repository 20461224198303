.ScoringApp-football__settings__penalty-settings {
  margin-top: 16px;
  overflow: hidden;
}

.ScoringApp-football__settings__penalty-settings__title {
  background: var(--blue-main);
  color: var(--light-2);
  font-weight: 700;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.ScoringApp-football__settings__penalty-settings__body {
}

.ScoringApp-football__settings__penalty-settings__body__content {
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ScoringApp-football__settings__penalty-settings__body__content__blurb {
  flex-grow: 1;
  padding-right: 16px;
}
