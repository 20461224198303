.requests-list-mobile {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding-top: 48px;
}

.requests-list-mobile__nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 48px;
  background: white;
  /* box-shadow: 0px 0px 3px rgba(40, 41, 61, 0.08); */
  box-shadow: 0px 0px 4px 1px rgba(40, 41, 61, 0.08);
  /* padding-left: 16px; */
  padding-right: 16px;
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.requests-list-mobile__nav__back-btn {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.requests-list-mobile__nav__content {
  font-weight: 600;
  color: var(--dark-1);
  font-family: var(--nhg-display);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.requests-list-mobile__nav__content__img {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-right: 12px;
}

.requests-list-mobile__nav__indicator {
  margin-left: auto;
}

.requests-list-mobile__body {
  padding: 12px;
}

.requests-list-mobile .home-request-card {
  margin-bottom: 12px;
  box-shadow: var(--elevation-mobile);
}

.requests-list-mobile .home-request-card .bold {
  font-weight: 700;
}
