.event-page {
}

.event-page .cover {
  height: 240px;
  background: var(--blue-subtle);
  background: var(--blue-main);
  background: rgb(185, 227, 254);
  background: linear-gradient(
    90deg,
    rgba(185, 227, 254, 1) 0%,
    rgba(235, 247, 255, 1) 100%
  );
  /* background: linear-gradient(
    90deg,
    rgba(120, 201, 252, 1) 0%,
    rgba(185, 227, 254, 1) 100%
  ); */
  /* position: relative; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.event-page .cover img {
  /* position: absolute; */
  width: 100%;
  z-index: -1;
  position: relative;
}

.event-page .cover .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #022e4b;
  z-index: 0;
  opacity: 0.01;
}

.event-page .cover .edit-cover-btn {
  height: 24px;
  /* width: 32px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: var(--orange-lighter);
  fill: var(--light-2);
  position: absolute;
  right: 48px;
  top: 24px;
  cursor: pointer;
  padding-left: 4px;
  padding-right: 12px;
  font-size: 10px;
  color: white;
  font-weight: 700;
  transition: all 0.2s ease-out;
}

.event-page .edit-profile-photo-btn {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: var(--orange-lighter);
  fill: var(--light-2);
  position: absolute;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.event-page .cover .edit-cover-btn .icon {
  height: 14px;
}

.event-page .head {
  background: white;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  /* height: 160px; */
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.event-page .head .organizer {
  height: 56px;
  width: 56px;
  background: var(--blue-subtle);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.event-page .head .organizer img {
  width: 100%;
}

.event-page .head .meta {
  padding-left: 24px;
}

.event-page .head .meta .title {
  font-size: 28px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 8px;
  color: var(--dark-2);
}

.event-page .head .meta .secondary {
  color: var(--dark-4);
}

.event-page .head .options {
  margin-left: auto;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.event-page .head .options .option {
  margin-left: 24px;
}

.event-page .head .options .option.follow {
  width: 40px;
  padding: 0;
}

.event-page .tabs {
  background: white;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  border-top: solid 1px var(--light-2);
  border-bottom: solid 1px var(--light-2);
  height: 40px;
  padding-left: 48px;
  padding-right: 48px;
}

.event-page .tabs .tab-item {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-right: 32px;
  color: var(--dark-4);
  font-weight: 500;
  border-bottom: solid 2px transparent;
  padding-top: 2px;
}

.event-page .tabs .tab-item.active {
  color: var(--dark-2);
  /* font-weight: 600; */
  border-bottom: solid 2px var(--blue-main);
}

.event-page .body {
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 48px;
}
