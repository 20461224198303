.fixture-points-timeline-mobile {
  margin-top: 12px;
  padding: 16px;
}

.fixture-points-timeline-mobile__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: flex-end; */
  margin-bottom: 16px;
}

.fixture-points-timeline-mobile__header__title {
  margin-right: auto;
  font-weight: 700;
  color: var(--dark-4);
}

.fixture-points-timeline-mobile__header__select-period {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-points-timeline-mobile__select-period-menu__item {
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}

.fixture-points-timeline-mobile__select-period-menu__item:hover {
  background: var(--light-2);
}

.fixture-points-timeline-mobile__body {
  height: 320px;
}
