.albums-card-mobile {
  background: var(--light-1);
  margin-top: 12px;
  border-radius: 12px;
  box-shadow: var(--elevation-2);
  padding: 12px;
  padding-bottom: 12px;
}

.albums-card-mobile__header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.albums-card-mobile__header__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.albums-card-mobile__header__btn {
  margin-left: auto;
  color: var(--blue-main);
  cursor: pointer;
  font-weight: 600;
}

.albums-card-mobile__gallery {
}

.albums-card-mobile__gallery__item {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}

.albums-card-mobile__gallery__item__image {
  height: 48px;
  width: 48px;
  border-radius: 4px;
  background: var(--blue-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.albums-card-mobile__gallery__item__image img {
  max-height: 100%;
  width: auto;
}

.albums-card-mobile__gallery__item__desc {
  padding-left: 12px;
}

.albums-card-mobile__gallery__item__desc__primary {
  font-weight: 600;
  font-family: var(--nhg-display);
  color: var(--dark-2);
}

.albums-card-mobile__footer {
  margin-top: 12px;
  padding-top: 8px;
  /* padding-bottom: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: solid 1px var(--light-2);
  font-size: 10px;
  font-weight: 600;
  color: var(--dark-4);
}

.albums-card-mobile__footer__show-more {
}
