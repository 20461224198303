.modal-form-mobile {
}

.modal-form-mobile-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: var(--light-2); */
}

.modal-form-mobile {
  /* height: 90%; */
  width: 90%;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  max-height: 90%;
  display: flex;
  flex-direction: column;
}

.modal-form-mobile__title {
  /* height: 40px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
  /* background: var(--blue-darker); */
  padding-left: 16px;
}

.modal-form-mobile__title__icon-left {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.modal-form-mobile__title__text {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 700;
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 16px;
  /* line-height: 24px; */
  /* display: flex; */
  /* align-items: center; */

  /* Dark / Dark 1 */

  color: var(--dark-1);
}

.modal-form-mobile__title__dismiss-btn {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.modal-form-mobile__secondary-text {
  padding: 16px;
  padding-bottom: 0px;
  /* font-size: 12px; */
}

.modal-form-mobile__form {
  height: 100%;
}

.modal-form-mobile__form__group {
  padding: 16px;
  padding-bottom: 8px;
}

.modal-form-mobile__form__group__label {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 10px;
  /* line-height: 14px; */
  /* identical to box height, or 140% */

  letter-spacing: 0.2px;

  /* Dark / Dark 4 */

  color: var(--dark-4);
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal-form-mobile__form__group__label .char-count {
  margin-left: auto;
}

.modal-form-mobile__form__group__input {
  height: 36px;
  width: 100%;
  border-radius: 8px;
  border: solid 1px var(--light-3);
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
}

.modal-form-mobile__form__group__input:focus-within {
  border-color: var(--blue-main);
}

.modal-form-mobile__form__group__input.error {
  border-color: var(--red-main);
}

.modal-form-mobile__form__group__input input {
  height: 100%;
  flex-grow: 1;
  font-family: var(--nhg);
  font-weight: 500;
  font-size: 12px;
  color: var(--dark-2);
  border: none;
  outline: none;
  min-width: 0;
  padding-left: 8px;
  padding-right: 8px;
}

.modal-form-mobile__form__group__textarea {
  min-height: 160px;
  max-height: 320px;
  width: 100%;
  border-radius: 8px;
  border: solid 1px var(--light-3);
  position: relative;
  flex-wrap: nowrap;
}

.modal-form-mobile__form__group__textarea textarea {
  height: auto;
  width: 100%;
  min-height: 160px;
  max-height: 320px;
  padding: 8px;
  font-family: var(--nhg);
  outline: none;
  border: none;
}

.modal-form-mobile__form__group__input__select {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
}

.modal-form-mobile__form__group__input__select__icon {
  margin-left: auto;
}

.modal-form-mobile__form__options {
  padding: 16px;
}

.modal-form-mobile__form__options__submit {
  /* margin-top: 8px; */
  /* margin-bottom: 24px; */
  /* margin: 8px; */
  /* margin-left: 16px; */
  /* margin-right: 16px; */
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-main);
  border-radius: 8px;
  outline: none;
  border: none;

  font-family: var(--nhg-display);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  /* line-height: 19px; */
  /* or 158% */

  color: var(--light-1);
  box-shadow: var(--elevation-primary);
  /* display: inline-block; */
}

.modal-form-mobile__form__options__cancel {
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: var(--blue-main); */
  border-radius: 8px;
  outline: none;
  border: none;

  font-family: var(--nhg-display);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  /* line-height: 19px; */
  /* or 158% */

  color: var(--dark-3);
  margin-top: 16px;
}

.modal-form-mobile__body {
  flex-grow: 1;
  overflow: hidden;
  display: flex;

  flex-direction: column;
}

.modal-form-mobile__body__interests-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 16px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
}

.modal-form-mobile__body__interests-grid .interest {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
  /* border: solid 1px var(--light-3); */
  box-shadow: var(--elevation-2);
  border-radius: 4px;
  overflow: hidden;
}

.modal-form-mobile__body__interests-grid .interest .icon {
  height: 24px;
  width: 24px;
  background: var(--blue-main);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-form-mobile__body__interests-grid .interest.inactive .icon {
  background: var(--light-3);
}

.modal-form-mobile__body__interests-grid .interest .icon .sports-icon-svg {
  fill: var(--light-2);
  height: 16px;
}

.modal-form-mobile__body__interests-grid .interest .desc {
  flex-grow: 1;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  color: var(--dark-3);
}

.modal-form-mobile__body__location-search {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal-form-mobile__body__location-search__input {
  background: var(--light-2);
  padding: 16px;
}

.modal-form-mobile__body__location-search__input input {
  height: 36px;
  width: 100%;
  flex-grow: 1;
  font-family: var(--nhg);
  font-weight: 500;
  font-size: 12px;
  color: var(--dark-2);
  border: none;
  outline: none;
  min-width: 0;
  padding-left: 8px;
  padding-right: 8px;
  border: solid 1px var(--light-3);
  border-radius: 4px;
}

.modal-form-mobile__body__location-search__results {
  flex-grow: 1;
  background: var(--light-2);
}

.modal-form-mobile__body__select-img {
}

.modal-form-mobile__body__select-img__list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 16px;
  padding: 16px;
}

.modal-form-mobile__body__select-img__list__item {
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  position: relative;
}

.modal-form-mobile__body__select-img__list__item img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.modal-form-mobile__body__select-img__list__item__select {
  position: absolute;
  top: 4px;
  right: 4px;
  background: var(--blue-main);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.modal-form-mobile__body__select-img__list__item__select.selected {
  opacity: 1;
}

.modal-form-mobile__body__select-img__list__item__select__icon {
  height: 12px;
  fill: white;
  position: absolute;
}

.modal-form-mobile__body__select-img__footer {
  padding: 8px;
  text-align: center;
  font-weight: 700;
  color: var(--blue-main);
  border-top: solid 1px var(--light-2);
}

.modal-form-mobile__form__select {
  overflow: auto;
  flex-grow: 1;
}

.modal-form-mobile__form__select__option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom: solid 1px var(--light-2);
}

.modal-form-mobile__form__select__option__label {
}

.modal-form-mobile__form__select__option__icon-wrapper {
  margin-left: auto;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  border: solid 1px var(--light-3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-form-mobile__form__select__option__icon-wrapper__icon {
  height: 12px;
  fill: white;
}

.modal-form-mobile__form__select__option.selected
  .modal-form-mobile__form__select__option__icon-wrapper {
  border: none;
  background: var(--green-main);
}
