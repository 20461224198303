.fixture-badminton-stats {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 32px;
}

.fixture-badminton-stats__box-score {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  padding: 16px;
}

.fixture-badminton-stats__box-score__column {
}

.fixture-badminton-stats__box-score__column__label {
  font-size: 8px;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--light-4);
  margin-bottom: 8px;
}

.fixture-badminton-stats__box-score__column__value {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  /* font-weight: 700; */
}

.fixture-badminton-stats__box-score__column__value.bold {
  font-weight: 700;
}

.fixture-badminton-stats__box-score__column__value__image {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.fixture-badminton-stats__box-score__column.players {
  flex-grow: 1;
}

.fixture-badminton-stats__box-score__column.period {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 24px;
}

.fixture-badminton-stats__summary {
  padding: 16px;
  margin-top: 32px;
}

.fixture-badminton-stats__summary__header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-badminton-stats__summary__header__player {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-badminton-stats__summary__header__player.away {
  justify-content: flex-end;
}

.fixture-badminton-stats__summary__header__title {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-4);
}

.fixture-badminton-stats__summary__header__player__image {
  background: var(--blue-subtle);
  height: 24px;
  width: 24px;
  border-radius: 4px;
  overflow: hidden;
}

.fixture-badminton-stats__summary__header__player.home
  .fixture-badminton-stats__summary__header__player__image {
  margin-right: 16px;
}

.fixture-badminton-stats__summary__header__player.away
  .fixture-badminton-stats__summary__header__player__image {
  margin-left: 16px;
}

.match-stats-list {
}

.match-stats-list-item {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  margin-top: 16px;
}

.match-stats-list-item .home-value {
  grid-column-start: 1;
  grid-column-end: 2;
  margin-left: 8px;
}

.match-stats-list-item .home-bar {
  grid-column-start: 2;
  grid-column-end: 5;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.match-stats-list-item .home-bar .bar-container {
  height: 8px;
  background: var(--light-2);
  width: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: flex-end;
}

.match-stats-list-item .home-bar .bar-container .fill {
  height: 100%;
  background: var(--blue-main);
}

.match-stats-list-item .desc {
  grid-column-start: 5;
  grid-column-end: 9;
  text-align: center;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-2);
}

.match-stats-list-item .away-value {
  grid-column-start: 12;
  grid-column-end: 13;
  text-align: right;
  margin-right: 8px;
}

.match-stats-list-item .away-bar {
  grid-column-start: 9;
  grid-column-end: 12;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.match-stats-list-item .away-bar .bar-container {
  height: 8px;
  background: var(--light-2);
  width: 100%;
}

.match-stats-list-item .away-bar .bar-container .fill {
  height: 100%;
  background: var(--orange-main);
}

.fixture-points-timeline {
  margin-top: 32px;
  padding: 16px;
}

.fixture-points-timeline__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: flex-end; */
  margin-bottom: 16px;
}

.fixture-points-timeline__header__title {
  margin-right: auto;
  font-weight: 700;
  color: var(--dark-4);
}

.fixture-points-timeline__header__select-period {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-points-timeline__select-period-menu__item {
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}

.fixture-points-timeline__select-period-menu__item:hover {
  background: var(--light-2);
}

.fixture-points-timeline__body {
  height: 320px;
}
