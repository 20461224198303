.ScoringApp-cricket__scoring__status {
  height: 20px;
  background: var(--blue-main);
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  flex-shrink: 0;
}

.ScoringApp-cricket__scoring__status__text {
}

.ScoringApp-cricket__scoring__status__edit {
  text-decoration: underline;
  cursor: pointer;
  /* margin-left: 12px; */
  /* padding-right: 12px; */
}

.ScoringApp-cricket__scoring__status__edit__menu {
  overflow: hidden;
  margin-top: 4px;
}

.ScoringApp-cricket__scoring__status__edit__menu__item {
  padding: 12px;
  border-bottom: solid 1px var(--light-2);
  color: var(--dark-2);
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.ScoringApp-cricket__scoring__status__edit__menu__item:hover {
  background: var(--light-2);
}

.ScoringApp-cricket__scoring__modal-form__body__group {
  padding: 16px;
  padding-bottom: 0;
}

.ScoringApp-cricket__scoring__modal-form__body__group__label {
  margin-bottom: 4px;
  font-family: var(--nhg-display);
  font-size: 10px;
}

.ScoringApp-cricket__scoring__modal-form__body__group__input {
}

.ScoringApp-cricket__scoring__modal-form__body__group__input input {
  height: 32px;
  box-shadow: var(--elevation-1);
  /* border: solid 1px px var(--light-2); */

  border: solid 1px var(--light-3);
  border-radius: 4px;
  padding-left: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ScoringApp-cricket__scoring__modal-form__body__group__input input:focus {
  outline: solid 1px var(--blue-main);
}

.ScoringApp-cricket__scoring__modal-form__body__group__submit {
  padding: 16px;
}

.ScoringApp-cricket__scoring__modal-form__body__group__submit__btn {
  border: none;
  margin-top: 8px;
  /* margin: 16px; */
  width: 100%;
}
