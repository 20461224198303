.notifications-page-mobile {
  background: white;
  /* margin-top: 2px; */
  box-shadow: var(--elevation-mobile);
  /* border-radius: 8px; */
  /* border-top-right-radius: 0; */
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.notifications-page-mobile .notifications-menu-header {
  padding: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-2);
  border-bottom: solid 1px var(--light-2);
}

.notifications-page-mobile .notifications-list {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

.notifications-page-mobile .notifications-menu-footer {
  border-top: solid 1px var(--light-2);
  padding: 12px;
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 12px;

  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.notifications-page-mobile .notifications-menu-footer a {
  color: var(--blue-lighter);
}

.notifications-page-mobile .notifications-list-item {
  padding: 16px;
  /* margin-bottom: 60px; */
}
