.ScoringApp-badminton__scoring__stats-list__list__item {
  background: white;
  margin-bottom: 12px;
}

.ScoringApp-badminton__scoring__stats-list__list__item__order {
  font-weight: 600;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ScoringApp-badminton__scoring__stats-list__list__item__order__text {
  flex-grow: 1;
}

.ScoringApp-badminton__scoring__stats-list__list__item__order__delete {
  cursor: pointer;
}

.ScoringApp-badminton__scoring__stats-list__list__item__order__delete .icon {
  stroke: var(--red-lighter);
  height: 12px;
}

.ScoringApp-badminton__scoring__stats-list__list__item__body {
  border-top: solid 1px var(--light-2);
  border-bottom: solid 1px var(--light-2);
}

.ScoringApp-badminton__scoring__stats-list__list__item__body__statement {
  padding: 12px;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
}

.ScoringApp-badminton__scoring__stats-list__list__item__body__statement__text {
  flex-grow: 1;
}

.ScoringApp-badminton__scoring__stats-list__list__item__body__statement__switch {
  cursor: pointer;
}

.ScoringApp-badminton__scoring__stats-list__list__item__body__statement__switch
  .icon {
  fill: var(--light-4);
  height: 16px;
}

.ScoringApp-badminton__scoring__stats-list__list__item__body__statement .bold {
  font-weight: 600;
}

.ScoringApp-badminton__scoring__stats-list__list__item__body__relations {
}

.ScoringApp-badminton__scoring__stats-list__list__item__body__relations__relation {
  border: solid 1px var(--light-3);
  border-radius: 4px;
  margin: 12px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ScoringApp-badminton__scoring__stats-list__list__item__body__relations__relation__text {
  flex-grow: 1;
}

.ScoringApp-badminton__scoring__stats-list__list__item__body__relations__relation__option {
  cursor: pointer;
}

.ScoringApp-badminton__scoring__stats-list__list__item__body__relations__relation__option
  .icon {
  stroke: var(--red-lighter);
  height: 14px;
}

.ScoringApp-badminton__scoring__stats-list__list__item__body__options {
  display: flex;
  flex-direction: row;
  /* padding: 12px; */
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
}

.ScoringApp-badminton__scoring__stats-list__list__item__body__options__option {
  height: 24px;
  border-radius: 4px;
  border: solid 1px var(--light-3);
  display: flex;
  flex-direction: row;
  align-items: center;
  /* font-family: var(--nhg-display); */
  font-size: 10px;
  padding-right: 8px;
  margin-right: 12px;
  color: var(--light-4);
  cursor: pointer;
  margin-top: 12px;
}

.ScoringApp-badminton__scoring__stats-list__list__item__body__options__option
  .icon {
  stroke: var(--light-4);
  height: 12px;
}

.ScoringApp-badminton__scoring__stats-list__list__item__body__options__option:hover {
  background: var(--blue-main);
  color: var(--light-2);
}

.ScoringApp-badminton__scoring__stats-list__list__item__body__options__option:hover
  .icon {
  stroke: var(--light-2);
}

.ScoringApp-badminton__scoring__stats-list__list__item__server {
  padding: 8px;
  color: var(--dark-4);
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: row;
}

.ScoringApp-badminton__scoring__stats-list__list__item__server__text {
  flex-grow: 1;
}

.ScoringApp-badminton__scoring__stats-list__list__item__server__switch {
  cursor: pointer;
}

.ScoringApp-badminton__scoring__stats-list__list__item__server__switch .icon {
  height: 16px;
  fill: var(--light-4);
}
