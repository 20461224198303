.ScoringApp-badminton__scoring__current-set__body {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.ScoringApp-badminton__scoring__current-set__body__section {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.ScoringApp-badminton__scoring__current-set__body__section.home {
  border-right: dashed 1px var(--light-3);
}

.ScoringApp-badminton__scoring__current-set__body__section__header {
  display: flex;
  flex-direction: row;
  padding: 16px;
  flex-shrink: 0;
}

.ScoringApp-badminton__scoring__current-set__body__section.away
  .ScoringApp-badminton__scoring__current-set__body__section__header {
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.ScoringApp-badminton__scoring__current-set__body__section__header__img {
  height: 54px;
  width: 54px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-subtle);
}

.ScoringApp-badminton__scoring__current-set__body__section__header__desc {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
}

.ScoringApp-badminton__scoring__current-set__body__section__header__desc__name {
  font-size: 16px;
  font-weight: 700;
}

.ScoringApp-badminton__scoring__current-set__body__section__header__desc__server {
  margin-top: 8px;
  height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  border: solid 1px var(--light-3);
  color: var(--light-4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 64px;
}

.ScoringApp-badminton__scoring__current-set__body__section__header__desc__server.selected {
  border-color: var(--red-lighter);
  background: var(--red-lighter);
  color: var(--light-2);
  font-weight: 600;
}

.ScoringApp-badminton__scoring__current-set__body__section.away
  .ScoringApp-badminton__scoring__current-set__body__section__header__desc {
  align-items: flex-end;
}

.ScoringApp-badminton__scoring__current-set__body__section__score {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 40px;
}

.ScoringApp-badminton__scoring__current-set__body__section__score__action {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ScoringApp-badminton__scoring__current-set__body__section__score__action
  .icon {
  stroke: var(--dark-4);
  height: 24px;
}

.ScoringApp-badminton__scoring__current-set__body__section__score__action:hover {
  background: var(--light-2);
}

.ScoringApp-badminton__scoring__current-set__body__section__score__value {
  font-family: var(--din);
  font-size: 80px;
  margin-top: 32px;
  margin-bottom: 32px;
}
