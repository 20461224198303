.ScoringApp-football .select-formation-menu {
  width: 140px;
  max-height: 300px;
  overflow: auto;
}

.ScoringApp-football .select-formation-menu__list__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  cursor: pointer;
}

.ScoringApp-football .select-formation-menu__list__item:hover {
  background: var(--light-2);
}
