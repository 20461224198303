.manage-event-parties {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 32px;
  padding: 48px;
  padding-top: 32px;
  /* padding-bottom: 0; */
}

.manage-event-parties .left-column {
  grid-column-start: 1;
  grid-column-end: 5;
}

.manage-event-parties .right-column {
  grid-column-start: 5;
  grid-column-end: 13;
}
