.organization-photos {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 32px;
  padding-bottom: 48px;
}

.organization-photos .photos-card {
  margin-top: 32px;
}

.organization-photos .app-albums-card {
  margin-top: 32px;
}

.organization-photos .card-section {
  margin-top: 32px;
}

.organization-photos .card-section .card-section-title {
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  margin-bottom: 16px;
  font-weight: 700;
  color: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  border-bottom: solid 1px var(--light-4);
  padding-bottom: 12px;
}

.organization-photos .card-section .card-section-title .reset {
  margin-left: auto;
  color: var(--red-lighter);
}

.organization-photos .left-column {
  grid-column-start: 1;
  grid-column-end: 9;
}

.organization-photos .right-column {
  grid-column-start: 9;
  grid-column-end: 13;
}

.organization-photos .organization-photos-gallery-item {
  /* width: 33%;
  padding-right: 24px; */
  margin-bottom: 16px;
  cursor: pointer;
}

.organization-photos .organization-photos-gallery-item__desc {
  margin-top: 8px;
  font-weight: 700;
}

.organization-photos .organization-photos-gallery-item .placeholder-icon {
  stroke: white;
}

.organization-photos-gallery-image {
  height: 120px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  background: var(--blue-subtle);
  /* margin-top: 16px; */
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.organization-photos-gallery-image.has-image {
  background: black;
}

.organization-photos-gallery-image .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #022e4b;
  z-index: 0;
  opacity: 0.1;
}

.organization-photos-gallery-image img {
  width: 100%;
}

.organization-photos_albums {
  margin-top: 32px;
  /* padding: 16px; */
}

.organization-photos_all-photos {
  margin-top: 32px;
  /* padding: 16px; */
}

.organization-photos_gallery {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.organization-photos .card-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--dark-4);
  display: flex;
  flex-direction: row;
  padding-left: 16px;
  padding-top: 16px;
  padding-right: 16px;
}

.organization-photos .card-title .btn {
  margin-left: auto;
  color: var(--blue-main);
}

/* .organization-photos .show-more-container {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  padding-right: 24px;
  margin-top: 16px;
} */

.organization-photos .show-more-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-4);
  border-top: solid 1px var(--light-2);
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}

.organization-photos .filter-group {
  margin-bottom: 24px;
}

.organization-photos .filter-group .filter-group-title {
  font-weight: 700;
  color: var(--dark-2);
  margin-bottom: 8px;
}

.organization-photos .filter-group .filter-group-input {
}

.organization-photos
  .filter-group
  .filter-group-input
  .app-input
  .input-wrapper {
  background: white;
}

.organization-photos .filter-group .filter-group-applied {
  /* padding-top: 16px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.organization-photos .filter-group .filter-group-applied .app-chip {
  margin-right: 12px;
  margin-top: 12px;
  cursor: pointer;
  background: var(--blue-main);
  color: var(--light-1);
  padding-right: 4px;
}
