.profile-timeline {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 32px;
}

.profile-timeline .card-section {
  margin-top: 32px;
}

.profile-timeline .card-section .card-section-title {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 12px;
  font-weight: 700;
  color: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.profile-timeline .left-column {
  grid-column-start: 1;
  grid-column-end: 9;
}

.profile-timeline .right-column {
  grid-column-start: 9;
  grid-column-end: 13;
}

.profile-timeline .right-column .app-card {
  margin-top: 32px;
  padding: 16px;
}

.profile-timeline .profile-timeline-list {
  margin-top: 32px;
}

.profile-timeline .profile-timeline-list .profile-timeline-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.profile-timeline
  .profile-timeline-list
  .profile-timeline-item
  .profile-timeline-item-progress-bar {
  position: relative;
  width: 40px;
  flex-shrink: 0;
}

.profile-timeline
  .profile-timeline-list
  .profile-timeline-item
  .profile-timeline-item-progress-bar
  .marker {
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background: var(--blue-lighter);
}

.profile-timeline
  .profile-timeline-list
  .profile-timeline-item
  .profile-timeline-item-progress-bar
  .bar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
  background: var(--blue-subtle);
  width: 8px;
}

.profile-timeline
  .profile-timeline-list
  .profile-timeline-item
  .profile-timeline-item-content {
  padding-bottom: 32px;
  width: 100%;
}

.profile-timeline
  .profile-timeline-list
  .profile-timeline-item
  .profile-timeline-item-content
  .profile-timeline-item-date {
  color: var(--dark-4);
  font-weight: 400;
}

.profile-timeline
  .profile-timeline-list
  .profile-timeline-item
  .profile-timeline-item-content
  .profile-timeline-item-card {
  padding: 16px;
  margin-top: 16px;
}
