.app-filters-mobile {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 24px;
  position: relative;
}

.app-filters-mobile__content {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: white;
  box-shadow: var(--elevation-mobile);
  border-radius: 8px;
  height: 50%;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}

.app-filters-mobile__content__title {
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 12px;
  border-bottom: solid 1px var(--light-2);
  box-shadow: var(--elevation-mobile);
  z-index: 2;
}

.app-filters-mobile__content__body {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  /* display: grid; */

  /* grid-template-columns: 30% 70%; */
}

.app-filters-mobile__content__body__categories {
  border-right: solid 1px var(--light-2);
  background: var(--light-2);
  width: 30%;
  flex-shrink: 0;
  height: 100%;
}

.app-filters-mobile__content__body__categories__item {
  height: 40px;
  background: white;
  border-bottom: solid 1px var(--light-2);
  padding-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  color: var(--dark-4);
}

.app-filters-mobile__content__body__categories__item.selected {
  color: var(--light-2);
  background: var(--blue-darkest);
  /* border-left: solid 2px var(--green-main); */
}

.app-filters-mobile__content__body__items {
  box-shadow: var(--elevation-mobile);
  z-index: 1;
  width: 70%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex-shrink: 0;
}

.app-filters-mobile__content__body__items__item {
  height: 40px;
  background: white;
  border-bottom: solid 1px var(--light-2);
  padding-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  /* font-weight: 600; */
  color: var(--dark-2);
  padding-right: 16px;
}

.app-filters-mobile__content__body__items__item__check {
  margin-left: auto;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background: var(--green-main);
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-filters-mobile__dismiss {
  position: absolute;
  height: 40px;
  width: 40px;
  top: -40px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
