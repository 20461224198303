.organization-overview-members-card {
  overflow: hidden;
}

.organization-overview-members-card .card-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  background: var(--blue-main);
  padding: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.organization-overview-members-card .admins-list {
  border-bottom: solid 1px var(--light-2);
  padding: 16px;
  padding-bottom: 0;
}

.organization-overview-members-card .admins-list .app-button {
  margin-bottom: 16px;
}

.organization-overview-members-card .admins-list .admin-list-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.organization-overview-members-card .admins-list .admin-list-item .image {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 4px;
}

.organization-overview-members-card
  .admins-list
  .admin-list-item
  .image.has-image {
  background: black;
}

.organization-overview-members-card .admins-list .admin-list-item .image img {
  width: 100%;
}

.organization-overview-members-card .admins-list .admin-list-item .text {
  padding-left: 16px;
}

.organization-overview-members-card
  .admins-list
  .admin-list-item
  .text
  .primary {
  font-weight: 600;
  color: var(--dark-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.organization-overview-members-card
  .admins-list
  .admin-list-item
  .text
  .secondary {
  color: var(--dark-4);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.organization-overview-members-card .members-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 16px;
  padding: 16px;
  padding-bottom: 0;
}

.organization-overview-members-card .members-list .members-list-item {
  display: flex;
  flex-direction: column;
  /* margin-top: 16px; */
  margin-bottom: 16px;
}

.organization-overview-members-card .members-list .members-list-item .image {
  width: 100%;
  background: var(--blue-subtle);
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 4px;
}

.organization-overview-members-card
  .members-list
  .members-list-item
  .image.has-image {
  background: black;
}

.organization-overview-members-card
  .members-list
  .members-list-item
  .image
  img {
  width: 100%;
}

.organization-overview-members-card .members-list .members-list-item .text {
  text-align: center;
}

.organization-overview-members-card
  .members-list
  .members-list-item
  .text
  .primary {
  font-weight: 600;
  color: var(--dark-2);
  margin-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.organization-overview-members-card
  .members-list
  .members-list-item
  .text
  .secondary {
  color: var(--dark-4);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 2px;
}

.organization-overview-members-card .foot {
  padding: 8px;
  border-top: solid 1px var(--light-2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--dark-4);
  cursor: pointer;
}
