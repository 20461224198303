.mobile-auth-form {
  grid-column-start: 1;
  grid-column-end: 5;
}

.mobile-auth-form__header {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 700;
  font-size: 5.55vw;
  margin-top: 32px;
  margin-bottom: 24px;
  /* line-height: 24px; */
  /* display: flex; */
  /* align-items: center; */

  /* Dark / Dark 1 */

  color: var(--dark-1);
}

.mobile-auth-form__header__secondary {
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-4);
  margin-top: 8px;
  font-size: 12px;
}

.mobile-auth-form__form {
}

.mobile-auth-form__form__row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 16px;
}

.mobile-auth-form__form__row__column {
  margin-bottom: 24px;
}

.mobile-auth-form__form__group {
  margin-bottom: 24px;
}

.mobile-auth-form__form__group__label {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 10px;
  /* line-height: 14px; */
  /* identical to box height, or 140% */

  letter-spacing: 0.2px;

  /* Dark / Dark 4 */

  color: var(--dark-4);
  margin-bottom: 8px;
}

.mobile-auth-form__form__group__field {
  height: 36px;
  width: 100%;
  border-radius: 8px;
  border: solid 1px var(--light-3);
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
}

.mobile-auth-form__form__group__field:focus-within {
  border-color: var(--blue-main);
}

.mobile-auth-form__form__group__field.error {
  border-color: var(--red-main);
}

.mobile-auth-form__form__group__field__icon {
  /* margin-left: 4px; */
  /* margin-right: 4px; */
  flex-shrink: 0;
  width: 32px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-auth-form__form__group__field__input {
  height: 100%;
  flex-grow: 1;
  font-family: var(--nhg);
  font-weight: 500;
  font-size: 12px;
  color: var(--dark-2);
  border: none;
  outline: none;
  min-width: 0;
}

.mobile-auth-form__form__group:last-child {
  margin-bottom: 0;
}

.mobile-auth-form__form__error {
  /* background: var(--red-subtle); */
  border-radius: 8px;
  padding: 8px;
  padding-left: 12px;
  color: var(--red-main);
  font-weight: 700;
  font-family: var(--nhg);
}

.mobile-auth-form__form__group__assistive {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
}

.mobile-auth-form__form__group__assistive-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-auth-form__form__group__assistive-text {
  font-size: 10px;
  color: var(--red-main);
  font-weight: 600;
  margin-left: 4px;
}

.mobile-auth-form__form__submit {
  margin-top: 8px;
  margin-bottom: 24px;
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-main);
  border-radius: 8px;
  outline: none;
  border: none;

  font-family: var(--nhg-display);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  /* line-height: 19px; */
  /* or 158% */

  color: var(--light-1);
  box-shadow: var(--elevation-primary);
}

.mobile-auth-form__footer {
}

.mobile-auth-form__footer__statement {
  margin-top: 16px;
  font-family: var(--nhg);
  color: var(--dark-3);
}

.mobile-auth-form__footer__statement__link {
  text-decoration: underline;
  color: var(--dark-3);
}
