.organization-overview__interaction {
  /* padding: 12px; */
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  display: flex;
  flex-direction: row;
  height: 56px;
}

.organization-overview__interaction__item {
  width: 33.33%;
  flex-shrink: 0;
  border-right: solid 1px var(--light-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.organization-overview__interaction__item:last-child {
  border-right: none;
}

.organization-overview__interaction__item__value {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 700;
  font-size: 16px;
  /* line-height: 19px; */
  /* display: flex; */
  /* align-items: center; */
  /* text-align: center; */

  /* Dark / Dark 2 */

  color: var(--dark-2);
}

.organization-overview__interaction__item__label {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 500;
  font-size: 12px;
  /* line-height: 11px; */
  /* display: flex; */
  /* align-items: center; */
  /* text-align: center; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
  margin-top: 2px;
}
