.event-standings-mobile {
}

.event-standings-mobile__tables {
}

.event-standings-mobile__tables__table {
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  margin-top: 12px;

  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
}

.event-standings-mobile__tables__table__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.event-standings-mobile__tables__table__columns {
  display: flex;
  flex-direction: row;
}

.event-standings-mobile__tables__table .row {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 36px;
}

.event-standings-mobile__tables__table .logo {
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-right: 8px;
  overflow: hidden;
}

.event-standings-mobile__tables__table .logo img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.event-standings-mobile__tables__table__columns-fixed {
  width: 60%;
  flex-shrink: 0;
}

.event-standings-mobile__tables__table__columns-scroll {
  width: 40%;
  flex-shrink: 0;
  overflow-x: auto;
}

.event-standings-mobile__tables__table__columns-scroll.inset {
  box-shadow: inset 2px 0 9px -7px rgba(0, 0, 0, 0.4);
}

.event-standings-mobile__tables__table .td {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.event-standings-mobile__tables__table .td.number {
  justify-content: flex-end;
}

.event-standings-mobile__tables__table__header {
  /* border-bottom: solid 2px var(--light-4); */
}

.event-standings-mobile__tables__table__header .td {
  font-weight: 700;
}

.event-standings-mobile__tables__table__body {
}

.event-standings-mobile__tables__table__body .td {
}

.event-standings-mobile__tables__table__body__order {
  /* height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center; */
}

.event-standings-mobile__tables__table__legend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 8px;
}

.event-standings-mobile__tables__table__legend__item {
  font-size: 10px;
  font-weight: 500;
  color: var(--dark-4);
  margin-right: 16px;
  margin-top: 8px;
  /* font-family: var(--nhg-display); */
}

.event-standings-mobile__tables__table__body__reorder {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.manage-event-standings
  .standings-list
  .standings-list-item__items__row__data
  .standings-list-item__items__row__data__reorder.up {
  transform: rotateZ(180deg);
  transform-origin: 16px 16px;
}
