.fixture-report {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.fixture-report__container {
  grid-column-start: 4;
  grid-column-end: 10;
  padding-top: 48px;
}

.fixture-report__container .ql-container {
  border: none;
}

.fixture-report #create-report-editor {
}

.fixture-report #create-report-editor .ql-container {
  border: none;
}

.fixture-report #create-report-editor .ql-toolbar {
  border: none;
  /* border-bottom: solid 1px var(--light-3); */
  background: var(--blue-subtle);
}

.fixture-report #create-report-editor .ql-editor {
  min-height: 400px;
  background: white;
}

#create-report-editor.quill > .ql-container > .ql-editor.ql-blank::before {
  left: 16px;
}

.save-report-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.save-report-wrapper .submit-btn {
  margin-left: 16px;
}

#create-report-editor .ql-mention-list-container {
  background: white;
  border-radius: 8px;
  box-shadow: var(--elevation-4);
  z-index: 1;
}

#create-report-editor .ql-mention-list-container .ql-mention-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

#create-report-editor .mention-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

#create-report-editor .mention-item:hover {
  background: var(--light-2);
}

#create-report-editor .mention-item__image {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

#create-report-editor .mention-item__image img {
  width: 100%;
}

#create-report-editor .mention-item__text {
  font-size: 12px;
  font-family: var(--nhg);
}

#create-report-editor .mention-item__text__primary {
  font-weight: 600;
  color: var(--dark-1);
}

#create-report-editor .mention-item__text__secondary {
  font-size: 10px;
  color: var(--dark-3);
}

.fixture-report__edit-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 16px;
}
