.claim-profile-card {
  padding: 24px;
  background: var(--blue-darker);
}

.claim-profile-card .desc {
  text-align: center;
  color: white;
}

.claim-profile-card .desc.primary {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 4px;
}

.claim-profile-card .desc.secondary {
  font-weight: 100;
  font-size: 10px;
}

.claim-profile-card .app-button {
  margin-top: 16px;
}
