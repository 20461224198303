.profile-page-mobile {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding-top: 48px;
}

.profile-page-mobile__nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 48px;
  background: white;
  /* box-shadow: 0px 0px 3px rgba(40, 41, 61, 0.08); */
  box-shadow: 0px 0px 4px 1px rgba(40, 41, 61, 0.08);
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  z-index: 4;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-page-mobile__nav__back-btn {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-page-mobile__nav__align-right {
  margin-left: auto;
}

.profile-page-mobile__nav__content {
  font-weight: 600;
  color: var(--dark-1);
  font-family: var(--nhg-display);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-page-mobile__nav__content__img {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-right: 12px;
  overflow: hidden;
}

.profile-page-mobile__cover {
  height: 16.66vw;
  background: var(--blue-subtle);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.profile-page-mobile__cover__pressable {
  height: 16.66vw;
  /* background: var(--blue-subtle); */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.profile-page-mobile__cover img {
  width: 100%;
  max-height: 100%;
}

.profile-page-mobile__cover__edit-btn {
  position: absolute;
  right: 6px;
  bottom: 6px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--orange-main);
  margin-left: 16px;
  background: var(--orange-subtle);
  border-radius: 4px;
}

.profile-page-mobile__cover__edit-btn .icon {
  height: 10px;
}

.profile-page-mobile__header {
  background: white;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 3;
  position: relative;
}

.profile-page-mobile__header__image {
  height: 72px;
  width: 72px;
  background: var(--blue-subtle);
  border-radius: 8px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.profile-page-mobile__header__image__pressable {
  height: 72px;
  width: 72px;
  border-radius: 8px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.profile-page-mobile__header__image img {
  height: 100%;
}

.profile-page-mobile__header__desc {
  padding-left: 16px;
}

.profile-page-mobile__header__desc__primary {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 700;
  font-size: 14px;
  /* line-height: 19px; */
  /* display: flex; */
  /* align-items: center; */

  /* Dark / Dark 2 */

  color: var(--dark-2);
}

.profile-page-mobile__header__desc__secondary {
  font-family: var(--nhg);
  /* font-style: normal; */
  font-weight: 400;
  font-size: 12px;
  /* line-height: 15px; */
  /* display: flex; */
  /* align-items: center; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
  margin-top: 2px;
}

.profile-page-mobile__header .follow-button {
  height: 24px;
  margin-top: 8px;
  font-size: 10px;
  min-width: 100px;
}

.profile-page-mobile__tabs {
  display: flex;
  flex-direction: row;
  height: 40px;
  background: var(--light-1);
  border-top: solid 1px var(--light-2);
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 16px;
  /* box-shadow: var(--elevation-mobile); */
  box-shadow: 0px 0px 4px 1px rgba(40, 41, 61, 0.08);
  z-index: 2;
  position: relative;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: -2px;
}

.profile-page-mobile__tabs__tab {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--dark-4);
  margin-right: 24px;
  font-size: 10px;
  flex-shrink: 0;
}

.profile-page-mobile__tabs__tab.active {
  font-weight: 600;
  color: var(--dark-2);
  border-bottom: solid 2px var(--blue-main);
}

.profile-page-mobile__body {
  /* height: 1000px; */
}

/* Account settings */

.profile-page-mobile__nav .account-button-menu {
  background: white;
  box-shadow: var(--elevation-6);
  margin-top: 10px;
  border-radius: 8px;
  overflow: hidden;
  z-index: 5;
  position: relative;
}

.profile-page-mobile__nav .account-button-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-3);
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.profile-page-mobile__nav .account-button-menu-item .icon {
  margin-right: 12px;
}

.profile-page-mobile__nav .account-button-menu-item .desc {
}

.profile-page-mobile__nav .account-button-menu-icon {
  stroke: var(--light-4);
  height: 20px;
  margin-top: 2px;
}
