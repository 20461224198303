.manage-event-standings-mobile {
}

.manage-event-standings-mobile__header {
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 16px;
}

.manage-event-standings-mobile__header__option {
  margin-left: auto;
  color: var(--blue-main);
  font-family: var(--nhg-display);
  font-weight: 700;
}

.manage-event-standings-mobile__list {
}

.recalculate-event-standings-mobile__periods {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.recalculate-event-standings-mobile__periods__item {
  /* background: var(--blue-main); */
  border: solid 1px var(--blue-main);
  color: var(--blue-main);
  margin-right: 12px;
  margin-bottom: 12px;
  height: 24px;
  padding-right: 4px;
  cursor: pointer;
}

.recalculate-event-standings-mobile__periods__item__icon {
  height: 14px;
  stroke: var(--light-4);
}
