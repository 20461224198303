.manage-event-posts {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  padding: 48px;
  grid-column-gap: 48px;
}

.manage-event-posts .left-column {
  grid-column-start: 1;
  grid-column-end: 7;
}

.manage-event-posts .right-column {
  grid-column-start: 7;
  grid-column-end: 13;
}
