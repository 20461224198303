.search-page-mobile {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding-top: 88px;
  padding-bottom: 24px;
}

.search-page-mobile__header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 48px;
  background: white;
  /* box-shadow: 0px 0px 3px rgba(40, 41, 61, 0.08); */
  /* box-shadow: 0px 0px 4px 1px rgba(40, 41, 61, 0.08); */
  /* padding-left: 16px; */
  padding-right: 16px;
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-page-mobile__header__back-btn {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-page-mobile__header__input-wrapper {
  height: 28px;
  flex-grow: 1;
  position: relative;
}

.search-page-mobile__header__input-wrapper input {
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 8px;
  border-radius: 8px;
  border: none;
  background: var(--light-2);
  font-family: var(--nhg);
  font-size: 12px;
}

.search-page-mobile__header__input-wrapper__loading {
  position: absolute;
  right: 8px;
  top: 8px;
}

.search-page-mobile__header__options {
}

.search-page-mobile__tabs {
  display: flex;
  flex-direction: row;
  height: 40px;
  background: var(--light-1);
  /* border-top: solid 1px var(--light-2); */
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 16px;
  /* box-shadow: var(--elevation-mobile); */
  box-shadow: 0px 0px 4px 1px rgba(40, 41, 61, 0.08);
  z-index: 2;
  position: fixed;
  top: 48px;
  width: 100%;
  /* top: 0; */
}

.search-page-mobile__tabs__tab {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--dark-4);
  margin-right: 24px;
  font-size: 10px;
  flex-shrink: 0;
}

.search-page-mobile__tabs__tab.active {
  font-weight: 600;
  color: var(--dark-2);
  border-bottom: solid 2px var(--blue-main);
}

.search-page-mobile__body {
}

.search-page-mobile__section {
  background: var(--light-1);
  /* border-radius: 8px; */
  box-shadow: var(--elevation-mobile);
  padding-top: 12px;
  margin-top: 12px;
  padding-bottom: 12px;
}

.search-page-mobile__section__title {
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: var(--dark-4);
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-page-mobile__section__title__show-more {
  color: var(--blue-lighter);
  margin-left: auto;
  cursor: pointer;
}

.search-page-mobile__section__items {
}

.search-page-mobile__section__items__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 16px; */
  transition: all 0.2s ease-out;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  color: var(--dark-1);
}

.search-page-mobile__section__items__item__image {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.search-page-mobile__section__items__item__image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.search-page-mobile__section__items__item__desc {
}

.search-page-mobile__section__items__item__desc__primary {
  font-weight: 600;
  color: var(--dark-2);
}

.search-page-mobile__section__items__item__desc__secondary {
  color: var(--dark-3);
  font-size: 10px;
  margin-top: 2px;
}

.search-page-mobile__section__show-more {
  /* height: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-family: var(--nhg-display);
  margin-top: 16px;
  color: var(--dark-4);
}
