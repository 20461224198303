.event-results-summary {
  padding: 16px;
  padding-bottom: 0;
  grid-column-start: 1;
  grid-column-end: 7;
}

.event-results-summary .card-title {
  font-weight: 600;
  color: var(--dark-4);
}

.event-results-summary .list {
  /* padding-top: 16px; */
}

.event-results-summary .event-results-summary-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-weight: 600;
  color: var(--dark-4);
  margin-top: 8px;
  cursor: pointer;
}
