.app-checkbox {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  cursor: pointer;
}

.app-checkbox .checkbox-indicator {
  height: 16px;
  width: 16px;
  border: solid 1px var(--blue-main);
  border-radius: 4px;
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-out;

  background: white;
}

.app-checkbox.checked .checkbox-indicator {
  background: var(--blue-main);
}

.app-checkbox .checkbox-label {
  margin-left: 16px;
  font-size: 12px;
  font-weight: 400;
}
