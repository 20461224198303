.create-post-card {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

  /* padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px; */
  padding: 16px;
}

.create-post-card .submit-post-btn {
  flex-shrink: 0;
  /* width: 80px; */
}

.create-post-card .avatar {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 8px;
  flex-shrink: 0;
  /* margin-right: 16px; */
}

.create-post-card .input-toggle {
  flex-grow: 1;
  color: var(--dark-4);
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.create-post-card .input-wrapper {
  flex-grow: 1;
  padding-right: 24px;
  padding-left: 24px;
  position: relative;
}

.create-post-card .input-wrapper .media-options {
  position: absolute;
  left: 136px;
  bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: 0.4px;
}

.create-post-card .input-wrapper .character-count {
  position: absolute;
  right: 24px;
  bottom: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: 0.4px;
  font-weight: 700;
  color: var(--dark-3);
  font-size: 10px;
}

.create-post-card .input-wrapper .character-count.red {
  color: var(--red-lighter);
}

.create-post-card .input-wrapper .attach-image-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 10px;
  color: var(--dark-4);
  margin-right: 24px;
  margin-left: 24px;
  cursor: pointer;
}

.create-post-card .input-wrapper .attach-video-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 10px;
  color: var(--light-4);
}

.create-post-card .input-wrapper .media-selector {
  position: absolute;
  bottom: 0;
  right: 24px;
  height: 20px;
  border: solid 1px var(--light-3);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
  border-radius: 4px;
  font-size: 10px;
  color: var(--dark-3);
}

.create-post-card .input-wrapper .media-selector:hover {
  background: var(--blue-subtle);
}

.create-post-card .input-wrapper #create-post-editor {
  /* height: 100px; */
  /* border: solid 1px var(--light-3); */
  /* border-radius: 4px; */
  /* padding: 8px; */
  display: flex;
  flex-direction: column-reverse;
  border: none;
}

.create-post-card .input-wrapper #custom-toolbar {
  border: none;
  position: relative;
  z-index: 0;
}

.create-post-card .input-wrapper #custom-toolbar .ql-formats {
  margin-left: -14px;
}

.create-post-card .input-wrapper #create-post-editor .ql-container {
  border: none;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-size: 12px;
  font-style: normal;
  font-family: var(--nhg);
  padding-left: 0;
  left: 0;
  /* color: red; */
}

.quill > .ql-container > .ql-editor.ql-blank br {
  display: none;
}

.create-post-card .input-wrapper #create-post-editor .ql-editor {
  min-height: 80px;
  border-bottom: solid 1px var(--light-3);
  /* border-radius: 8px; */
  padding: 0;
  padding-top: 8px;
  /* padding-left: 0; */
  /* padding: 8px; */
  /* background: var(--light-2); */
}

.create-post-card .input-wrapper #create-post-editor .ql-toolbar {
  border: none;
  padding-left: 0;
  padding-bottom: 0;
}

.create-post-card .input-wrapper #custom-toolbar svg .ql-stroke {
  stroke: var(--light-4);
  /* fill: var(--light-4); */
}

.create-post-card .input-wrapper #custom-toolbar .ql-active svg .ql-stroke {
  stroke: var(--blue-main);
  /* fill: var(--light-4); */
}

.create-post-card .input-wrapper #custom-toolbar svg .ql-fill {
  /* stroke: var(--light-4); */
  fill: var(--light-4);
}

.create-post-card .input-wrapper #custom-toolbar .ql-active svg .ql-fill {
  /* stroke: var(--blue-main); */
  fill: var(--blue-main);
}

.create-post-card .ql-mention-list-container {
  background: white;
  border-radius: 8px;
  box-shadow: var(--elevation-4);
  z-index: 1;
}

.create-post-card .ql-mention-list-container .ql-mention-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.create-post-card .mention-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.create-post-card .mention-item:hover {
  background: var(--light-2);
}

.create-post-card .mention-item__image {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.create-post-card .mention-item__image img {
  width: 100%;
}

.create-post-card .mention-item__text {
  font-size: 12px;
  font-family: var(--nhg);
}

/* .rdw-inline-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
  }
  
  .rdw-block-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
  } */

.create-post-card .mention-item__text__primary {
  font-weight: 600;
  color: var(--dark-1);
}

.create-post-card .mention-item__text__secondary {
  font-size: 10px;
  color: var(--dark-3);
}

.create-post-card .input-wrapper .selected-media-container {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-column-gap: 8px;
  border-bottom: solid 1px var(--light-3);
  padding-bottom: 8px;
  /* padding-top: 8px; */
  /* padding-bottom: 16px; */
}

.create-post-card .input-wrapper .selected-media-container__preview {
  height: 64px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  position: relative;
}

.create-post-card .input-wrapper .selected-media-container__preview img {
  width: 100%;
}

.create-post-card .input-wrapper .selected-media-container__preview .video {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-post-card
  .input-wrapper
  .selected-media-container__preview
  .video
  video {
  width: 100%;
}

.create-post-card .input-wrapper .selected-media-container__preview__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #022e4b36;
  cursor: pointer;
}

.create-post-card
  .input-wrapper
  .selected-media-container__preview__overlay
  .media-type {
  position: absolute;
  left: 4px;
  top: 4px;
}

.create-post-card
  .input-wrapper
  .selected-media-container__preview__overlay
  .media-type
  .media-type__icon {
  stroke: white;
  height: 14px;
  width: 14px;
}

.create-post-card
  .input-wrapper
  .selected-media-container__preview__overlay
  .deselect {
  height: 14px;
  width: 14px;
  border-radius: 100%;
  background: var(--red-main);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 4px;
  right: 4px;
}

.create-post-card
  .input-wrapper
  .selected-media-container__preview__overlay
  .deselect
  .deselect-icon {
  stroke: white;
  height: 12px;
}
