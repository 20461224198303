.fixture-lineups-mobile {
}

.fixture-lineups-mobile__header {
  background: var(--light-1);
  position: sticky;
  top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  box-shadow: var(--elevation-mobile);
}

.fixture-lineups-mobile__header__team-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  flex-grow: 1;
  padding-left: 16px;
  padding-right: 16px;
}

.fixture-lineups-mobile__header__team-selector__team {
  flex-grow: 1;
  font-family: var(--nhg-display);
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--dark-2);
}

.fixture-lineups-mobile__header__team-selector__team__logo {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-subtle);
  margin-right: 8px;
}

.fixture-lineups-mobile__header__team-selector__icon {
}

.fixture-lineups-mobile__header__view-selector {
  display: flex;
  flex-direction: row;
  border-left: solid 1px var(--light-3);
}

.fixture-lineups-mobile__header__view-selector__option {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixture-lineups-mobile__content-wrapper {
  padding-left: 16px;
  padding-right: 16px;
}

.fixture-lineups-mobile__content {
  margin-top: 12px;
  background: var(--light-1);
  box-shadow: var(--elevation-mobile);
  border-radius: 8px;
}

.fixture-lineups-mobile__content__section {
}

.fixture-lineups-mobile__content__section__header {
  border-bottom: solid 1px var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
}

.fixture-lineups-mobile__content__section__header__title {
  flex-grow: 1;
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.fixture-lineups-mobile__content__section__header__formation {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-lineups-mobile__content__section__header__formation__icon {
  height: 12px;
  stroke: var(--dark-4);
  width: 12px;
  margin-left: 8px;
}

.fixture-lineups-mobile__content__section__list {
}

.fixture-lineups-mobile__content__section__list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
}

.fixture-lineups-mobile__content__section__list__item__image {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-subtle);
  margin-right: 8px;
}

.fixture-lineups-mobile__content__section__list__item__name {
  flex-grow: 1;
}

.fixture-lineups-mobile__content__section__list__item__options {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-lineups-mobile__content__section__list__item__options__option {
  /* font-family: var(--nhg-display); */
  /* font-style: normal; */
  /* font-weight: 600; */
  font-size: 10px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
  width: 32px;
  text-align: right;
}

.fixture-lineups-mobile__content__section__list__item__options__num {
  font-size: 10px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
  /* width: 32px; */
  padding-left: 2px;
  padding-right: 2px;
  text-align: center;
}

.fixture-lineups-mobile__content__section__list__item__options__num.editable {
  border-bottom: dashed 1px var(--dark-4);
}

.fixture-lineups-mobile__content__section__list__item__options__delete {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.fixture-lineups-mobile__content__section__list__item__options__delete__icon {
  stroke: var(--red-lighter);
  height: 14px;
  margin-left: 16px;
}

.fixture-lineups-mobile__select-formation-menu {
  width: 140px;
  max-height: 300px;
  overflow: auto;
}

.fixture-lineups-mobile__select-formation-menu__list__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  cursor: pointer;
}

.fixture-lineups-mobile__select-formation-menu__list__item:hover {
  background: var(--light-2);
}
