.page-search-bar {
  padding: 0;
  padding-left: 16px;
  padding-right: 4px;
  height: 40px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 20px;
}

.page-search-bar.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.page-search-bar .page-search-bar-form {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}

.page-search-bar .page-search-bar-input {
  flex-grow: 1;
  padding: 0;
  height: 100%;
  padding-right: 16px;
  outline: none;
  border: none;
  padding-left: 8px;
  font-size: 12px;
}

.page-search-bar .page-search-bar-input:disabled {
  background: white;
}

.page-search-bar .submit-page-search-btn {
  width: 32px;
  padding: 0;
  border-radius: 5px;
}

.page-search-bar .submit-page-search-btn.submit-disabled {
  opacity: 0.3;
}
