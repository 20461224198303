.video-info__desc .ql-container {
  border: none;
  font-family: var(--nhg);
}

.video-info__desc .ql-editor {
  padding: 0;
}

.video-info__desc .truncate-button {
  cursor: pointer;
  color: var(--blue-main);
}

.video-info__desc .edit-btn {
  color: var(--orange-main);
  font-weight: 700;
  cursor: pointer;
}

.video-info__desc .delete-btn {
  color: var(--red-main);
  font-weight: 700;
  cursor: pointer;
  margin-top: 8px;
}

.edit-video-desc-wrapper {
  padding-right: 16px;
  margin-top: 16px;
}
