.organization-overview {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 32px;
}

.organization-overview .left-column {
  grid-column-start: 1;
  grid-column-end: 9;
}

.organization-overview .profile-activity-card {
  margin-top: 32px;
  padding: 16px;
  /* padding-bottom: 4px; */
}

/* .organization-overview .organization-posts {
  margin-top: 32px;
} */

.organization-overview .right-column {
  grid-column-start: 9;
  grid-column-end: 13;
}

.organization-overview .right-column .app-card {
  margin-top: 32px;
  /* padding: 16px; */
}
