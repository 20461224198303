.add-event-org-roles {
  width: 400px;
}

.add-event-org-roles__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: solid 1px var(--light-2);
}

.add-event-org-roles__title__text {
  font-weight: 600;
  font-family: var(--nhg-display);
  font-size: 14px;
}

.add-event-org-roles__title__dismiss-btn {
  margin-left: auto;
  cursor: pointer;
}

.add-event-org-roles__title__dismiss-btn__icon {
  stroke: var(--dark-4);
  height: 14px;
}

.add-event-org-roles__form {
  padding: 16px;
}

.add-event-org-roles__form__group {
  margin-bottom: 16px;
}

.add-event-org-roles__form__group__label {
  font-size: 10px;
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-4);
  margin-bottom: 8px;
}

.add-event-org-roles__form__group__input {
  position: relative;
}

.add-event-org-roles__submit {
  padding: 16px;
  padding-top: 0;
}

.add-event-org-roles .app-search-menu {
  margin-top: 0;
  padding: 0;
  padding-top: 16px;
}

.add-event-org-roles .app-search-menu .search-results-section__title {
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: var(--dark-4);
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
}

.add-event-org-roles .app-search-menu .search-results-section__items__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 16px; */
  transition: all 0.2s ease-out;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  color: var(--dark-1);
  /* font-weight: 700; */
}

.add-event-org-roles
  .app-search-menu
  .search-results-section__items__item:hover {
  background: var(--light-2);
}

.add-event-org-roles
  .app-search-menu
  .search-results-section__items__item__image {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.add-event-org-roles
  .app-search-menu
  .search-results-section__items__item__image
  img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.add-event-org-roles
  .app-search-menu
  .search-results-section__items__item__desc {
}

.add-event-org-roles
  .app-search-menu
  .search-results-section__items__item__desc__primary {
  font-weight: 600;
  color: var(--dark-2);
}

.add-event-org-roles
  .app-search-menu
  .search-results-section__items__item__desc__secondary {
  color: var(--dark-3);
  font-size: 10px;
}
