.ScoringApp-football__settings__period-settings {
  margin-top: 16px;
  overflow: hidden;
}

.ScoringApp-football__settings__period-settings__title {
  background: var(--blue-main);
  color: var(--light-2);
  font-weight: 700;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.ScoringApp-football__settings__period-settings__body {
}

.ScoringApp-football__settings__period-settings__period {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  /* height: 44px; */
  border-bottom: solid 1px var(--light-2);
}

.ScoringApp-football__settings__period-settings__period__order {
  width: 32px;
}

.ScoringApp-football__settings__period-settings__period__details {
}

.ScoringApp-football__settings__period-settings__period__options {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.ScoringApp-football__settings__period-settings__period__options__option {
  margin-left: 16px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  border: none;
  transition: all 0.2s ease-out;
}

.ScoringApp-football__settings__period-settings__period__options__option__icon {
  height: 14px;
}

.ScoringApp-football__settings__period-settings__period__options__option.edit {
  background: var(--orange-subtle);
}

.ScoringApp-football__settings__period-settings__period__options__option.delete {
  background: var(--red-subtle);
}

.ScoringApp-football__settings__period-settings__period__options__option.edit
  .ScoringApp-football__settings__period-settings__period__options__option__icon {
  fill: var(--orange-main);
}

.ScoringApp-football__settings__period-settings__period__options__option.delete
  .ScoringApp-football__settings__period-settings__period__options__option__icon {
  stroke: var(--red-main);
}

.ScoringApp-football__settings__period-settings__period__options__option.disabled {
  opacity: 40%;
  pointer-events: none;
  cursor: default;
}

.ScoringApp-football__settings__period-settings__body__add-period {
  padding-left: 16px;
  color: var(--green-main);
  font-weight: 700;
  cursor: pointer;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ScoringApp-football__settings__period-settings__form {
  display: flex;
  flex-direction: row;
  padding: 16px;
}

.ScoringApp-football__settings__period-settings__form__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.ScoringApp-football__settings__period-settings__form__row__group {
  position: relative;
}

.ScoringApp-football__settings__period-settings__form__row__group.text {
  flex-grow: 1;
  margin-right: 16px;
}

.ScoringApp-football__settings__period-settings__form__row__group__label {
  position: absolute;
  top: -8px;
  left: 4px;
  background: white;
  font-size: 8px;
  color: var(--dark-4);
  padding: 4px;
}

.ScoringApp-football__settings__period-settings__form__row__group__input {
  height: 32px;
  border: solid 1px var(--light-3);
  padding-left: 8px;
  font-size: 12px;
  border-radius: 4px;
  width: 100%;
}

.ScoringApp-football__settings__period-settings__form__row__group__input.duration {
  width: 80px;
}

.ScoringApp-football__settings__period-settings__form__row__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.ScoringApp-football__settings__period-settings__form__row__options__option {
  margin-left: 16px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  border: none;
  transition: all 0.2s ease-out;
  background: var(--light-2);
}

.ScoringApp-football__settings__period-settings__form__row__options__option.submit {
  background: var(--green-subtle);
}

.ScoringApp-football__settings__period-settings__form__row__options__option__icon {
  height: 14px;
}

.ScoringApp-football__settings__period-settings__form__row__options__option.submit
  .ScoringApp-football__settings__period-settings__form__row__options__option__icon {
  fill: var(--green-main);
}

/* .ScoringApp-football__settings__period-settings__form__row__options__option.cancel {
  background: var(--green-subtle);
} */

.ScoringApp-football__settings__period-settings__form__row__options__option.cancel
  .ScoringApp-football__settings__period-settings__form__row__options__option__icon {
  stroke: var(--dark-4);
}
