.fixture-events-summary {
}

.fixture-events-summary.mobile {
  margin-top: 12px;
}

.fixture-events-summary .card-title {
  padding: 16px;
  font-weight: 700;
  color: var(--dark-2);
  border-bottom: solid 1px var(--light-3);
}

.fixture-events-summary.mobile .card-title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.fixture-events-summary__list {
}

.fixture-events-summary__list__period {
  background: var(--light-2);
  color: var(--light-4);
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: 700;
}

.fixture-events-summary__list__show-more {
  /* background: var(--light-2); */
  color: var(--light-4);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: 700;
  cursor: pointer;
}

.fixture-events-summary__list__item {
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-bottom: solid 1px var(--light-2);
}

.fixture-events-summary__list__item:last-child {
  border: none;
}

.fixture-events-summary__list__item .timestamp {
  /* font-weight: 700; */
  color: var(--dark-4);
  width: 40px;
  font-family: var(--nhg-display);
  flex-shrink: 0;
}

.fixture-events-summary__list__item .desc {
  flex-grow: 1;
}

.fixture-events-summary__list__item .desc__title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-events-summary__list__item .desc__title__icon {
  margin-right: 4px;
  height: 15px;
  /* width: 15px; */
}

.fixture-events-summary__list__item .desc__title__text {
  color: var(--dark-4);
  /* font-family: var(--nhg-display); */
  font-weight: 600;
}

.fixture-events-summary__list__item .profile-image {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background: var(--blue-subtle);
  margin-right: 16px;
  overflow: hidden;
}

.fixture-events-summary__list__item .profile-image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.fixture-events-summary.mobile
  .fixture-events-summary__list__item
  .profile-image {
  display: none;
}

.fixture-events-summary__list__item .desc-text {
  margin-bottom: 4px;
}

.fixture-events-summary__list__item .team-image {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  overflow: hidden;
}

.fixture-events-summary__list__item .team-image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.fixture-events-summary.mobile .fixture-events-summary__list__item .team-image {
  display: none;
}

.fixture-events-summary__footer {
  border-top: solid 1px var(--light-3);
  text-align: center;
  font-weight: 700;
  color: var(--dark-4);
  padding-top: 8px;
  padding-bottom: 8px;
}

.fixture-events-summary__list__item.cricket {
  display: flex;
  flex-direction: row;
}

.fixture-events-summary__list__item.cricket .ball {
  height: 32px;
  width: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--dark-4);
}

.fixture-events-summary__list__item.cricket .runs {
  height: 32px;
  width: 32px;
  background: var(--light-2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: var(--dark-3);
  margin-right: 32px;
}

.fixture-events-summary__list__item.cricket .runs.green {
  background: var(--green-darker);
  color: var(--light-1);
}

.fixture-events-summary__list__item.cricket .runs.red {
  background: var(--red-main);
  color: var(--light-1);
}

.fixture-events-summary__list__item.cricket .desc {
  padding-top: 9px;
}

.fixture-events-summary-over__header {
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  background: var(--blue-subtle);
  font-family: var(--nhg-display);
}

.fixture-events-summary-over__header__primary {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  margin-bottom: 4px;
}

.fixture-events-summary-over__header__primary__text {
}

.fixture-events-summary-over__header__secondary {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-events-summary-over__header__secondary__text {
}
