.app-notification {
  height: 16px;
  /* width: 16px; */
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px;
  background: var(--red-main);
  color: var(--light-1);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}
