.manage-event-partners-mobile {
  padding-bottom: 16px;
}

.manage-event-partners-mobile__section {
  margin-top: 16px;
  box-shadow: var(--elevation-mobile);
  background: white;
  padding: 16px;
}

.manage-event-partners-mobile__section__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */
  color: var(--dark-4);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.manage-event-partners-mobile__section__title__text {
}

.manage-event-partners-mobile__section__title__option {
  color: var(--blue-main);
  margin-left: auto;
}

.manage-event-partners-mobile__section__desc {
}

.manage-event-partners-mobile__section__list {
}

.manage-event-partners-mobile__section__list__item {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-event-partners-mobile__section__list__item__image {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  border-radius: 8px;
  /* margin-bottom: 8px; */
  margin-right: 16px;
  overflow: hidden;
}

.manage-event-partners-mobile__section__list__item__image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.manage-event-partners-mobile__section__list__item__desc {
}

.manage-event-partners-mobile__section__list__item__desc__primary {
  font-weight: 600;
  margin-bottom: 4px;
}

.manage-event-partners-mobile__section__list__item__desc__secondary {
  color: var(--dark-4);
  margin-bottom: 4px;
  font-size: 10px;
}

.manage-event-partners-mobile__section__list__item__option {
  margin-left: auto;
}
