/* STYLES FOR MOBILE HOME PAGE */

.home-page-mobile {
}

.home-page-mobile__posts-list .app-post-card {
  margin-top: 16px;
}

.home-page-mobile .app-match-report {
  margin-top: 16px;
  margin-bottom: 0;
}

.home-page-mobile .app-match-report .app-match-report__fixture__team__name{
  font-size:12px
}

.home-page-mobile .app-match-report .app-match-report__headline{
  font-size:16px
}

.home-page-mobile__posts-list .show-more-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 32px;
}
