.profile-overview-gallery-card {
  padding: 0;
  margin-top: 32px;
}

.profile-overview-gallery-card .card-title {
  font-weight: 600;
  color: var(--dark-4);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  padding-bottom: 0;
}

.profile-overview-gallery-card .card-title .card-title-link {
  margin-left: auto;
  color: var(--blue-main);
  cursor: pointer;
}

.profile-overview-gallery-card .gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 8px;
  grid-template-rows: repeat(2, 72px);
  grid-row-gap: 8px;
  /* padding: 16px; */
  margin-top: 16px;
  padding-bottom: 16px;
}

.profile-overview-gallery-card .gallery-grid .gallery-grid-item {
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 16px; */
}

.profile-overview-gallery-card .gallery-grid .gallery-grid-item img {
  width: 100%;
}

.profile-overview-gallery-card .profile-overview-gallery-card-footer {
  /* margin-top: 16px; */
  padding: 8px;
  border-top: solid 1px var(--light-2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--dark-4);
  cursor: pointer;
}
