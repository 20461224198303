.app-map-marker {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: white; */
}

.app-map-marker .app-map-marker__icon {
  stroke: var(--blue-main);
  height: 24px;
}

.app-map-marker .app-map-marker__label {
  background: var(--blue-main);
  border: solid 4px var(--blue-main);
  margin-top: 4px;
  border-radius: 8px;
  padding: 4px;
  font-size: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  color: white;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-align: center;
}
