.form-group .organized-by-dropdown {
  width: 100%;
}

.form-group .organizers-menu {
  margin-top: 4px;
  margin-bottom: 4px;
  max-height: 220px;
  overflow-y: auto;
}

.form-group .organizers-menu .organizers-menu-item {
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  border-bottom: solid 1px var(--light-2);
}

.form-group .organized-by-dropdown input {
  pointer-events: none;
  background: white;
}

.form-group .dismiss-icon {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 2px var(--light-4);
  position: absolute;
  right: 0;
  cursor: pointer;
}
