.profile-videos {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 32px;
  padding-bottom: 48px;
}

.profile-videos .card-section {
  margin-top: 32px;
}

.profile-videos .card-section .card-section-title {
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  margin-bottom: 16px;
  font-weight: 700;
  color: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  border-bottom: solid 1px var(--light-4);
  padding-bottom: 12px;
}

.profile-videos .card-section .card-section-title .reset {
  margin-left: auto;
  color: var(--red-lighter);
}

.profile-videos .left-column {
  grid-column-start: 1;
  grid-column-end: 9;
}

.profile-videos .right-column {
  grid-column-start: 9;
  grid-column-end: 13;
}

.profile-videos .right-column .app-card {
  margin-top: 32px;
  padding: 16px;
}

.profile-videos .profile-videos-gallery {
  margin-top: 32px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
}

.profile-videos .profile-videos-gallery .profile-videos-gallery-item {
  width: 33%;
  padding-right: 24px;
}

.profile-videos
  .profile-videos-gallery
  .profile-videos-gallery-item
  .profile-videos-gallery-image {
  height: 200px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  background: var(--blue-subtle);
  margin-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;
}

.profile-videos-gallery-image img {
  width: 100%;
}

.profile-videos .show-more-container {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  padding-right: 24px;
  /* margin-top: 16px; */
  background: var(--light-1);
  height: 32px;
  border-radius: 8px;
  font-weight: 700;
  color: var(--dark-4);
  cursor: pointer;
}

.profile-videos .filter-group {
  margin-bottom: 24px;
}

.profile-videos .filter-group .filter-group-title {
  font-weight: 700;
  color: var(--dark-2);
  margin-bottom: 8px;
}

.profile-videos .filter-group .filter-group-input {
}

.profile-videos .filter-group .filter-group-input .app-input .input-wrapper {
  background: white;
}

.profile-videos .filter-group .filter-group-applied {
  /* padding-top: 16px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.profile-videos .filter-group .filter-group-applied .app-chip {
  margin-right: 12px;
  margin-top: 12px;
  cursor: pointer;
  background: var(--blue-main);
  color: var(--light-1);
  padding-right: 4px;
}

.profile-videos .no-videos-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 32px;
}

.profile-videos .no-videos-container .text {
  font-size: 16px;
  color: var(--dark-4);
}

.profile-videos .profile-videos-gallery .video-list-item {
  width: 100%;
}

.profile-videos-gallery__section {
  margin-bottom: 16px;
}

.profile-videos-gallery__section__title {
  font-size: 14px;
  font-weight: 600;
  color: var(--dark-2);
  margin-bottom: 16px;
}
