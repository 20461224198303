.manage-event-registrations {
  padding: 16px;
}

.manage-event-registrations .card-title {
  font-weight: 600;
  color: var(--dark-4);
}

.manage-event-registrations .registrations-table {
  margin-top: 16px;
  width: 100%;
  border-spacing: 0;
}

.manage-event-registrations .registrations-table__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
}

.manage-event-registrations .registrations-table__row.header {
  font-weight: 700;
  border-bottom: solid 1px var(--light-2);
}

.manage-event-registrations .registrations-table__row__td {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 16px;
}

.manage-event-registrations .registrations-table__row__td:last-child {
  justify-content: flex-end;
  padding-right: 0;
}

.manage-event-registrations .registrations-table__row__td.with-image {
}

.manage-event-registrations .registrations-table__row__td .td-image {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 12px;
  overflow: hidden;
}

.manage-event-registrations .registrations-table__row__td .td-image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.manage-event-registrations .registrations-table__row__td .edit-btn {
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  background: var(--orange-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--orange-main);
  font-size: 10px;
  letter-spacing: 0.2px;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  line-height: 24px;
  /* width: 100px; */
}

.manage-event-registrations .registrations-table__row__td .number-input {
  height: 32px;
  box-shadow: var(--elevation-1);
  border: solid 1px var(--light-2);
  border-radius: 8px;
  padding-left: 12px;
  width: 100%;
}

.manage-event-registrations .registrations-table__row__td .number-input.error {
  border-color: var(--red-main);
}

.manage-event-registrations
  .registrations-table__row__td
  .filter-input-dropdown {
  width: 100%;
}

.manage-event-registrations .registrations-table__row__td .options {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-event-registrations .registrations-table__row__td .options .btn {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
}

.manage-event-registrations .registrations-table__row__td .options .btn .icon {
  height: 14px;
}

.manage-event-registrations
  .registrations-table__row__td
  .options
  .btn.success {
  background: var(--green-main);
  fill: white;
}

.manage-event-registrations .registrations-table__row__td .options .btn.cancel {
  background: none;
  stroke: var(--red-main);
}
