.onboarding-page {
  height: 100%;
  width: 100%;
  /* background: rgba(255, 255, 255, 0.9); */
  background: white;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  /* z-index: 15; */
}

.onboarding-page .link-text {
  color: var(--blue-main);
}

.onboarding-page .text-link {
  text-decoration: underline;
}

.onboarding-page .text-link:hover {
  color: var(--blue-main);
}

.onboarding-page .form-container {
  grid-column-start: 1;
  grid-column-end: 2;
  height: 100%;
  padding-left: 16.66%;
  padding-right: 16.66%;
  padding-top: 48px;
}

.onboarding-page .form-container .logo {
  height: 32px;
}

.onboarding-page .form-container .logo img {
  height: 100%;
}

.onboarding-page .form-container .onboarding-primary-text {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 32px;
  margin-top: 48px;
  /* line-height: 44px; */
}

.onboarding-page .form-container .onboarding-secondary-text {
  font-size: 16px;
  margin-top: 12px;
  color: var(--dark-4);
}

.onboarding-page .graphic {
  grid-column-start: 2;
  grid-column-end: 3;
  height: 100%;
  /* padding: 8.33%; */
  /* padding-left: 0; */
  /* border-radius: 8px; */
  /* overflow: hidden; */
  background: var(--blue-lighter);
}

.onboarding-form {
  width: 100%;
  padding-right: 8.33%;
  /* z-index: 15; */
  position: relative;
  /* background: white; */
}

.onboarding-form .row {
  display: grid;
  /* grid-template-columns: repeat(2, minmax(0, 1fr)); */
  /* grid-column-gap: 40px; */
}

.onboarding-form .form-group {
  margin-top: 40px;
  position: relative;
  display: flex;
  flex-direction: row;
  /* z-index: 1; */
}

.onboarding-form .form-group .assistive {
  position: absolute;
  left: 0;
  top: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-bottom: 16px; */
}

.onboarding-form .form-group .assistive .assistive-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.onboarding-form .form-group .assistive .assistive-text {
  font-size: 10px;
  color: var(--red-main);
  font-weight: 600;
  margin-left: 8px;
}

.onboarding-form .form-group.vertical {
  flex-direction: column;
}

.onboarding-form .form-group .preference-options {
}

.onboarding-form .form-group .static-label {
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-3);
  letter-spacing: 0.2px;
}

.onboarding-form .form-group .preference-options .preference-options-item {
  margin-top: 16px;
  padding: 8px;
  padding-right: 8px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  color: var(--dark-2);
}

.onboarding-form
  .form-group
  .preference-options
  .preference-options-item.selected {
  background: var(--green-main);
  color: white;
}

.onboarding-form
  .form-group
  .preference-options
  .preference-options-item
  .icon {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  margin-right: 16px;
  border-radius: 4px;
}

.onboarding-form
  .form-group
  .preference-options
  .preference-options-item
  .text {
  flex-grow: 1;
  /* text-align: center; */
}

.onboarding-form
  .form-group
  .preference-options
  .preference-options-item
  .primary {
  font-weight: 600;
}

.onboarding-form .form-group .dropdown-input-wrapper {
  width: 100%;
  position: relative;
}

.onboarding-form .form-group .dropdown-input-wrapper__multi-select-container {
  display: flex;
  flex-direction: row;
  /* align-items:center; */
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
  position: relative;
  border-bottom: solid 2px var(--light-4);
  flex-wrap: wrap;
}

.onboarding-form
  .form-group
  .dropdown-input-wrapper__multi-select-container.invalid {
  border-bottom-color: var(--red-main);
}

/* .onboarding-form .form-group .dropdown-input-wrapper__multi-select-container__input-wrapper{
  flex-grow:1;
  position:relative;
}  */

.onboarding-form
  .form-group
  .dropdown-input-wrapper__multi-select-container__inputs-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
}

.onboarding-form
  .form-group
  .dropdown-input-wrapper__multi-select-container__inputs-wrapper
  input {
  padding-left: 0;
  width: inherit;
  flex-grow: 1;
  min-width: 80px;
  border-bottom: none;
}

.onboarding-form
  .form-group
  .dropdown-input-wrapper__multi-select-container__inputs-wrapper
  input:disabled {
  background: none;
}

.onboarding-form
  .form-group
  .dropdown-input-wrapper__multi-select-container__inputs-wrapper
  .multi-select-item {
  margin-top: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  height: 24px;
  padding-right: 4px;
  background: var(--blue-main);
  color: white;
}

.onboarding-form
  .form-group
  .dropdown-input-wrapper__multi-select-container__inputs-wrapper
  .multi-select-item__text {
  pointer-events: none;
}

.onboarding-form .dropdown-input-wrapper-menu {
  width: 100%;
  max-height: 220px;
  overflow: auto;
  background: white;
  /* z-index: 10; */
}

.onboarding-form .dropdown-input-wrapper-menu .city-item {
  padding: 16px;
  border-bottom: solid 1px var(--light-2);
  transition: all 0.2s ease-out;
}

.onboarding-form .dropdown-input-wrapper-menu .city-item:hover {
  cursor: pointer;
  background: var(--blue-subtle);
}

.onboarding-form .dropdown-input-wrapper-menu .city-item .state {
  color: var(--dark-4);
}

.onboarding-form .form-group .input {
  height: 40px;
  font-size: 16px;
  border: none;
  border-bottom: solid 2px var(--light-4);
  width: 100%;
  outline: none;
  font-family: var(--nhg);
  padding-left: 32px;
}

input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: transparent;
}

input[type="date"]:focus:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:focus:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:focus:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:focus:in-range::-webkit-datetime-edit-text {
  color: var(--light-4);
}

.onboarding-form .form-group .input:focus {
  border-bottom-color: var(--blue-main);
}

.onboarding-form .form-group .input.invalid {
  border-bottom-color: var(--red-main);
}

.onboarding-form
  .form-group
  .input[type="date"]::-webkit-calendar-picker-indicator {
  /* color: rgba(0, 0, 0, 0); */
  /* opacity: 1; */
  display: block;
  /* background: url("../../icons/calendar.svg") no-repeat; */
  width: 14px;
  height: 14px;
  /* color: var(--light-3); */
  filter: brightness(50%);
  /* border-width: thin; */
}

.onboarding-form .form-group label {
  position: absolute;
  left: 32px;
  bottom: 12px;
  font-size: 16px;
  color: var(--dark-4);
  transition: all 0.2s ease-out;
  pointer-events: none;
}

.onboarding-form .form-group .static-icon {
  position: absolute;
  left: 0;
  bottom: 11px;
  color: var(--dark-4);
  transform: all 0.4s ease-out;
  pointer-events: none;
}

.onboarding-form
  .form-group
  .dropdown-input-wrapper__multi-select-container
  .static-icon {
  top: 12px;
}

.onboarding-form .form-group .input:focus + label {
  font-size: 10px;
  bottom: 38px;
  left: 2px;
}

.onboarding-form .form-group .input.hide-label-on-active + label {
  font-size: 12px;
}

.onboarding-form .form-group .input.hide-label-on-active:focus + label {
  display: none;
}

.onboarding-form .form-group .input:active + label,
.onboarding-form .form-group .input.hasValue + label {
  font-size: 10px;
  bottom: 38px;
  left: 2px;
}

.onboarding-form
  .form-group
  .dropdown-input-wrapper__multi-select-container
  .input:active
  + label,
.onboarding-form
  .form-group
  .dropdown-input-wrapper__multi-select-container
  .input.hasValue
  + label {
  /* bottom:auto; */
  font-size: 10px;
  top: -16px;
  left: 2px;
}

.onboarding-form .options-group {
  margin-top: 56px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: flex-end;
  -ms-align-items: center;
  align-items: center;
}

.onboarding-form .options-group .submit-button {
  width: 160px;
  border: none;
}

.onboarding-form .options-group .skip-button {
  margin-right: 32px;
  color: var(--blue-lighter);
  font-weight: 700;
  font-size: 14px;
}

.onboarding-form .form-group .sports-selector {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 32px;
  margin-top: 8px;
}

.onboarding-form .form-group .sports-selector .sports-selector-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
  overflow: hidden;
}

.onboarding-form .form-group .sports-selector .sport-icon {
  height: 40px;
  width: 40px;
  background: var(--light-1);
  /* margin-right: 8px; */
  flex-shrink: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.onboarding-form .form-group .sports-selector .sport-text {
  flex-grow: 1;
  /* text-align: center; */
  font-weight: 700;
  color: var(--dark-2);
  pointer-events: none;
  text-align: center;
}

.onboarding-form .form-group .sports-selector .sport-icon .sports-icon-svg {
  height: 24px;
  fill: var(--blue-lighter);
}

.onboarding-form
  .form-group
  .sports-selector
  .sport-icon.selected
  .sports-icon-svg {
  fill: white;
}

.onboarding-form .form-group .sports-selector .sports-selector-item.selected {
  background: var(--green-main);
}

.onboarding-form
  .form-group
  .sports-selector
  .sports-selector-item.selected
  .sport-icon {
  background: var(--green-darker);
}

.onboarding-form
  .form-group
  .sports-selector
  .sports-selector-item.selected
  .sports-icon-svg {
  fill: white;
}

.onboarding-form
  .form-group
  .sports-selector
  .sports-selector-item.selected
  .sport-text {
  color: white;
}

.onboarding-form .form-group .sports-selector .input-wrapper {
  position: relative;
  margin-top: 16px;
}

.onboarding-form .form-group .sports-selector .input-wrapper label {
  left: 0;
}

.onboarding-form .form-group .sports-selector .input-wrapper .input {
  padding-left: 0;
}

.onboarding-form .sports-preference-input-menu {
  width: 200px;
  height: 200px;
  overflow-y: auto;
}

.onboarding-form .sports-preference-input-menu .other-sports-selector-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  border-bottom: solid 1px var(--light-3);
  padding-left: 16px;
  padding-right: 16px;
}

.onboarding-form
  .sports-preference-input-menu
  .other-sports-selector-item
  .other-sports-selector-item-text {
  flex-grow: 1;
}
