.directory-page-mobile {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.directory-page-mobile__filters {
  position: absolute;
  width: 100%;
  height: 40px;
  background: var(--light-1);
  box-shadow: var(--elevation-mobile);
  z-index: 1;
}

.directory-page-mobile__list {
  padding-top: 40px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.directory-page-mobile__list__item {
}

.directory-page-mobile .show-more-container {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 22px; */
  /* identical to box height, or 220% */

  /* display: flex; */
  /* align-items: center; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.directory-page-mobile__filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  padding-left: 16px;
}

.directory-page-mobile__filters__item {
  border: solid 1px var(--light-2);
  box-shadow: var(--elevation-mobile);
  height: 24px;
  border-radius: 8px;
  color: var(--dark-3);
  font-family: var(--nhg-display);

  font-weight: 600;
  font-size: 10px;
  /* line-height: 22px; */
  /* or 220% */

  /* display: flex; */
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  flex-shrink: 0;
  margin-right: 12px;
  /* position: relative; */
}

.directory-page-mobile__filters__item.selected {
  background: var(--blue-main);
  color: var(--light-2);
}
