.filter-input-menu {
  /* width: 100%; */
  background: white;
  border-radius: 8px;
  box-shadow: var(--elevation-2);
  height: 240px;
  overflow-y: auto;
}

.filter-input-menu .filter-input-menu-list {
  /* padding: 16px; */
  padding-top: 8px;
  padding-bottom: 8px;
}

.filter-input-menu .filter-input-menu-list .filter-input-menu-list-item {
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.filter-input-menu .filter-input-menu-list .filter-input-menu-list-item:hover {
  cursor: pointer;
  background: var(--blue-subtle);
}

.filter-input-dropdown .app-input .input-wrapper {
  height: 32px;
  box-shadow: var(--elevation-1);
  border: solid 1px var(--light-2);
}

.filter-input-dropdown .app-input .input-wrapper input {
  /* font-size: 12px; */
  /* padding-left: 12px; */
}
