.follow-button {
}

.follow-button .show-on-hover {
  display: none;
}

.follow-button:hover .hide-on-hover {
  display: none;
}

.follow-button.secondary:hover {
  border-color: var(--red-main);
}

.follow-button:hover .show-on-hover {
  display: block;
  color: var(--red-main);
}
