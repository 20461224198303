.location-search-mobile {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.location-search-mobile__content {
  background: white;
  box-shadow: var(--elevation-mobile);
  border-radius: 8px;
  height: 70%;
  width: 90%;
  overflow: hidden;
}

.location-search-mobile__content__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-bottom: solid 1px var(--light-2);
}

.location-search-mobile__content__search__back-btn {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.location-search-mobile__content__search__input {
  flex-grow: 1;
  height: 100%;
}

.location-search-mobile__content__search__input input {
  height: 100%;
  width: 100%;
  font-family: var(--nhg);
  border: none;
  outline: none;
  font-size: 12px;
}

.location-search-mobile__content__selected {
  background: var(--light-2);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
  padding-bottom: 4px;
}

.location-search-mobile__content__selected__item {
  background: var(--blue-main);
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  font-family: var(--nhg-display);
  font-size: 10px;
  font-weight: 600;
  height: 20px;
  border-radius: 8px;
  margin-right: 12px;
  margin-bottom: 12px;
  /* padding-right: 8px; */
}

.location-search-mobile__content__items {
}

.location-search-mobile__content__items__item {
  height: 40px;
  padding-left: 16px;
  border-bottom: solid 1px var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-family: var(--nhg);
}
