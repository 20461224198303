.manage-event-awards {
  display: grid;
  padding-left: 48px;
  padding-right: 48px;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 32px;
}

.manage-event-awards__section {
  padding-top: 32px;
  box-sizing: border-box;
}

.manage-event-awards__section.left {
  grid-column-start: 1;
  grid-column-end: 5;
  border-right: solid 1px var(--light-3);
  padding-right: 32px;
}

.manage-event-awards__section.middle {
  grid-column-start: 5;
  grid-column-end: 9;
  border-right: solid 1px var(--light-3);
  padding-right: 32px;
}

.manage-event-awards__section.right {
  grid-column-start: 9;
  grid-column-end: 13;
}

.manage-event-awards__section__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.manage-event-awards__section__title__text {
  font-size: 14px;
  font-weight: 700;
  color: var(--dark-2);
}

.manage-event-awards__section__title__add-type-btn {
  margin-left: auto;
  font-weight: 700;
  color: var(--blue-main);
  cursor: pointer;
}

.manage-event-awards__section__description {
  line-height: 20px;
  color: var(--dark-3);
}

.manage-event-awards__section__grid {
  /* display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); */
  margin-top: 24px;
  /* grid-column-gap: 24px; */
}

.manage-event-awards__section__list {
  margin-top: 24px;
}

.add-award-type-form__wrapper {
  margin-top: 12px;
}

.add-award-type-form {
  /* display: flex;
  flex-direction: row;
  align-items: center; */
  margin-left: auto;
  padding: 12px;
}

.add-award-type-form__title {
  font-weight: 600;
  color: var(--dark-3);
}

.add-award-type-form__input {
  /* height: 14px; */
  /* border: none; */
  /* border-bottom: solid 1px var(--light-4); */
  font-size: 12px;
  /* width: 240px; */
  outline: none;
  /* padding-bottom: 4px; */
  background: none;
  height: 32px;
  border: solid 1px var(--light-3);
  width: 100%;
  border-radius: 8px;
  line-height: 32px;
  padding-left: 12px;
  margin-top: 12px;
}

.add-award-type-form__options {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 12px;
  margin-top: 12px;
}

.add-award-type-form__options__option {
  /* height: 20px; */
  /* width: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 8px; */
  cursor: pointer;
  height: 32px;
  font-size: 10px;
  font-weight: 700;
  border-radius: 8px;
}

.add-award-type-form__options__option.success {
  background: var(--green-main);
  color: white;
}

.add-award-type-form__options__option.cancel {
  background: var(--light-2);
  color: var(--dark-4);
}

.event-award-type-card {
  padding: 16px;
}

.event-award-type-card__title {
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.event-award-type-card__title__delete-btn {
  margin-left: auto;
  cursor: pointer;
}

.event-award-type-card__title__delete-btn__icon {
  stroke: var(--red-main);
  height: 14px;
}

.event-award-type-card__section {
  margin-top: 16px;
}

.event-award-type-card__section__title {
  font-size: 10px;
  color: var(--dark-4);
  margin-bottom: 8px;
  /* margin-bottom: 16px; */
}

.event-award-type-card__section__list {
}

.event-award-type-card__section__list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.event-award-type-card__section__list__item__image {
  height: 36px;
  width: 36px;
  background: var(--blue-subtle);
  border-radius: 4px;
  flex-shrink: 0;
  overflow: hidden;
}

.event-award-type-card__section__list__item__image img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.event-award-type-card__section__list__item__desc {
  flex-grow: 1;
  padding-right: 8px;
  padding-left: 8px;
  font-size: 12px;
}

.event-award-type-card__section__list__item__desc__primary {
  margin-bottom: 4px;
}

.event-award-type-card__section__list__item__desc__secondary {
  font-weight: 100;
  color: var(--dark-4);
}

.event-award-type-card__section__list__item__options {
  display: flex;
  flex-direction: row;
}

.event-award-type-card__section__list__item__options__option {
  cursor: pointer;
}

.event-award-type-card__section__list__item__options__option__icon {
  stroke: var(--red-main);
  height: 14px;
}

.fixture-award-type-card {
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.fixture-award-type-card__title {
  font-weight: 700;
}

.fixture-award-type-card__options {
  margin-left: auto;
}

.fixture-award-type-card__options__option {
  cursor: pointer;
}

.fixture-award-type-card__options__option__icon {
  stroke: var(--red-main);
  height: 14px;
}

.add-event-award-form {
  position: relative;
  margin-bottom: 16px;
}

.add-event-award-form .app-search-menu {
  /* width: 100%; */
  width: 100%;
  box-shadow: var(--elevation-5);
  margin-top: -4px;
}

.add-event-award-form .app-search-menu .search-results-section {
  /* padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px; */
  padding-top: 8px;
}

.add-event-award-form .app-search-menu .search-results-section__title {
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: var(--dark-4);
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
}

.add-event-award-form .app-search-menu .search-results-section__items {
}

.add-event-award-form .app-search-menu .search-results-section__items__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 16px; */
  transition: all 0.2s ease-out;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  color: var(--dark-1);
  /* font-weight: 700; */
}

.add-event-award-form
  .app-search-menu
  .search-results-section__items__item:hover {
  background: var(--light-2);
}

.add-event-award-form
  .app-search-menu
  .search-results-section__items__item__image {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.add-event-award-form
  .app-search-menu
  .search-results-section__items__item__image
  img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.add-event-award-form
  .app-search-menu
  .search-results-section__items__item__desc {
}

.add-event-award-form
  .app-search-menu
  .search-results-section__items__item__desc__primary {
  font-weight: 600;
  color: var(--dark-2);
}

.add-event-award-form
  .app-search-menu
  .search-results-section__items__item__desc__secondary {
  color: var(--dark-3);
  font-size: 10px;
}

.event-placements-list .event-placements-list__item {
  padding: 16px;
  margin-top: 16px;
}

.event-placements-list .event-placements-list__item__title {
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.event-placements-list .delete-event-placement {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.event-placements-list .delete-event-placement__image {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: var(--blue-subtle);
  overflow: hidden;
}

.event-placements-list .delete-event-placement__image img {
  width: 100%;
}

.event-placements-list .delete-event-placement__text {
  padding-left: 16px;
}

.event-placements-list .delete-event-placement__text__primary {
  font-weight: 700;
}

.event-placements-list .delete-event-placement__text__secondary {
  color: var(--dark-3);
}

.event-placements-list .delete-event-placement__option {
  margin-left: auto;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.event-placements-list .delete-event-placement__option:hover {
  background: var(--light-2);
}

.manage-event-awards__add-btn {
  height: 32px;
  background: var(--blue-subtle);
  border-radius: 8px;
  box-shadow: var(--elevation-1);
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue-main);
  font-weight: 700;
  cursor: pointer;
}
