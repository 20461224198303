.delete-match-report {
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.delete-match-report__body {
}

.delete-match-report__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.delete-match-report__options__loading {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.delete-match-report__options__option {
  background: var(--light-2);
  margin-left: 16px;
}

.delete-match-report__options__option.neutral {
  /* color: var(--dark-4); */
}
