.manage-event-roles {
}

.manage-event-roles .card-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-event-roles .card-title .add-button {
  margin-left: auto;
}

.manage-event-roles .roles-list {
  /* padding-top: 16px; */
  /* display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr)); */
}

.manage-event-roles .roles-list .event-role-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.manage-event-roles .roles-list .event-role-item .image {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  border-radius: 8px;
  /* margin-bottom: 8px; */
  margin-right: 16px;
}

.manage-event-roles .roles-list .event-role-item .image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.manage-event-roles .roles-list .event-role-item .primary-text {
  font-weight: 600;
  margin-bottom: 4px;
}

.manage-event-roles .roles-list .event-role-item .secondary-text {
  color: var(--dark-4);
  margin-bottom: 4px;
  font-size: 10px;
}

.manage-event-roles .roles-list .event-role-item .delete-button {
  /* font-size: 10px; */
  stroke: var(--red-main);
  margin-left: auto;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--red-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.manage-event-roles .roles-list .event-role-item .delete-button .icon {
  height: 14px;
}

.manage-event-roles .add-role-form {
  margin-top: 16px;
  margin-bottom: 16px;
  background: var(--blue-subtle);
  border-radius: 8px;
  padding: 16px;
}

.manage-event-roles .add-role-form .form-group {
  margin-bottom: 16px;
}

.manage-event-roles .add-role-form .form-group .form-group-label {
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 8px;
}

.manage-event-roles .add-role-form .form-group .form-group-input {
  background: white;
}

.manage-event-roles .add-role-form .options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.manage-event-roles .add-role-form .options .btn {
  height: 32px;
  border: none;
  outline: none;
  margin-left: 16px;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
}

.manage-event-roles .add-role-form .options .btn.submit {
  background: var(--green-main);
}

.manage-event-roles .add-role-form .options .btn.cancel {
  color: var(--dark-4);
}

/*  */

.manage-event-role-card .card-secondary-title {
  margin-top: 8px;
}

.manage-event-role-card .search-container {
  position: relative;
  margin-top: 16px;
}

.manage-event-role-card .search-container .app-search-menu {
  margin-top: 0;
  padding: 0;
  padding-top: 16px;
}

.manage-event-role-card
  .search-container
  .app-search-menu
  .search-results-section__title {
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: var(--dark-4);
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
}

.manage-event-role-card
  .search-container
  .app-search-menu
  .search-results-section__items__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 16px; */
  transition: all 0.2s ease-out;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  color: var(--dark-1);
  /* font-weight: 700; */
}

.manage-event-role-card
  .search-container
  .app-search-menu
  .search-results-section__items__item:hover {
  background: var(--light-2);
}

.manage-event-role-card
  .search-container
  .app-search-menu
  .search-results-section__items__item__image {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.manage-event-role-card
  .search-container
  .app-search-menu
  .search-results-section__items__item__image
  img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.manage-event-role-card
  .search-container
  .app-search-menu
  .search-results-section__items__item__desc {
}

.manage-event-role-card
  .search-container
  .app-search-menu
  .search-results-section__items__item__desc__primary {
  font-weight: 600;
  color: var(--dark-2);
}

.manage-event-role-card
  .search-container
  .app-search-menu
  .search-results-section__items__item__desc__secondary {
  color: var(--dark-3);
  font-size: 10px;
}

.manage-event-role-card .roles-list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.manage-event-role-card .roles-list__item__image {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  border-radius: 8px;
  /* margin-bottom: 8px; */
  margin-right: 16px;
  overflow: hidden;
}

.manage-event-role-card .roles-list__item__image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.manage-event-role-card .roles-list__item__content {
  flex-grow: 1;
}

.manage-event-role-card .roles-list__item__content__primary {
  font-weight: 600;
  margin-bottom: 4px;
}

.manage-event-role-card .roles-list__item__content__secondary {
  color: var(--dark-4);
  margin-bottom: 4px;
  font-size: 10px;
}

.manage-event-role-card .roles-list__item__options__delete {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.manage-event-role-card .roles-list__item__options__delete:hover {
  background: var(--light-2);
  border-radius: 4px;
}
