.media-description .ql-container {
  border: none;
}

.media-description .ql-editor {
  padding: 0;
  font-family: var(--nhg);
}

.media-description .truncate-button {
  cursor: pointer;
  color: var(--blue-main);
}

.media-description .edit-btn {
  color: var(--orange-main);
  font-weight: 700;
  cursor: pointer;
}

.media-description .delete-btn {
  color: var(--red-main);
  font-weight: 700;
  cursor: pointer;
  margin-top: 8px;
}
