.manage-event-venue {
  display: flex;
  flex-direction: column;
}

.manage-event-venue .map {
  flex-grow: 1;
  position: relative;
  background: var(--light-2);
  margin-top: 16px;
  margin-bottom: 16px;
  height: 300px;
}

.manage-event-venue .address {
  display: flex;
  flex-direction: row;
  position: relative;
}

.manage-event-venue .address .text {
  flex-grow: 1;
}

.manage-event-venue .address .text .primary-text {
  font-weight: 700;
}

.manage-event-venue .address .edit-btn {
  height: 24px;
  width: 24px;
  background: var(--orange-subtle);
  border-radius: 8px;
  box-shadow: var(--elevation-1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 16px;
}

.manage-event-venue .address .edit-btn .icon {
  fill: var(--orange-main);
  height: 14px;
}

.manage-event-venue .address .delete-btn {
  height: 24px;
  width: 24px;
  background: var(--red-subtle);
  border-radius: 8px;
  box-shadow: var(--elevation-1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 16px;
}

.manage-event-venue .address .delete-btn.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.manage-event-venue .address .delete-btn .icon {
  stroke: var(--red-main);
  height: 14px;
}

.manage-event-venue .address .address-input-wrapper {
  position: relative;
  width: 100%;
}

.manage-event-venue .address .address-input-wrapper__input {
  position: relative;
  flex-grow: 1;
}

.manage-event-venue .address .address-input-wrapper__input .submitting {
  position: absolute;
  right: 8px;
  top: 4px;
}

.manage-event-venue .address .address-input-wrapper input {
  width: 100%;
  height: 32px;
  border: solid 1px var(--light-3);
  border-radius: 8px;
  padding-left: 12px;
}

.manage-event-venue .address .address-input-wrapper input:focus {
  outline: solid 1px var(--blue-main);
}

.manage-event-venue .address .address-input-wrapper-menu {
  /* height: 240px; */
  overflow-y: auto;
}

.manage-event-venue .address .address-input-wrapper-menu .placeholder-text {
  text-align: center;
  color: var(--light-4);
  padding: 16px;
}

.manage-event-venue .address .address-input-wrapper-menu.app-card {
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0;
}

.manage-event-venue .address .address-input-wrapper-menu .address-item {
  height: 40px;
  border-bottom: solid 1px var(--light-2);
  display: flex;
  align-items: center;
  font-size: 10px;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
}

.manage-event-venue .address .address-input-wrapper-menu .address-item:hover {
  background: var(--light-2);
}

.address-input-wrapper__form {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.address-input-wrapper__form__dismiss-btn {
  height: 32px;
  width: 32px;
  margin-left: 8px;
  border-radius: 8px;
  background: var(--light-2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.address-input-wrapper__form__dismiss-btn__icon {
  stroke: var(--dark-4);
  height: 14px;
}
