.manage-event-prompt-mobile {
  border-radius: 8px;
  background: white;
  box-shadow: var(--elevation-mobile);
  margin-top: 16px;
  padding: 16px;
}

.manage-event-prompt-mobile__text {
  line-height: 1.5em;
}

.manage-event-prompt-mobile__text__primary {
  font-family: var(--nhg-display);
  color: var(--dark-1);
  font-weight: 700;
}

.manage-event-prompt-mobile__text__secondary {
  color: var(--dark-2);
}

.manage-event-prompt-mobile__action {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: var(--orange-main);
  color: white;
  height: 36px;
  font-weight: 700;
  font-family: var(--nhg-display);
  font-size: 12px;
  margin-top: 12px;
}
