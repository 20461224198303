.home-page .show-more-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 32px; */
  padding-bottom: 32px;
}

.home-page .show-more-container .show-more-button {
  width: 88px;
}
