.app-radio {
}

.app-radio .radio-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.app-radio .radio-item .radio-indicator {
  height: 16px;
  width: 16px;
  border-radius: 100%;
  border: solid 1px var(--blue-main);
  background: white;
  box-sizing: border-box;
}

.app-radio .radio-item.selected .radio-indicator {
  border-width: 4px;
}

.app-radio .radio-item .radio-label {
  margin-left: 16px;
  font-size: 12px;
  font-weight: 400;
}
