.add-extra-menu {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.add-extra-menu__column {
}

.add-extra-menu__column:first-child {
  border-right: solid 1px var(--light-2);
}

.add-extra-menu__column__item {
  height: 32px;
  border-bottom: solid 1px var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}

.add-extra-menu__column__item:hover {
  background: var(--light-2);
}

.add-extra-menu__column__item.selected {
  background: var(--blue-main);
  color: white;
}

.add-extra-menu__column__item.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.add-wicket-menu {
}

.add-wicket-menu__item {
  height: 32px;
  border-bottom: solid 1px var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}

.add-wicket-menu__item:hover {
  background: var(--light-2);
}

.delete-over-item {
  background: var(--red-lighter);
  color: var(--light-1);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}
