.badminton-box-score-mobile {
  /* margin-top: 16px; */
  display: flex;
  flex-direction: row;
  padding: 16px;

  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  margin-top: 12px;
  /* padding-bottom: 24px; */
}

.badminton-box-score-mobile__column {
}

.badminton-box-score-mobile__column__label {
  font-size: 8px;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--light-4);
  margin-bottom: 8px;
}

.badminton-box-score-mobile__column__value {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  /* font-weight: 700; */
}

.badminton-box-score-mobile__column__value.bold {
  font-weight: 700;
}

.badminton-box-score-mobile__column__value__image {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
}

.badminton-box-score-mobile__column.players {
  flex-grow: 1;
}

.badminton-box-score-mobile__column.period {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 24px;
}
