.home-followers-summary {
  padding: 16px;
  padding-bottom: 12px;
  margin-top: 32px;
}

.home-followers-summary .connections-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 32px;
  grid-row-gap: 24px;
  margin-top: 24px;
}

.home-followers-summary .connections-list .connection {
  /* width: 33%; */
  /* padding-right: 16px; */
  /* margin-bottom: 16px; */
  /* display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center; */
}

.home-followers-summary .connections-list .connection .avatar {
  height: 64px;
  /* width: 56px; */
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--light-2);
  overflow: hidden;
}

.home-followers-summary .connections-list .connection .avatar img {
  width: 100%;
}

.home-followers-summary .connections-list .connection .name {
  font-family: var(--nhg);
  /* text-align: center; */
  /* width: 100%; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* font-size: 12px; */
  color: var(--dark-1);
}

.home-followers-summary .connections-card-footer {
  text-align: center;
  border-top: solid 1px var(--light-2);
  padding-top: 8px;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-3);
  margin-top: 24px;
  cursor: pointer;
}
