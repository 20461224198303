.score-badminton-body__main {
  grid-column-start: 1;
  grid-column-end: 3;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.score-badminton-body__main__current-set {
  flex-grow: 1;
  background: var(--light-1);
}

.score-badminton-body__main__box-score {
  height: 120px;
  background: var(--blue-darkest);
  flex-shrink: 0;
}

.score-badminton-body__events {
  height: 100%;
  background: var(--light-2);
}

.score-badminton-current-set {
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
}

.score-badminton-current-set__player {
  width: 50%;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  border-right: dashed 1px var(--light-3);
  height: 100%;
}

.score-badminton-current-set__player__details__info__service-indicator {
  margin-top: 8px;
  height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  border: solid 1px var(--light-3);
  color: var(--light-4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.score-badminton-current-set__player__details__info__service-indicator.selected {
  border-color: var(--red-lighter);
  background: var(--red-lighter);
  color: var(--light-2);
  font-weight: 600;
}

.score-badminton-current-set__player__details {
  position: absolute;
  display: flex;

  /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.score-badminton-current-set__player__details.home {
  left: 24px;
  top: 32px;
  flex-direction: row;
}

.score-badminton-current-set__player__details.away {
  right: 24px;
  top: 32px;
  flex-direction: row-reverse;
}

.score-badminton-current-set__player__details__image {
  height: 54px;
  width: 54px;
  border-radius: 8px;
  background: var(--blue-subtle);
  /* margin-top: 32px; */
}

.score-badminton-current-set__player__details__info {
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.score-badminton-current-set__player__details__info__name {
  font-family: var(--nhg-display);
  font-size: 14px;
  font-weight: 600;
  color: var(--dark-1);
  /* margin-top: 32px; */
}

.score-badminton-current-set__player__details.away
  .score-badminton-current-set__player__details__info {
  align-items: flex-end;
}

.score-badminton-current-set__score {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-top: 16px;
  padding-bottom: 16px;
}

.score-badminton-current-set__score__action {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.score-badminton-current-set__score__action:hover {
  background: var(--light-2);
}

.score-badminton-current-set__score__action .icon {
  stroke: var(--light-4);
  height: 24px;
}

.score-badminton-current-set__score__value {
  font-family: var(--din);
  font-size: 80px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.score-badminton-current-set .time {
  position: absolute;
  top: 32px;
  left: calc(50% - 68px);
}

.score-badminton-current-set .time .selected-period-selector {
  height: 32px;
  min-width: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-darkest);
  padding-left: 16px;
  padding-right: 8px;
  border-radius: 8px;
  color: white;
  font-weight: 700;
  margin-left: 16px;
  cursor: pointer;
}

.score-badminton-current-set .time .selected-period-selector-menu {
  background: var(--light-1);
  width: 100%;
  margin-top: 4px;
  border-radius: 8px;
  box-shadow: var(--elevation-5);
  /* z-index: 100; */
  position: relative;
  overflow: hidden;
  z-index: 5;
}

.score-badminton-current-set .time .selected-period-selector-menu__item {
  text-align: center;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: solid 1px var(--light-2);
  cursor: pointer;
}

.score-badminton-current-set .time .selected-period-selector-menu__item:hover {
  background: var(--light-2);
}

.score-badminton-current-set
  .time
  .selected-period-selector-menu__item:last-child {
  border: none;
}

.score-badminton-current-set
  .time
  .selected-period-selector
  .selected-period-selector__icon {
  stroke: white;
  height: 14px;
  margin-left: 8px;
}

.score-badminton-events-list {
  height: 100%;
  overflow: auto;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.score-badminton-event-list-item {
  margin-top: 16px;
}

.score-badminton-event-list-item__order {
  font-weight: 600;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-badminton-event-list-item__order__text {
  flex-grow: 1;
}

.score-badminton-event-list-item__order__delete {
  cursor: pointer;
}

.score-badminton-event-list-item__order__delete .icon {
  stroke: var(--red-lighter);
  height: 12px;
}

.score-badminton-event-list-item__body {
  border-top: solid 1px var(--light-2);
  border-bottom: solid 1px var(--light-2);
}

.score-badminton-event-list-item__body__statement {
  padding: 12px;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
}

.score-badminton-event-list-item__body__statement__text {
  flex-grow: 1;
}

.score-badminton-event-list-item__body__statement__switch {
  cursor: pointer;
}

.score-badminton-event-list-item__body__statement__switch .icon {
  fill: var(--light-4);
  height: 16px;
}

.score-badminton-event-list-item__body__statement.bold {
  font-weight: 600;
}

.score-badminton-event-list-item__body__relations {
}

.score-badminton-event-list-item__body__relations__relation {
  border: solid 1px var(--light-3);
  border-radius: 4px;
  margin: 12px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-badminton-event-list-item__body__relations__relation__text {
  flex-grow: 1;
}

.score-badminton-event-list-item__body__relations__relation__option {
  cursor: pointer;
}

.score-badminton-event-list-item__body__relations__relation__option .icon {
  stroke: var(--red-lighter);
  height: 14px;
}

.score-badminton-event-list-item__server {
  padding: 8px;
  color: var(--dark-4);
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: row;
}

.score-badminton-event-list-item__server__text {
  flex-grow: 1;
}

.score-badminton-event-list-item__server__switch {
  cursor: pointer;
}

.score-badminton-event-list-item__server__switch .icon {
  height: 16px;
  fill: var(--light-4);
}

.score-badminton-event-list-item__body__options {
  display: flex;
  flex-direction: row;
  /* padding: 12px; */
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
}

.score-badminton-event-list-item__body__options__option {
  height: 24px;
  border-radius: 4px;
  border: solid 1px var(--light-3);
  display: flex;
  flex-direction: row;
  align-items: center;
  /* font-family: var(--nhg-display); */
  font-size: 10px;
  padding-right: 8px;
  margin-right: 12px;
  color: var(--light-4);
  cursor: pointer;
  margin-top: 12px;
}

.score-badminton-event-list-item__body__options__option .icon {
  /* fill: var(--light-4); */
  stroke: var(--light-4);
  height: 12px;
}

.score-badminton-event-list-item__body__options__option:hover {
  background: var(--blue-main);
  color: var(--light-2);
}

.score-badminton-event-list-item__body__options__option:hover .icon {
  stroke: var(--light-2);
}

.score-badminton-box-score {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  padding: 16px;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
}

.score-badminton-box-score__column {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--light-3);
}

.score-badminton-box-score__column.players {
  flex-grow: 1;
  font-weight: 600;
}

.score-badminton-box-score__column.period {
  margin-left: 32px;
  align-items: center;
}

.score-badminton-box-score__column__label {
  margin-bottom: 8px;
  font-size: 10px;
}

.score-badminton-box-score__column__value {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-badminton-box-score__column__value__image {
  height: 20px;
  width: 20px;
  background: var(--blue-lighter);
  border-radius: 4px;
  margin-right: 16px;
}

.score-tennis-event-list-item {
  margin-top: 16px;
  padding: 16px;
}

.score-tennis-event-list-item__header {
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  border-bottom: solid 1px var(--light-2);
  margin-bottom: 12px;
}

.score-tennis-event-list-item__header__title {
  font-weight: 600;
  color: var(--dark-4);
}

.score-tennis-event-list-item__header__delete-btn {
  margin-left: auto;
}

.score-tennis-event-list-item__header__delete-btn .icon {
  stroke: var(--red-lighter);
  height: 12px;
}

.score-tennis-event-list-item__metas {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: solid 1px var(--light-2);
}

.score-tennis-event-list-item__metas__meta {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.score-tennis-event-list-item__metas__meta__name {
  margin-left: 4px;
}

.score-tennis-event-list-item__metas__meta .filter-input-dropdown {
  margin-left: auto;
}

.score-tennis-event-list-item__metas__meta .filter-input-dropdown__select {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: auto; */
}

.score-tennis-event-list-item__body__statement__switch {
  cursor: pointer;
}

.score-tennis-event-list-item__body__statement__switch .icon {
  fill: var(--light-4);
  height: 16px;
}

.score-tennis-event-list-item__body__statement__delete {
  cursor: pointer;
}

.score-tennis-event-list-item__body__statement__delete .icon {
  stroke: var(--red-lighter);
  height: 12px;
  margin-top: 2px;
}

.score-tennis-event-list-item__metas__meta
  .score-tennis-event-list-item__body__statement__switch {
  margin-left: auto;
}

.score-tennis-event-list-item__points {
}

.score-tennis-event-list-item__points__point {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.score-tennis-event-list-item__points__point__statement {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  /* align-items: center; */
}

.score-tennis-event-list-item__points__point__statement__name {
  margin-left: 4px;
}

.score-tennis-event-list-item__points__point__delete-btn {
  margin-top: 4px;
  /* margin-left: auto; */
}

.score-tennis-event-list-item__points__point__delete-btn .icon {
  stroke: var(--red-lighter);
  height: 12px;
}

.score-tennis-event-list-item__points__point__value {
  font-weight: 600;
  color: var(--dark-4);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-tennis__create-tie-break-btn {
  margin-top: 16px;
  color: var(--dark-4);
  /* width: 140px; */
}

.score-tennis-event-list-item__body__options {
  display: flex;
  flex-direction: row;
  /* padding: 12px; */
  /* padding-left: 12px; */
  /* padding-right: 12px; */
  padding-bottom: 12px;
}

.score-tennis-event-list-item__body__options__option {
  height: 24px;
  border-radius: 4px;
  border: solid 1px var(--light-3);
  display: flex;
  flex-direction: row;
  align-items: center;
  /* font-family: var(--nhg-display); */
  font-size: 8px;
  padding-right: 8px;
  margin-right: 12px;
  color: var(--dark-4);
  cursor: pointer;
  margin-top: 12px;
}

.score-tennis-event-list-item__body__options__option .icon {
  /* fill: var(--light-4); */
  stroke: var(--light-4);
  height: 12px;
}

.score-tennis-event-list-item__body__options__option:hover {
  background: var(--blue-main);
  color: var(--light-2);
}

.score-tennis-event-list-item__body__options__option:hover .icon {
  stroke: var(--light-2);
}
