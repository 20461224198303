.photo-browser-mobile {
  height: 100%;
  width: 100%;
  background: black;
  /* display: grid; */
  /* grid-template-columns: repeat(24, minmax(0, 1fr)); */
  /* grid-template-rows: 100%; */
}

.photo-browser-mobile__image {
  /* grid-column-start: 1; */
  /* grid-column-end: 18; */
  background: black;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--elevation-6);
  overflow: hidden;
  /* padding-bottom: 240px; */
}

.photo-browser-mobile__image__close {
  position: absolute;
  top: 12px;
  left: 12px;
  stroke: white;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  height: 32px;
  width: 32px;
  z-index: 2;
}

.photo-browser-mobile__image__prev {
  position: absolute;
  height: 100%;
  width: 32px;
  /* border-radius: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: var(--light-4); */
  top: 0;
  left: 0;
  stroke: var(--dark-1);
  cursor: pointer;
  z-index: 1;
  /* padding-bottom: 240px; */
}

.photo-browser-mobile__image__next {
  position: absolute;
  height: 100%;
  width: 32px;
  /* border-radius: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: var(--light-4); */
  top: 0;
  right: 0;
  stroke: var(--dark-1);
  cursor: pointer;
  /* padding-bottom: 240px; */
}

.photo-browser-mobile__image__close .icon {
}

.photo-browser-mobile__image img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.photo-browser-mobile__image video {
  width: 100%;
}

.photo-browser-mobile__sidebar {
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  width: 100%;
  bottom: 0;
  /* height: 200px; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: var(--light-2);
}

.photo-browser-mobile__sidebar__source {
  height: 40px;
}

.photo-browser-mobile__sidebar__source__fixture {
  display: grid;
  grid-template-columns: 45% 10% 45%;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  /* border-bottom: solid 1px var(--light-2); */
}

.photo-browser-mobile__sidebar__source__fixture__team {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.photo-browser-mobile__sidebar__source__fixture__team.away {
  justify-content: flex-end;
}

.photo-browser-mobile__sidebar__source__fixture__team__image {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  margin-right: 12px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo-browser-mobile__sidebar__source__fixture__team__image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.photo-browser-mobile__sidebar__source__fixture__team__name {
  font-weight: 700;
  flex-grow: 1;
  font-size: 10px;
}

.photo-browser-mobile__sidebar__source__fixture__team.away
  .photo-browser-mobile__sidebar__source__fixture__team__name {
  text-align: right;
}

.photo-browser-mobile__sidebar__source__fixture__team__score {
  font-weight: 700;
}

.photo-browser-mobile__sidebar__source__fixture__seperator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: 700;
  color: var(--dark-4);
  letter-spacing: 1px;
}

.photo-browser-mobile__sidebar__source__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  /* width: 100%; */
  /* border-bottom: solid 1px var(--light-2); */
}

.photo-browser-mobile__sidebar__source__row__image {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  margin-right: 12px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.photo-browser-mobile__sidebar__source__row__image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.photo-browser-mobile__sidebar__source__row__text {
  flex-grow: 1;
  font-size: 10px;
}

.photo-browser-mobile__sidebar__source__row__text__primary {
  font-weight: 700;
}

.photo-browser-mobile__sidebar__source__row__text__secondary {
  color: var(--light-4);
}

.photo-browser-mobile__sidebar__description {
  height: 120px;
  overflow: hidden;
  /* background: blue; */
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  transition: all 0.2s ease-out;
}

.photo-browser-mobile__sidebar__description.expanded {
  height: 160px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 16px;
}

.photo-browser-mobile__sidebar__description .truncate-button {
  cursor: pointer;
  color: var(--blue-main);
  margin-top: 4px;
  margin-bottom: 4px;
}

.photo-browser-mobile__sidebar__description .ql-editor {
  padding: 0;
  font-size: 12px;
  font-family: var(--nhg);
}

.photo-browser-mobile__sidebar__options {
  height: 40px;
  /* background: red; */
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  /* padding-right: 12px; */
  /* border-top: solid 1px var(--light-2); */
}

.photo-browser-mobile__sidebar__options__option.right {
  margin-left: auto;
  font-size: 10px;
}

.photo-browser-mobile__sidebar__options__option {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.photo-browser-mobile__sidebar__options__option .value {
  margin-left: 0px;
  font-weight: 700;
  color: var(--dark-4);
}

.photo-browser-mobile__sidebar__options__option__text {
  text-align: right;
}

.photo-browser-mobile__sidebar__options__option__text__primary {
  font-weight: 700;
}

.photo-browser-mobile__sidebar__options__option__text__secondary {
  color: var(--dark-4);
}

.photo-browser-mobile__sidebar__options__option__image {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-left: 12px;
  overflow: hidden;
}

.photo-browser-mobile__sidebar__options__option__image img {
  width: 100%;
}

.photo-browser-mobile__sidebar__options__option__more {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
}
