.profile-image-cropper {
  height: 300px;
  margin-top: 16px;
  /* position: relative; */
}

.profile-image-cropper .upload-btn {
  position: relative;
  overflow: hidden;
}

.profile-image-cropper .upload-btn .upload-btn-progress {
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  height: 100%;
  transition: all 0.2s ease-out;
}
