.fixture-summary .match-lineups-card {
  padding: 16px;
  padding-bottom: 0;
}

.fixture-summary .match-lineups-card .card-title {
  font-weight: 700;
  color: var(--dark-4);
  text-align: center;
  margin-bottom: 16px;
}

.fixture-summary .match-lineups-card .tab-selector {
  height: 28px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border: solid 1px var(--blue-lighter);
  border-radius: 8px;
  margin-bottom: 16px;
}

.fixture-summary .match-lineups-card .tab-selector .tab-selector-option {
  width: 50%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  color: var(--dark-3);
  cursor: pointer;
}

.fixture-summary
  .match-lineups-card
  .tab-selector
  .tab-selector-option.selected {
  background: var(--blue-main);
  color: white;
}

.fixture-summary .match-lineups-card .tab-selector .tab-selector-option .logo {
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background: var(--blue-subtle);
  overflow: hidden;
}

.fixture-summary
  .match-lineups-card
  .tab-selector
  .tab-selector-option
  .logo
  img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.fixture-summary
  .match-lineups-card
  .tab-selector
  .tab-selector-option
  .formation {
  margin-left: 8px;
  font-weight: 600;
  font-size: 10px;
}

.fixture-summary .match-lineups-card .pitch {
  height: 340px;
  background: var(--blue-subtle);
  margin-bottom: 16px;
}

.fixture-summary .match-lineups-card .lineup-list {
}

.fixture-summary .match-lineups-card .lineup-list .section {
}

.fixture-summary .match-lineups-card .lineup-list .section .section-title {
  background: var(--blue-subtlest);
  border-radius: 8px;
  font-weight: 700;
  color: var(--dark-3);
  padding: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.fixture-summary .match-lineups-card .lineup-list .lineup-list-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border-bottom: solid 1px var(--blue-subtlest);
  padding: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: "Inter", sans-serif;
  font-size: 12px;
}

.fixture-summary .match-lineups-card .lineup-list .lineup-list-item .number {
  width: 32px;
}

.fixture-summary
  .match-lineups-card
  .lineup-list
  .lineup-list-item
  .profile-pic {
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-right: 8px;
  overflow: hidden;
}

.fixture-summary
  .match-lineups-card
  .lineup-list
  .lineup-list-item
  .profile-pic
  img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.fixture-summary .match-lineups-card .lineup-list .lineup-list-item .name {
  margin-right: auto;
}

.fixture-summary .match-lineups-card .lineup-list .lineup-list-item .pos {
}

.fixture-summary .match-lineups-card .fixture-lineups-summary-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-weight: 600;
  color: var(--dark-4);
  margin-top: 16px;
}
