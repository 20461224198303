.add-video-mobile {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-video-mobile-sources {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 16px;

  padding: 16px;
}

.add-video-mobile-sources__item {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--light-2);
  border-radius: 8px;
}
