.event-overview__about {
  padding: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.event-overview__about__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.event-overview__about__desc {
  margin-top: 10px;
  font-family: var(--nhg);
  /* font-style: normal; */
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* or 167% */

  /* Dark / Dark 2 */

  color: var(--dark-2);
  border-bottom: solid 1px var(--light-2);
}

.event-overview__about__dates {
  padding-bottom: 10px;
  /* border-bottom: solid 1px var(--light-2); */
}

.event-overview__about__dates__group {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}

.event-overview__about__dates__group__key {
  font-weight: 600;
  color: var(--dark-2);
  font-family: var(--nhg-display);
}

.event-overview__about__dates__group__value {
  margin-left: auto;
}

.event-overview__about__venue {
  /* padding-top: 12px; */
  margin-top: 10px;
  /* border-top: solid 1px var(--light-2); */
}

.event-overview__about__venue__map {
  background: var(--blue-subtle);
  height: 160px;
}

.event-overview__about__venue__org {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.event-overview__about__venue__org__image {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-right: 16px;
}

.event-overview__about__venue__org__desc {
  margin-top: 12px;
}

.event-overview__about__venue__org__desc__primary {
  font-weight: 600;
  color: var(--dark-2);
  /* font-family: var(--nhg-display); */
}

.event-overview__about__venue__org__desc__secondary {
  color: var(--dark-4);
  margin-top: 4px;
}
