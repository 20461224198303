.home-profile-card {
  /* padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 16px; */
  padding: 0;
}

.home-profile-card__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
}

.home-profile-card__header__image {
  height: 48px;
  width: 48px;
  border-radius: 100%;
  background: var(--blue-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* margin-bottom: 16px; */
  border: solid 2px var(--light-2);
  box-shadow: var(--elevation-3);
  margin-right: 16px;
}

.home-profile-card__header__image img {
  height: 100%;
}

.home-profile-card__header__meta .primary {
  font-weight: 700;
  margin-bottom: 4px;
  font-size: 16px;
  font-family: var(--nhg-display);
}

.home-profile-card__header__meta .secondary {
  color: var(--dark-2);
  font-family: var(--nhg);
  font-weight: 400;
}

.home-profile-card__desc {
  padding: 16px;
  padding-top: 0;
  margin: 0;
  /* margin-bottom: 16px; */
  line-height: 19px;
  color: var(--dark-3);
}

.home-profile-card .profile-link {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  color: var(--dark-1);
  flex-direction: column;
}

.home-profile-card .image {
  height: 98px;
  width: 98px;
  border-radius: 100%;
  background: var(--blue-subtle);
  /* margin-right: 16px; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 16px;
  border: solid 2px var(--light-2);
  box-shadow: var(--elevation-3);
}

.home-profile-card .image img {
  height: 100%;
}

.home-profile-card .meta {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  /* margin-right: auto; */
  /* font-family: "Inter", sans-serif; */
  text-align: center;
}

.home-profile-card .meta .primary {
  font-weight: 700;
  margin-bottom: 4px;
  font-size: 16px;
  font-family: var(--nhg-display);
}

.home-profile-card .meta .secondary {
  color: var(--dark-2);
  font-family: var(--nhg);
  font-weight: 400;
}

.home-profile-card .links {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: solid 1px whitesmoke;
  border-bottom: solid 1px whitesmoke;
}

.home-profile-card .links .link {
  flex: 1;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
}

.home-profile-card .links .link .icon {
  height: 32px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  stroke: var(--dark-4);
}

.home-profile-card .links .link .icon img {
  height: 20px;
}

.home-profile-card .links .link .desc {
  font-size: 8px;
  letter-spacing: 1px;
  color: var(--dark-1);
  font-family: var(--din);
}

.home-profile-card .links .link.active .icon {
  stroke: var(--blue-main);
}

.home-profile-card .links .link.active .desc {
  font-weight: 700;
  color: var(--blue-main);
}

.home-profile-card .links .link:hover .icon {
  stroke: var(--blue-lighter);
}

.home-profile-card .links .link:hover .desc {
  color: var(--blue-lighter);
}
