.landing-page {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.landing-page .landing-page-header {
  /* height: 88px; */
  display: flex;
  flex-direction: row;
  padding-left: 8.33%;
  padding-right: 8.33%;
  align-items: center;
  margin-top: 64px;
  margin-bottom: 64px;
}

.landing-page .landing-page-header .logo {
  margin-right: auto;
}

.landing-page .landing-page-header .logo img {
  height: 32px;
}

.landing-page .hero-section {
  height: 50%;
  padding-left: 8.33%;
  padding-right: 8.33%;
}

.landing-page .hero-section .content {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.landing-page .hero-section .content .primary-text {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 24px;
}

.landing-page .hero-section .content .secondary-text {
  font-size: 16px;
  color: var(--dark-3);
  line-height: 24px;
  margin-bottom: 32px;
}

.landing-page .hero-section .content .cta {
  width: 240px;
  height: 48px;
}
