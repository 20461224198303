.profile-overview-participation-card {
  padding: 16px;
}

.profile-overview-participation-card .card-title {
  font-weight: 600;
  color: var(--dark-4);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-overview-participation-card .profile-overview-participation-card-list {
}

.profile-overview-participation-card
  .profile-overview-participation-card-list
  .profile-overview-participation-card-list-item {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.profile-overview-participation-card
  .profile-overview-participation-card-list
  .profile-overview-participation-card-list-item
  .image {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-overview-participation-card
  .profile-overview-participation-card-list
  .profile-overview-participation-card-list-item
  .image
  img {
  width: 100%;
}

.profile-overview-participation-card
  .profile-overview-participation-card-list
  .profile-overview-participation-card-list-item
  .desc {
}

.profile-overview-participation-card
  .profile-overview-participation-card-list
  .profile-overview-participation-card-list-item
  .desc
  .primary-text {
  font-weight: 600;
  line-height: 20px;
  color: var(--dark-1);
}

.profile-overview-participation-card
  .profile-overview-participation-card-list
  .profile-overview-participation-card-list-item
  .desc
  .secondary-text {
  color: var(--dark-4);
}

.profile-overview-participation-card .card-title .card-title-link {
  color: var(--blue-main);
  margin-left: auto;

  cursor: pointer;
}
