.manage-event-teams-mobile {
  padding: 16px;
}

.manage-event-teams-mobile__card {
  background: white;
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  padding: 12px;
  margin-bottom: 16px;
}

.manage-event-teams-mobile__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */
  color: var(--dark-4);
}

.manage-event-teams-mobile__list {
}

.manage-event-teams-mobile__list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.manage-event-teams-mobile__list__item__image {
  height: 36px;
  width: 36px;
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-right: 16px;
  overflow: hidden;
}

.manage-event-teams-mobile__list__item__image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.manage-event-teams-mobile__list__item__text {
  flex-grow: 1;
}

.manage-event-teams-mobile__list__item__text__primary {
  font-weight: 600;
  margin-bottom: 4px;
}

.manage-event-teams-mobile__list__item__text__secondary {
  color: var(--green-darker);
  font-size: 10px;
}

.manage-event-teams-mobile__list__item__text__secondary.orange {
  color: var(--orange-main);
}

.manage-event-teams-mobile__list__item__action {
  background: var(--light-2);
  height: 24px;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  color: var(--dark-4);
  cursor: pointer;
  /* flex-shrink: 0; */
}

.manage-event-teams-mobile__list__item__action.confirm {
  background: var(--green-main);
  color: white;
}
