.event-overview {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  /* grid-template-rows: masonry; */
  grid-gap: 32px;
}

.event-overview .left-column {
  grid-column-start: 1;
  grid-column-end: 8;
}

.event-overview .left-column .sub-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  /* grid-template-rows: masonry; */
  grid-gap: 32px;
  margin-top: 32px;
}

.event-overview .right-column {
  grid-column-start: 8;
  grid-column-end: 13;
}

.event-overview .event-details-card {
  margin-top: 32px;
  padding: 16px;
}

.event-overview .event-details-card .event-organizer {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  padding-top: 8px;
}

.event-overview .event-details-card .event-organizer .image {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  border-radius: 8px;
}

.event-overview .event-details-card .event-organizer .text {
  padding-left: 16px;
}

.event-overview .event-details-card .event-organizer .text .primary {
  font-weight: 600;
}

.event-overview .event-details-card .event-organizer .text .secondary {
  color: var(--dark-4);
}

.event-overview .event-details-card .event-details-card-title {
  font-weight: 600;
  color: var(--dark-1);
  margin-bottom: 8px;
}

.event-overview .event-details-card .blurb {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  line-height: 19px;
}

.event-overview .event-details-card .dates {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  border: solid 1px var(--light-2);
}

.event-overview .event-details-card .dates .date-group {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.event-overview .event-details-card .dates .date-group:last-child {
  margin-bottom: 0;
}

.event-overview .event-details-card .dates .date-group .date-group-key {
  font-weight: 600;
  color: var(--dark-1);
  margin-bottom: 8px;
}

.event-overview .event-details-card .dates .date-group .date-group-value {
  margin-left: auto;
  /* font-weight: 600; */
  /* font-family: "Inter", sans-serif; */
}

.event-overview .event-details-card .dates .date-group .edit-date-btn {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--orange-main);
  margin-left: 16px;
  background: var(--orange-subtle);
  border-radius: 4px;
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.event-overview .event-details-card .dates .date-group .edit-date-btn .icon {
  height: 10px;
}

.event-overview .event-location-card {
  margin-top: 32px;
  padding: 16px;
}

.event-overview .event-location-card .map {
  height: 280px;
  border-radius: 8px;
  background: var(--blue-subtle);
}

.event-overview .event-location-card .venue {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.event-overview .event-location-card .venue .image {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background: var(--blue-subtle);
}

.event-overview .event-location-card .venue .desc {
  padding-left: 16px;
  flex-grow: 1;
}

.event-overview .event-location-card .venue .desc .primary {
  font-weight: 600;
  color: var(--dark-2);
}

.event-overview .event-location-card .venue .desc .secondary {
}
