.app-placeholder.pulse {
  animation: pulse 1s infinite ease-in-out;
  min-height: 10px;
  background: var(--light-3);
}

@keyframes pulse {
  0% {
    opacity: 0.2;
    /* background-color: rgba(165, 165, 165, 0.1); */
  }
  50% {
    opacity: 0.4;
    /* background-color: rgba(165, 165, 165, 0.3); */
  }
  100% {
    opacity: 0.2;
    /* background-color: rgba(165, 165, 165, 0.1); */
  }
}
