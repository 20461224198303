.profile-overview-mobile__about {
  padding: 12px;
  padding-bottom: 24px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  /* margin-top: 12px; */
}

.profile-overview-mobile__about__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.profile-overview-mobile__about__groups {
}

.profile-overview-mobile__about__groups__group {
  /* display: flex;
  flex-direction: column; */
  margin-top: 12px;
}

.profile-overview-mobile__about__groups__group.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-overview-mobile__about__groups__group__key {
  font-weight: 600;
  color: var(--dark-2);
  font-family: var(--nhg-display);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-overview-mobile__about__groups__group__value {
  /* margin-left: auto; */
}

.profile-overview-mobile__about__groups__group.row
  .profile-overview-mobile__about__groups__group__value {
  margin-left: auto;
}

.profile-overview-mobile__about__groups__group__value.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 16px;
}

.profile-overview-mobile__about__groups__group__value .chip {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  /* border: solid 1px var(--light-3); */
  box-shadow: var(--elevation-2);
  border-radius: 4px;
  overflow: hidden;
  /* width: 50%; */
}

.profile-overview-mobile__about__groups__group__value .chip .icon {
  height: 24px;
  width: 24px;
  background: var(--blue-main);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-overview-mobile__about__groups__group__value
  .chip
  .icon
  .sports-icon-svg {
  fill: white;
  height: 16px;
}

.profile-overview-mobile__about__groups__group__value .chip .desc {
  flex-grow: 1;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  color: var(--dark-3);
}

.profile-overview-mobile__about__groups__group__edit-btn {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--orange-main);
  margin-left: 16px;
  background: var(--orange-subtle);
  border-radius: 4px;
  /* transition: all 0.2s ease-out; */
}

.profile-overview-mobile__about__groups__group__edit-btn__icon {
  height: 10px;
}
