.ScoringApp-football__scoring__delete-stats-form {
  background: white;
  width: 30%;
  border-radius: 8px;
  overflow: hidden;
}

.ScoringApp-football__scoring__delete-stats-form__header {
  background: var(--blue-darker);
  font-weight: 700;
  height: 32px;
  display: flex;
  align-items: center;
  color: white;
  padding: 16px;
}

.ScoringApp-football__scoring__delete-stats-form__body {
  padding: 16px;
  /* padding-top: 0; */
  display: flex;
  flex-direction: row;
}

.ScoringApp-football__scoring__delete-stats-form__body__timestamp {
  background: var(--blue-subtle);
  width: 36px;
  min-height: 36px;
  color: var(--blue-darker);
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding-top: 11px;
  border-radius: 8px;
  margin-right: 16px;
}

.ScoringApp-football__scoring__delete-stats-form__body__statements {
}

.ScoringApp-football__scoring__delete-stats-form__body__statements
  .ScoringApp-football__scoring__period__list__item__section__statements__statement {
  justify-content: flex-start;
}

.ScoringApp-football__scoring__delete-stats-form__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  /* padding-top: 8px; */
  border-top: solid 1px var(--light-2);
}

.ScoringApp-football__scoring__delete-stats-form__options__option {
  margin-left: 16px;
}

.ScoringApp-football__scoring__delete-stats-form__options__option.neutral {
  background: var(--light-2);
  border: none;
  color: var(--dark-4);
}
