.fixture-stats-page {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 32px;
  padding-bottom: 48px;
}

.fixture-stats-page .left-column {
  grid-column-start: 1;
  grid-column-end: 7;
}

/* .fixture-stats-page .middle-column {
  grid-column-start: 7;
  grid-column-end: 10;
} */

.fixture-stats-page .right-column {
  grid-column-start: 7;
  grid-column-end: 13;
}

.fixture-stats-page .team-details {
  margin-top: 32px;
  /* background: var(--blue-subtle); */
  padding: 16px;
}

.fixture-stats-page .team-details__meta {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.fixture-stats-page .team-details .logo {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 8px;
  overflow: hidden;
}

.fixture-stats-page .team-details .logo img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.fixture-stats-page .team-details .team-name {
  font-size: 16px;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-2);
  flex-grow: 1;
}

.fixture-stats-page .left-column .team-details .logo {
  margin-left: 16px;
}

.fixture-stats-page .right-column .team-details .logo {
  margin-right: 16px;
}

.fixture-stats-page .right-column .team-details .team-name {
  text-align: right;
}

.fixture-stats-page .shots-summary-new {
  margin-top: 32px;
  /* padding-left: 16px; */
  /* padding-right: 16px; */
}

.fixture-stats-page .shots-summary-new .shots-summary-list {
  /* flex-grow: 1; */
}

.fixture-stats-page .shots-summary-new .shots-summary-list .summary-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 32px;
}

.fixture-stats-page
  .shots-summary-new
  .shots-summary-list
  .summary-item:last-child {
  margin-bottom: 0;
}

.fixture-stats-page
  .shots-summary-new
  .shots-summary-list
  .summary-item
  .legend {
  height: 32px;
  width: 32px;
  border-radius: 100%;
  /* margin-right: 16px; */
}

.fixture-stats-page
  .shots-summary-new
  .shots-summary-list
  .summary-item
  .label {
  color: var(--dark-3);
  font-weight: 700;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
}

.fixture-stats-page
  .right-column
  .shots-summary-new
  .shots-summary-list
  .summary-item
  .label {
  text-align: right;
}

.fixture-stats-page
  .shots-summary-new
  .shots-summary-list
  .summary-item
  .value {
  /* margin-left: auto; */
  font-weight: 700;
  padding-left: 12px;
  padding-right: 12px;
  /* color: var(--blue-main); */
}

.fixture-stats-page .shots-summary-new .concentric-chart {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  position: relative;
  grid-column-start: 6;
  grid-column-end: 10;
  height: 160px;
  margin-top: 32px;
}

.fixture-stats-page .shots-summary-new .concentric-chart .circle {
  position: absolute;
  border-radius: 100%;
}

.fixture-stats-page .key-players {
  margin-top: 32px;
  padding: 16px;
}

.fixture-stats-page .key-players .card-title {
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-2);
}

.fixture-stats-page .key-players .key-players-list {
  margin-top: 16px;
}

.fixture-stats-page .key-players .key-players-list .key-players-list-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px var(--light-2);
}

.fixture-stats-page
  .key-players
  .key-players-list
  .key-players-list-item
  .player-image {
  background: var(--blue-subtle);
  height: 48px;
  width: 48px;
  border-radius: 4px;
}

.fixture-stats-page
  .key-players
  .key-players-list
  .key-players-list-item
  .player-info {
  flex-grow: 1;
  padding-left: 16px;
  padding-right: 16px;
}

.fixture-stats-page
  .key-players
  .key-players-list
  .key-players-list-item
  .player-name {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  /* margin-top: 16px; */
  /* flex-grow: 1; */
}

.fixture-stats-page
  .key-players
  .key-players-list
  .key-players-list-item
  .player-pos {
  /* font-weight: 600; */
  color: var(--dark-3);
}

.fixture-stats-page
  .key-players
  .key-players-list
  .key-players-list-item
  .stat-value {
  font-size: 16px;
  font-family: var(--din);
}

.fixture-stats-page
  .key-players
  .key-players-list
  .key-players-list-item
  .highlighted-stat {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  background: var(--blue-darker);
  width: 48px;
  height: 48px;
  border-radius: 8px;
  color: white;
}

.fixture-stats-page
  .key-players
  .key-players-list
  .key-players-list-item
  .stat-label {
  font-family: var(--din-narrow);
}

.fixture-stats-page .match-stats {
  margin-top: 32px;
  padding: 16px;
}

.fixture-stats-page .match-stats .card-title {
  text-align: center;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-2);
  border-bottom: solid 1px var(--light-2);
  padding-bottom: 16px;
}

.fixture-stats-page .match-stats .match-stats-list {
  /* padding: 16px; */
  padding-top: 0;
}

.fixture-stats-page .match-stats .match-stats-list .match-stats-list-item {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  margin-top: 16px;
}

.fixture-stats-page
  .match-stats
  .match-stats-list
  .match-stats-list-item
  .home-value {
  grid-column-start: 1;
  grid-column-end: 3;
}

.fixture-stats-page
  .match-stats
  .match-stats-list
  .match-stats-list-item
  .home-bar {
  grid-column-start: 3;
  grid-column-end: 5;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.fixture-stats-page
  .match-stats
  .match-stats-list
  .match-stats-list-item
  .home-bar
  .fill {
  background: var(--blue-lighter);
}

.fixture-stats-page
  .match-stats
  .match-stats-list
  .match-stats-list-item
  .away-bar
  .fill {
  background: var(--orange-lighter);
}

.fixture-stats-page
  .match-stats
  .match-stats-list
  .match-stats-list-item
  .home-bar
  .bar-container {
  height: 8px;
  background: var(--light-2);
  width: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: flex-end;
}

.fixture-stats-page
  .match-stats
  .match-stats-list
  .match-stats-list-item
  .away-value {
  grid-column-start: 11;
  grid-column-end: 13;
  text-align: right;
}

.fixture-stats-page
  .match-stats
  .match-stats-list
  .match-stats-list-item
  .away-bar {
  grid-column-start: 9;
  grid-column-end: 11;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.fixture-stats-page
  .match-stats
  .match-stats-list
  .match-stats-list-item
  .away-bar
  .bar-container {
  height: 8px;
  background: var(--light-2);
  width: 100%;
}

.fixture-stats-page
  .match-stats
  .match-stats-list
  .match-stats-list-item
  .away-bar
  .bar-container
  .fill {
  height: 100%;
}

.fixture-stats-page
  .match-stats
  .match-stats-list
  .match-stats-list-item
  .home-bar
  .bar-container
  .fill {
  height: 100%;
}

.fixture-stats-page
  .match-stats
  .match-stats-list
  .match-stats-list-item
  .desc {
  grid-column-start: 5;
  grid-column-end: 9;
  text-align: center;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-2);
}

.fixture-stats-page .match-form {
  margin-top: 32px;
  padding: 16px;
}

.fixture-stats-page .match-form .card-title {
  /* text-align: center; */
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-4);
  display: grid;
  grid-template-columns: 40% auto 40%;
}

.fixture-stats-page .match-form .home-form {
  border-left: solid 2px #8951ff;
  padding-left: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.fixture-stats-page .match-form .away-form {
  border-right: solid 2px #ffaa2b;
  padding-right: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: flex-end;
}

.fixture-stats-page .match-form .card-title .text {
  grid-column-start: 2;
  grid-column-end: 3;
  text-align: center;
}

.fixture-stats-page .match-form .card-chart {
  margin-top: 16px;
}

.fixture-stats-page .head-to-head {
  margin-top: 32px;
  padding: 16px;
}

.fixture-stats-page .head-to-head .card-title {
  /* text-align: center; */
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-4);
}

.fixture-stats-page .table-stats {
  grid-column-start: 1;
  grid-column-end: 13;
  margin-top: 32px;
  overflow: hidden;
}

.fixture-stats-page .table-stats__header {
  background: var(--blue-darker);
  font-weight: 700;
  font-size: 10px;
  color: white;
}

.fixture-stats-page .table-stats__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-bottom: solid 1px var(--light-2);
  width: 100%;
}

.fixture-stats-page .table-stats__header .table-stats__row {
  height: 24px;
}

.fixture-stats-page .table-stats__row__data {
  padding-left: 16px;
  padding-right: 16px;
  flex-shrink: 0;
}

.fixture-stats-page .table-stats__body__seperator {
  padding-left: 16px;
  padding-right: 16px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  background: var(--light-3);
  /* color: white; */
}

.fixture-stats-page .table-stats__body__seperator__img {
  height: 24px;
  width: 24px;
  background-color: var(--light-2);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.fixture-stats-page .table-stats__body__seperator__img img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

/* FOOTBALL STATS */
.fixture-stats-page__football__match-stats {
  grid-column-start: 1;
  grid-column-end: 7;
}

.fixture-stats-page__football__player-stats {
  grid-column-start: 7;
  grid-column-end: 13;
}

.fixture-stats-page .match-stats__teams {
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: solid 1px var(--light-2);
}

.fixture-stats-page .match-stats__teams__team {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-stats-page .match-stats__teams__team.away {
  margin-left: auto;
}

.fixture-stats-page .match-stats__teams__team__image {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  border-radius: 4px;
}

.fixture-stats-page .match-stats__teams__team__image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.fixture-stats-page .match-stats__teams__team__name {
  margin-left: 16px;
  margin-right: 16px;
}

.fixture-stats-page .match-stats__shots {
  padding-top: 16px;
  border-top: solid 1px var(--light-2);
  margin-top: 16px;
}

.fixture-stats-page .shots-summary {
  grid-column-start: 1;
  grid-column-end: 7;
  margin-top: 32px;
  padding: 16px;
}

.fixture-stats-page .shots-summary .card-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: solid 1px var(--light-2);
}

.fixture-stats-page .shots-summary .card-title__team {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40%;
}

.fixture-stats-page .shots-summary .card-title__team.away {
  justify-content: flex-end;
}

.fixture-stats-page .shots-summary .card-title__team__image {
  height: 24px;
  width: 24px;
  background-color: var(--blue-subtle);
  border-radius: 4px;
}

.fixture-stats-page .shots-summary .card-title__team__image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.fixture-stats-page .shots-summary .card-title__team__name {
  margin-left: 16px;
  margin-right: 16px;
}

.fixture-stats-page .shots-summary .card-title__label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-2);
  width: 20%;
  /* padding-bottom: 16px; */
}

.fixture-stats-page .shots-summary .shots-summary__body {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
}

.fixture-stats-page .shots-summary .shots-summary__body__section {
  width: 50%;
}

.fixture-stats-page .shots-summary .shots-summary__body__section.home {
  border-right: solid 1px var(--light-2);
  padding-right: 16px;
}

.fixture-stats-page .shots-summary .shots-summary__body__section.away {
  padding-left: 16px;
}

.fixture-stats-page .shots-summary .shots-summary__body__section__title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-stats-page .shots-summary .shots-summary__body__section__title__value {
  font-size: 24px;
  font-weight: 100;
  font-family: var(--nhg-display);
}

.fixture-stats-page
  .shots-summary
  .shots-summary__body__section.away
  .shots-summary__body__section__title__value {
  margin-left: auto;
}

.fixture-stats-page .shots-summary__body__section__graphic {
  height: 160px;
  margin-top: 16px;
  position: relative;
}

.shots-summary__body__section__graphic__total-shots {
  background: var(--blue-subtle);
  position: absolute;
  bottom: 0;
}

.shots-summary__body__section__graphic__shots-on-goal {
  background: var(--blue-main);
  position: absolute;
  bottom: 0;
}

.fixture-stats-page
  .shots-summary
  .shots-summary__body__section.home
  .shots-summary__body__section__graphic__total-shots {
  left: 0;
}

.fixture-stats-page
  .shots-summary
  .shots-summary__body__section.home
  .shots-summary__body__section__graphic__shots-on-goal {
  left: 0;
}

.fixture-stats-page
  .shots-summary
  .shots-summary__body__section.away
  .shots-summary__body__section__graphic__total-shots {
  right: 0;
}

.fixture-stats-page
  .shots-summary
  .shots-summary__body__section.away
  .shots-summary__body__section__graphic__shots-on-goal {
  right: 0;
}

.fixture-stats-page__player-stats {
  margin-top: 32px;
  grid-column-start: 7;
  grid-column-end: 13;
}

.fixture-stats-page__player-stats__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: solid 1px var(--light-2);
}

.fixture-stats-page__player-stats__header__team {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-stats-page__player-stats__header__team__image {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  overflow: hidden;
  background: var(--blue-subtle);
}

.fixture-stats-page__player-stats__header__team__image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.fixture-stats-page__player-stats__header__team__name {
  margin-left: 16px;
  margin-right: 16px;
  font-weight: 700;
  color: var(--dark-2);
}

.fixture-stats-page__player-stats__header__team__menu__item {
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: solid 1px var(--light-2);
  cursor: pointer;
}

.fixture-stats-page__player-stats__header__category__menu__item {
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: solid 1px var(--light-2);
  cursor: pointer;
}

.fixture-stats-page__player-stats__header__team__menu__item:hover {
  background: var(--light-2);
}

.fixture-stats-page__player-stats__header__category__wrapper {
  margin-left: auto;
}

.fixture-stats-page__player-stats__header__category {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  color: var(--dark-2);
}

.fixture-stats-page .fixture-stats-page__player-stats__table__header {
  background: var(--blue-subtle);
  font-weight: 700;
  font-size: 10px;
  color: var(--blue-lighter);
}

/* .fixture-stats-page .table-stats__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-bottom: solid 1px var(--light-2);
  width: 100%;
} */

.fixture-stats-page
  .fixture-stats-page__player-stats__table__header
  .table-stats__row {
  height: 24px;
}

.fixture-stats-page .table-stats__row__data {
  padding-left: 16px;
  padding-right: 16px;
  color: var(--dark-2);
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.fixture-stats-page .table-stats__row__data .text-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.fixture-stats-page
  .fixture-stats-page__player-stats__table__header
  .table-stats__row__data {
  color: var(--blue-lighter);
}
