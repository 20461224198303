.home-request-card {
  margin-bottom: 24px;
  padding: 16px;
  position: relative;
}

.home-request-card .dismiss-btn {
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
}

.home-request-card .meta {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  line-height: 19px;
}

.home-request-card .meta .avatar {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  margin-right: 16px;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
}

.home-request-card .request-actions {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 16px;
}

.home-request-card .request-actions .action {
  flex: 1;
}

.home-request-card .request-actions .action:first-child {
  margin-right: 8px;
}

.home-request-card .request-actions .action:last-child {
  margin-left: 8px;
}
