.ScoringApp-cricket__scoring__stats-list__edit-item__wrapper {
  background: var(--light-2);
  padding: 8px;
}

.ScoringApp-cricket__scoring__stats-list__edit-item {
  background: white;
  /* border-radius: 4px; */
}

.ScoringApp-cricket__scoring__stats-list__edit-item__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  padding-left: 12px;
  border-bottom: solid 1px var(--light-2);
}

.ScoringApp-cricket__scoring__stats-list__edit-item__title__text {
  font-size: 14px;
  font-weight: 700;
  font-family: var(--nhg-display);
}

.ScoringApp-cricket__scoring__stats-list__edit-item__title__dismiss {
  cursor: pointer;
  margin-left: auto;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__title__dismiss__icon {
  stroke: var(--dark-4);
  height: 16px;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__main {
}

.ScoringApp-cricket__scoring__stats-list__edit-item__main__group {
  padding: 12px;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__main__group__label {
  font-family: var(--nhg-display);
  color: var(--dark-3);
  margin-right: auto;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__main__group__value {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__main__group__value__input {
  width: 64px;
  border: solid 1px var(--light-3);
  border-radius: 4px;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__main__group__value__input:focus {
  outline: solid 1px var(--blue-main);
}

.ScoringApp-cricket__scoring__stats-list__edit-item__extras {
  padding: 12px;
  border-top: solid 1px var(--light-2);
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__wickets {
  padding: 12px;
  border-top: solid 1px var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__create-btn {
  font-size: 10px;
  /* font-family: var(--nhg-display); */
  border: solid 1px var(--blue-darkest);
  color: var(--blue-darkest);
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  transition: all 0.2s ease-out;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__create-btn:hover {
  background: var(--blue-darkest);
  color: white;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__tag {
  font-size: 10px;
  /* font-family: var(--nhg-display); */
  border: solid 1px var(--dark-1);
  color: var(--dark-1);
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 2px;
  transition: all 0.2s ease-out;
  margin-right: 12px;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__tag__icon {
  stroke: var(--dark-4);
  height: 12px;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__tag:hover {
  border-color: var(--red-main);
  color: var(--red-main);
}

.ScoringApp-cricket__scoring__stats-list__edit-item__tag:hover
  .ScoringApp-cricket__scoring__stats-list__edit-item__tag__icon {
  stroke: var(--red-main);
}

.ScoringApp-cricket__scoring__stats-list__edit-item__add-extra-menu {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__add-extra-menu__column:first-child {
  border-right: solid 1px var(--light-2);
}

.ScoringApp-cricket__scoring__stats-list__edit-item__add-extra-menu__column__item {
  height: 32px;
  border-bottom: solid 1px var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__add-extra-menu__column__item:hover {
  background: var(--light-2);
}

.ScoringApp-cricket__scoring__stats-list__edit-item__add-extra-menu__column__item.selected {
  background: var(--blue-darkest);
  color: white;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__add-extra-menu__column__item.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.ScoringApp-cricket__scoring__stats-list__edit-item__danger-zone {
  background: var(--red-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  margin-top: 16px;
  font-weight: 700;
  color: var(--red-darker);
  cursor: pointer;
}
