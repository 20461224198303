.app-img-wrapper {
  position: relative;
}

.app-img-wrapper img {
  transition: all 0.2s ease-out;
}

.app-img-wrapper img.hidden {
  opacity: 0;
}

.app-img-wrapper .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #022e4b;
  z-index: 0;
  opacity: 0.1;
}
