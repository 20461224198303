.settings-form-new {
  flex-grow: 1;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  overflow: auto;
}

.settings-form-new__body {
  display: grid;
  grid-template-columns: 30% 70%;
  background: var(--blue-darkest);
  /* grid-column-gap: 32px; */
}

.settings-form-new__body .period-settings-new {
  padding: 24px;
}

.settings-form-new__footer {
  background: var(--blue-darkest);
}

.period-settings-card {
  overflow: hidden;
}

.period-settings-card__title {
  background: var(--blue-main);
  color: var(--light-2);
  font-weight: 700;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.period-settings-card__body__item {
  padding: 16px;
  border-top: solid 1px var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.period-settings-card__body__item__details {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.period-settings-card__body__item__details .order {
  width: 32px;
}

.period-settings-card__body__item__options {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.period-settings-card__body__item__options__option {
  margin-left: 16px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  border: none;
  transition: all 0.2s ease-out;
}

.period-settings-card__body__item__options__option__icon {
  height: 14px;
}

.period-settings-card__body__item__options__option.edit {
  background: var(--orange-subtle);
  /* border: solid 1px var(--orange-main); */
}

.period-settings-card__body__item__options__option__icon.edit {
  fill: var(--orange-main);
}

.period-settings-card__body__item__options__option.edit:hover {
  background: var(--orange-main);
}

.period-settings-card__body__item__options__option.edit:hover
  .period-settings-card__body__item__options__option__icon {
  fill: var(--light-2);
}

.period-settings-card__body__item__options__option.delete {
  background: var(--red-subtle);
}

.period-settings-card__body__item__options__option__icon.delete {
  stroke: var(--red-main);
}

.period-settings-card__body__item__options__option.delete:hover {
  background: var(--red-main);
}

.period-settings-card__body__item__options__option.delete:hover
  .period-settings-card__body__item__options__option__icon {
  fill: var(--light-2);
}

.period-settings-card__body__item__options__option.disabled {
  opacity: 40%;
  pointer-events: none;
  cursor: default;
}

.period-settings-card__add-period {
  padding: 16px;
  color: var(--green-main);
  font-weight: 700;
  cursor: pointer;
}

.period-form {
  padding: 16px;
  border-top: solid 1px var(--light-2);
}

.period-form__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.period-form__group {
  position: relative;
}

.period-form__group.text {
  flex-grow: 1;
  margin-right: 16px;
}

.period-form__group__label {
  position: absolute;
  top: -8px;
  left: 4px;
  background: white;
  font-size: 8px;
  color: var(--dark-4);
  padding: 4px;
}

.period-form__input {
  height: 32px;
  border: solid 1px var(--light-3);
  padding-left: 8px;
  font-size: 12px;
  border-radius: 4px;
}

.period-form__input {
  height: 32px;
  border: solid 1px var(--light-3);
  padding-left: 8px;
  font-size: 12px;
  border-radius: 4px;
}

.period-form__group.text .period-form__input {
  width: 100%;
}

.period-form__input.duration {
  width: 80px;
}

.period-form__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.period-form__options__option {
  margin-left: 16px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  background: none;
  border-radius: 4px;
  border: none;
  transition: all 0.2s ease-out;
}

.period-form__options__option .icon {
  height: 14px;
}

.period-form__options__option.submit {
  background: var(--green-subtle);
}

.period-form__options__option.submit .icon {
  fill: var(--green-main);
}

.period-form__options__option.submit:hover {
  background: var(--green-main);
}

.period-form__options__option.submit:hover .icon {
  fill: var(--light-1);
}

.period-form__options__option.cancel {
  background: var(--light-2);
}

.period-form__options__option.cancel .icon {
  stroke: var(--dark-4);
}

.period-form__options__option.disabled {
  opacity: 40%;
  pointer-events: none;
}

.settings-form-new__body .lineup-settings-new {
  padding: 24px;
  height: 100%;
}

.lineup-settings-card {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.lineup-settings-card__overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  z-index: 5;
}

.lineup-settings-card__overlay.show {
  opacity: 1;
  pointer-events: all;
}

.lineup-settings-card__title {
  background: var(--blue-main);
  color: var(--light-2);
  font-weight: 700;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.lineup-settings-card__body {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 25% 37.5% 37.5%;
  background: var(--light-2);
}

.lineup-settings-card__body__selector {
  border-right: solid 1px var(--light-2);
  background: var(--light-1);
  box-shadow: var(--elevation-1);
}

.lineup-settings-card__body__selector__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom: solid 1px var(--light-2);
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.lineup-settings-card__body__selector__item__img {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-right: 16px;
  overflow: hidden;
}

.lineup-settings-card__body__selector__item__img img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.lineup-settings-card__body__selector__item__text {
  font-weight: 700;
}

.lineup-settings-card__body__selector__item.selected {
  border-right: solid 4px var(--green-main);
  background: var(--blue-darkest);
  color: white;
}

.lineup-settings-card__body__starters {
  padding: 16px;
}

.lineup-settings-card .starters {
  height: 100%;
}

.lineup-settings-card .starters .card-title {
  /* border-bottom: solid 1px var(--light-3); */
  width: 100%;
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  /* text-align: center; */
  font-weight: 700;
  color: var(--dark-3);
  /* padding: 16px; */
}

.lineup-settings-card .starters .starters__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-3);
  border-top: solid 1px var(--light-3);
  width: 100%;
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  /* background: var(--light-2); */
}

.lineup-settings-card .starters__title__text {
  font-weight: 700;
  color: var(--dark-4);
  display: flex;
  flex-direction: row;
}

.lineup-settings-card .starters__title__text__icon {
  stroke: var(--dark-4);
  height: 14px;
}

.lineup-settings-card .starters__title__selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.lineup-settings-card .starters__title__selection__option {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
  cursor: pointer;
}

.lineup-settings-card .starters__title__selection__option__icon {
  fill: var(--dark-4);
}

.lineup-settings-card
  .starters__title__selection__option.selected
  .starters__title__selection__option__icon {
  fill: var(--blue-main);
}

.lineup-settings-card__body__subs {
  padding: 16px;
}
