.ScoringApp-cricket__scoring__modal-form {
  width: 33%;
  height: 80%;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: white;
}

.ScoringApp-cricket__scoring__modal-form__title {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--blue-darker);
  color: white;
  font-weight: 600;
  padding-left: 16px;
  padding-right: 8px;
  flex-shrink: 0;
}

.ScoringApp-cricket__scoring__modal-form__title__dismiss {
  margin-left: auto;
  cursor: pointer;
}

.ScoringApp-cricket__scoring__modal-form__tabs {
  display: flex;
  flex-direction: row;
  background: var(--blue-darkest);
  flex-shrink: 0;
}

.ScoringApp-cricket__scoring__modal-form__tabs__tab {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
  color: var(--light-3);
  font-family: var(--nhg-display);
  border-bottom: solid 2px transparent;
}

.ScoringApp-cricket__scoring__modal-form__tabs__tab.active {
  font-weight: 700;
  /* color: var(--green-main); */
  border-bottom-color: var(--green-main);
}

.ScoringApp-cricket__scoring__modal-form__list {
  flex-grow: 1;
  overflow: auto;
}

.ScoringApp-cricket__scoring__modal-form__list__item {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.ScoringApp-cricket__scoring__modal-form__list__item__img {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.ScoringApp-cricket__scoring__modal-form__list__item__name {
}

.ScoringApp-cricket__scoring__modal-form__list__item__name__primary {
  font-weight: 700;
}

.ScoringApp-cricket__scoring__modal-form__list__item__name__secondary {
  color: var(--dark-4);
}

.ScoringApp-cricket__scoring__modal-form__list__item__num {
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  font-size: 12px;
  color: var(--dark-4);
}

.ScoringApp-cricket__scoring__modal-form__list__item:hover {
  background: var(--light-2);
}
