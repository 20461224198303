.cricket-box-score {
  margin-bottom: 24px;
}

.cricket-box-score__main {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
}

.cricket-box-score__main__section {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cricket-box-score__main__section.home {
}

.cricket-box-score__main__section.away {
  justify-content: flex-end;
}

.cricket-box-score__main__section__team {
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cricket-box-score__main__section__team__logo {
  height: 48px;
  width: 48px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.cricket-box-score__main__section__team__logo img {
  width: 100%;
  height: auto;
}

.cricket-box-score__main__section__team__name {
  font-weight: 700;
  color: var(--dark-4);
}

.cricket-box-score__main__section__score {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--nhg-display);
}

.cricket-box-score__main__section__score__runs {
  font-size: 16px;
  font-weight: 700;
  color: var(--dark-2);
  margin-bottom: 4px;
}

.cricket-box-score__main__section__score__overs {
}

.cricket-box-score__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-2);
  padding-top: 8px;
  padding-bottom: 8px;
}

.cricket-box-score__footer__statement {
  font-size: 10px;
  font-weight: 700;
  color: var(--dark-4);
}
