/* .stats-page__badminton {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
} */

.stats-page-badminton__success-record {
  grid-column-start: 1;
  grid-column-end: 5;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 24px;
  margin-top: 32px;
}

.stats-page-badminton__success-record__games-played {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 2;
  padding: 16px;
  border-right: solid 1px var(--light-2);
}

.stats-page-badminton__success-record__games-played__label {
  font-family: var(--nhg-display);
  color: var(--blue-lighter);
  font-weight: 700;
  position: absolute;
  left: 16px;
  bottom: 28px;
}

.stats-page-badminton__success-record__games-played__value {
  /* position: absolute;
  left: 16px;
  top: 16px; */
  font-family: var(--din);
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
}

.stats-page__badminton__grid {
  grid-column-start: 1;
  grid-column-end: 5;
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 32px;
}

.stats-page__badminton__grid__left__column {
  grid-column-start: 1;
  grid-column-end: 4;
}

.stats-page__badminton__grid__right__column {
  grid-column-start: 4;
  grid-column-end: 5;
}

.stats-page__badminton__grid__left__column .points-record {
  width: 100%;
}

.stats-page__badminton__grid__left__column .service-record {
  width: 100%;
  margin-top: 24px;
}

.stats-page__badminton__grid__right__column .percentages-record {
  height: 100%;
}

.stats-page-badminton__success-record__bar-chart {
  flex-grow: 1;
  grid-column-start: 2;
  grid-column-end: 4;
}

.stats-page-badminton__success-record__bar-chart__item {
  margin-top: 24px;
}

.stats-page-badminton__success-record__bar-chart__item__labels {
  display: flex;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  font-size: 12px;
  color: var(--dark-3);
  font-weight: 700;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
}

.stats-page-badminton__success-record__bar-chart__item__labels__left {
}

.stats-page-badminton__success-record__bar-chart__item__labels__right {
  margin-left: auto;
}

.stats-page-badminton__success-record__bar-chart__bar-container {
  height: 12px;
  background: var(--light-2);
  border-radius: 8px;
  overflow: hidden;
}

.stats-page-badminton__success-record__bar-chart__bar-container__fill {
  height: 100%;
  border-radius: 8px;
}

.stats-page-badminton__success-record__games-won {
  grid-column-start: 4;
  grid-column-end: 5;
  position: relative;
  border-left: solid 1px var(--light-2);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.stats-page-badminton__success-record__games-won__graphic {
  padding: 16px;
  width: 100%;
  /* padding-top: 8px; */
  /* padding-bottom: 16px; */
  /* position: relative; */
}

.stats-page-badminton__success-record__games-won__label {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stats-page-badminton__success-record__games-won__label__num {
  font-family: var(--din);
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 8px;
}

.stats-page-badminton__success-record__games-won__label__text {
  font-size: 8px;
  margin-top: 4px;
  font-family: var(--nhg-display);
  color: var(--blue-lighter);
  font-weight: 700;
}

/* NEW DESIGN */

/* Record */
.organization-record__wrapper {
  /* margin: 16px; */
  margin-top: 32px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.profile-shooting-stats__doughnut__wrapper {
  border-top: solid 1px var(--light-2);
}
