.score-cricket {
  height: 100%;
  width: 100%;
  position: relative;
  background: white;
}

.score-cricket .header {
  height: 48px;
  position: absolute;
  top: 0;
  width: 100%;
  background: var(--blue-darker);
  box-shadow: var(--elevation-4);
  z-index: 3;
  display: grid;
  grid-template-columns: 24% 52% 24%;
  grid-template-rows: 100%;
}

.score-cricket .header .close-scoring-btn {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  padding-left: 16px;
  cursor: pointer;
}

.score-cricket .header .close-scoring-btn .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-cricket .header .close-scoring-btn .text {
  margin-left: 8px;
  font-weight: 700;
}

.score-cricket .header .time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.score-cricket .header .time .start {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-weight: 700;
  height: 32px;
  background: var(--blue-darkest);
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 12px;
  margin-left: 16px;
}

.score-cricket .header .time .start .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-cricket .header .time .end-of-period {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-weight: 700;
  background: var(--blue-darkest);
  height: 32px;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 12px;
  margin-left: 16px;
}

.score-cricket .header .time .end-of-period .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-cricket .header .time .current-time {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-darkest);
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  color: white;
  font-weight: 700;
  margin-left: 16px;
}

.score-cricket .header .settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
  cursor: pointer;
}

.score-cricket .header .settings .publish-stats-btn {
  background: var(--green-darker);
  height: 28px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
}

.score-cricket .header .page-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.score-cricket .header .page-heading .innings {
  background: var(--blue-darkest);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 8px;
  padding: 4px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 10px;
  font-weight: 700;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
}

.score-cricket .header .page-heading .innings.current {
  background: white;
  color: var(--blue-main);
}

.score-cricket .header.expanded {
  height: 100%;
}

.score-cricket .body {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-template-rows: 100%;
  background: var(--light-2);
  height: 100%;
  padding-top: 48px;
}

.score-cricket .left-column {
  background: white;
  display: flex;
  flex-direction: column;
  box-shadow: var(--elevation-4);
  z-index: 2;
}

.score-cricket .left-column .summary {
  flex-grow: 1;
  overflow: auto;
}

.score-cricket .left-column .actions {
  background: var(--light-2);
}

.score-cricket .left-column .summary {
}

.score-cricket .left-column .summary .batting-summary {
}

.score-cricket .left-column .summary .batting-summary .head {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  padding-bottom: 8px;
  width: 100%;
  color: var(--dark-4);
  font-weight: 600;
}

.score-cricket .left-column .summary .batting-summary .head .section-title {
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket .left-column .summary .section-title {
  font-size: 10px;
  color: var(--dark-4);
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket .left-column .summary .batting-summary .score {
  font-size: 16px;
  font-weight: 700;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  border-bottom: solid 1px var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket .left-column .summary .batting-summary .section-title .team {
  margin-left: auto;
}

.score-cricket .left-column .summary .batting-summary .score .app-button {
  margin-left: auto;
}

.score-cricket .left-column .summary .batting-summary .on-strike {
}

.score-cricket .left-column .summary .batting-summary .on-strike .fields {
  background: var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  height: 24px;
  font-size: 10px;
  color: var(--dark-4);
  font-weight: 600;
}

.score-cricket .left-column .summary .batting-summary .on-strike .row {
  border-bottom: solid 1px var(--light-2);
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}

.score-cricket .left-column .summary .batting-summary .on-strike .row .input {
  height: 32px;
  border-radius: 4px;
  border: solid 1px var(--light-3);
  display: flex;
  align-items: center;
  padding-left: 8px;
  width: 240px;
  color: var(--dark-4);
  cursor: pointer;
}

.score-cricket .left-column .summary .batting-summary .on-strike .row .batsman {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket
  .left-column
  .summary
  .batting-summary
  .on-strike
  .row
  .batsman
  .batsman-profile {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket
  .left-column
  .summary
  .batting-summary
  .on-strike
  .row
  .batsman
  .batsman-profile
  .profile-pic {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background: var(--blue-subtle);
  margin-right: 8px;
}

.score-cricket
  .left-column
  .summary
  .batting-summary
  .on-strike
  .row
  .batsman
  .icon {
  margin-right: 8px;
  cursor: pointer;
}

.score-cricket .left-column .summary .bowling-summary .section-title {
}

.score-cricket .left-column .summary .bowling-summary .head {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  padding-bottom: 16px;
  width: 100%;
  color: var(--dark-4);
  font-weight: 600;
}

.score-cricket .left-column .summary .bowling-summary .fields {
  background: var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  height: 24px;
  font-size: 10px;
  color: var(--dark-4);
  font-weight: 600;
}

.score-cricket .left-column .summary .bowling-summary .row {
  border-bottom: solid 1px var(--light-2);
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}

.score-cricket .left-column .summary .bowling-summary .row .bowler {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket
  .left-column
  .summary
  .bowling-summary
  .row
  .bowler
  .profile-pic {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background: var(--blue-subtle);
  margin-right: 8px;
}

.score-cricket .left-column .summary .bowling-summary .row .input {
  height: 32px;
  border-radius: 4px;
  border: solid 1px var(--light-3);
  display: flex;
  align-items: center;
  padding-left: 8px;
  width: 240px;
  color: var(--dark-4);
  cursor: pointer;
}

.score-cricket .left-column .current-over {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  /* padding-right: 24px; */
  background: white;
  box-shadow: var(--elevation-1);
  border-top: solid 1px var(--light-3);
  z-index: 4;
  border-bottom: solid 1px var(--light-3);
  width: 100%;
  height: 78px;
  flex-shrink: 0;
}

.score-cricket .left-column .current-over .label {
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: solid 1px var(--light-2);
  flex-shrink: 0;
}

.score-cricket .left-column .current-over .label .primary {
  font-size: 16px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket .left-column .current-over .label .primary .icon {
  stroke: var(--light-4);
  margin-left: auto;
}

.score-cricket .left-column .current-over .label .secondary {
  font-size: 10px;
  color: var(--dark-4);
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 4px;
}

.score-cricket .left-column .current-over .current-over__menu {
  box-shadow: var(--elevation-6);
  z-index: 5;
  position: relative;
  overflow: hidden;
}

.score-cricket .left-column .current-over .current-over__menu__item {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: solid 1px var(--light-2);
  font-weight: 700;
  color: var(--dark-4);
  cursor: pointer;
  width: 64px;
}

.score-cricket .left-column .current-over .current-over__menu__item:hover {
  background: var(--light-2);
}

.score-cricket .left-column .current-over .over-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 24px;
  background: var(--light-2);
  flex-grow: 1;
  overflow: auto;
}

.score-cricket .left-column .current-over .over-item {
  /* margin-left: 16px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-left: solid 1px var(--light-2);
  width: 64px;
  background: var(--light-1);
  flex-shrink: 0;
}

.score-cricket .left-column .current-over .over-item .over-item-run {
  height: 28px;
  width: 28px;
  border: solid 1px var(--light-4);
  background: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.score-cricket .left-column .current-over .over-item .over-item-run.active {
  border: solid 2px var(--blue-main);
}

.score-cricket .left-column .current-over .over-item .over-item-tag {
  height: 16px;
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 1px;
  /* border-bottom: solid 1px var(--light-4); */
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
  padding: 2px;
  /* background: var(--blue-darker); */
  color: white;
}

.score-cricket .left-column .current-over .over-item .over-item-tag.completed {
  background: var(--blue-darker);
}

.score-cricket .left-column .current-over .over-item .over-item-tag.extra {
  background: var(--light-4);
}

.score-cricket .left-column .current-over .over-item .over-item-wicket {
  height: 20px;
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 1px;
  /* border-top: solid 1px var(--light-4); */
  width: 100%;
  text-align: center;
  margin-top: 4px;
  padding: 2px;
  color: var(--red-lighter);
}

.score-cricket .left-column .current-over .over-item .runs {
}

.score-cricket .left-column .current-over .over-item .tag {
}

.score-cricket .left-column .current-over .app-button {
  /* margin-left: auto; */
  flex-shrink: 0;
  margin-left: 16px;
  margin-right: 16px;
}

.score-cricket .left-column .actions {
  box-shadow: var(--elevation-1);
  z-index: 3;
  display: flex;
  flex-direction: row;
  background: var(--blue-subtle);
}

.score-cricket .left-column .actions .tags {
  width: 25%;
  padding: 24px;
  border-right: solid 1px var(--light-3);
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.score-cricket .left-column .actions .tags .tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 10px;
  letter-spacing: 0.4px;
  font-weight: 600;
  color: var(--dark-3);
  cursor: pointer;
  box-shadow: var(--elevation-4);
}

.score-cricket .left-column .actions .tags .tag.active {
  background: var(--blue-main);
  color: white;
}

.score-cricket .left-column .actions .wickets {
  width: 40%;
  padding: 24px;
  border-right: solid 1px var(--light-3);
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.score-cricket .left-column .actions .wickets .wicket {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  letter-spacing: 0.4px;
  font-weight: 600;
  color: var(--dark-3);
  font-size: 10px;
  cursor: pointer;
  box-shadow: var(--elevation-4);
}

.score-cricket .left-column .actions .wickets .wicket.active {
  background: var(--blue-main);
  color: white;
}

.score-cricket .left-column .actions .runs {
  width: 35%;
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.score-cricket .left-column .actions .runs .run-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-cricket .left-column .actions .runs .run {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  letter-spacing: 0.4px;
  font-weight: 600;
  color: var(--dark-3);
  font-size: 10px;
  cursor: pointer;
  box-shadow: var(--elevation-4);
}

.score-cricket .right-column {
  background: var(--light-3);
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: 100%;
  overflow: auto;
}

.score-cricket .right-column .column-title {
  height: 36px;
  flex-shrink: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  background: var(--blue-darkest);
  color: var(--light-1);
}

.score-cricket .right-column .stats {
}

.score-cricket .right-column .overs {
}

.score-cricket .right-column .overs .over {
}

.score-cricket .right-column .overs .over .over-title {
  height: 24px;
  background: var(--blue-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 700;
  color: var(--blue-darker);
}

.score-cricket .right-column .overs .over .over-items {
}

.score-cricket .right-column .overs .over .over-items .over-item {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  border-bottom: solid 1px var(--light-2);
  padding-left: 16px;
  padding-right: 16px;
}

.score-cricket .right-column .overs .over .over-items .over-item .label {
  font-size: 16px;
  color: var(--light-4);
}

.score-cricket .right-column .overs .over .over-items .over-item .run {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  border: solid 1px var(--light-3);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  color: var(--dark-4);
  font-size: 10px;
  font-weight: 700;
}

.score-cricket .right-column .overs .over .over-items .over-item .text {
  margin-left: 16px;
  font-size: 10px;
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .over-item
  .text
  .primary {
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .over-item
  .text
  .secondary {
}

.score-cricket .right-column .overs .over .over-items .over-item .edit {
  margin-left: auto;
  cursor: pointer;
}

.score-cricket .right-column .overs .over .over-items .edit-over-item-form {
  background: white;
  border-bottom: solid 1px var(--light-2);
  padding: 16px;
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__title {
  color: var(--dark-2);
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__title__dismiss {
  margin-left: auto;
  cursor: pointer;
  height: 12px;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__items {
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__items__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  font-size: 10px;
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__items__item__label {
  flex-grow: 1;
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__items__item__value {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  border-top: solid 1px var(--light-2);
  /* padding-top: 16px; */
  flex-wrap: wrap;
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__tags__tag {
  border: solid 1px var(--light-4);
  border-radius: 4px;
  margin-right: 12px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  color: var(--dark-3);
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
  margin-top: 16px;
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__tags__tag__icon {
  stroke: var(--light-4);
  height: 12px;
  width: 12px;
  margin-left: 8px;
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__tags__tag.add {
  border: solid 1px var(--blue-subtle);
  color: var(--blue-main);
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__tags__tag:hover {
  color: var(--light-1);
  border-color: var(--red-main);
  background: var(--red-main);
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__tags__tag:hover
  .edit-over-item-form__tags__tag__icon {
  stroke: var(--light-1);
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__tags__tag.add:hover {
  border-color: var(--blue-main);
  background: var(--blue-main);
  color: var(--light-1);
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__options__option {
  margin-right: 12px;
  font-weight: 700;
  color: var(--blue-lighter);
  cursor: pointer;
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__options__option:hover {
  color: var(--blue-main);
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__menu {
  box-shadow: var(--elevation-1);
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__menu__items {
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__menu__items__item {
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-bottom: solid 1px var(--light-2);
  min-width: 80px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__menu__items__item:hover {
  background: var(--light-2);
}

.score-cricket
  .right-column
  .overs
  .over
  .over-items
  .edit-over-item-form__menu__items__item__loading {
  margin-left: auto;
}

.score-cricket .team {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.score-cricket .team .image {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-left: 16px;
}

.score-cricket .team .name {
  font-size: 12px;
  color: var(--dark-2);
}

.score-cricket .stat-overlay {
  height: 100%;
  width: 100%;
  z-index: 6;
  background: rgba(46, 52, 56, 0.84);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-cricket .stat-input-form {
  background: white;
  width: 32%;
  height: 80%;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.score-cricket .stat-input-form__title {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--blue-darker);
  color: white;
  font-weight: 600;
  padding-left: 16px;
  padding-right: 16px;
  flex-shrink: 0;
}

.score-cricket .stat-input-form__title .dismiss-btn {
  margin-left: auto;
  cursor: pointer;
}

.score-cricket .stat-input-form .timestamp-editor {
  height: 40px;
  border-bottom: solid 1px var(--light-2);
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
  flex-shrink: 0;
}

.score-cricket .stat-input-form .timestamp-editor .label {
  font-weight: 600;
  color: var(--dark-4);
}

.score-cricket .stat-input-form .timestamp-editor .input {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--light-4);
  border-radius: 4px;
  margin-left: auto;
  overflow: hidden;
}

.score-cricket .stat-input-form .timestamp-editor .input .icon {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-2);
  height: 100%;
  cursor: pointer;
}

.score-cricket .stat-input-form .timestamp-editor .input .value {
  width: 32px;
  border-left: solid 1px var(--light-4);
  border-right: solid 1px var(--light-4);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-cricket .stat-input-form .select-player {
  border-top: solid 1px var(--light-2);
}

.score-cricket .stat-input-form .select-player .select-player__title {
  padding: 16px;
  padding-bottom: 8px;
  font-weight: 600;
  color: var(--dark-4);
}

.score-cricket .stat-input-form .player-list .select-player__item {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.score-cricket .stat-input-form .player-list {
  flex-grow: 1;
  overflow: auto;
}

.score-cricket .stat-input-form .player-list .select-player__item:hover {
  background: var(--blue-subtlest);
}

.score-cricket .stat-input-form .player-list .select-player__item .img {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.score-cricket .stat-input-form .player-list .select-player__item .img img {
  width: 100%;
}

.score-cricket
  .stat-input-form
  .player-list
  .select-player__item
  .name__primary {
  font-weight: 700;
}

.score-cricket
  .stat-input-form
  .player-list
  .select-player__item
  .name__secondary {
  color: var(--dark-4);
}

.score-cricket
  .stat-input-form
  .player-list
  .select-player__item
  .num-container {
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
}

.score-cricket
  .stat-input-form
  .player-list
  .select-player__item
  .num-container
  .num-val {
  font-size: 12px;
  color: var(--dark-4);
}

.score-cricket .stat-input-form .select-player .team-selector {
  display: flex;
  flex-direction: row;
  background: var(--light-2);
}

.score-cricket
  .stat-input-form
  .select-player
  .team-selector
  .team-selector__item {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
}

.score-cricket
  .stat-input-form
  .select-player
  .team-selector
  .team-selector__item.active {
  border-bottom: solid 2px var(--blue-main);
}

.score-cricket .stat-input-form .saved-stats {
  padding-left: 16px;
  padding-right: 16px;
}

.score-cricket .stat-input-form .saved-stats .saved-stat-item {
  border: dashed 1px var(--blue-lighter);
  border-radius: 4px;
  margin-top: 16px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket .stat-input-form .saved-stats .saved-stat-item .timestamp {
  margin-right: 8px;
}

.score-cricket .stat-input-form .saved-stats .saved-stat-item .edit-btn {
  margin-left: auto;
  font-weight: 600;
  color: var(--orange-lighter);
  cursor: pointer;
}

.score-cricket .stat-input-form .associated-stat-title {
  background: var(--light-2);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 16px;
  color: var(--dark-4);
  font-weight: 600;
}

.score-cricket .stats-list {
  padding-left: 24px;
  padding-right: 24px;
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 48px;
  padding-top: 16px;
}

.score-cricket .stats-list .stat-item-container {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.score-cricket .stats-list .stat-item-container.away {
  justify-content: flex-end;
}

.score-cricket .stats-list .stat-item-container .stat-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 60%; */
  border: solid 1px var(--light-2);
  border-radius: 4px;
  overflow: hidden;
}

.score-cricket .stats-list .stat-item-container .stat-item.away {
  justify-content: flex-end;
}

.score-cricket .stats-list .stat-item-container .stat-item .img {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
}

.score-cricket .stats-list .stat-item-container .stat-item .timestamp {
  height: 32px;
  width: 48px;
  border: solid 1px var(--light-2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-cricket .stats-list .stat-item-container .stat-item .statement {
  margin-left: 16px;
  margin-right: 16px;
}

.score-cricket .stats-list .stat-item-container .stat-item.home .statement {
  margin-right: 32px;
}

.score-cricket .stats-list .stat-item-container .stat-item.away .statement {
  margin-left: 32px;
}

.score-cricket .stats-list .stat-item-container .stat-item .options {
  display: flex;
  flex-direction: row;
}

.score-cricket .stats-list .stat-item-container .stat-item .options .option {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--light-2);
}

.score-cricket .cricket-settings {
  position: absolute;
  top: 48px;
  left: 0px;
  width: 100%;
  height: calc(100% - 48px);
  background: var(--blue-darkest);
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .score-cricket .cricket-settings .settings-title {
  color: white;
  font-size: 24px;
  font-weight: 700;
  font-family: var(--nhg-display);
  margin-top: 32px;
} */

.score-cricket .cricket-settings .settings-form {
  margin-top: 32px;
}

.score-cricket .cricket-settings .settings-form .form-group {
  background: #044c7c;
  /* border-bottom: solid 1px var(--light-4); */
  padding: 16px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 8px;
  margin-bottom: 32px;
}

.score-cricket .cricket-settings .settings-form .form-group.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket .cricket-settings .settings-form .form-group .label {
  color: white;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 16px;
}

.score-cricket .cricket-settings .settings-form .form-group .value {
}

.score-cricket
  .cricket-settings
  .settings-form
  .form-group
  .value
  .innings-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  height: 32px;
  border: solid 1px var(--light-2);
  border-radius: 8px;
  overflow: hidden;
  /* margin-left: 40px; */
}

.score-cricket
  .cricket-settings
  .settings-form
  .form-group
  .value
  .innings-selector
  .minus {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: solid 1px var(--light-2);
}
.score-cricket
  .cricket-settings
  .settings-form
  .form-group
  .value
  .innings-selector
  .plus {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: solid 1px var(--light-2);
}
.score-cricket
  .cricket-settings
  .settings-form
  .form-group
  .value
  .innings-selector
  .num {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-2);
  font-weight: 700;
}

.score-cricket
  .cricket-settings
  .settings-form
  .form-group
  .value
  .settings-dropdown-container {
  position: relative;
}

.score-cricket
  .cricket-settings
  .settings-form
  .form-group
  .value
  .dropdown-input {
  height: 32px;
  border: solid 1px var(--light-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  padding-left: 16px;
  margin-top: 12px;
  padding-right: 8px;
  color: white;
}
.score-cricket
  .cricket-settings
  .settings-form
  .form-group
  .value
  .dropdown-menu {
  background: var(--light-2);
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;

  /* width: 100%; */
}

.score-cricket
  .cricket-settings
  .settings-form
  .form-group
  .value
  .dropdown-menu
  .menu-item {
  padding: 24px;
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: solid 1px var(--light-3);
}

.score-cricket
  .cricket-settings
  .settings-form
  .form-group
  .value
  .dropdown-menu
  .menu-item:last-child {
  border: none;
}

.score-cricket
  .cricket-settings
  .settings-form
  .form-group
  .value
  .innings-list {
}

.score-cricket
  .cricket-settings
  .settings-form
  .form-group
  .value
  .innings-list
  .inning {
  height: 32px;
  font-size: 12px;
  border: solid 1px var(--light-2);
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  border-radius: 8px;
  /* margin-right: 16px; */
}

.score-cricket
  .cricket-settings
  .settings-form
  .form-group
  .value
  .innings-list
  .add-inning {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--green-lighter);
  font-size: 10px;
  font-weight: 700;
  margin-top: 16px;
  cursor: pointer;
}

.score-cricket .lineups-page {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: var(--blue-darkest);
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding-top: 48px;
}

.score-cricket .lineups-page .lineups-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
  width: 100%;
  background: var(--blue-darker);
}

.score-cricket .lineups-page .lineups-header .dismiss-btn {
  position: absolute;
  right: 24px;
  cursor: pointer;
}

.score-cricket .lineups-page .lineup-editor {
  width: 400px;
  height: 80%;
  background: #044c7c;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.score-cricket .lineups-page .lineup-editor .team-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--light-2);
  font-weight: 700;
  height: 40px;
  flex-shrink: 0;
}

.score-cricket .lineups-page .lineup-editor .team-selector .team-selector-item {
  height: 40px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.score-cricket
  .lineups-page
  .lineup-editor
  .team-selector
  .team-selector-item.active {
  border-bottom: solid 1px var(--green-main);
  color: var(--green-main);
}

.score-cricket .lineups-page .lineup-editor .lineup-list {
  background: var(--light-2);
  flex-grow: 1;
  overflow: auto;
}

.score-cricket .lineups-page .lineup-editor .lineup-list .lineup-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  height: 40px;
  border-bottom: solid 1px var(--light-3);
}

.score-cricket
  .lineups-page
  .lineup-editor
  .lineup-list
  .lineup-list-item
  .image {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  border-radius: 100%;
  margin-right: 16px;
}

.score-cricket
  .lineups-page
  .lineup-editor
  .lineup-list
  .lineup-list-item
  .name {
}

.score-cricket
  .lineups-page
  .lineup-editor
  .lineup-list
  .lineup-list-item
  .checkbox {
  margin-left: auto;
}

.score-cricket .header .time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.score-cricket .header .time .start {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-weight: 700;
  height: 32px;
  background: var(--blue-darkest);
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 16px;
  cursor: pointer;
}

.score-cricket .header .time .start .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-cricket .header .time .pause {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-weight: 700;
  height: 32px;
  background: var(--blue-darkest);
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 16px;
  cursor: pointer;
}

.score-cricket .header .time .pause .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-cricket .header .time .end-of-period {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-weight: 700;
  background: var(--blue-darkest);
  height: 32px;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 16px;
}

.score-cricket .header .time .end-of-period .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-cricket .header .time .selected-period-selector {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-darkest);
  padding-left: 16px;
  padding-right: 8px;
  border-radius: 8px;
  color: white;
  font-weight: 700;
  /* margin-left: 16px; */
  cursor: pointer;
}

.score-cricket .header .time .selected-period-selector-menu {
  background: var(--light-1);
  width: 100%;
  margin-top: 4px;
  border-radius: 8px;
  box-shadow: var(--elevation-5);
  /* z-index: 100; */
  position: relative;
  overflow: hidden;
  z-index: 5;
}

.score-cricket .header .time .selected-period-selector-menu__item {
  text-align: center;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: solid 1px var(--light-2);
  cursor: pointer;
}

.score-cricket .header .time .selected-period-selector-menu__item:hover {
  background: var(--light-2);
}

.score-cricket .header .time .selected-period-selector-menu__item:last-child {
  border: none;
}

.score-cricket
  .header
  .time
  .selected-period-selector
  .selected-period-selector__icon {
  stroke: white;
  height: 14px;
  margin-left: 8px;
}

.score-cricket .header .settings .edit-lineups-btn {
  height: 28px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  border: solid 1px white;
}

.score-cricket .cricket-settings {
  position: absolute;
  padding-top: 48px;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  background: var(--blue-darkest);
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.score-cricket .cricket-settings .cricket-settings-header {
  position: absolute;
  height: 48px;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--blue-darker);
  display: grid;
  display: grid;
  grid-template-columns: 24% 52% 24%;
  grid-template-rows: 100%;
}

.score-cricket .cricket-settings .cricket-settings-header .dismiss {
  font-weight: 700;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
  margin-right: 24px;
}

.score-cricket .cricket-settings .cricket-settings-header .close-scoring-btn {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  padding-left: 16px;
  cursor: pointer;
}

.score-cricket
  .cricket-settings
  .cricket-settings-header
  .close-scoring-btn
  .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-cricket
  .cricket-settings
  .cricket-settings-header
  .close-scoring-btn
  .text {
  margin-left: 8px;
  font-weight: 700;
}

.score-cricket .cricket-settings .settings-title {
  color: white;
  font-size: 16px;
  font-weight: 700;
  font-family: var(--nhg-display);
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 32px; */
}

.score-cricket .cricket-settings .settings-form {
  margin-top: 48px;
  width: 400px;
  /* padding-bottom: 32px; */
}

.score-cricket .cricket-settings .settings-form .form-group {
  background: #044c7c;
  /* border-bottom: solid 1px var(--light-4); */
  padding: 16px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 8px;
  margin-bottom: 32px;
  padding-bottom: 24px;
}

.score-cricket .cricket-settings .settings-form .form-group.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket .cricket-settings .settings-form .form-group .label {
  color: white;
  font-weight: 700;
  /* font-size: 14px; */
  text-align: center;
  display: flex;
  flex-direction: row;
  /* margin-bottom: 16px; */
}

.score-cricket
  .cricket-settings
  .settings-form
  .form-group
  .label
  .primary-label {
  flex-grow: 1;
  text-align: center;
}

.score-cricket .cricket-settings .settings-form .form-group .label .duration {
  /* margin-left: auto; */
}

.score-cricket .cricket-settings .settings-form .form-group .value {
}

.score-cricket .period-form {
  display: flex;
  flex-direction: row;
}

.score-cricket .period-row {
  display: grid;
  grid-template-columns: 256px 80px;
  grid-template-rows: 32px;
  grid-column-gap: 16px;
  margin-top: 16px;
}

.score-cricket .period-row .th {
  color: white;
  font-weight: 700;
  text-align: center;
}

.score-cricket .period-row__options {
  display: flex;
  flex-direction: row;
  /* margin-left: 10px; */
}

.score-cricket .period-row__options__option {
  height: 32px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
}

.score-cricket .period-row__options__option.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.score-cricket .period-row__options__option .icon {
  height: 14px;
}

.score-cricket .period-row__options__option.submit .icon {
  fill: var(--green-main);
}

.score-cricket .period-row__options__option.cancel .icon {
  stroke: var(--red-main);
}

.score-cricket .period-form input {
  border-radius: 8px;
  border: none;
  padding-left: 12px;
  outline: none;
}

.score-cricket .period-form input.label-input {
}

.score-cricket .period-form input.duration-input {
}

.score-cricket .period-form input.submit-input {
  padding: 0;
  background: none;
  color: var(--green-main);
  font-weight: 700;
}

.score-cricket .period__options {
  display: flex;
  flex-direction: row;
}

.score-cricket .period__options__option {
  display: flex;
  flex-direction: row;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.score-cricket .period__options__option__icon {
  height: 14px;
}

.score-cricket .period__options__option__icon.delete {
  stroke: white;
}

.score-cricket .period__options__option__icon.edit {
  fill: white;
}

.score-cricket .selected-batting-team {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  /* z-index: 6; */
}

.score-cricket .selected-batting-team__name {
  font-size: 12px;
  color: var(--dark-2);
}

.score-cricket .selected-batting-team__image {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-left: 16px;
}

.score-cricket .selected-batting-team__icon {
  stroke: var(--light-4);
  height: 14px;
  margin-left: 8px;
}

.score-cricket .selected-batting-team__menu {
  box-shadow: var(--elevation-6);
  z-index: 6;
  position: relative;
  background: white;
}

.score-cricket .selected-batting-team__menu__item {
  padding: 12px;
  border-bottom: solid 1px var(--light-2);
  cursor: pointer;
}

.score-cricket .selected-batting-team__menu__item:hover {
  background: var(--light-2);
}

.score-cricket .publish-stats {
  position: absolute;
  padding-top: 48px;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  background: var(--blue-darkest);
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.score-cricket .publish-stats__header {
  position: absolute;
  height: 48px;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--blue-darker);
  display: grid;
  display: grid;
  grid-template-columns: 24% 52% 24%;
  grid-template-rows: 100%;
}

.score-cricket .publish-stats__header .close-scoring-btn {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  padding-left: 16px;
  cursor: pointer;
}

.score-cricket .publish-stats__header .close-scoring-btn .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-cricket .publish-stats__header .close-scoring-btn .text {
  margin-left: 8px;
  font-weight: 700;
}

.score-cricket .publish-stats__header .publish-stats-title {
  color: white;
  font-size: 16px;
  font-weight: 700;
  font-family: var(--nhg-display);
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 32px; */
}

.score-cricket .publish-stats__header .publish-stats-btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.score-cricket .publish-stats__header .publish-stats-btn {
  background: var(--green-darker);
  height: 28px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  margin-left: auto;
}

.score-cricket .publish-stats__body {
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, 50%);
}

.score-cricket .publish-stats__body .stats-table-container {
  padding: 24px;
}

.score-cricket .publish-stats__body .stats-table {
  /* height: 200%; */
  background: var(--blue-darker);
  border-radius: 8px;
  overflow: hidden;
}

.score-cricket .publish-stats__body .stats-table__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.score-cricket .publish-stats__body .stats-table__header__title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket .publish-stats__body .stats-table__header__title__logo {
  height: 32px;
  width: 32px;
  background: var(--blue-main);
  border-radius: 8px;
}

.score-cricket .publish-stats__body .stats-table__header__title__text {
  margin-left: 12px;
  color: white;
  font-size: 14px;
  font-weight: 700;
}

.score-cricket
  .publish-stats__body
  .stats-table__header__value-selector__wrapper {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket
  .publish-stats__body
  .stats-table__header__value-selector__seperator {
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 700;
  color: var(--light-2);
  font-size: 16px;
}

.score-cricket .publish-stats__body .stats-table__header__value-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
}

.score-cricket .publish-stats__body .stats-table__header__value-selector__icon {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-main);
  cursor: pointer;
}

.score-cricket
  .publish-stats__body
  .stats-table__header__value-selector__icon
  .icon {
  stroke: white;
  height: 14px;
}

.score-cricket .publish-stats__body .stats-table__header__value-selector__val {
  height: 32px;
  min-width: 32px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
}

.score-cricket .publish-stats__body .stats-table__body {
  position: relative;
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .stats-table__body__section {
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .stats-table__body__section
  .stats-table__body__section__title {
  background: var(--blue-main);
  padding: 4px;
  padding-left: 24px;
  font-size: 10px;
  font-weight: 700;
  color: var(--light-2);
  text-align: center;
  /* border-bottom: solid 1px var(--light-3); */
  /* border-top: solid 1px var(--light-3); */
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .stats-table__body__section
  .stats-table__body__section__body {
  background: white;
  display: grid;
  grid-template-columns: 168px 1fr;
  position: relative;
}

.score-cricket .publish-stats__body .stats-table__body__overlay {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-left: 30%;
  padding-right: 30%;
  padding-top: 64px;
}

.score-cricket .publish-stats__body .stats-table__body .column {
  border-right: solid 1px var(--light-3);
}

.score-cricket .publish-stats__body .stats-table__body .column.stats {
  overflow-x: auto;
}

.score-cricket .publish-stats__body .stats-table__body .column__header {
}

.score-cricket .publish-stats__body .stats-table__body .column__row {
  display: flex;
  flex-direction: row;
  /* border-bottom: solid 1px var(--light-2); */
}

.score-cricket .publish-stats__body .stats-table__body .column__row__data {
  height: 32px;
  width: 144px;
  display: flex;
  flex-direction: row;
  align-items: center;

  border-right: solid 1px var(--light-2);
  border-bottom: solid 1px var(--light-2);
  flex-shrink: 0;
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .column__row__data__value {
  width: 100%;

  padding-left: 24px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .column__row__data__value.hover:hover {
  background: var(--light-2);
  cursor: pointer;
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .column__row__data.highlight {
  background: var(--blue-subtle);
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .column__row__data__form {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  min-width: 0;
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .column__row__data__form__input {
  flex-grow: 1;
  width: calc(100% - 64px);
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .column__row__data__form__input
  input {
  height: 100%;
  border: none;
  width: 100%;
  padding-left: 24px;
  outline: none;
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .column__row__data__form
  button {
  height: 32px;
  width: 32px;
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .column__row__data__form
  button {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .column__row__data__form
  button
  .icon {
  height: 14px;
  fill: black;
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .column__row__data__form
  button
  .icon.submit {
  fill: var(--green-main);
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .column__row__data__form
  button
  .icon.cancel {
  stroke: var(--light-4);
}

.score-cricket .publish-stats__body .stats-table__body .column__row__data.bold {
  font-weight: 700;
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .column__row__data.light {
  color: var(--light-4);
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .column.players
  .column__row {
  width: 100%;
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .column.players
  .column__row__data {
  width: 100%;
}

.score-cricket
  .publish-stats__body
  .stats-table__body
  .column
  .column__header
  .column__row__data {
  font-weight: 700;
}

.score-cricket .publish-stats .confirm-publish-stats {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(46, 52, 56, 0.88);
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-cricket .publish-stats .confirm-publish-stats__form {
  background: white;
  width: 30%;
  border-radius: 8px;
  overflow: hidden;
}

.score-cricket .publish-stats .confirm-publish-stats__form__text {
  padding: 16px;
  padding-top: 0;
}

.score-cricket .publish-stats .confirm-publish-stats__form__header {
  background: var(--blue-darker);
  font-weight: 700;
  height: 32px;
  display: flex;
  align-items: center;
  color: white;
  padding: 16px;
}

.score-cricket .publish-stats .confirm-publish-stats__form__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  padding-top: 8px;
}

.score-cricket
  .publish-stats
  .confirm-publish-stats__form__options
  .app-button {
  margin-left: 16px;
}
