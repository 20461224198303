.stats-page {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 16px;
  padding-bottom: 48px;
}

.stats-page .summary-blocks {
  grid-column-start: 1;
  grid-column-end: 5;
  display: grid;
  grid-template-columns: 24px repeat(5, minmax(0, 1fr));
  grid-column-gap: 16px;
  /* grid-template-rows: 104px 104px;
  grid-row-gap: 16px; */
  margin-top: 32px;
}

.stats-page .summary-blocks .summary-blocks-label {
  background: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-end;
  /* justify-content: center; */
  color: white;
  /* font-weight: 600; */
  border-radius: 4px;
  position: relative;
  /* grid-row-start: 1;
  grid-row-end: 3; */
}

.stats-page .summary-blocks .summary-blocks-label .vertical-text {
  /* transform: rotateY(-90deg); */
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */
  transform-origin: 0 0;
  font-size: 10px;
  letter-spacing: 1px;
  font-family: var(--nhg);
  font-weight: 700;
  transform: rotate(270deg);
  margin: 0;
  margin-left: 5px;
  margin-bottom: -4px;
}

.stats-page .summary-blocks .summary-block {
  /* margin-top: 16px; */
  padding: 16px;
  /* height: 104px; */
}

.stats-page .summary-blocks .summary-block .value {
  font-family: var(--din);
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-2);
}

.stats-page .summary-blocks .summary-block .label {
  font-family: var(--nhg-display);
  color: var(--blue-lighter);
  /* color: var(--light-4); */
  font-weight: 600;
}

.stats-page .participation-history {
  grid-column-start: 1;
  grid-column-end: 5;
  margin-top: 32px;
  /* padding: 16px; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  /* padding-bottom: 16px; */
}

.stats-page .participation-history .title {
  font-weight: 700;
  color: var(--dark-4);
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.stats-page .participation-history .participation-table {
  flex-grow: 1;
}

.stats-page .participation-history .participation-table .table {
  margin-top: 8px;
  width: 100%;
  font-size: 12px;

  /* padding: 8px; */
}

.stats-page .participation-history .participation-table .table .tr {
  /* height: 24px; */
  padding-top: 12px;
  padding-bottom: 12px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  /* padding-top: 16px; */
  /* padding-bottom: 16px; */
  padding-left: 8px;
  padding-right: 8px;
}

.stats-page .participation-history .participation-table .table .tr.even {
  background: var(--blue-subtlest);
}

.stats-page .participation-history .participation-table .table .tr.header-row {
  border-bottom: solid 2px var(--light-2);
}
.stats-page .participation-history .participation-table .table .th {
  text-align: left;
  font-family: var(--nhg);
  font-weight: 600;
  color: var(--dark-1);
  padding-right: 8px;
  padding-left: 8px;
  /* border-bottom: solid 1px var(--light-4); */
}

.stats-page .participation-history .participation-table .table .td {
  /* border: solid 1px black; */
  /* font-size: 12p  x; */

  /* padding-right: 8px; */
  /* padding-right: 8px; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;

  padding-right: 8px;
  padding-left: 8px;
}

.stats-page .participation-history .participation-table .table .td .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stats-page
  .participation-history
  .participation-table
  .table
  .td
  .table-row-image {
  height: 20px;
  width: 20px;
  background: var(--blue-subtle);
  border-radius: 100%;
  margin-right: 8px;
  flex-shrink: 0;
  display: inline-block;
}

.stats-page .participation-history .participation-table .table .td.num-data {
  /* font-family: var(--din); */
  /* text-align: right; */
  /* min-width: 28px; */
  justify-content: flex-end;
}

.stats-page
  .participation-history
  .participation-table
  .table
  table
  th.num-data {
  /* font-family: var(--din); */
  text-align: right;
  /* min-width: 24px; */
}

.stats-page .participation-history .participation-table .table .tfoot {
  border-top: solid 2px var(--light-2);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.stats-page .participation-history .participation-table .table .tfoot .filter {
}

.stats-page
  .participation-history
  .participation-table
  .table
  .tfoot
  .filter
  .team-selector {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 4px;
  width: 160px;
  border: solid 1px var(--light-3);
  stroke: var(--dark-3);
  border-radius: 4px;
  height: 24px;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-4);
}

.stats-page
  .participation-history
  .participation-table
  .table
  .tfoot
  .filter
  .team-selector
  .icon {
  transform: rotate(-90deg);
  margin-left: auto;
}

.stats-page
  .participation-history
  .participation-table
  .table
  .tfoot
  .pagination {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  stroke: var(--blue-main);
  margin-left: auto;
}

.stats-page
  .participation-history
  .participation-table
  .table
  .tfoot
  .pagination
  .page-nums {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  font-weight: 700;
  color: var(--light-4);
}

.stats-page
  .participation-history
  .participation-table
  .table
  .tfoot
  .pagination
  .page-nums
  .page-num {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: -4px;
}

.stats-page
  .participation-history
  .participation-table
  .table
  .tfoot
  .pagination
  .page-nums
  .page-num.active {
  color: var(--dark-1);
}

.stats-page .wickets-taken-container {
  grid-column-start: 3;
  grid-column-end: 5;
  margin-top: 32px;
  /* padding: 16px; */
  /* flex-shrink: 0; */
  /* width: 220px; */
  /* margin-right: 16px; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.stats-page .wickets-taken-container .graphic {
  margin-bottom: 8px;
}

.stats-page .wickets-taken-container .wickets-taken-item {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-grow: 1;
  border-bottom: solid 1px var(--light-2);
  /* margin-top: 8px; */
  padding: 16px;
}

.stats-page .wickets-taken-container .wickets-taken-item .value {
  font-family: var(--din);
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  color: var(--dark-2);
}

.stats-page .wickets-taken-container .wickets-taken-item .label {
  font-family: var(--nhg-display);
  color: var(--blue-lighter);
  font-weight: 600;
  margin-bottom: 2px;
  margin-left: 8px;
}

.stats-page .radar-chart {
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 32px;
}

.stats-page .basketball-shots-summary {
  grid-column-start: 1;
  grid-column-end: 5;
  margin-top: 32px;
  display: block;
  padding: 16px;
  /* display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 16px; */
}

.stats-page .shots-summary .graphic {
  /* height: 200px; */

  /* border: solid 4px var(--light-4); */
  /* border-bottom: none; */
  position: relative;
  /* flex-grow: 1; */
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 16px;
  padding-right: 0;
}

.stats-page .shots-summary .graphic .total-shots {
  height: 100%;
  width: 100%;
  background: var(--green-subtle);
  border-radius: 8px;
}

.stats-page .shots-summary .graphic .shots-on-target {
  position: absolute;
  right: 0px;
  bottom: 16px;
  width: 40%;
  height: 50%;
  background: var(--green-lighter);
  border-radius: 8px;
}

.stats-page .shots-summary .numbers {
  /* margin-left: 16px; */
  /* width: 33%; */
  grid-column-start: 3;
  grid-column-end: 4;
  padding-left: 16px;
  padding-right: 16px;
  border-left: solid 1px var(--light-2);
  /* padding-bottom: 16px; */
  padding-top: 16px;
}

.stats-page .shots-summary .numbers .number-item {
  margin-bottom: 32px;
  /* margin-top: 16px; */
}

.stats-page .shots-summary .numbers .number-item .value {
  font-family: var(--din);
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-2);
}

.stats-page .shots-summary .numbers .number-item .label {
  font-family: var(--nhg-display);
  color: var(--blue-lighter);
  font-weight: 600;
}

.stats-page .summary-blocks-defense {
  margin-top: 32px;
  grid-column-start: 1;
  grid-column-end: 3;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 16px;
}

.stats-page .summary-blocks-defense .summary-blocks-defense-label {
  background: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  /* justify-content: center; */
  color: white;
  /* font-weight: 600; */
  border-radius: 4px;
  position: relative;
  height: 24px;
  padding-left: 8px;
  font-size: 10px;
  letter-spacing: 1px;
  font-family: var(--nhg);
  font-weight: 700;
  grid-column-start: 1;
  grid-column-end: 3;
}

.stats-page .summary-blocks-defense .summary-block {
  margin-top: 16px;
  padding: 16px;
}

.stats-page .summary-blocks-defense .summary-block .value {
  font-family: var(--din);
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-2);
}

.stats-page .summary-blocks-defense .summary-block .label {
  font-family: var(--nhg-display);
  color: var(--blue-lighter);
  font-weight: 600;
}

.stats-page .conversion-summary {
  grid-column-start: 4;
  grid-column-end: 5;
  margin-top: 32px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.stats-page .conversion-summary .conversion-summary-item {
  flex-grow: 1;
  padding-bottom: 16px;
  padding-top: 8px;
}

.stats-page .conversion-summary .conversion-summary-item .graphic {
  padding: 32px;
  padding-bottom: 8px;
  padding-top: 0px;
  position: relative;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.stats-page .conversion-summary .conversion-summary-item .graphic .value {
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  margin-top: 9px;
  margin-left: 5px;
}

.stats-page .conversion-summary .conversion-summary-item .label {
  font-family: var(--nhg-display);
  color: var(--dark-2);
  font-weight: 600;
  text-align: center;
}

.stats-page .awards {
  grid-column-start: 1;
  grid-column-end: 5;
  margin-top: 32px;
  height: 140px;
  padding: 16px;
}

.stats-page .card-title {
  font-weight: 700;
  color: var(--dark-4);
}

.stats-page .line-chart-container {
  margin-bottom: 16px;
}

.stats-page .line-chart-container .text {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  font-size: 14px;
  color: var(--dark-3);

  font-weight: 700;
  /* padding-left: 8px; */
  /* padding-right: 8px; */
  /* margin-bottom: 8px; */
}

.stats-page .line-chart-container .text .right {
  margin-left: auto;
}

.stats-page .line-chart-container .text {
  font-family: var(--din);
  /* font-size: 32px; */
  font-weight: 700;
  color: var(--dark-2);
  padding-left: 4px;
}

.stats-page .line-chart-container .text.top .left {
  font-family: var(--nhg-display);
  color: var(--blue-lighter);
  /* color: var(--light-4); */
  font-weight: 600;
  font-size: 12px;
}

.stats-page .line-chart-container .line-chart {
  height: 12px;
  background: var(--light-2);
  border-radius: 8px;
  overflow: hidden;
  margin-top: 12px;
  margin-bottom: 12px;
}

.stats-page .line-chart-container .line-chart .fill {
  height: 100%;
}

.stats-page .line-chart-container .line-chart .fill {
  background: var(--blue-main);
}

.stats-page .summary-blocks .fielding-block {
  grid-column-start: 6;
  grid-column-end: 7;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.stats-page .summary-blocks .fielding-block .fielding-block-items {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
}

.stats-page
  .summary-blocks
  .fielding-block
  .fielding-block-items
  .fielding-block-item {
  /* width: 33%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 33%;
}

.stats-page
  .summary-blocks
  .fielding-block
  .fielding-block-items
  .fielding-block-item
  .label {
  font-family: var(--nhg-display);
  color: var(--blue-lighter);
  /* color: var(--light-4); */
  font-weight: 600;
}

.stats-page
  .summary-blocks
  .fielding-block
  .fielding-block-items
  .fielding-block-item
  .value {
  font-family: var(--din);
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-2);
}

.stats-page .summary-blocks .fielding-block .summary-blocks-fielding-label {
  background: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  /* justify-content: center; */
  color: white;
  /* font-weight: 600; */
  border-radius: 4px;
  position: relative;
  height: 24px;
  /* padding-left: 8px; */
  font-size: 10px;
  letter-spacing: 1px;
  font-family: var(--nhg);
  font-weight: 700;
}

.stats-page .summary-blocks .dismissals-block {
  grid-column-start: 1;
  grid-column-end: 6;
  margin-top: 16px;
  /* padding: 16px; */
  display: grid;
  grid-template-columns: 24px repeat(4, minmax(0, 1fr));
  grid-column-gap: 16px;
  /* align-items: center; */
}

.stats-page .summary-blocks .dismissals-block .dismissals-block-label {
  border-right: solid 1px var(--light-2);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-end;
  /* justify-content: center; */
  color: var(--light-4);
  /* font-weight: 600; */
  border-radius: 4px;
  position: relative;
}

.stats-page
  .summary-blocks
  .dismissals-block
  .dismissals-block-label
  .vertical-text {
  transform-origin: 0 0;
  font-size: 10px;
  letter-spacing: 1px;
  font-family: var(--nhg);
  font-weight: 700;
  transform: rotate(270deg);
  margin: 0;
  margin-left: 5px;
  margin-bottom: -4px;
}

.stats-page .summary-blocks .dismissals-block .dismissals-block-items {
  grid-column-start: 2;
  grid-column-end: 6;
  display: grid;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
  grid-column-gap: 16px;
  /* margin-left: 16px; */
  /* padding-right: 16px; */
}

.stats-page
  .summary-blocks
  .dismissals-block
  .dismissals-block-items
  .dismissals-block-item {
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  justify-content: center;
  padding: 16px;
}

.stats-page
  .summary-blocks
  .dismissals-block
  .dismissals-block-items
  .dismissals-block-item
  .label {
  font-family: var(--nhg-display);
  color: var(--blue-lighter);
  /* color: var(--light-4); */
  font-weight: 600;
}

.stats-page
  .summary-blocks
  .dismissals-block
  .dismissals-block-items
  .dismissals-block-item
  .value {
  font-family: var(--din);
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-2);
}

/* NEW STATS */

/* Fielding */

.profile-fielding-stats {
  /* margin: 16px; */
  /* display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 12px; */
  margin-top: 32px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.profile-fielding-stats__title {
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
  text-align: center;
}

.profile-fielding-stats__items {
  /* display: grid; */
  /* grid-template-columns: repeat(3, minmax(0, 1fr)); */
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
}

.profile-fielding-stats__items__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: solid 1px var(--light-2);
}

.profile-fielding-stats__items__item:last-child {
  border: none;
}

.profile-fielding-stats__items__item__value {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-1);
}

.profile-fielding-stats__items__item__key {
  font-family: var(--nhg-display);
  font-weight: 600;
  font-size: 12px;
  color: var(--dark-4);
  margin-top: 8px;
  text-align: center;
}

.profile-fielding-stats__graphic {
  position: relative;
  height: 40px;
}

.profile-fielding-stats__graphic__text {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 56px;
  letter-spacing: -6px;
  font-weight: 700;
  font-style: italic;
  line-height: 40px;
}

.profile-fielding-stats__graphic__text.green {
  color: rgba(99, 234, 71, 0.24);
}

.profile-fielding-stats__graphic__text.blue {
  color: rgba(19, 160, 250, 0.48);
  margin-bottom: 4px;
  margin-right: 8px;
}

/* Wickets taken */

.profile-wickets-taken {
  /* margin: 16px; */
  margin-top: 32px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.profile-wickets-taken__highlight {
  padding: 16px;
}

.profile-wickets-taken__highlight__value {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-1);
}

.profile-wickets-taken__highlight__label {
  font-family: var(--nhg-display);
  font-weight: 600;
  font-size: 12px;
  color: var(--dark-4);
  margin-top: 8px;
  /* text-align: center; */
}

.profile-wickets-taken__highlight__graph {
  padding: 12px;
}
