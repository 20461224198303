.app-tooltip {
  padding: 4px;
  font-size: 10px;
  background: var(--blue-darkest);
  margin: 4px;
  color: white;
  border-radius: 4px;
  /* transition: all 0.2s ease-out; */
  font-weight: 700;
}
