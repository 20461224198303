.scoring-app {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.scoring-app .scoring-app-page {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.scoring-app .scoring-app-page-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  background: white;
  box-shadow: var(--elevation-1);
  font-size: 24px;
  padding-left: 48px;
  padding-right: 48px;
  font-family: var(--nhg-display);
  font-weight: 700;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.scoring-app .scoring-app-page-title .back-btn {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.scoring-app .tabs {
  height: 40px;
  background: white;
  /* padding-left: 368px; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 48px;
  padding-right: 48px;
  border-top: solid 1px var(--light-2);
  border-bottom: solid 1px var(--light-2);
  flex-shrink: 0;
}

.scoring-app .tabs .tab-item {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-right: 32px;
  color: var(--dark-4);
  font-weight: 500;
  border-bottom: solid 2px transparent;
  padding-top: 2px;
}

.scoring-app .tabs .tab-item.active {
  color: var(--dark-2);
  /* font-weight: 600; */
  border-bottom: solid 2px var(--blue-main);
}

.scoring-app .scoring-app-body {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}
