.ScoringApp-football__settings {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ScoringApp-football__settings__grid {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: grid;
  grid-template-columns: 30% 70%;
}

.ScoringApp-football__settings__grid__left-column {
  padding-left: 16px;
  padding-right: 16px;
}

.ScoringApp-football__settings__grid__right-column {
  padding-top: 16px;
  padding-right: 16px;
}
