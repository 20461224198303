.fixture-stats-page-cricket {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 48px;
  padding-top: 32px;
}

.fixture-stats-page-cricket .home-scorecards {
}

.fixture-stats-page-cricket .away-scorecards {
}

.fixture-stats-page-cricket .batting-scorecard {
}

.fixture-stats-page-cricket .batting-scorecard .title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
}

.fixture-stats-page-cricket .batting-scorecard .secondary-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  background: var(--blue-darkest);
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: white;
}

.fixture-stats-page-cricket .scorecard-table__header {
  background: var(--blue-subtle);
  font-weight: 700;
  font-size: 10px;
  color: var(--blue-darker);
}

.fixture-stats-page-cricket .scorecard-table__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-bottom: solid 1px var(--light-2);
}

.fixture-stats-page-cricket .scorecard-table__header .scorecard-table__row {
  height: 24px;
}

.fixture-stats-page-cricket .scorecard-table__row__data {
  padding-left: 16px;
  padding-right: 16px;
}

.fixture-stats-page-cricket .bowling-scorecard {
  margin-top: 32px;
}
