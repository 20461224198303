.vertical-text-container {
  /* background: red; */
  position: absolute;
  /* left: 0; */
  bottom: 0;
  transform: rotateZ(-90deg);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  /* position: relative; */
}

.vertical-text-container__text {
  /* position: absolute; */
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: fit-content;

  /* position: relative; */
  /* background: blue; */
  left: 0;
}

.vertical-text-container__text.blue {
  color: rgba(19, 160, 250, 0.48);
  margin-bottom: 6px;
  margin-left: 4px;
}

.vertical-text-container__text.green {
  color: rgba(99, 234, 71, 0.24);
}
