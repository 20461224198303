.App-mobile-layout {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--light-2);
}

.App-mobile-layout__header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 48px;
  background: white;
  /* box-shadow: 0px 0px 3px rgba(40, 41, 61, 0.08); */
  box-shadow: 0px 0px 4px 1px rgba(40, 41, 61, 0.08);
  padding-left: 16px;
  padding-right: 16px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 12px;
  z-index: 2;
  transition: all 0.2s ease-out;
  will-change: transform;
}

.App-mobile-layout.hide-nav .App-mobile-layout__header {
  transform: translateY(-50px);
}

.App-mobile-layout__header__left-content {
  grid-column-start: 1;
  grid-column-end: 2;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App-mobile-layout__back-btn {
  height: 48px;
  /* width: 48px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-mobile-layout__header__search-wrapper {
  grid-column-start: 2;
  grid-column-end: 4;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-mobile-layout__header__search {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--light-4);
  border-radius: 8px;
  height: 24px;
}

.App-mobile-layout__header__search__icon {
  position: absolute;
  left: 0px;
  top: 5px;
}

.App-mobile-layout__header__search__text {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 400;
  font-size: 12px;
  /* line-height: 14px; */
  /* display: flex; */
  /* align-items: center; */
  /* text-align: center; */

  /* Light / Light 4 */

  color: var(--light-4);
}

.App-mobile-layout__header__right-content {
  grid-column-start: 4;
  grid-column-end: 5;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.App-mobile-layout__header__profile-icon {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background: var(--blue-subtle);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-mobile-layout__header__profile-icon img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.App-mobile-layout__body {
  height: 100%;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 64px;
  overflow-x: hidden;
  overflow-y: auto;
}

.App-mobile-layout.hide-nav .App-mobile-layout__body {
  padding-top: 0;
  padding-bottom: 0;
}

.App-mobile-layout__nav {
  height: 64px;
  width: 100%;
  background: var(--light-1);
  box-shadow: 0px 0px 4px 1px rgba(40, 41, 61, 0.08);
  position: absolute;
  bottom: 0;
  color: var(--dark-3);
  stroke: var(--dark-3);
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  z-index: 4;
  transition: all 0.2s ease-out;
  will-change: transform;
}

.App-mobile-layout.hide-nav .App-mobile-layout__nav {
  transform: translateY(70px);
}

.App-mobile-layout__nav__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-mobile-layout__nav__item__icon {
  margin-bottom: 4px;
}

.App-mobile-layout__nav__item__text {
  font-family: var(--nhg-display);
  font-weight: 600;
  font-size: 6px;
  letter-spacing: 1%;
  color: var(--dark-3);
}

.App-mobile-layout__nav__item.active {
  stroke: var(--blue-main);
}

.App-mobile-layout__nav__item.active .App-mobile-layout__nav__item__text {
  color: var(--blue-main);
}

.App-mobile-layout__nav__item__btn {
  height: 36px;
  width: 36px;
  background: var(--green-main);
  box-shadow: 0px 0px 9px -2px var(--green-main);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  stroke: white;
}

.App-mobile-layout__action-sheet-overlay {
  height: 100%;
  width: 100%;
  background: rgba(46, 52, 56, 0.88);
  position: absolute;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  will-change: opacity;
  transition: all 0.2s ease-out;
}

.App-mobile-layout__action-sheet {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--light-1);
  box-shadow: 0px 0px 4px 1px rgba(40, 41, 61, 0.08);
  padding-bottom: 64px;
  z-index: 3;
  transform: translateY(100%);
  transition: all 0.2s ease-out;
  will-change: transform;
  border-radius: 4px;
}

.App-mobile-layout__action-sheet.show {
  transform: translateY(0);
}

.App-mobile-layout__action-sheet-overlay.show {
  opacity: 0.88;
  pointer-events: all;
}

.App-mobile-layout__action-sheet__item {
  padding: 16px;
  border-bottom: solid 1px var(--light-2);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.App-mobile-layout__action-sheet__item__icon {
  height: 56px;
  width: 56px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  margin-right: 16px;
}

.App-mobile-layout__action-sheet__item__text {
}

.App-mobile-layout__action-sheet__item__text__primary {
  font-weight: 700;
  color: var(--dark-1);
}

.App-mobile-layout__action-sheet__item__text__secondary {
  font-size: 10px;
  line-height: 16px;
  margin-top: 4px;
  color: var(--dark-4);
}

.App-mobile-layout__action-sheet__dismiss-wrapper {
  position: absolute;
  top: -8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-mobile-layout__action-sheet__dismiss-wrapper__btn {
  background: white;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: var(--mobile-elevation);
  /* margin-top: -8px; */
}

.App-mobile-layout .no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column-start: 1;
  grid-column-end: 4;
  font-size: 12px;
  color: var(--dark-4);
  line-height: 20px;
  height: 120px;
  text-align: center;
}

.App-mobile-layout .no-data .link {
  color: var(--blue-lighter);
}

.App-mobile-layout__modal {
  height: 100%;
  width: 100%;
  /* background: rgba(46, 52, 56, 0.88); */
  position: absolute;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  will-change: opacity;
  transition: all 0.2s ease-out;
  overflow: hidden;
}

.App-mobile-layout__modal.open {
  pointer-events: all;
  opacity: 1;
  /* z-index: 10; */
}

.App-mobile-layout__modal__background {
  height: 100%;
  width: 100%;
  /* display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center; */
  overflow: hidden;
  background: rgba(46, 52, 56, 0.88);
  will-change: opacity;
  transition: all 0.2s ease-out;
}

/* ///////////////////////////////////////////////////////////// */
