.toggle-wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  cursor: pointer;
}

.app-toggle {
  height: 16px;
  width: 24px;
  box-sizing: border-box;
  background: var(--light-3);
  border: solid 1px var(--light-4);
  border-radius: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding-left: 1px;
  padding-right: 1px;

  transition: all 0.2s ease-out;
}

.app-toggle.selected {
  background: var(--blue-main);
  border-color: var(--blue-main);
  justify-content: flex-end;
}

.app-toggle .toggle-handle {
  background: white;
  height: 12px;
  width: 12px;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  box-shadow: var(--elevation-1);
}

.toggle-wrapper .toggle-label {
  font-size: 12px;
  margin-left: 12px;
}
