.event-registration-mobile {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: var(--blue-darker);
  display: flex;
  flex-direction: column;
  /* padding-top: 48px; */
}

.event-registration-mobile__header {
}

.event-registration-mobile__header__nav {
  height: 48px;
  background: white;
  box-shadow: 0px 0px 4px 1px rgba(40, 41, 61, 0.08);
  /* box-shadow: 0px 0px 3px rgba(40, 41, 61, 0.08); */

  /* padding-left: 16px; */
  padding-right: 16px;
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--blue-darkest);
}

.event-registration-mobile__header__nav__back-btn {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.event-registration-mobile__header__nav__title {
  padding-right: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-family: var(--nhg-display);
  font-size: 10px;
  color: var(--light-2);
  letter-spacing: 1px;
}

.event-registration-mobile__header__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.event-registration-mobile__header__content__img {
  background: var(--blue-subtle);
  height: 80px;
  width: 80px;
  border-radius: 8px;
}

.event-registration-mobile__header__title {
  /* text-align: center; */
  padding-left: 16px;
  font-family: var(--nhg-display);
}

.event-registration-mobile__header__title__primary {
  /* font-style: normal; */
  font-weight: 700;
  font-size: 16px;
  /* line-height: 19px; */
  /* display: flex; */
  /* align-items: center; */

  /* Dark / Dark 2 */

  color: var(--light-1);
  margin-bottom: 4px;
}

.event-registration-mobile__header__title__secondary {
  margin-bottom: 4px;
  color: var(--light-1);
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  letter-spacing: 0.4px;
}

.event-registration-mobile__body {
  flex-grow: 1;
  background: white;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.event-registration-mobile__body__title {
  padding: 16px;
  border-bottom: solid 1px var(--light-2);
}

.event-registration-mobile__body__title__primary {
  font-weight: 700;
  font-size: 14px;
  color: var(--dark-2);
  /* margin-bottom: 8px; */
  font-family: var(--nhg-display);
}

.event-registration-mobile__body__list {
  padding: 16px;
  /* padding-bottom: 0; */
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.event-registration-mobile__body__list__desc {
  color: var(--dark-3);
  line-height: 1.5em;
}

.event-registration-mobile__body__list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  border: solid 1px var(--light-2);
  margin-top: 16px;
}

.event-registration-mobile__body__list__item__img {
  height: 36px;
  width: 36px;
  border-radius: 8px;
  background: var(--blue-subtle);
}

.event-registration-mobile__body__list__item__desc {
  padding-left: 8px;
  font-family: var(--nhg-display);
  flex-grow: 1;
}

.event-registration-mobile__body__list__item__desc__primary {
  font-weight: 700;
  color: var(--dark-2);
  margin-bottom: 4px;
}

.event-registration-mobile__body__list__item__desc__secondary {
  color: var(--dark-3);
}

.event-registration-mobile__body__list__item__indicator {
  height: 100%;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-registration-mobile__body__list__item__indicator__icon {
  height: 16px;
  width: 16px;
  background: var(--green-main);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-registration-mobile__footer {
  /* height: 56px; */
  background: white;
  border-top: solid 1px var(--light-2);
  flex-shrink: 0;
  padding: 16px;
  box-shadow: var(--elevation-mobile);
}

.event-registration-mobile__footer__btn {
  background: var(--blue-main);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 48px;
  border-radius: 8px;
  box-shadow: var(--elevation-primary);
  padding-left: 12px;
  padding-right: 16px;
}

.event-registration-mobile__footer__btn__right {
  margin-left: auto;
  font-family: var(--nhg-display);
  font-size: 16px;
  font-weight: 700;
  color: var(--light-1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-registration-mobile__footer__btn__left {
}

.event-registration-mobile__footer__btn__left__primary {
  font-size: 12px;
  font-family: var(--nhg-display);
  /* font-size: 8px; */
  color: var(--light-2);
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
}

.event-registration-mobile__footer__btn__left__secondary {
  font-family: var(--nhg-display);
  font-size: 6px;
  color: var(--light-2);
  font-weight: 600;
  letter-spacing: 1px;
}

.event-registration-mobile__invoice {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  flex-grow: 1;
}

.event-registration-mobile__invoice__content {
  background: white;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.event-registration-mobile__invoice__content__header {
  text-align: center;
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 20px;
  color: var(--dark-2);
  padding: 16px;
  border-bottom: solid 1px var(--light-2);
}

.event-registration-mobile__invoice__content__body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* flex-grow: 1; */
}

.event-registration-mobile__invoice__content__body__group {
  width: 50%;
  padding: 16px;
}

.event-registration-mobile__invoice__content__body__group__label {
  font-family: var(--nhg-display);
  color: var(--dark-3);
  font-size: 8px;
  letter-spacing: 1px;
  margin-bottom: 8px;
}

.event-registration-mobile__invoice__content__body__group__value {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.event-registration-mobile__invoice__content__body__group__value__img {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 12px;
}

.event-registration-mobile__invoice__content__body__group__value__img img {
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
}

.event-registration-mobile__invoice__content__footer {
  text-align: center;
  padding: 8px;
  font-weight: 600;
  color: var(--dark-4);
  font-family: var(--nhg-display);
  border-top: solid 1px var(--light-2);
  /* font-size: 10px; */
}

.event-registration-mobile__invoice__content__barcode {
  margin-top: auto;
  border-top: solid 1px var(--light-2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
}
