.app-alert {
  min-height: 40px;
  background: var(--blue-subtle);
  border-left: solid 4px var(--blue-main);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 24px;
}

.app-alert.green {
  background: var(--green-subtle);
  border-color: var(--green-main);
}

.app-alert.orange {
  background: var(--orange-subtle);
  border-color: var(--orange-main);
}

.app-alert.red {
  background: var(--red-subtle);
  border-color: var(--red-main);
}

.app-alert .alert-indicator {
  margin-right: 24px;
  height: 40px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  flex-shrink: 0;
}

.app-alert .alert-indicator img {
  height: 14px;
  width: 14px;
}

.app-alert .alert-content {
  flex-grow: 1;
}

.app-alert .alert-content .alert-head {
  font-size: 12px;
  /* font-family: "Inter", sans-serif; */
  font-weight: 700;
  height: 40px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.app-alert .alert-content .alert-head .alert-head-label {
  margin-right: auto;
  color: var(--dark-3);
  margin-right: 16px;
}

.app-alert .alert-content .alert-head .alert-head-right {
  margin-right: 16px;
  margin-left: auto;
}

.app-alert .alert-content .alert-head .alert-head-right img {
  height: 14px;
  width: 14px;
}

.app-alert .alert-content .alert-body {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  padding-right: 32px;
  padding-bottom: 16px;
  color: var(--dark-3);
  line-height: 19px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.app-alert .alert-content .alert-foot {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 16px;
  padding-bottom: 16px;
}

.app-alert.red .alert-content .alert-head .alert-head-label {
  color: var(--red-darker);
  /* margin-right: 16px; */
}

.app-alert .dismiss-button {
  cursor: pointer;
}
