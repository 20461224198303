.photos-card {
}

.photos-card__card-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--dark-4);
  display: flex;
  flex-direction: row;
  padding-left: 16px;
  padding-top: 16px;
  padding-right: 16px;
  align-items: center;
}

.photos-card__card-title .add-photo-btn {
  margin-left: auto;
  color: var(--blue-main);
  cursor: pointer;
}

.photos-card__gallery {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 4px;
  /* padding-left: 4px; */
  /* padding-right: 4px; */
  padding-bottom: 16px;
}

.photos-card__gallery__gallery-item {
  margin-bottom: 4px;
  cursor: pointer;
  position: relative;
}

.photos-card__gallery__gallery-item__image {
  height: 120px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  background: black;
  /* margin-top: 16px; */
  /* border-radius: 8px; */
  overflow: hidden;
  position: relative;
}

.photos-card__gallery__gallery-item__image img {
  width: 100%;
}

.photos-card__gallery__gallery-item__image-options {
  position: absolute;
  top: 4px;
  right: 4px;
  opacity: 0;
  pointer-events: none;
}

.photos-card__gallery__gallery-item__image-options__option {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photos-card__gallery__gallery-item__image-options__option.delete {
  background: var(--red-main);
  border-radius: 2px;
}

.photos-card__gallery__gallery-item:hover
  .photos-card__gallery__gallery-item__image-options {
  opacity: 1;
  pointer-events: all;
}

.photos-card__show-more-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  color: var(--dark-4);
  border-top: solid 1px var(--light-2);
  padding-top: 12px;
  padding-bottom: 16px;
  cursor: pointer;
  height: 48px;
}
