.mobile-landing-page__wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.mobile-landing-page {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  /* grid-template-rows: 100%; */
  padding-left: 24px;
  padding-right: 24px;
}

.mobile-landing-page__logo {
  padding-top: 24px;
  grid-column-start: 1;
  grid-column-end: 5;
}

.mobile-landing-page__logo img {
  height: 4.44vw;
}

.mobile-landing-page__primary {
  grid-column-start: 1;
  grid-column-end: 5;
  font-size: 5.55vw;
  margin-top: 32px;
  font-weight: 700;
  font-family: var(--nhg-display);
  color: var(--dark-1);
}

.mobile-landing-page__secondary {
  grid-column-start: 1;
  grid-column-end: 5;
  font-family: var(--nhg);
  font-weight: 400;
  font-size: 3.33vw;
  color: var(--dark-4);
  line-height: 1.5em;
}

.mobile-landing-page__secondary__p {
  margin-top: 24px;
}

.mobile-landing-page__cta {
  height: 8.88vw;
  background: var(--blue-main);
  border-radius: 2px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--light-1);
  padding-left: 4.44vw;
  padding-right: 4.44vw;
  width: 29.72vw;
  box-shadow: var(--elevation-primary);
  clip-path: polygon(0 0, 100% 0, 89.458% 100%, 0% 100%);
  font-size: 3.33vw;
}

.mobile-landing-page__logo-bottom {
}

.mobile-landing-page__logo-bottom img {
}

.mobile-landing-page__graphic {
  position: absolute;
  top: 30vh;
  right: 0;
  width: 200%;
  transform: rotate(-66.37deg);
  transform-origin: top right;
  pointer-events: none;
}

.mobile-landing-page__graphic__blue {
  background: var(--blue-darker);
  height: 40vh;
  box-shadow: 0px 0px 3px rgba(5, 142, 230, 0.4);
  z-index: 3;
}

.mobile-landing-page__graphic__dark-green {
  background: var(--green-darker);
  height: 16px;
  box-shadow: 0px 0px 3px rgba(55, 206, 24, 0.4);
  z-index: 2;
}

.mobile-landing-page__graphic__green {
  background: var(--green-main);
  height: 16px;
  box-shadow: 0px 0px 3px rgba(99, 234, 71, 0.4);
  z-index: 1;
}

.mobile-landing-page__logo-bottom {
  position: absolute;
  bottom: 40px;
  right: 24px;
  height: 4.44vw;
  z-index: 4;
}
