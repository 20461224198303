.event-stats-page {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 32px;
  padding-bottom: 48px;
}

.event-stats-page .left-column {
  grid-column-start: 1;
  grid-column-end: 9;
}

.event-stats-page .right-column {
  grid-column-start: 9;
  grid-column-end: 13;
}

.event-stats-page .top-teams {
  margin-top: 32px;
  padding: 16px;
}

.event-stats-page .top-teams .card-title {
  font-family: var(--nhg-display);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  color: var(--dark-4);
}

.event-stats-page .top-teams .card-title .text {
  margin-right: auto;
  font-weight: 700;
}

.event-stats-page .top-teams .card-title .app-button {
  margin-left: auto;
  padding-right: 8px;
  border-radius: 4px;
}

.event-stats-page .top-teams .card-title .app-button .icon {
  stroke: var(--dark-3);
  height: 14px;
}

.event-stats-page .top-teams .card-chart {
  margin-top: 16px;
}

.event-stats-page .top-players {
  margin-top: 32px;
  padding: 16px;
}

.event-stats-page .top-players .card-title {
  font-family: var(--nhg-display);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  color: var(--dark-4);
}

.event-stats-page .top-players .card-title .text {
  margin-right: auto;
  font-weight: 700;
}

.event-stats-page .top-players .card-title .app-button {
  margin-left: auto;
  padding-right: 8px;
  border-radius: 4px;
}

.event-stats-page .top-players .card-title .app-button .icon {
  stroke: var(--dark-3);
  height: 14px;
}

.event-stats-page .top-players .top-players-list {
  padding-top: 16px;
}

.event-stats-page .top-players .top-players-list .top-players-list-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px var(--light-2);
}
.event-stats-page
  .top-players
  .top-players-list
  .top-players-list-item
  .player-image {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-right: 16px;
  position: relative;
}

.event-stats-page
  .top-players
  .top-players-list
  .top-players-list-item
  .player-image
  .player-image__img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.event-stats-page
  .top-players
  .top-players-list
  .top-players-list-item
  .player-meta {
}

.event-stats-page
  .top-players
  .top-players-list
  .top-players-list-item
  .player-meta
  .primary-text {
  font-weight: 600;
  color: var(--dark-2);
}

.event-stats-page
  .top-players
  .top-players-list
  .top-players-list-item
  .player-meta
  .primary-text
  a {
  color: var(--dark-2);
}

.event-stats-page
  .top-players
  .top-players-list
  .top-players-list-item
  .player-meta
  .secondary-text {
  color: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.event-stats-page
  .top-players
  .top-players-list
  .top-players-list-item
  .player-meta
  .secondary-text
  a {
  color: var(--dark-4);
}

.event-stats-page
  .top-players
  .top-players-list
  .top-players-list-item
  .player-image
  .team-logo {
  height: 12px;
  width: 12px;
  background: var(--blue-darker);
  border-radius: 4px;
  /* margin-right: 4px; */
  position: absolute;
  right: -2px;
  bottom: -2px;
}

.event-stats-page
  .top-players
  .top-players-list
  .top-players-list-item
  .player-image
  .team-logo
  .team-logo__img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.event-stats-page .top-players .top-players-list .top-players-list-item .value {
  margin-left: auto;
  font-size: 24px;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-2);
}

.event-stats-page .stat-type-selection-menu {
  background: white;
  box-shadow: var(--elevation-3);
  border-radius: 8px;
  height: 240px;
  overflow: auto;
}

.event-stats-page .stat-type-selection-item {
  padding-left: 24px;
  padding-right: 24px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
  cursor: pointer;
}

.event-stats-page .stat-type-selection-item:hover {
  background: var(--light-2);
}
