.app-albums-card {
}

.app-albums-card__card-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--dark-4);
  display: flex;
  flex-direction: row;
  padding: 16px;
}

.app-albums-card__card-title .add-album-btn {
  margin-left: auto;
  color: var(--blue-main);
  cursor: pointer;
}

.app-albums-card__gallery {
  padding-left: 16px;
  padding-right: 16px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 16px;
}

.app-albums-card__gallery__gallery-item {
  cursor: pointer;
  margin-bottom: 16px;
}

.app-albums-card__gallery__gallery-item__image {
  border-radius: 4px;
  overflow: hidden;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: var(--blue-subtle);
}

.app-albums-card__gallery__gallery-item__image.has-image {
  background: black;
}

.app-albums-card__gallery__gallery-item__image img {
  width: 100%;
}

.app-albums-card__gallery__gallery-item__image .placeholder-icon {
}

.app-albums-card__gallery__gallery-item__image .overlay {
}

.app-albums-card__gallery__gallery-item__desc {
  margin-top: 8px;
  font-weight: 700;
  font-size: 14px;
}

.add-album-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.add-album-form__input {
  height: 16px;
  outline: none;
  border: none;
  border-bottom: solid 1px var(--light-4);
  font-size: 12px;
  padding-bottom: 4px;
  width: 200px;
}

.add-album-form__option {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
}

.add-album-form__option__icon {
  height: 14px;
}

.add-album-form__option.success .add-album-form__option__icon {
  fill: var(--green-main);
}

.add-album-form__option.cancel .add-album-form__option__icon {
  stroke: var(--red-main);
}
