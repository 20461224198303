.basketball-shots-summary {
  margin-top: 32px;
  padding: 16px;

  /* padding-top: 0px; */
}

.left-column .basketball-shots-summary {
  padding-right: 8px;
}

.right-column .basketball-shots-summary {
  padding-left: 8px;
}

.basketball-shots-summary__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.basketball-shots-summary__item:last-child {
  margin-bottom: 8px;
}

.basketball-shots-summary__item__graph {
  height: 60px;
  width: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.basketball-shots-summary__item__graph .percentage {
  position: absolute;
  font-size: 10px;
  font-weight: 700;
  margin-top: 0px;
}

.basketball-shots-summary__item__graph canvas {
  margin-top: -8px;
}

.basketball-shots-summary__item__values {
  margin-right: auto;
  display: flex;
  flex-direction: row;
}

.right-column .basketball-shots-summary__item__values {
  margin-left: auto;
  margin-right: 0;
  display: flex;
  flex-direction: row;
}

.basketball-shots-summary__item__values__value {
  font-family: var(--nhg-display);
  font-weight: 700;
  height: 24px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  margin-right: 24px;
  color: var(--dark-2);
}

.right-column .basketball-shots-summary__item__values__value {
  align-items: flex-end;
  margin-right: 0;
  margin-left: 24px;
}

.basketball-shots-summary__item__values__value__number {
  font-size: 20px;
}

.basketball-shots-summary__item__values__value__label {
  color: var(--blue-lighter);
}

.basketball-shots-summary .horizontal-stacked-bar-summary {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  font-weight: 700;
  font-family: var(--nhg-display);
  margin-right: 8px;
  margin-top: 8px;
}

.right-column .basketball-shots-summary .horizontal-stacked-bar-summary {
  margin-right: 0;
  margin-left: 8px;
}

.basketball-shots-summary .horizontal-stacked-bar-summary .fill {
  flex-shrink: 0;
  background: var(--blue-lighter);
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 8px;
  color: white;
}

.right-column .basketball-shots-summary .horizontal-stacked-bar-summary .fill {
  justify-content: flex-end;
  padding-right: 8px;
}

.basketball-shots-summary .horizontal-stacked-bar-summary .background {
  flex-shrink: 0;
  background: var(--light-3);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8px;
}

.right-column
  .basketball-shots-summary
  .horizontal-stacked-bar-summary
  .background {
  justify-content: flex-start;
  padding-left: 8px;
}
