.info-group .value.address {
  display: flex;
  flex-direction: row;
}

.info-group .value.address .text {
  flex-grow: 1;
}

.organization-address-input {
  margin-top: 0 !important;
}
