.fixture-points-timeline__header__legend {
  display: flex;
  flex-direction: row;
}

.fixture-points-timeline__header__legend__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 24px;
}

.fixture-points-timeline__header__legend__item__color {
  height: 14px;
  width: 14px;
  border-radius: 4px;
  margin-right: 4px;
  opacity: 0.8;
}

.fixture-points-timeline__header__legend__item__label {
  font-size: 10px;
  color: var(--dark-3);
}
