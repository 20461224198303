.profile-overview-mobile__events {
  padding: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.profile-overview-mobile__events__header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-overview-mobile__events__header__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.profile-overview-mobile__events__header__link {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--blue-main);
  margin-left: auto;
}

.profile-overview-mobile__events__list {
}

.profile-overview-mobile__events__list__item {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.profile-overview-mobile__events__list__item__image {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.profile-overview-mobile__events__list__item__image img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.profile-overview-mobile__events__list__item__desc {
}

.profile-overview-mobile__events__list__item__desc__primary {
  font-weight: 600;
  line-height: 20px;
  color: var(--dark-1);
}

.profile-overview-mobile__events__list__item__desc__secondary {
  color: var(--dark-4);
}
