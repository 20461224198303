.event-overview .event-organizers-card {
  margin-top: 32px;
  /* padding: 16px; */
  /* background: var(--blue-subtle); */
}

.event-organizers-card .card-title {
  font-weight: 600;
  color: var(--dark-4);
  padding: 16px;
  padding-bottom: 0;
}

.event-overview .event-organizers-card__list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 24px;
  padding: 16px;
}

.event-overview .event-organizers-card .role-wrapper {
  /* width: 50%; */
  /* height: 100px; */
  /* padding: 16px; */
  margin-bottom: 16px;
}

.event-overview .event-organizers-card .role-wrapper .image {
  height: 88px;
  background: var(--blue-subtle);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.event-overview .event-organizers-card .role-wrapper .image img {
  width: 100%;
}

.event-overview .event-organizers-card .role-wrapper .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
  overflow: hidden;
}

.event-overview .event-organizers-card .role-wrapper .text .primary {
  font-weight: 600;
  /* color: white; */
  text-align: center;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--dark-2);
  width: 100%;
}

.event-overview .event-organizers-card .role-wrapper .text .secondary {
  color: var(--dark-4);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4px;
}
