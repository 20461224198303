.organization-posts-mobile .app-post-card {
  margin-top: 12px;
}

.organization-posts-mobile .show-more-container {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
