.home-page-mobile__profile-card {
  background: var(--light-1);
  box-shadow: var(--elevation-mobile);
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.home-page-mobile__profile-card__profile-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 16px;
  /* padding-bottom: 16px; */
}

.home-page-mobile__profile-card__profile-info__image {
  height: 48px;
  width: 48px;
  border-radius: 100%;
  background: var(--blue-subtle);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page-mobile__profile-card__profile-info__image img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.home-page-mobile__profile-card__profile-info__text {
  padding-left: 12px;
}

.home-page-mobile__profile-card__profile-info__text__primary {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 700;
  font-size: 16px;
  /* line-height: 19px; */

  color: var(--dark-1);
  margin-bottom: 2px;
}

.home-page-mobile__profile-card__profile-info__text__secondary {
  font-family: var(--nhg);
  /* font-style: normal; */
  font-weight: 400;
  font-size: 12px;
  /* line-height: 15px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}
