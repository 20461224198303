.manage-event-standings {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  padding: 48px;
  grid-column-gap: 48px;
}

.manage-event-standings .left-column {
  grid-column-start: 1;
  grid-column-end: 8;
}

.manage-event-standings .right-column {
  grid-column-start: 8;
  grid-column-end: 13;
}

.manage-event-standings .recalculate-event-standings {
  padding: 24px;
}

.manage-event-standings .recalculate-event-standings__periods {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.manage-event-standings .recalculate-event-standings__periods__item {
  /* background: var(--blue-main); */
  border: solid 1px var(--blue-main);
  color: var(--blue-main);
  margin-right: 12px;
  margin-bottom: 12px;
  height: 24px;
  padding-right: 4px;
  cursor: pointer;
}

.manage-event-standings .recalculate-event-standings__periods__item__icon {
  height: 14px;
  stroke: var(--light-4);
}

.manage-event-standings
  .recalculate-event-standings__periods__item:hover
  .recalculate-event-standings__periods__item__icon {
  stroke: var(--red-main);
}

.manage-event-standings .recalculate-event-standings .card-title {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 24px;
}

.manage-event-standings
  .recalculate-event-standings
  .recalculate-event-standings-form {
  margin-top: 24px;
}

.manage-event-standings
  .recalculate-event-standings
  .recalculate-event-standings-form
  .form-group {
}

.manage-event-standings
  .recalculate-event-standings
  .recalculate-event-standings-form
  .form-options {
  margin-top: 24px;
}

.manage-event-standings
  .recalculate-event-standings
  .recalculate-event-standings-form
  .form-group
  .form-group-label {
  color: var(--dark-3);
  margin-bottom: 8px;
}

.manage-event-standings
  .recalculate-event-standings
  .recalculate-event-standings-form
  .form-group
  .form-group-input {
}

.manage-event-standings
  .recalculate-event-standings
  .recalculate-event-standings-form
  .form-group
  .form-group-input
  input {
  height: 32px;
  padding: 0;
  width: 100%;
  border: solid 1px var(--light-3);
  padding-left: 8px;
  padding-right: 8px;
  outline: none;
  border-radius: 8px;
}

.manage-event-standings
  .recalculate-event-standings
  .recalculate-event-standings-form
  .form-group
  .form-group-input
  input:focus {
  border-color: var(--blue-main);
}

.manage-event-standings
  .recalculate-event-standings
  .recalculate-event-standings-form
  .form-group
  .filter-input-dropdown
  .input-wrapper {
  border: solid 1px var(--light-3);
}

.manage-event-standings .standings-list {
}

.manage-event-standings .standings-list .standings-list-item {
  margin-bottom: 32px;
}

.manage-event-standings .standings-list .standings-list-item__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
  height: 40px;
  padding-left: 16px;
  padding-right: 4px;
}

.manage-event-standings .standings-list .standings-list-item__header .label {
  font-weight: 700;
  margin-right: auto;
}

.manage-event-standings
  .standings-list
  .standings-list-item__header
  .createdAt {
  color: var(--dark-4);
}

.manage-event-standings
  .standings-list
  .standings-list-item__header
  .delete-btn {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-event-standings
  .standings-list
  .standings-list-item__header
  .delete-btn
  .icon {
  stroke: var(--red-lighter);
  height: 14px;
}

.manage-event-standings .standings-list .standings-list-item__fields {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  height: 24px;
  background: var(--light-2);
  font-size: 10px;
  font-weight: 700;
  color: var(--dark-4);
}

.manage-event-standings .standings-list .standings-list-item__fields__field {
  display: flex;
  flex-direction: row;
  padding-right: 8px;
  padding-left: 8px;
}

.manage-event-standings
  .standings-list
  .standings-list-item__fields__field:first-child {
  padding-left: 0;
}

.manage-event-standings
  .standings-list
  .standings-list-item__fields__field:last-child {
  padding-right: 0;
}

.manage-event-standings .standings-list .standings-list-item__items {
}

.manage-event-standings .standings-list .standings-list-item__items__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
  padding-left: 16px;
  padding-right: 16px;
  height: 32px;
}

.manage-event-standings .standings-list .standings-list-item__items__row__data {
  display: flex;
  flex-direction: row;
  padding-right: 8px;
  padding-left: 8px;
}

.manage-event-standings
  .standings-list
  .standings-list-item__items__row__data
  .standings-list-item__items__row__data__order {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-event-standings
  .standings-list
  .standings-list-item__items__row__data
  .standings-list-item__items__row__data__reorder {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.manage-event-standings
  .standings-list
  .standings-list-item__items__row__data
  .standings-list-item__items__row__data__reorder.up {
  transform: rotateZ(180deg);
  transform-origin: 16px 16px;
}

.manage-event-standings
  .standings-list
  .standings-list-item__items__row__data:first-child {
  padding-left: 0;
}

.manage-event-standings
  .standings-list
  .standings-list-item__items__row__data:last-child {
  padding-right: 0;
}

.manage-event-standings
  .standings-list
  .standings-list-item__items__row__data__form {
  max-width: 100%;
}

.manage-event-standings
  .standings-list
  .standings-list-item__items__row__data__form
  input {
  width: 100%;
}
