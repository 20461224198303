.modal-form-mobile__body__categories-list {
  display: flex;
  flex-direction: row;
  padding: 16px;
  flex-wrap: wrap;
}

.modal-form-mobile__body__categories-list__item {
  height: 20px;
  border-radius: 4px;
  background: var(--light-2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 10px;
  /* line-height: 14px; */
  letter-spacing: 0.2px;
  color: var(--dark-4);
}

.modal-form-mobile__body__categories-list__item.selected {
  background: var(--blue-main);
  color: var(--light-2);
}
