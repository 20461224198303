.photos-card-mobile {
  background: var(--light-1);
  margin-top: 12px;
  border-radius: 12px;
  box-shadow: var(--elevation-2);
  padding: 12px;
  padding-bottom: 8px;
}

.photos-card-mobile__header {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.photos-card-mobile__header__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.photos-card-mobile__header__btn {
  margin-left: auto;
  color: var(--blue-main);
  cursor: pointer;
  font-weight: 600;
}

.photos-card-mobile__gallery {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 4px;
  margin-bottom: 12px;
}

.photos-card-mobile__gallery__item {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-subtle);
  overflow: hidden;
}

.photos-card-mobile__gallery__item img {
  max-height: 100%;
  width: auto;
}

.photos-card-mobile__footer {
  margin-top: 12px;
  padding-top: 8px;
  /* padding-bottom: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: solid 1px var(--light-2);
  font-size: 10px;
  font-weight: 600;
  color: var(--dark-4);
}

.photos-card-mobile__footer__show-more {
}
