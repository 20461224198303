.select-media-wrapper {
  width: 40%;
  max-height: 80%;
  overflow: hidden;
  display: flex;
}

.select-media {
  display: flex;
  flex-grow: 1;
  height: inherit;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
}

.select-media__primary-header {
  height: 48px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-3);
  /* background: var(--blue-darkest); */
  padding-left: 24px;
  padding-right: 24px;
  font-size: 14px;
  font-family: var(--nhg-display);
  font-weight: 700;
  /* color: white; */
  color: var(--dark-3);
}

.select-media__primary-header__dismiss {
  margin-left: auto;
  height: 100%;
  /* width: 48px; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.select-media__header {
  height: 48px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-3);
  padding-left: 24px;
  padding-right: 16px;
  font-size: 12px;
  font-family: var(--nhg-display);
  font-weight: 700;
}

.select-media__header__title {
}

.select-media__header__tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
}

.select-media__header__tabs .upload-new-btn {
  margin-left: auto;
}

.select-media__header__tabs__tab {
  margin-right: 16px;
  color: var(--dark-3);
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  /* font-size: 12px; */
  transition: all 0.2s ease-out;
  /* border-bottom: solid 2px transparent; */
}

.select-media__header__tabs__tab.selected {
  color: var(--blue-main);
  border-bottom: solid 2px var(--blue-main);
}

.select-media__header__link {
  color: var(--blue-main);
  margin-left: auto;
}

.select-media__images {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 8px;
  flex-grow: 1;
  overflow: auto;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  /* margin-top: 16px; */
  padding-top: 16px;
}

.select-media__images__item {
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  /* margin-top: 24px; */
  position: relative;
  cursor: pointer;
  height: 80px;
  overflow: hidden;
  margin-top: 8px;
}

.select-media__images__item__select {
  position: absolute;
  top: 4px;
  right: 4px;
  background: var(--blue-main);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  opacity: 0;
  transition: all 0.2s ease-out;
  /* position: relative; */
}

.select-media__images__item__select.selected {
  opacity: 1;
}

.select-media__images__item:hover .select-media__images__item__select {
  opacity: 1;
}

.select-media__images__item__select__icon {
  height: 12px;
  fill: white;
  position: absolute;
}

.select-media__images__item__select__icon-delete {
  height: 12px;
  stroke: white;
  position: absolute;
  display: none;
}

.select-media__images__item:hover .select-media__images__item__select.selected {
  background: var(--red-main);
}

.select-media__images__item:hover
  .select-media__images__item__select.selected
  .select-media__images__item__select__icon {
  display: none;
}

.select-media__images__item:hover
  .select-media__images__item__select.selected
  .select-media__images__item__select__icon-delete {
  display: block;
}

.select-media__images__item img {
  width: 100%;
}

.select-media__show-more {
  grid-column-start: 1;
  grid-column-end: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-top: 16px;
  border: solid 1px var(--light-2);
  border-radius: 8px;
  box-shadow: var(--elevation-3);
  font-size: 12px;
  font-weight: 700;
  color: var(--blue-lighter);
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.select-media__show-more:hover {
  color: var(--blue-main);
  box-shadow: var(--elevation-4);
}

.select-media__videos {
  flex-grow: 1;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 8px;
  padding: 24px;
  padding-top: 16px;
}

.select-media__videos__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  margin-top: 8px;
  height: 160px;
  /* height: 320px; */
}

.select-media__videos__item .video {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-media__videos__item .video video {
  width: 100%;
}

.select-media__videos__item__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #022e4b36;
  cursor: pointer;
  /* opacity: 0.1; */
}

.select-media__videos__item__select {
  position: absolute;
  top: 4px;
  right: 4px;
  background: var(--blue-main);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  opacity: 0;
  transition: all 0.2s ease-out;
  /* position: relative; */
}

.select-media__videos__item__select.selected {
  opacity: 1;
}

.select-media__videos__item:hover .select-media__videos__item__select {
  opacity: 1;
}

.select-media__videos__item__select__icon {
  height: 12px;
  fill: white;
  position: absolute;
}

.select-media__videos__item__select__icon-delete {
  height: 12px;
  stroke: white;
  position: absolute;
  display: none;
}

.select-media__videos__item:hover .select-media__videos__item__select.selected {
  background: var(--red-main);
}

.select-media__videos__item:hover
  .select-media__videos__item__select.selected
  .select-media__videos__item__select__icon {
  display: none;
}

.select-media__videos__item:hover
  .select-media__videos__item__select.selected
  .select-media__videos__item__select__icon-delete {
  display: block;
}

.select-media__albums {
  flex-grow: 1;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 8px;
  padding: 24px;
  padding-top: 16px;
}

.select-media__albums__item {
  position: relative;
  margin-top: 8px;
  /* height: 160px;    */
}

.select-media__albums__item__image {
  height: 160px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-subtle);
}

.select-media__albums__item__image img {
  width: 100%;
}

.select-media__albums__item__image.has-image {
  background: black;
}

.select-media__albums__item__image .overlay {
}

.select-media__albums__item__desc {
  margin-top: 4px;
  font-weight: 700;
  font-size: 10px;
}

.select-media__albums__item__overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.select-media__albums__item__select {
  position: absolute;
  top: 4px;
  right: 4px;
  background: var(--blue-main);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.select-media__albums__item__select.selected {
  opacity: 1;
}

.select-media__albums__item:hover .select-media__albums__item__select {
  opacity: 1;
}

.select-media__albums__item__select__icon {
  height: 12px;
  fill: white;
  position: absolute;
}

.select-media__albums__item__select__icon-delete {
  height: 12px;
  stroke: white;
  position: absolute;
  display: none;
}

.select-media__albums__item:hover .select-media__albums__item__select.selected {
  background: var(--red-main);
}

.select-media__albums__item:hover
  .select-media__albums__item__select.selected
  .select-media__albums__item__select__icon {
  display: none;
}

.select-media__albums__item:hover
  .select-media__albums__item__select.selected
  .select-media__albums__item__select__icon-delete {
  display: block;
}

.select-media__footer {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-top: solid 1px var(--light-3);
  font-size: 14px;
  color: var(--blue-main);
  font-weight: 700;
  cursor: pointer;
}
