.ScoringApp-cricket__scoring__add-form__wrapper {
  background: var(--light-2);
  padding: 8px;
}

.ScoringApp-cricket__scoring__add-form {
  background: white;
}

.ScoringApp-cricket__scoring__add-form__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  padding-left: 12px;
  border-bottom: solid 1px var(--light-2);
}

.ScoringApp-cricket__scoring__add-form__title__text {
  font-size: 14px;
  font-weight: 700;
  font-family: var(--nhg-display);
}

.ScoringApp-cricket__scoring__add-form__title__dismiss {
  cursor: pointer;
  margin-left: auto;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScoringApp-cricket__scoring__add-form__title__dismiss__icon {
  stroke: var(--dark-4);
  height: 16px;
}

.ScoringApp-cricket__scoring__add-form__body {
  padding-bottom: 12px;
}

.ScoringApp-cricket__scoring__add-form__body__group {
  padding: 12px;
  padding-bottom: 0;
}

.ScoringApp-cricket__scoring__add-form__body__group__label {
  font-family: var(--nhg-display);
  color: var(--dark-3);
  margin-right: auto;
  font-size: 10px;
  margin-bottom: 4px;
}

.ScoringApp-cricket__scoring__add-form__body__group__input {
}

.ScoringApp-cricket__scoring__add-form__body__group__input input {
  height: 28px;
  border: solid 1px var(--light-4);
  border-radius: 4px;
  padding-left: 8px;
  width: 50%;
}

.ScoringApp-cricket__scoring__add-form__body__group__input input:focus {
  outline: solid 1px var(--blue-darker);
}

.ScoringApp-cricket__scoring__add-ball-btn-wrapper {
  padding: 12px;
}

.ScoringApp-cricket__scoring__add-ball-btn-wrapper__btn {
}

.ScoringApp-cricket__scoring__add-form__body__group__input__container {
  height: 28px;
  border: solid 1px var(--light-4);
  border-radius: 4px;
  padding-left: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.ScoringApp-cricket__scoring__add-form__body__group__input__container__value {
}

.ScoringApp-cricket__scoring__add-form__body__group__input__container__placeholder {
  color: var(--light-4);
}

.ScoringApp-cricket__scoring__add-form__body__group__input__container__dismiss {
  margin-left: auto;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScoringApp-cricket__scoring__add-form__body__group__input__container__dismiss__icon {
  stroke: var(--red-main);
  height: 14px;
}
