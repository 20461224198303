.fixture-summary-mobile__details {
  padding: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  margin-top: 12px;
}

.fixture-summary-mobile__details__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.fixture-summary-mobile__details__location {
  margin-top: 12px;
}

.fixture-summary-mobile__details__location__map {
  height: 160px;
  /* width: 90px; */
  border-radius: 8px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  overflow: hidden;
}

.fixture-summary-mobile__details__location__text {
  margin-top: 12px;
}

.fixture-summary-mobile__details__location__text__primary {
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-2);
}

.fixture-summary-mobile__details__location__text__secondary {
  margin-top: 4px;
  color: var(--dark-4);
}

.fixture-summary-mobile__details__location__text__datetime__wrapper {
  margin-top: 12px;
}

.fixture-summary-mobile__details__location__text__datetime {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-summary-mobile__details__location__text__datetime__label {
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-2);
}

.fixture-summary-mobile__details__location__text__datetime__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  margin-right: 8px;
}

.fixture-summary-mobile__details__location__text__datetime__value {
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-2);
}
