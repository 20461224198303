.mobile-upload-display-photo {
  /* padding: 16px; */
}

.mobile-upload-display-photo__input-container {
  position: relative;
  height: 160px;
  /* border: dashed 1px var(--blue-lighter); */
  border-radius: 8px;
  /* margin-top: 16px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: var(--blue-subtle); */
  flex-shrink: 0;
  cursor: pointer;
}

.mobile-upload-display-photo__input-container .input-icon {
  margin-top: -16px;
}

.mobile-upload-display-photo__input-container input {
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.mobile-upload-display-photo .profile-image-cropper {
  margin-top: 0;
  height: auto;
}
