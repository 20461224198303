.manage-event-fixtures {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-template-rows: 100%;
  padding-top: 16px;
  padding-right: 48px;
  /* padding-bottom: 48px; */
}

.manage-event-fixtures .manage-event-fixtures-filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-right: 48px;
  padding-left: 48px;
}

.manage-event-fixtures .manage-event-fixtures-list {
  flex-grow: 1;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  padding-right: 48px;
  grid-column-start: 1;
  grid-column-end: 9;
  /* padding-left: 48px; */
}

.manage-event-fixtures .manage-event-fixtures-list.expanded {
  grid-column-end: 13;
  /* padding-right: 0; */
}

.manage-event-fixtures .manage-event-fixtures-part {
  margin-left: 32px;
  min-width: 296px;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.manage-event-fixtures .manage-event-fixtures-part:first-child {
  margin-left: 48px;
}

.manage-event-fixtures .manage-event-fixtures-part.create {
  /* margin-left: 48px; */
  /* width: 24px; */
  /* min-width: 0px; */
  flex-shrink: 0;
}

.manage-event-fixtures .manage-event-fixtures-part__header {
  box-shadow: var(--elevation-1);
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--blue-subtle);
  border-radius: 4px;
}

.manage-event-fixtures .manage-event-fixtures-part__header__move-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  /* border-left: solid 1px var(--blue-lightest); */
}

.manage-event-fixtures .manage-event-fixtures-part__header__move-btn .icon {
  height: 12px;
  fill: var(--blue-lightest);
}

.manage-event-fixtures
  .manage-event-fixtures-part__header__move-btn:hover
  .icon {
  fill: var(--blue-main);
}

.manage-event-fixtures .manage-event-fixtures-part__header__move-btn.left {
  transform: rotate(180deg);
  transform-origin: 12px 12px;
}

.manage-event-fixtures .manage-event-fixtures-part__header__options {
  height: 24px;
  /* width: 24px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: rotate(90deg); */
  /* transform-origin: 12px 12px; */
  /* position: absolute;
  right: 0;
  top: 0; */
}

.manage-event-fixtures .manage-event-fixtures-part__header__menu {
  overflow: hidden;
}

.manage-event-fixtures .manage-event-fixtures-part__header__menu__item {
  text-align: center;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-bottom: solid 1px var(--light-2);
  color: var(--dark-1);
  font-weight: 400;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80px;
}

.manage-event-fixtures .edit-event-part-label-form {
  flex-grow: 1;
}

.manage-event-fixtures .edit-event-part-label-form input {
  width: 100%;
  text-align: center;
  background: none;
  border: none;
  color: var(--blue-main);
  font-weight: 600;
  /* margin-bottom: 16px; */
  font-size: 10px;
  height: 24px;
  outline: none;
}

.manage-event-fixtures .manage-event-fixtures-part__header__menu__item:hover {
  background: var(--light-2);
}

.manage-event-fixtures .manage-event-fixtures-part .part-name {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: var(--blue-main);
  font-weight: 600;
  /* margin-bottom: 16px; */
  font-size: 10px;
  flex-shrink: 0;
}

.manage-event-fixtures .manage-event-fixtures-part.create .part-name {
  background: none;
  border: solid 1px var(--blue-main);
  color: var(--blue-main);
  stroke: var(--blue-main);
  /* width: 24px; */
}

.manage-event-fixtures .manage-event-fixtures-part.create .part-name.expanded {
  width: 296px;
  /* color: white; */
}

.manage-event-fixtures .manage-event-fixtures-part.create .part-name .icon {
  height: 12px;
}

.manage-event-fixtures .manage-event-fixtures-part__options {
  margin-top: 12px;
  /* display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 12px;
  grid-template-rows: 24px; */
}

.manage-event-fixtures .manage-event-fixtures-part .subparts {
  display: flex;
  flex-direction: row;
}

.manage-event-fixtures
  .manage-event-fixtures-part
  .subparts
  .manage-event-fixtures-part {
  margin-left: 8px;
  margin-right: 8px;
}

.manage-event-fixtures
  .manage-event-fixtures-part
  .subparts
  .manage-event-fixtures-part:first-child {
  margin-left: 0;
}

.manage-event-fixtures
  .manage-event-fixtures-part
  .subparts
  .manage-event-fixtures-part:last-child {
  margin-right: 0;
}

.manage-event-fixtures .calendar-fixture-list-item {
  margin-top: 16px;
}

.manage-event-fixtures .calendar-fixture-list-item__wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.manage-event-fixtures .calendar-fixture-list-item__edit-part {
  border-top: solid 1px var(--light-2);

  padding-left: 8px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.manage-event-fixtures .calendar-fixture-list-item__edit-part__target {
  font-size: 10px;
  color: var(--dark-4);
}

.manage-event-fixtures .calendar-fixture-list-item__edit-part__menu__wrapper {
  position: relative;
}

.manage-event-fixtures .calendar-fixture-list-item__edit-part__menu {
  background: white;
  /* margin-top: -20px; */
  height: 160px;
  overflow: auto;
}

.manage-event-fixtures .calendar-fixture-list-item__edit-part__menu__title {
  padding: 8px;
  font-size: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: var(--blue-subtle);
}

.manage-event-fixtures
  .calendar-fixture-list-item__edit-part__menu__title__list__item {
}

.manage-event-fixtures
  .calendar-fixture-list-item__edit-part__menu__title__list__item__label {
  padding: 8px;
  cursor: pointer;
  font-size: 10px;
  font-weight: 700;
}

.manage-event-fixtures
  .calendar-fixture-list-item__edit-part__menu__title__list__item__label.disabled {
  pointer-events: none;
  color: var(--dark-4);
}

.manage-event-fixtures
  .calendar-fixture-list-item__edit-part__menu__title__list__item__label:hover {
  background: var(--blue-main);
  color: white;
}

.manage-event-fixtures
  .calendar-fixture-list-item__edit-part__menu__title__list__item__sub-items {
  padding-left: 16px;
}

.manage-event-fixtures .calendar-fixture-list-item .participants {
  flex-grow: 1;
}

.manage-event-fixtures .calendar-fixture-list-item .participants .participant {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding: 8px;
  padding-right: 16px;
  cursor: pointer;
}

.manage-event-fixtures
  .calendar-fixture-list-item
  .participants
  .participant
  .image {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  margin-right: 16px;
  overflow: hidden;
}

.manage-event-fixtures
  .calendar-fixture-list-item
  .participants
  .participant
  .image
  img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.manage-event-fixtures
  .calendar-fixture-list-item
  .participants
  .participant
  .name {
  flex-grow: 1;
  color: var(--dark-1);
}

.manage-event-fixtures
  .calendar-fixture-list-item
  .participants
  .participant
  .score {
  /* font-family: var(--nhg-display); */
  font-weight: 600;
  color: var(--dark-2);
}

.manage-event-fixtures .calendar-fixture-list-item .datetime {
  width: 25%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  border-left: solid 1px var(--light-2);
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-4);
  letter-spacing: 0.4px;
  /* font-size: 12px; */
}

.manage-event-fixtures .calendar-fixture-list-item .datetime .date {
  margin-bottom: 4px;
}

.manage-event-fixtures .manage-event-fixtures-form-container {
  grid-column-start: 9;
  grid-column-end: 13;
}

.generate-fixtures {
  padding: 16px;
}

.generate-fixtures__title {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--nhg-display);
}

.generate-fixtures__section {
  /* margin-top: 16px; */
  margin-top: 16px;
}

.generate-fixtures__section__options {
  /* margin-top: 16px; */
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 16px;
}

.generate-fixtures__section__options__item {
  margin-top: 16px;
  padding: 16px;
  border-left: solid 2px var(--blue-lighter);
}

.generate-fixtures__section__options__item__title {
  font-weight: 600;
  margin-bottom: 8px;
}

.generate-fixtures__section__options__item__description {
  font-size: 10px;
}

.generate-fixtures-form {
  padding: 16px;
}

.generate-fixtures-form__title {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--nhg-display);
}

.generate-fixtures-form__body__form-group {
  margin-top: 16px;
}

.generate-fixtures-form__body__form-group__label {
}

.generate-fixtures-form__body__form-group__input {
  margin-top: 8px;
}

.generate-fixtures-form__body__form-group__input input {
  height: 32px;
  width: 100%;
  box-shadow: var(--elevation-1);
  border: solid 1px var(--light-2);
  border-radius: 8px;
  padding-left: 12px;
}

.generate-fixtures-form__footer {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.generate-fixtures-form__footer_option {
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 600;
}

.generate-fixtures-form__footer_option.success {
  background: var(--green-main);
  color: white;
}

.generate-fixtures-form__footer_option.cancel {
  color: var(--red-main);
}

.delete-event-part-btn {
  height: 24px;
  /* background: white; */
  color: var(--red-lighter);
  border: solid 1px var(--red-lighter);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 16px;
}

.add-fixture-btn {
  justify-content: center;
  height: 24px;
  background: var(--green-main);
  color: var(--light-1);
  /* border: solid 1px var(--green-main); */
  /* border: solid 1px var(--green-main); */
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
  flex-shrink: 0;
  box-shadow: var(--elevation-1);
  /* margin-top: 16px; */
}

.add-part-btn {
  justify-content: center;
  height: 24px;
  background: white;
  color: var(--blue-lighter);
  /* border: solid 1px var(--green-main); */
  /* border: solid 1px var(--green-main); */
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
  flex-shrink: 0;
  box-shadow: var(--elevation-1);
  margin-top: 12px;
}

.add-part-btn.header {
  margin-top: 0;
}

.add-part-btn:hover {
  color: var(--blue-main);
}

.add-fixture-form-container {
  /* margin-top: 16px; */
  padding: 16px;
  box-sizing: border-box;
  width: 296px;
}

.add-fixture-form {
}

.add-fixture-form__title {
  font-weight: 600;
  font-family: var(--nhg-display);
  text-align: center;
  color: var(--dark-3);
}

.add-fixture-form__form-group {
  margin-top: 16px;
}

.add-fixture-form__form-group .start-date-input {
  width: 100%;
  height: 32px;
  box-shadow: var(--elevation-1);
  border: solid 1px var(--light-2);
  border-radius: 8px;
  padding-left: 16px;
  font-family: var(--nhg);
  color: var(--light-4);
  padding-right: 12px;
  font-size: 12px;
}

.add-fixture-form__footer {
  /* margin-top: 16px; */
  display: flex;
  flex-direction: column;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 12px;
}

.add-fixture-form__footer__btn {
  height: 32px;
  margin-top: 16px;
  /* width: 100%; */
  border: none;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-fixture-form__footer__btn.success {
  background: var(--green-main);
  color: white;
}

.add-fixture-form__footer__btn.cancel {
  color: var(--light-4);
  background: var(--light-2);
}

.manage-event-fixtures .manage-event-fixtures-part .fixtures-list {
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 16px;
}

.add-event-part-form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add-event-part-form input {
  height: 24px;
  box-shadow: var(--elevation-1);
  border: solid 1px var(--light-2);
  border-radius: 4px;
  padding-left: 12px;
  flex-grow: 1;
  font-size: 10px;
  outline: none;
}

.add-event-part-form button {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: none;
  margin-left: 8px;
  cursor: pointer;
}

.add-event-part-form button .icon {
  stroke: var(--light-4);
}

.add-event-part-form__btn.success {
  background: var(--green-main);
}

.add-event-part-form__btn.success .icon {
  fill: var(--light-1);
  stroke: none;
}
