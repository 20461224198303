.create-support-ticket {
  width: 40%;
}

.create-support-ticket__header {
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.create-support-ticket__header__title {
  font-size: 20px;
  font-weight: 700;
  color: var(--dark-2);
  font-family: var(--nhg-display);
}

.create-support-ticket__header__dismiss {
  margin-left: auto;
  cursor: pointer;
}

.create-support-ticket__header__dismiss__icon {
  stroke: var(--light-4);
}

.create-support-ticket__body {
}

.create-support-ticket__body__row {
  display: grid;
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  grid-template-columns: repeat(2, minmax(0, 1fr));
  /* grid-column-gap: 16px; */
}

.create-support-ticket__body__group {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
}

.create-support-ticket__body__group__label {
  color: var(--dark-3);
  margin-bottom: 4px;
  font-size: 10px;
}

.create-support-ticket__body__group__label .required {
  color: var(--red-main);
}

.create-support-ticket__body__group__input {
}

.create-support-ticket__body__group__input input {
  width: 100%;
  height: 32px;
  border: solid 1px var(--light-3);
  border-radius: 4px;
  outline: none;
  padding-left: 8px;
  font-family: var(--nhg);
}

.create-support-ticket__body__group__input input:focus {
  border-color: var(--blue-main);
}

.create-support-ticket__body__group__input textarea {
  width: 100%;
  height: 32px;
  border: solid 1px var(--light-3);
  border-radius: 4px;
  outline: none;
  padding: 8px;
  height: 280px;
  font-family: var(--nhg);
  resize: none;
}

.create-support-ticket__body__group__input textarea:focus {
  border-color: var(--blue-main);
}

.create-support-ticket__footer {
  padding: 16px;
}

.create-support-ticket__error {
  font-size: 10px;
  font-weight: 600;
  color: var(--red-darker);
  background: var(--red-subtle);
  border-radius: 4px;
  margin: 16px;
  padding: 8px;
}

.ticket-submitted {
  padding: 16px;
}

.ticket-submitted__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.ticket-submitted__title__text {
  font-weight: 600;
  font-size: 16px;
}

.ticket-submitted__title__dismiss {
  cursor: pointer;
  margin-left: auto;
}

.ticket-submitted__title__dismiss__icon {
  stroke: var(--dark-4);
  height: 14px;
}

.ticket-submitted__desc {
  color: var(--dark-3);
}
