.home-upcoming-events-card {
  /* padding: 16px; */
  /* margin-top: 32px; */
}

.home-upcoming-events-card .calendar-fixture-list {
}

.home-upcoming-events-card__calendar {
  padding: 16px;
  padding-bottom: 0;
}

.home-upcoming-events-card .home-upcoming-events-card__fixture-list-item {
  box-shadow: none;
  border-bottom: solid 1px var(--light-2);
  margin-top: 0;
  /* margin-bottom: 16px; */
}

.home-upcoming-events-card .calendar-fixture-list .calendar-fixture-list-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  /* margin-top: 16px; */
}

.home-upcoming-events-card
  .calendar-fixture-list
  .calendar-fixture-list-item
  .participants {
  flex-grow: 1;
  padding-bottom: 16px;
}

.home-upcoming-events-card
  .calendar-fixture-list
  .calendar-fixture-list-item
  .participants
  .participant {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding: 16px;
  padding-bottom: 0px;
  /* padding-left: 16px;
  padding-right: 16px; */
}

.home-upcoming-events-card
  .calendar-fixture-list
  .calendar-fixture-list-item
  .participants
  .participant
  .image {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  margin-right: 16px;
}

.home-upcoming-events-card
  .calendar-fixture-list
  .calendar-fixture-list-item
  .participants
  .participant
  .name {
  flex-grow: 1;
  color: var(--dark-1);
}

.home-upcoming-events-card
  .calendar-fixture-list
  .calendar-fixture-list-item
  .participants
  .participant
  .score {
  /* font-family: var(--nhg-display); */
  font-weight: 600;
  color: var(--dark-2);
}

.home-upcoming-events-card
  .calendar-fixture-list
  .calendar-fixture-list-item
  .datetime {
  width: 25%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  border-left: solid 1px var(--light-2);
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-4);
  letter-spacing: 0.4px;
  /* font-size: 12px; */
}

.home-upcoming-events-card
  .calendar-fixture-list
  .calendar-fixture-list-item
  .datetime
  .date {
  margin-bottom: 4px;
}
