.profile-overview-organizations-card {
  padding: 16px;
}

.profile-overview-organizations-card .card-title {
  font-weight: 600;
  color: var(--dark-4);
  display: flex;
  align-items: center;
}

.profile-overview-organizations-card .card-title .card-title-link {
  margin-left: auto;
  color: var(--blue-main);
  cursor: pointer;
}

.profile-overview-organizations-card .profile-orgs-summary-list {
}

.profile-overview-organizations-card
  .profile-orgs-summary-list
  .profile-orgs-summary-list-item {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.profile-overview-organizations-card
  .profile-orgs-summary-list
  .profile-orgs-summary-list-item
  .image {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.profile-overview-organizations-card
  .profile-orgs-summary-list
  .profile-orgs-summary-list-item
  .image
  img {
  width: 100%;
}

.profile-overview-organizations-card
  .profile-orgs-summary-list
  .profile-orgs-summary-list-item
  .desc {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
}

.profile-overview-organizations-card
  .profile-orgs-summary-list-item
  .desc
  .primary-text {
  font-weight: 600;
  line-height: 20px;
  color: var(--dark-1);
}
.profile-overview-organizations-card
  .profile-orgs-summary-list-item
  .desc
  .secondary-text {
  color: var(--dark-4);
}
