.organization-events {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 32px;
}

.organization-events .card-section {
  margin-top: 32px;
}

.organization-events .card-section .card-section-title {
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  margin-bottom: 12px;
  font-weight: 700;
  color: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  border-bottom: solid 1px var(--light-4);
  padding-bottom: 12px;
}

.organization-events .card-section .card-section-title .reset {
  margin-left: auto;
  color: var(--red-lighter);
}

.organization-events .left-column {
  grid-column-start: 1;
  grid-column-end: 9;
}

.organization-events .right-column {
  grid-column-start: 9;
  grid-column-end: 13;
}

.organization-events .right-column .app-card {
  margin-top: 32px;
  padding: 16px;
}

.organization-events .organization-events-list {
  margin-top: 32px;
}

.organization-events .filter-group {
  margin-bottom: 24px;
}

.organization-events.filter-group .filter-group-title {
  font-weight: 700;
  color: var(--dark-2);
  margin-bottom: 8px;
}

.organization-events .filter-group .filter-group-input {
}

.organization-events
  .filter-group
  .filter-group-input
  .app-input
  .input-wrapper {
  background: white;
}

.organization-events .filter-group .filter-group-applied {
  /* padding-top: 16px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.organization-events .filter-group .filter-group-applied .app-chip {
  margin-right: 12px;
  margin-top: 12px;
  cursor: pointer;
  background: var(--blue-main);
  color: var(--light-1);
  padding-right: 4px;
}

.organization-events .no-events-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 32px;
}

.organization-events .no-events-container .text {
  font-size: 16px;
  color: var(--dark-4);
}

.organization-events .show-more-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 32px; */
  padding-bottom: 32px;
}

.organization-events .show-more-container .show-more-button {
  width: 104px;
}
