.manage-event-registrations-mobile {
}

.manage-event-registrations-mobile__header {
  background: white;
  box-shadow: var(--elevation-mobile);
  margin-bottom: 12px;
  height: 36px;
  display: flex;
  flex-direction: row;
  color: var(--dark-3);
}

.manage-event-registrations-mobile__header__selector {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  border-right: solid 1px var(--light-3);
}

.manage-event-registrations-mobile__header__selector__text {
  font-family: var(--nhg-display);
}

.manage-event-registrations-mobile__header__selector__icon {
  margin-left: auto;
}

.manage-event-registrations-mobile__header__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  font-family: var(--nhg-display);
}

.manage-event-registrations-mobile__list {
}

.manage-event-registrations-mobile__list__item {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  box-shadow: var(--elevation-mobile);
  padding: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.manage-event-registrations-mobile__list__item__image {
  height: 48px;
  width: 48px;
  border-radius: 4px;
  background: var(--blue-subtle);
}

.manage-event-registrations-mobile__list__item__text {
  padding-left: 16px;
  font-family: var(--nhg-display);
  flex-grow: 1;
}

.manage-event-registrations-mobile__list__item__text__primary {
  font-weight: 700;
  color: var(--dark-2);
}

.manage-event-registrations-mobile__list__item__text__secondary {
  color: var(--dark-3);
  margin-top: 2px;
}

.manage-event-registrations-mobile__list__item__text__tertiary {
  font-size: 10px;
  color: var(--dark-4);
  margin-top: 2px;
}

.manage-event-registrations-mobile__list__item__edit-btn {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--orange-main);
  margin-left: 16px;
  background: var(--orange-subtle);
  border-radius: 4px;
}

.edit-event-registration-mobile__team-selection-items {
  overflow: auto;
}

.edit-event-registration-mobile__team-selection-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom: solid 1px var(--light-2);
}

.edit-event-registration-mobile__team-selection-item__img {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  background: var(--blue-subtle);
}

.edit-event-registration-mobile__team-selection-item__text {
  padding-left: 16px;
  font-family: var(--nhg-display);
  flex-grow: 1;
}

.edit-event-registration-mobile__team-selection-item__text__primary {
  font-weight: 700;
  color: var(--dark-2);
}

.edit-event-registration-mobile__team-selection-item__text__secondary {
  color: var(--dark-3);
  margin-top: 2px;
}
