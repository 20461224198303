.fixture-videos {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.fixture-videos .upload-btn-container {
  grid-column-start: 3;
  grid-column-end: 11;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.fixture-videos .video-list {
  grid-column-start: 3;
  grid-column-end: 11;
  margin-top: 32px;
}

.add-video-wrapper {
  width: 32%;
  max-height: 90%;
}

.add-video-wrapper .add-video-container {
  padding: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.add-video-wrapper .add-video-container__title {
  text-align: center;
  font-family: var(--nhg-display);
  font-size: 24px;
  font-weight: 700;
}

.add-video-wrapper .add-video-container__secondary-title {
  text-align: center;
  /* font-family: var(--nhg-display); */
  font-size: 14px;
  /* font-weight: 700; */
  margin-top: 16px;
  line-height: 20px;
  color: var(--dark-3);
}

.add-video-wrapper .add-video-container__is-live-stream {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.add-video-wrapper .add-video-container__is-live-stream__label {
  font-size: 14px;
  color: var(--dark-3);
}

.add-video-wrapper .add-video-container__is-live-stream__input {
  margin-left: auto;
}

.add-video-wrapper .add-video-container__options {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 24px;
}

.add-video-wrapper .add-video-container__option {
  display: flex;
  flex-direction: row;
  padding: 16px;
  align-items: center;
  box-shadow: var(--elevation-3);
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.add-video-wrapper .add-video-container__option:hover {
  background: var(--blue-main);
  color: white;
}

.add-video-wrapper .add-video-container__option .icon {
  stroke: var(--dark-4);
  width: 40px;
}

.add-video-wrapper .add-video-container__option .desc {
  flex-grow: 1;
  text-align: center;
  font-weight: 700;
}

.add-video-wrapper .add-video-container__link-input {
  margin-top: 24px;
  height: 32px;
  margin-bottom: 24px;
  border: solid 1px var(--light-4);
  padding-left: 12px;
  border-radius: 8px;
  outline: none;
}

.add-video-wrapper .add-video-container__link-input:focus {
  border: solid 1px var(--blue-main);
}

.fixture-videos__pinner {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 4px;
}

.fixture-videos__pinner__btn {
  font-size: 10px;
  font-weight: 600;
  color: var(--light-4);
  cursor: pointer;
}
