.event-roles-mobile {
  padding: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.event-roles-mobile__wrapper {
}

.event-roles-mobile__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.event-roles-mobile__item__image {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  border-radius: 8px;
  overflow: hidden;
  display: flex;

  align-items: center;
  justify-content: center;
}

.event-roles-mobile__item__image img {
  max-height: 100%;
  max-width: 100%;
}

.event-roles-mobile__item__text {
  padding-left: 16px;
}

.event-roles-mobile__item__text__primary {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 700;
  color: var(--dark-2);
  margin-bottom: 4px;
}

.event-roles-mobile__item__text__secondary {
  font-family: var(--nhg);
  /* font-style: normal; */
  /* font-weight: 700; */
  color: var(--dark-4);
}
