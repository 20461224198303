.organization-overview__about {
  padding: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.organization-overview__about__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-2);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.organization-overview__about__desc {
  margin-top: 10px;
  font-family: var(--nhg);
  /* font-style: normal; */
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* or 167% */

  /* Dark / Dark 2 */

  color: var(--dark-2);
}

.organization-overview__about__tags {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.organization-overview__about__tags__tag {
  height: 20px;
  border-radius: 4px;
  background: var(--blue-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 12px;
  margin-bottom: 8px;
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 10px;
  /* line-height: 14px; */
  /* identical to box height, or 140% */

  letter-spacing: 0.2px;

  /* Primary/Main */

  color: var(--blue-main);
}

.organization-overview__about__tags__tag.edit {
  background: var(--orange-subtle);
  color: var(--orange-main);
}
