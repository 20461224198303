.directory-page-mobile__list__item {
  background: white;
  /* border-top: solid 1px var(--light-2); */
  /* border-bottom: solid 1px var(--light-2); */
  padding: 16px;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  box-shadow: var(--elevation-mobile);
}

.directory-page-mobile__list__item__image {
  height: 60px;
  width: 60px;
  border-radius: 8px;
  background: var(--blue-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
}

.directory-page-mobile__list__item__image img {
  width: 100%;
}

.directory-page-mobile__list__item__image.black {
  background: var(--dark-1);
}

.directory-page-mobile__list__item__text {
  padding-left: 16px;
}

.directory-page-mobile__list__item__text__primary {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 700;
  font-size: 14px;
  /* line-height: 22px; */
  /* or 183% */

  /* display: flex; */
  /* align-items: center; */

  color: var(--dark-1);
}

.directory-page-mobile__list__item__text__secondary {
  font-family: var(--nhg);
  /* font-style: normal; */
  font-weight: 400;
  font-size: 10px;
  /* line-height: 22px; */
  /* or 220% */

  /* display: flex; */
  /* align-items: center; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 6px;
}

.directory-page-mobile__list__item__text__secondary .meta-icon {
  height: 12px;
  width: 12px;
  stroke: var(--dark-4);
  margin-top: 1px;
  flex-shrink: 0;
  margin-right: 8px;
}
