.ScoringApp-football__scoring__form {
  background: white;
  width: 32%;
  height: 80%;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ScoringApp-football__scoring__form__header {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--blue-darker);
  color: white;
  font-weight: 600;
  padding-left: 16px;
  padding-right: 8px;
  flex-shrink: 0;
}

.ScoringApp-football__scoring__form__header__title {
}

.ScoringApp-football__scoring__form__header__dismiss {
  margin-left: auto;
  cursor: pointer;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScoringApp-football__scoring__form__meta {
  height: 40px;
  border-bottom: solid 1px var(--light-2);
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
  flex-shrink: 0;
}

.ScoringApp-football__scoring__form__meta__label {
  font-size: 10px;
}

.ScoringApp-football__scoring__form__meta__editor {
  margin-left: auto;
}

.ScoringApp-football__scoring__form__meta__editor select {
  background: var(--light-2);
  border-radius: 4px;
  border: none;
  height: 28px;
  padding-left: 8px;
  padding-right: 4px;
  outline: none;
}

.ScoringApp-football__scoring__form__meta__editor input {
  background: var(--light-2);
  border-radius: 4px;
  border: none;
  height: 28px;
  outline: none;
  width: 64px;
  padding-left: 8px;
}

.ScoringApp-football__scoring__form__select-player {
  flex-grow: 1;
  /* background: var(--blue-subtlest); */
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ScoringApp-football__scoring__form__select-player__tabs {
  height: 40px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 1px;
  background: var(--light-2);
  border-bottom: solid 1px var(--light-2);
  flex-shrink: 0;
}

.ScoringApp-football__scoring__form__select-player__tabs__tab {
  height: 100%;
  /* width: 10%; */
  border-bottom: solid 2px transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  cursor: pointer;
  background: white;
}

.ScoringApp-football__scoring__form__select-player__tabs__tab.selected {
  border-bottom-color: var(--blue-main);
}

.ScoringApp-football__scoring__form__select-player__list {
  flex-grow: 1;
  overflow: auto;
}

.ScoringApp-football__scoring__form__select-player__list__item {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.ScoringApp-football__scoring__form__select-player__list__item:hover {
  background: var(--blue-subtlest);
}

.ScoringApp-football__scoring__form__select-player__list__item__img {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.ScoringApp-football__scoring__form__select-player__list__item__name {
}

.ScoringApp-football__scoring__form__select-player__list__item__name__primary {
  font-weight: 700;
  color: var(--dark-2);
}
.ScoringApp-football__scoring__form__select-player__list__item__name__secondary {
  color: var(--dark-4);
}

.ScoringApp-football__scoring__form__select-player__list__item__num {
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  font-size: 12px;
  color: var(--dark-4);
}

.ScoringApp-football__scoring__form__saved {
  display: flex;
  flex-direction: row;
  padding: 16px;
}

.ScoringApp-football__scoring__form__saved__timestamp {
  width: 28px;
  flex-shrink: 0;
}

.ScoringApp-football__scoring__form__saved__statement {
}

.ScoringApp-football__scoring__form__saved__statement.skipped {
  font-style: italic;
  color: var(--dark-4);
}

.ScoringApp-football__scoring__form__saved__statement .bold {
  font-weight: 700;
}

.ScoringApp-football__scoring__form__secondary-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  padding-left: 16px;
  padding-right: 16px;
}

.ScoringApp-football__scoring__form__secondary-header__title {
  font-weight: 600;
  color: var(--dark-3);
}

.ScoringApp-football__scoring__form__secondary-header__skip {
  margin-left: auto;
  font-weight: 700;
  color: var(--blue-main);
  cursor: pointer;
}
