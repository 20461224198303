.app-chip {
  min-height: 20px;
  border-radius: 20px;
  box-shadow: var(--elevation-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 600;
  font-size: 10px;
}
