.stats-page {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 16px;
  padding-bottom: 48px;
}

.stats-page .success-record {
  margin-top: 40px;
  grid-column-start: 1;
  grid-column-end: 5;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.stats-page .success-record .total-games-card {
  height: 160px;
  width: 160px;
  /* background: var(--blue-subtle); */
  position: relative;
  color: var(--blue-main);
}

.stats-page .success-record .total-games-card .label {
  font-family: var(--nhg-display);
  color: var(--blue-lighter);
  /* color: var(--light-4); */
  font-weight: 600;
}

.stats-page .success-record .total-games-card .value {
  font-family: var(--din);
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-2);
}

.stats-page .success-record .record-items {
  flex-grow: 1;
  padding-left: 48px;
}

.stats-page .success-record .record-items .record-item {
  margin-bottom: 16px;
}

.stats-page .success-record .record-items .record-item .text {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  font-size: 14px;
  color: var(--dark-3);

  font-weight: 700;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 8px;
}

.stats-page .success-record .record-items .record-item .label {
  flex-grow: 1;
  font-size: 12px;
}

.stats-page .success-record .record-items .record-item .value {
  font-family: var(--nhg-display);
  /* font-family: var(--din); */
  font-weight: 700;
}

.stats-page .success-record .record-items .record-item.won .value {
  color: var(--green-main);
}

.stats-page .success-record .record-items .record-item.drawn .value {
  color: var(--dark-4);
}

.stats-page .success-record .record-items .record-item.lost .value {
  color: var(--red-main);
}

.stats-page .success-record .record-items .record-item .line-chart {
  height: 12px;
  background: var(--light-1);
  border-radius: 8px;
  overflow: hidden;
}

.stats-page .success-record .record-items .record-item .line-chart .fill {
  height: 100%;
}

.stats-page .success-record .record-items .record-item.won .line-chart .fill {
  background: var(--green-lighter);
}

.stats-page .success-record .record-items .record-item.drawn .line-chart .fill {
  background: var(--light-4);
}

.stats-page .success-record .record-items .record-item.lost .line-chart .fill {
  background: var(--red-lighter);
}

.stats-page .success-record .total-games-card {
  padding: 16px;
  position: relative;
}

.stats-page .success-record .total-games-card .label {
  font-weight: 700;
}

.stats-page .success-record .total-games-card .value {
  position: absolute;
  left: 16px;
  bottom: 16px;
  font-family: var(--din);
  font-size: 48px;
  font-weight: 600;
}

.stats-page .shots-summary-new {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  grid-column-start: 1;
  grid-column-end: 5;
  margin-top: 32px;
  /* display: grid; */
  /* grid-template-columns: repeat(12, minmax(0, 1fr)); */
  /* grid-column-gap: 0; */
}

.stats-page .shots-summary-new .graphic {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding: 24px;
}

.stats-page .shots-summary-new .shots-summary-list {
  /* flex-grow: 1; */
  /* grid-column-start: 5; */
  /* grid-column-end: 10; */
  margin-left: 48px;
  flex-grow: 1;
}

.stats-page .shots-summary-new .shots-summary-list .summary-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 16px;
}

.stats-page .shots-summary-new .shots-summary-list .summary-item:last-child {
  margin-bottom: 0;
}

.stats-page .shots-summary-new .shots-summary-list .summary-item .legend {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  margin-right: 16px;
}

.stats-page .shots-summary-new .shots-summary-list .summary-item .label {
  color: var(--dark-3);
  font-weight: 700;
}

.stats-page .shots-summary-new .shots-summary-list .summary-item .value {
  margin-left: auto;
  font-weight: 700;
  /* color: var(--blue-main); */
}

.stats-page .shots-summary-new .concentric-chart {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  position: relative;
  grid-column-start: 1;
  grid-column-end: 5;
  height: 112px;
  width: 112px;
}

.stats-page .shots-summary-new .concentric-chart .circle {
  position: absolute;
  border-radius: 100%;
}

.stats-page .shots-summary-new .shot-rates {
  /* grid-column-start: 10; */
  /* grid-column-end: 13; */
  /* display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; */
  margin-left: 32px;
  width: 184px;
}

.shot-rates__item {
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
}

.shot-rates__item__value {
  font-size: 32px;
  font-family: var(--din);
  font-weight: 600;
  margin-right: 16px;
}

.shot-rates__item:first-child {
  margin-bottom: 20px;
}

.shot-rates__item__label {
  font-size: 10px;
  flex-grow: 1;
  padding-right: 16px;
  /* padding-right: 50%; */
}

/* .shot-rates__item:last-child {
  margin-top: 10px;
} */

.stats-page .shots-summary-new .shot-rates .vertical-bar-chart {
  /* width: px; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
}

.stats-page .shots-summary-new .shot-rates .vertical-bar-chart .value {
  font-weight: 700;
}

.stats-page .shots-summary-new .shot-rates .vertical-bar-chart .bar {
  height: 24px;
  width: 4px;
  background: var(--light-2);
  margin-bottom: 12px;
  margin-top: 12px;
  border-radius: 8px;
  position: relative;
}

.stats-page .shots-summary-new .shot-rates .vertical-bar-chart .bar .fill {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 8px;
}

.stats-page .shots-summary-new .shot-rates .vertical-bar-chart .label {
  text-align: center;
  font-size: 10px;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 700;
  color: var(--dark-2);
  letter-spacing: 0.4px;
}

.stats-page .team-form {
  grid-column-start: 1;
  grid-column-end: 5;
  margin-top: 32px;
  overflow: hidden;
  /* padding: 16px; */
}

.stats-page .team-form .section-title {
  /* margin-bottom: 8px; */
  font-family: var(--nhg-display);
  color: var(--dark-2);
  font-weight: 700;
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.stats-page .team-form .section-title .text-form {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: auto;
}

.stats-page .team-form .section-title .text-form .text-form-item {
  margin-left: 8px;
}

.stats-page .team-form .section-title .text-form .text-form-item.win {
  color: var(--green-main);
}

.stats-page .team-form .section-title .text-form .text-form-item.draw {
  color: var(--dark-4);
}

.stats-page .team-form .section-title .text-form .text-form-item.loss {
  color: var(--red-darker);
}

.stats-page .team-form .card-content {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.stats-page .team-form .card-content .card-chart {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.stats-page .team-form .card-content .card-chart .line-chart {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  flex-grow: 1;
}

.stats-page .team-form .card-content .card-chart .line-chart canvas {
  height: 100%;
  width: 100%;
}

.stats-page .team-form .card-content .prev-next {
  width: 36%;
  flex-shrink: 0;
  background: var(--blue-subtle);
}

/* NEW DESIGN */

/* Record */
.organization-record {
  /* margin: 16px; */
  margin-top: 32px;
  /* display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr)); */
  display: flex;
  flex-direction: row;
  /* grid-column-gap: 12px; */
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.organization-record__meta {
  /* grid-column-start: 1;
  grid-column-end: 2; */
  width: 25%;
  display: flex;
  flex-direction: column;
  border-right: solid 1px var(--light-2);
}

.organization-record__meta__title {
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
}

.organization-record__meta__highlight {
  padding: 16px;
  margin-top: auto;
}

.organization-record__meta__highlight__value {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-1);
}

.organization-record__meta__highlight__label {
  font-family: var(--nhg-display);
  font-weight: 600;
  font-size: 12px;
  color: var(--dark-4);
  margin-top: 8px;
}

.organization-record__items {
  width: 75%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 16px; */
}

.organization-record__items__item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.organization-record__items__item__value {
  font-family: var(--nhg-display);
  font-weight: 700;
  /* font-size: 24px; */
  color: var(--dark-1);
  /* margin-bottom: 8px; */
  width: 54px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.organization-record__items__item__graph {
  /* height: 120px; */
  width: 100%;
  flex-grow: 1;
}

.organization-record__items__item__graph__track {
  height: 8px;
  width: 100%;
  background: var(--light-3);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.organization-record__items__item__graph__fill {
  width: 50%;
  height: 8px;
  border-radius: 4px;
  background: linear-gradient(75.26deg, #78c9fc 18.6%, #63ea47 100%);
}

.organization-record__items__item__label {
  font-family: var(--nhg-display);
  font-weight: 600;
  font-size: 12px;
  color: var(--dark-4);
  width: 54px;
  /* margin-top: 8px; */
}

/* Form */

.organization-form {
  /* margin: 16px; */
  margin-top: 32px;
  /* display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr)); */
  /* display: flex;
  flex-direction: row; */
  grid-column-gap: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  grid-column-start: 3;
  grid-column-end: 5;
}

.organization-form__title {
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
  grid-column-start: 1;
  grid-column-end: 5;
}

.organization-form__items {
  grid-column-start: 1;
  grid-column-end: 5;
}

.organization-form__items__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.organization-form__items__item__meta {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  padding-left: 16px;
}

.organization-form__items__item__meta__image {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.organization-form__items__item__meta__image img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.organization-form__items__item__meta__text {
}

.organization-form__items__item__meta__text__primary {
  font-weight: 700;
  color: var(--dark-1);
}

.organization-form__items__item__meta__text__secondary {
  font-weight: 400;
  color: var(--dark-4);
  font-size: 10px;
  margin-top: 2px;
}

.organization-form__items__item__result {
  width: 25%;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: var(--nhg-display);
}

.organization-form__items__item__result__text {
  font-weight: 700;
}

.organization-form__items__item__result__score {
}

.organization-form__items__item__result__text.green {
  color: var(--green-main);
}

.organization-form__items__item__result__text.orange {
  color: var(--orange-main);
}

.organization-form__items__item__result__text.red {
  color: var(--red-main);
}
