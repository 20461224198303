.directory-categories {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  flex-wrap: nowrap;
  padding-top: 4px;
  padding-bottom: 24px;
  /* margin-bottom: 16px; */
}

.directory-categories .directory-category {
  padding: 12px;
  margin-right: 16px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  border-radius: 24px;
  color: var(--dark-4);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-weight: 100;
}

.directory-categories .directory-category.selected {
  background: var(--blue-darker);
  color: white;
  font-weight: 600;
}
