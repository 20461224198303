.app-slider {
}

.app-slider input {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 4px;
  background: var(--blue-subtle);
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.app-slider input:hover {
  opacity: 1;
}

.app-slider input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: white;
  cursor: pointer;
  box-shadow: var(--elevation-3);
}

.app-slider input::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: white;
  cursor: pointer;
  box-shadow: var(--elevation-3);
}
