.profile-overview-gallery-card {
  padding: 0;
}

.profile-overview-gallery-card .gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: 120px 120px;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  /* padding: 16px; */
  padding-bottom: 16px;
  margin-top: 16px;
}

.profile-overview-gallery-card .gallery-grid .gallery-grid-item {
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  overflow: hidden;
  /* margin-bottom: 16px; */
}

.profile-overview-gallery-card .gallery-grid .gallery-grid-item img {
  width: 100%;
}

.profile-overview-gallery-card .profile-overview-gallery-card-footer {
  /* margin-top: 16px; */
  padding: 8px;
  border-top: solid 1px var(--light-2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--dark-4);
}
