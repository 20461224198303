.fixture-stats-cricket-wrapper {
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 40px;
  padding-bottom: 40px;
}

.fixture-stats-cricket {
}

.fixture-stats-cricket__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  /* padding-top: 12px; */
  /* padding-bottom: 12px; */
}

.fixture-stats-cricket__header__title {
  font-size: 16px;
  font-weight: 700;
  font-family: var(--nhg-display);
}

.fixture-stats-cricket__header__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.fixture-stats-cricket__header__options__option {
  margin-left: 12px;
  height: 24px;
  border: solid 1px var(--light-3);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  color: var(--dark-3);
  font-size: 10px;
  cursor: pointer;
}

.fixture-stats-cricket__header__options__option:hover {
  background: var(--light-2);
}

.fixture-stats-cricket__header__options__option.selected {
  background: var(--blue-darker);
  font-weight: 600;
  color: var(--light-1);
  border: none;
}

.fixture-stats-cricket__body {
}

.fixture-stats-cricket__table {
}

.fixture-stats-cricket__table__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  background: var(--light-2);
  padding-left: 16px;
  padding-right: 16px;
  font-size: 10px;
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-2);
}

.fixture-stats-cricket__table__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: solid 1px var(--light-2);
  /* font-size: 10px; */
  /* font-family: var(--nhg-display); */
  /* font-weight: 600; */
}

.fixture-stats-cricket__table__row__td {
  flex-shrink: 0;
  /* display: flex;
  flex-direction: row;
  align-items: center; */
}

.fixture-stats-cricket__table__row__td__img {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  overflow: hidden;
  background: var(--blue-subtle);
  margin-right: 12px;
}

.fixture-stats-cricket__table__legend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 8px;
  padding-bottom: 8px;
}

.fixture-stats-cricket__table__legend__item {
  font-size: 10px;
  font-weight: 500;
  color: var(--dark-4);
  margin-left: 16px;
  margin-top: 8px;
  /* font-family: var(--nhg-display); */
}
