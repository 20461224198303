.event-fixtures {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 32px;
}

.event-fixtures .left-column {
  grid-column-start: 1;
  grid-column-end: 9;
}

.event-fixtures .left-column .event-fixtures-header {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 32px;
  margin-bottom: 16px;
}

.event-fixtures .left-column .event-fixtures-list {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
}

.event-fixtures
  .left-column
  .event-fixtures-list
  .event-fixture-card-container {
  width: 50%;
  padding-right: 32px;
}

.event-fixtures .left-column .event-fixtures-list .event-fixture-card {
  padding: 16px;
  margin-top: 32px;
}

.event-fixtures .right-column {
  grid-column-start: 9;
  grid-column-end: 13;
}

.event-fixtures .card-section {
  margin-top: 32px;
}

.event-fixtures .card-section .card-section-title {
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  margin-bottom: 16px;
  font-weight: 700;
  color: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  border-bottom: solid 1px var(--light-4);
  padding-bottom: 12px;
}

.event-fixtures .card-section .card-section-title .reset {
  margin-left: auto;
  color: var(--red-lighter);
}

.event-fixtures .event-fixture-card {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.event-fixtures .event-fixture-card .container {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.event-fixtures .event-fixture-card .container .logo {
  height: 24px;
  width: 24px;
  border-radius: 8px;
  background: var(--blue-subtle);
}

.event-fixtures .event-fixture-card .container .score {
  font-size: 16px;
  /* color: var(--blue-main); */
}

.event-fixtures .event-fixture-card .container .desc {
  padding-left: 8px;
  padding-right: 8px;
  color: var(--dark-1);
  /* font-family: "Inter", sans-serif; */
  /* font-weight: 600; */
}

.event-fixtures .event-fixture-card .container.left {
  justify-content: flex-start;
  width: 45%;
}

.event-fixtures .event-fixture-card .container.left .score {
  margin-left: auto;
  margin-right: 8px;
}

.event-fixtures .event-fixture-card .container.right .score {
  margin-right: auto;
  margin-left: 8px;
}

.event-fixtures .event-fixture-card .container.middle {
  width: 10%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: var(--dark-3);
  font-weight: 600;
  letter-spacing: 0.2px;
}

.event-fixtures .event-fixture-card .container.right {
  width: 45%;
  justify-content: flex-end;
}

.event-fixtures .event-parts-list {
}

.event-fixtures .event-parts-list .event-part {
  margin-bottom: 32px;
}

.event-fixtures .event-part-title {
  margin-right: 32px;
  font-weight: 600;
  background: var(--blue-subtle);
  color: var(--light-1);
  margin-top: 32px;
  height: 24px;
  border-radius: 8px;
  color: var(--blue-darker);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.event-fixtures .filter-group {
  margin-bottom: 24px;
}

.event-fixtures .filter-group .filter-group-title {
  font-weight: 700;
  color: var(--dark-2);
  margin-bottom: 8px;
}

.event-fixtures .filter-group .filter-group-input {
}

.event-fixtures .filter-group .filter-group-input .app-input .input-wrapper {
  background: white;
}

.event-fixtures .filter-group .filter-group-applied {
  /* padding-top: 16px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.event-fixtures .filter-group .filter-group-applied .app-chip {
  margin-right: 12px;
  margin-top: 12px;
  cursor: pointer;
  background: var(--blue-main);
  color: var(--light-1);
  padding-right: 4px;
}
