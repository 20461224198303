.fixture-stats-mobile__cricket {
}

.fixture-stats-scorecard-mobile {
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  margin-top: 12px;
  padding-bottom: 24px;
}

.fixture-stats-scorecard-mobile__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  /* background: var(--blue-darker); */
  padding-top: 8px;
  padding-bottom: 8px;
}

.fixture-stats-scorecard-mobile__title__image {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.fixture-stats-scorecard-mobile__title__image img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
}

.fixture-stats-scorecard-mobile__title__text {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;

  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-2);
  /* color: white; */
}

.fixture-stats-scorecard-mobile__table {
}

.fixture-stats-scorecard-mobile__table__header {
  background: var(--light-2);
}

.fixture-stats-scorecard-mobile__table__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-stats-scorecard-mobile__table__td {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 44px;
  border-bottom: solid 1px var(--light-2);
}

.fixture-stats-scorecard-mobile__table__td:first-child {
  align-items: flex-start;
}

.fixture-stats-scorecard-mobile__table__td__line {
  font-size: 10px;
}

.fixture-stats-scorecard-mobile__table__header
  .fixture-stats-scorecard-mobile__table__td {
  height: 24px;
  font-size: 10px;
  font-family: var(--nhg-display);
  letter-spacing: 1px;
}

.fixture-stats-scorecard-mobile__table__td__line {
}

.fixture-stats-scorecard-mobile__table__body {
}

.fixture-stats-scorecard-mobile__info {
  padding: 12px;
  font-size: 10px;
}

.fixture-stats-scorecard-mobile__info__item {
}

.fixture-stats-scorecard-mobile__info__item .bold {
  font-weight: 600;
}

.fixture-stats-scorecard-mobile__legend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 8px;
  padding-bottom: 16px;
}

.fixture-stats-scorecard-mobile__legend__item {
  font-size: 10px;
  font-weight: 500;
  color: var(--dark-4);
  margin-left: 16px;
  margin-top: 8px;
  /* font-family: var(--nhg-display); */
}
