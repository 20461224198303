.mobile-auth-page__wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.mobile-auth-page {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  /* grid-template-rows: 100%; */
  padding-left: 24px;
  padding-right: 24px;
}

.mobile-auth-page__logo {
  grid-column-start: 1;
  grid-column-end: 5;
  margin-top: 24px;
}

.mobile-auth-page__logo img {
}

.mobile-auth-page__graphic {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
}

.mobile-auth-page__graphic img {
  width: 100%;
}

.mobile-auth-page__logo-bottom {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-auth-page__logo-bottom img {
}
