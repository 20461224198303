.app-party-list__body__item__options__admin-option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.app-party-list__body__item__options__admin-option .label {
  margin-right: 8px;
  font-size: 10px;
  color: var(--dark-4);
}

.app-party-list__header__back {
  position: absolute;
  left: 16px;
  top: 16px;
  stroke: var(--dark-4);
  cursor: pointer;
}

.app-party-list__footer {
  padding: 16px;
}

.app-party-list__body__input {
  padding: 16px;
}

.app-party-list__body__input .search {
  /* width: 320px; */
  grid-column-start: 2;
  grid-column-end: 3;
  position: relative;
  /* z-index: 10; */
  /* display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center; */
  /* flex-grow: 1; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* position: absolute; */
  /* width: 50%; */
  /* margin-right: auto; */
}

.app-party-list__body__input .search .search-wrapper {
  /* width: 100%; */
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  position: relative;
}

.app-party-list__body__input .search .search-wrapper .app-input {
  width: 100%;
  /* background: var(--blue-subtlest); */
  border-radius: 8px;
}

.app-party-list__body__input .input-wrapper {
  box-shadow: none;
  border: solid 1px var(--light-3);
  /* box-sizing: border-box; */
  height: 32px;
  /* border-radius: 4px; */
}

.app-party-list__body__input .search .app-search-menu {
  /* width: 100%; */
  width: 100%;
  box-shadow: var(--elevation-5);
  margin-top: 4px;
}

.app-party-list__body__input .search .app-search-menu .search-results-section {
  /* padding: 16px;
    padding-top: 8px;
    padding-bottom: 8px; */
  padding-top: 8px;
}

.app-party-list__body__input
  .search
  .app-search-menu
  .search-results-section__title {
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: var(--dark-4);
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
}

.app-party-list__body__input
  .search
  .app-search-menu
  .search-results-section__items {
}

.app-party-list__body__input
  .search
  .app-search-menu
  .search-results-section__items__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 16px; */
  transition: all 0.2s ease-out;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  color: var(--dark-1);
  /* font-weight: 700; */
}

.app-party-list__body__input
  .search
  .app-search-menu
  .search-results-section__items__item:hover {
  background: var(--light-2);
}

.app-party-list__body__input
  .search
  .app-search-menu
  .search-results-section__items__item__image {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
  overflow: hidden;
}

.app-party-list__body__input
  .search
  .app-search-menu
  .search-results-section__items__item__desc {
}

.app-party-list__body__input
  .search
  .app-search-menu
  .search-results-section__items__item__desc__primary {
  font-weight: 600;
  color: var(--dark-2);
}

.app-party-list__body__input
  .search
  .app-search-menu
  .search-results-section__items__item__desc__secondary {
  color: var(--dark-3);
  font-size: 10px;
}

.app-party-list .role-selection-wrapper {
}

.app-party-list .role-selection-wrapper .selected-role {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
}

.app-party-list .role-selection-wrapper .role-selection-menu {
  margin-top: -12px;
}

.app-party-list
  .role-selection-wrapper
  .role-selection-menu
  .role-selection-menu-item {
  padding: 8px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  cursor: pointer;
}

.app-party-list
  .role-selection-wrapper
  .role-selection-menu
  .role-selection-menu-item:hover {
  background: var(--light-2);
}

.app-party-list__body__item__options {
  display: flex;
  flex-direction: row;

  align-items: center;
}

.app-party-list__body__item__options__delete-option {
  /* width: 24px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  cursor: pointer;
}

.delete-org-member-menu {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-org-member-menu .desc {
  margin-bottom: 8px;
}
