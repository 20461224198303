.organization-record-mobile__wrapper {
  margin: 16px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.profile-shooting-stats-mobile__doughnut__wrapper {
  border-top: solid 1px var(--light-2);
}
