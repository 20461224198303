.organization-posts-mobile .app-match-report {
  margin-top: 16px;
  margin-bottom: 0;
}

.organization-posts-mobile
  .app-match-report
  .app-match-report__fixture__team__name {
  font-size: 12px;
}

.organization-posts-mobile .app-match-report .app-match-report__headline {
  font-size: 16px;
}
