.app-uploader-wrapper {
  width: 32%;
  max-height: 90%;
}

.app-uploader {
  padding: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.app-uploader .title {
  text-align: center;
  font-family: var(--nhg-display);
  font-size: 24px;
  font-weight: 700;
}

.app-uploader .input-container {
  position: relative;
  height: 200px;
  border: dashed 1px var(--blue-lighter);
  border-radius: 8px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: var(--blue-subtle); */
  flex-shrink: 0;
  cursor: pointer;
}

.app-uploader .input-container .input-icon {
}

.app-uploader .input-container .input-placeholder {
  color: var(--dark-4);
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 8px;
}

.app-uploader .input-container .input-seperator {
  color: var(--dark-4);
  font-weight: 700;
  margin-bottom: 8px;
}

.app-uploader .input-container .browse-files-btn {
  margin-top: 8px;
  margin-bottom: 8px;
}

.app-uploader .input-container input {
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.app-uploader .input-container input:focus {
  outline: none;
}

.app-uploader-size-error {
  text-align: center;
  margin-top: 8px;
  color: var(--red-main);
  font-weight: 700;
}

/* .app-uploader .files-list {
  flex-grow: 1;
  overflow: auto;
}

.app-uploader .files-list .file-item {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  
}

.app-uploader .files-list .file-item .preview {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 8px;
  overflow: hidden;
}

.app-uploader .files-list .file-item .preview img {
  height: 100%;
}

.app-uploader .files-list .file-item .meta {
  flex-grow: 1;
  padding-left: 16px;
}

.app-uploader .files-list .file-item .meta .desc {
  display: flex;
  flex-direction: row;
}

.app-uploader .files-list .file-item .meta .desc .file-name {
  font-weight: 700;
  margin-right: auto;
}

.app-uploader .files-list .file-item .meta .desc .file-size {
  font-weight: 700;
  color: var(--dark-4);
}

.app-uploader .files-list .file-item .meta .progress-container {
}

.app-uploader .files-list .file-item .meta .progress-container .progress-bar {
  height: 8px;
  background: var(--light-2);
  border-radius: 8px;
  margin-top: 16px;
}

.app-uploader
  .files-list
  .file-item
  .meta
  .progress-container
  .progress-bar
  .progress-amount {
  height: 100%;
  border-radius: 8px;
  background: var(--blue-main);
  transition: all 0.2s ease-out;
}

.app-uploader
  .files-list
  .file-item
  .meta
  .progress-container
  .progress-bar
  .progress-amount.complete {
  background: var(--green-main);
}

.app-uploader
  .files-list
  .file-item
  .meta
  .progress-container
  .progress-bar
  .progress-amount.error {
  background: var(--red-main);
}

.app-uploader .files-list .file-item .options {
  padding-left: 16px;
} */

.app-uploader .upload-btn {
  margin-top: 48px;
  flex-shrink: 0;
}
