.ScoringApp-football__publish {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ScoringApp-football__publish__grid {
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 16px;
  /* padding-left: 16px;
  padding-right: 16px; */
}

.ScoringApp-football__publish__grid__container {
  padding-top: 16px;
  width: 100%;
}

.ScoringApp-football__publish__table {
  /* height: 100%; */
  background: white;
  border-radius: 8px;
  /* border-top-right-radius: 8px; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ScoringApp-football__publish__table__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  background: var(--blue-darker);
}

.ScoringApp-football__publish__table__header__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: auto;
}

.ScoringApp-football__publish__table__header__title__logo {
  height: 28px;
  width: 28px;
  border-radius: 4px;
  background: var(--blue-lighter);
}

.ScoringApp-football__publish__table__header__title__text {
  margin-left: 12px;
  color: white;
  font-size: 14px;
  font-weight: 700;
}

.ScoringApp-football__publish__table__header__value {
  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 4px;
  overflow: hidden;
}

.ScoringApp-football__publish__table__header__value__score {
  height: 28px;
  min-width: 32px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
}

.ScoringApp-football__publish__table__header__value__option {
  height: 28px;
  min-width: 32px;
  background: var(--blue-darkest);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
}

.ScoringApp-football__publish__table__header__value__option__icon {
  height: 14px;
  fill: var(--light-2);
}

.ScoringApp-football__publish__table__body {
  flex-grow: 1;
  overflow: hidden;
  display: grid;
  grid-template-columns: 168px 1fr;
  position: relative;
  min-height: 160px;
}

.ScoringApp-football__publish__table__body__overlay {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(46, 52, 56, 0.94);
}

.ScoringApp-football__publish__table__body__overlay__text {
  width: 30%;
  text-align: center;
  color: var(--light-2);
}

.ScoringApp-football__publish__table__body__column {
  border-right: solid 1px var(--light-3);
}

.ScoringApp-football__publish__table__body__column.players {
  /* position: absolute;
  left: 0;
  top: 0; */
}

.ScoringApp-football__publish__table__body__column.stats {
  overflow-x: auto;
}

.ScoringApp-football__publish__table__body__column__header {
}

.ScoringApp-football__publish__table__body__column__row {
  display: flex;
  flex-direction: row;
}

.ScoringApp-football__publish__table__body__column.players
  .ScoringApp-football__publish__table__body__column__row {
  width: 100%;
}

.ScoringApp-football__publish__table__body__column__row__data {
  height: 32px;
  width: 144px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: solid 1px var(--light-2);
  border-bottom: solid 1px var(--light-2);
  flex-shrink: 0;
}

.ScoringApp-football__publish__table__body__column__row__data.highlight {
  background: var(--blue-subtle);
}

.ScoringApp-football__publish__table__body__column.players
  .ScoringApp-football__publish__table__body__column__row__data {
  width: 100%;
}

.ScoringApp-football__publish__table__body__column__header
  .ScoringApp-football__publish__table__body__column__row__data {
  font-weight: 700;
  pointer-events: none;
}

.ScoringApp-football__publish__table__body__column.stats
  .ScoringApp-football__publish__table__body__column__row__data {
  cursor: pointer;
}

.ScoringApp-football__publish__table__body__column.stats
  .ScoringApp-football__publish__table__body__column__row__data:hover {
  background: var(--light-2);
}

.ScoringApp-football__publish__table__body__column__row__data__value {
  width: 100%;
  padding-left: 12px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ScoringApp-football__publish__table__body__column__row__data__value.light {
  color: var(--light-4);
}

.ScoringApp-football__publish__table__body__column__row__data__value.bold {
  font-weight: 700;
}

.ScoringApp-football__publish__table__body__column__row__data__form {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  min-width: 0;
  background: white;
}

.ScoringApp-football__publish__table__body__column__row__data__form__input {
  flex-grow: 1;
  width: calc(100% - 64px);
}

.ScoringApp-football__publish__table__body__column__row__data__form__input
  input {
  height: 100%;
  border: none;
  width: 100%;
  padding-left: 12px;
  outline: none;
}

.ScoringApp-football__publish__table__body__column__row__data__form__btn {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
}

.ScoringApp-football__publish__table__body__column__row__data__form__btn.submit {
}

.ScoringApp-football__publish__table__body__column__row__data__form__btn.cancel {
}

.ScoringApp-football__publish__table__body__column__row__data__form__btn__icon {
  stroke: var(--light-4);
  height: 14px;
}

.ScoringApp-football__publish__table__body__column__row__data__form__btn.submit
  .ScoringApp-football__publish__table__body__column__row__data__form__btn__icon {
  fill: var(--green-main);
}

.ScoringApp-football__publish__table__body__column__row__data__form__btn.cancel
  .ScoringApp-football__publish__table__body__column__row__data__form__btn__icon {
}

.ScoringApp-football__publish__confirm {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ScoringApp-football__publish__confirm__form {
  background: white;
  width: 30%;
  border-radius: 8px;
  overflow: hidden;
}

.ScoringApp-football__publish__confirm__form__header {
  background: var(--blue-darker);
  font-weight: 700;
  height: 32px;
  display: flex;
  align-items: center;
  color: white;
  padding: 16px;
}

.ScoringApp-football__publish__confirm__form__text {
  padding: 16px;
  padding-top: 0;
}

.ScoringApp-football__publish__confirm__form__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  padding-top: 8px;
}

.ScoringApp-football__publish__confirm__form__options .app-button {
  margin-left: 16px;
}

.ScoringApp-football__publish__table__header__form {
  height: 28px;
  display: flex;
  align-items: center;
  /* margin-left: auto; */
  border-radius: 4px;
  overflow: hidden;
}

.ScoringApp-football__publish__table__header__form__input {
  height: 100%;
  width: 64px;
  outline: none;
  border: none;
  padding-left: 8px;
}

.ScoringApp-football__publish__table__header__form__btn {
  height: 28px;
  width: 28px;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-darkest);
}

.ScoringApp-football__publish__table__header__form__btn__icon {
  height: 14px;
  stroke: var(--light-4);
}

.ScoringApp-football__publish__table__header__form__btn.submit {
}

.ScoringApp-football__publish__table__header__form__btn.submit
  .ScoringApp-football__publish__table__header__form__btn__icon {
  fill: var(--green-main);
  stroke: none;
}
