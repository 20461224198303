.ScoringApp-cricket__scoring__modal-form__error-msg {
  padding: 16px;
  line-height: 20px;
}

.ScoringApp-cricket__scoring__modal-form__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
}

.ScoringApp-cricket__scoring__modal-form__options__option {
  margin-left: 16px;
}

.ScoringApp-cricket__scoring__modal-form__options__option.cancel {
  background: var(--light-2);
  color: var(--dark-4);
}
