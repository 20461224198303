.stats-page {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 16px;
  padding-bottom: 48px;
}

.stats-page .summary-blocks {
  grid-column-start: 1;
  grid-column-end: 5;
  display: grid;
  grid-template-columns: 24px repeat(5, minmax(0, 1fr));
  grid-column-gap: 16px;
  margin-top: 32px;
}

.stats-page .summary-blocks .summary-blocks-label {
  background: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-end;
  /* justify-content: center; */
  color: white;
  /* font-weight: 600; */
  border-radius: 4px;
  position: relative;
}

.stats-page .summary-blocks .summary-blocks-label .vertical-text {
  /* transform: rotateY(-90deg); */
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */
  transform-origin: 0 0;
  font-size: 10px;
  letter-spacing: 1px;
  font-family: var(--nhg);
  font-weight: 700;
  transform: rotate(270deg);
  margin: 0;
  margin-left: 5px;
  margin-bottom: -4px;
}

.stats-page .summary-blocks .summary-block {
  /* margin-top: 16px; */
  padding: 16px;
}

.stats-page .summary-blocks .summary-block .value {
  font-family: var(--din);
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-2);
}

.stats-page .summary-blocks .summary-block .label {
  font-family: var(--nhg-display);
  color: var(--blue-lighter);
  /* color: var(--light-4); */
  font-weight: 600;
}

.stats-page .participation-history {
  grid-column-start: 1;
  grid-column-end: 5;
  margin-top: 32px;
  /* padding: 16px; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  /* padding-bottom: 16px; */
}

.stats-page .participation-history .title {
  font-weight: 700;
  color: var(--dark-4);
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.stats-page .participation-history .participation-table {
  flex-grow: 1;
}

.stats-page .participation-history .participation-table .table {
  margin-top: 8px;
  width: 100%;
  font-size: 12px;

  /* padding: 8px; */
}

.stats-page .participation-history .participation-table .table .tr {
  /* height: 24px; */
  padding-top: 12px;
  padding-bottom: 12px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  /* padding-top: 16px; */
  /* padding-bottom: 16px; */
  padding-left: 8px;
  padding-right: 8px;
}

.stats-page .participation-history .participation-table .table .tr.even {
  background: var(--blue-subtlest);
}

.stats-page .participation-history .participation-table .table .tr.header-row {
  border-bottom: solid 2px var(--light-2);
}
.stats-page .participation-history .participation-table .table .th {
  text-align: left;
  font-family: var(--nhg);
  font-weight: 600;
  color: var(--dark-1);
  padding-right: 8px;
  padding-left: 8px;
  /* border-bottom: solid 1px var(--light-4); */
}

.stats-page .participation-history .participation-table .table .td {
  /* border: solid 1px black; */
  /* font-size: 12p  x; */

  /* padding-right: 8px; */
  /* padding-right: 8px; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;

  padding-right: 8px;
  padding-left: 8px;
}

.stats-page .participation-history .participation-table .table .td .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--dark-3);
}

.stats-page
  .participation-history
  .participation-table
  .table
  .td
  .table-row-image {
  height: 20px;
  width: 20px;
  background: var(--blue-subtle);
  border-radius: 100%;
  margin-right: 8px;
  flex-shrink: 0;
  display: inline-block;
}

.stats-page
  .participation-history
  .participation-table
  .table
  .td
  .table-row-image
  img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.stats-page .participation-history .participation-table .table .td.num-data {
  /* font-family: var(--din); */
  /* text-align: right; */
  /* min-width: 28px; */
  justify-content: flex-end;
}

.stats-page
  .participation-history
  .participation-table
  .table
  table
  th.num-data {
  /* font-family: var(--din); */
  text-align: right;
  /* min-width: 24px; */
}

.stats-page .participation-history .participation-table .table .tfoot {
  border-top: solid 2px var(--light-2);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.stats-page .participation-history .participation-table .table .tfoot .filter {
}

.stats-page
  .participation-history
  .participation-table
  .table
  .tfoot
  .filter
  .team-selector {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 4px;
  width: 160px;
  border: solid 1px var(--light-3);
  stroke: var(--dark-3);
  border-radius: 4px;
  height: 24px;
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-4);
}

.stats-page
  .participation-history
  .participation-table
  .table
  .tfoot
  .filter
  .team-selector
  .icon {
  transform: rotate(-90deg);
  margin-left: auto;
}

.stats-page
  .participation-history
  .participation-table
  .table
  .tfoot
  .pagination {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  stroke: var(--blue-main);
  margin-left: auto;
}

.stats-page
  .participation-history
  .participation-table
  .table
  .tfoot
  .pagination
  .page-nums {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  font-weight: 700;
  color: var(--light-4);
}

.stats-page
  .participation-history
  .participation-table
  .table
  .tfoot
  .pagination
  .page-nums
  .page-num {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: -4px;
}

.stats-page
  .participation-history
  .participation-table
  .table
  .tfoot
  .pagination
  .page-nums
  .page-num.active {
  color: var(--dark-1);
}

.stats-page .pitch-position-container {
  grid-column-start: 3;
  grid-column-end: 5;
  margin-top: 32px;
  padding: 16px;
  /* flex-shrink: 0; */
  /* width: 220px; */
  /* margin-right: 16px; */
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.stats-page .pitch-position-container .marker {
  opacity: 0.5;
}

.stats-page .pitch-position-container .card-title {
  font-weight: 700;
  color: var(--dark-4);
}

.stats-page .pitch-position-container .pitch {
  flex-grow: 1;
  background: var(--green-subtle);
  /* margin-left: 16px; */
  margin-top: 8px;
}

.stats-page .radar-chart {
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 32px;
}

.stats-page .shots-summary {
  grid-column-start: 1;
  grid-column-end: 4;
  margin-top: 32px;
  /* padding: 16px; */
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 16px;
}

.stats-page .shots-summary .graphic {
  /* height: 200px; */

  /* border: solid 4px var(--light-4); */
  /* border-bottom: none; */
  position: relative;
  /* flex-grow: 1; */
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 16px;
  padding-right: 0;
}

.stats-page .shots-summary .graphic .total-shots {
  height: 100%;
  width: 100%;
  background: var(--green-subtle);
  border-radius: 8px;
}

.stats-page .shots-summary .graphic .shots-on-target {
  position: absolute;
  right: 0px;
  bottom: 16px;
  width: 40%;
  height: 50%;
  background: var(--green-lighter);
  border-radius: 8px;
}

.stats-page .shots-summary .numbers {
  /* margin-left: 16px; */
  /* width: 33%; */
  grid-column-start: 3;
  grid-column-end: 4;
  padding-left: 16px;
  padding-right: 16px;
  border-left: solid 1px var(--light-2);
  /* padding-bottom: 16px; */
  padding-top: 16px;
}

.stats-page .shots-summary .numbers .number-item {
  margin-bottom: 32px;
  /* margin-top: 16px; */
}

.stats-page .shots-summary .numbers .number-item .value {
  font-family: var(--din);
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-2);
}

.stats-page .shots-summary .numbers .number-item .label {
  font-family: var(--nhg-display);
  color: var(--blue-lighter);
  font-weight: 600;
}

.stats-page .summary-blocks-defense__football {
  margin-top: 32px;
  grid-column-start: 1;
  grid-column-end: 3;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  /* grid-column-gap: 16px; */
}

.stats-page .summary-blocks-defense__football .summary-blocks-defense-label {
  background: var(--blue-subtle);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  color: var(--blue-darker);
  /* font-weight: 600; */
  border-radius: 4px;
  position: relative;
  height: 24px;
  padding-left: 8px;
  font-size: 10px;
  letter-spacing: 1px;
  font-family: var(--nhg-display);
  font-weight: 700;
  grid-column-start: 1;
  grid-column-end: 3;
}

.stats-page .summary-blocks-defense__football .summary-block {
  /* margin-top: 16px; */
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px var(--light-2);
}

.stats-page .summary-blocks-defense__football .summary-block .value {
  font-family: var(--din);
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-2);
}

.stats-page .summary-blocks-defense__football .summary-block .label {
  font-family: var(--nhg-display);
  color: var(--blue-lighter);
  font-weight: 600;
}

.stats-page .conversion-summary {
  grid-column-start: 4;
  grid-column-end: 5;
  margin-top: 32px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.stats-page .conversion-summary .conversion-summary-item {
  flex-grow: 1;
  padding-bottom: 16px;
  padding-top: 8px;
}

.stats-page .conversion-summary .conversion-summary-item .graphic {
  padding: 32px;
  padding-bottom: 8px;
  padding-top: 0px;
  position: relative;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.stats-page .conversion-summary .conversion-summary-item .graphic .value {
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  margin-top: 9px;
  margin-left: 5px;
}

.stats-page .conversion-summary .conversion-summary-item .label {
  font-family: var(--nhg-display);
  color: var(--dark-2);
  font-weight: 600;
  text-align: center;
}

.stats-page .awards {
  grid-column-start: 1;
  grid-column-end: 5;
  margin-top: 32px;
  height: 140px;
  padding: 16px;
}

.stats-page .card-title {
  font-weight: 700;
  color: var(--dark-4);
}

.stats-page .passing-summary {
  display: flex;
  flex-direction: row;
  grid-column-start: 1;
  grid-column-end: 5;
  margin-top: 32px;
}

.stats-page .passing-summary__summary {
  width: 75%;
  padding: 16px;
  border-right: solid 1px var(--light-2);
}

.stats-page .passing-summary__summary__title {
  font-family: var(--nhg-display);
  color: var(--dark-2);
  font-weight: 700;
  font-size: 16px;
}

.stats-page .passing-summary__summary__graph {
  height: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  background: var(--light-3);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
}

.stats-page .passing-summary__summary__graph__fill {
  min-width: 1%;
  background: var(--blue-main);
  height: 100%;
  border-radius: 8px;
}

.stats-page .passing-summary__summary__values {
  display: flex;
  flex-direction: row;
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--blue-lighter);
}

.stats-page .passing-summary__summary__values__item {
}

.stats-page .passing-summary__summary__values__item:last-child {
  margin-left: auto;
}

.stats-page .passing-summary__summary__values__item__num {
  /* font-family: var(--din); */
  font-weight: 700;
  color: var(--dark-2);
}

.stats-page .passing-summary__accuracy {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stats-page .passing-summary__accuracy__value {
  font-size: 44px;
  font-family: var(--din);
  font-weight: 700;
  color: var(--dark-2);
}

.stats-page .passing-summary__accuracy__label {
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--blue-lighter);
}

/* NEW DESIGNS */

.profile-stats-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 12px;
}

.profile-attack-stats {
  /* margin: 16px; */
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-column-gap: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.profile-attack-stats.no-margin {
  margin-top: 12px;
}

.profile-attack-stats__title {
  grid-column-start: 1;
  grid-column-end: 5;
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
}

.profile-attack-stats__items {
  grid-column-start: 1;
  grid-column-end: 5;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;
  /* flex-shrink: 0; */
}

.profile-attack-stats__items__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-attack-stats__items__item__value {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-1);
}

.profile-attack-stats__items__item__label {
  font-family: var(--nhg-display);
  font-weight: 600;
  font-size: 12px;
  color: var(--dark-4);
  margin-top: 8px;
  text-align: center;
}

.profile-attack-stats__graphic {
  grid-column-start: 5;
  grid-column-end: 6;
  position: relative;
  /* font-size: 76px;
  letter-spacing: -6px;
  font-weight: 700;
  font-style: italic; */
}

.profile-attack-stats__graphic__blue {
  color: rgba(19, 160, 250, 0.48);

  height: 76px;
  position: absolute;
  left: 69px;
  bottom: 8px;
  transform-origin: 0 76px;
  transform: rotateZ(-90deg);
}

.profile-attack-stats__graphic__green {
  color: rgba(99, 234, 71, 0.24);
  height: 76px;
  position: absolute;
  left: 73px;
  bottom: 5px;
  transform-origin: 0 76px;
  transform: rotateZ(-90deg);
}

/* Shooting */

.profile-shooting-stats {
  /* margin: 16px; */
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  padding-bottom: 16px;
}

.profile-shooting-stats__title {
  grid-column-start: 1;
  grid-column-end: 5;
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
}

.profile-shooting-stats__items {
  padding-left: 16px;
  padding-right: 16px;
  grid-column-start: 1;
  grid-column-end: 3;
}

.profile-shooting-stats__items__item {
  margin-bottom: 24px;
}

.profile-shooting-stats__items__item__meta {
  display: flex;
  flex-direction: row;
  font-family: var(--nhg-display);
}

.profile-shooting-stats__items__item__meta__key {
  font-weight: 600;
  color: var(--dark-4);
}

.profile-shooting-stats__items__item__meta__value {
  font-weight: 700;
  color: var(--dark-1);
  margin-left: auto;
  margin-right: 8px;
}

.profile-shooting-stats__items__item__graph {
  margin-top: 12px;
}

.profile-shooting-stats__items__item__graph__track {
  height: 8px;
  border-radius: 4px;
  background: var(--light-3);
  overflow: hidden;
}

.profile-shooting-stats__items__item__graph__fill {
  height: 100%;
  border-radius: 4px;
  background: linear-gradient(75.26deg, #78c9fc 18.6%, #63ea47 100%);
}

.profile-shooting-stats__doughnut {
  grid-column: span 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  padding-top: 0;
  /* -ms-grid-column-span: 2; */
}

.profile-shooting-stats__doughnut__meta {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.profile-shooting-stats__doughnut__meta__value {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-1);
}

.profile-shooting-stats__doughnut__meta__key {
  font-weight: 600;
  color: var(--dark-4);
  font-size: 10px;
  text-align: center;
}

.profile-shooting-stats__doughnut__bg {
  height: calc(100% + 0px);
  width: calc(100% + 0px);
  position: absolute;
  left: 3px;
  top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  padding-top: 0;
  z-index: 0;
  /* margin-top: 8px; */
  /* margin-right: 8px; */
}

/* Passing */

.profile-passing-stats {
  /* margin: 16px; */
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  grid-column-start: 1;
  grid-column-end: 3;
}

.profile-passing-stats__title {
  grid-column-start: 1;
  grid-column-end: 5;
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
}

.profile-passing-stats__items {
  padding-left: 16px;
  padding-right: 16px;
  grid-column-start: 1;
  grid-column-end: 5;
}

.profile-passing-stats__items__item {
}

.profile-passing-stats__items__item__meta {
  display: flex;
  flex-direction: row;
  font-family: var(--nhg-display);
}

.profile-passing-stats__items__item__meta__key {
  font-weight: 600;
  color: var(--dark-4);
}

.profile-passing-stats__items__item__meta__value {
  font-weight: 700;
  color: var(--dark-1);
  margin-left: auto;
  margin-right: 8px;
}

.profile-passing-stats__items__item__graph {
  margin-top: 12px;
  margin-bottom: 12px;
}

.profile-passing-stats__items__item__graph__track {
  height: 8px;
  border-radius: 4px;
  background: var(--light-3);
  overflow: hidden;
}

.profile-passing-stats__items__item__graph__fill {
  height: 100%;
  border-radius: 4px;
  background: linear-gradient(75.26deg, #78c9fc 18.6%, #63ea47 100%);
}

.profile-passing-stats__doughnut {
  grid-column: span 3;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 16px;
  padding-top: 0;
  margin-top: 32px;
  margin-bottom: 32px;
}

.profile-passing-stats__doughnut__bg {
  height: calc(100% + 0px);
  width: calc(100% + 0px);
  position: absolute;
  left: 3px;
  top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  padding-top: 0;
  z-index: 0;
}

.profile-passing-stats__doughnut__meta {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.profile-passing-stats__doughnut__meta__value {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-1);
}

.profile-passing-stats__doughnut__meta__key {
  font-weight: 600;
  color: var(--dark-4);
  font-size: 10px;
  text-align: center;
}

.profile-passing-stats__graphic {
  grid-column-start: 4;
  grid-column-end: 5;
  position: relative;
  /* font-size: 64px; */
  /* letter-spacing: -6px; */
  /* font-weight: 700; */
  /* font-style: italic; */
}

.profile-passing-stats__graphic__blue {
  color: rgba(19, 160, 250, 0.48);

  height: 64px;
  position: absolute;
  left: 36px;
  bottom: -32px;
  transform-origin: 0 32px;
  transform: rotateZ(-90deg);
}

.profile-passing-stats__graphic__green {
  color: rgba(99, 234, 71, 0.24);
  height: 64px;
  position: absolute;
  left: 40px;
  bottom: -40px;
  transform-origin: 0 32px;
  transform: rotateZ(-90deg);
}

/* Positions */

.profile-positions-played {
  /* margin: 16px; */
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  padding-bottom: 16px;
  grid-column-start: 3;
  grid-column-end: 5;
}

.profile-positions-played__title {
  grid-column-start: 1;
  grid-column-end: 5;
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
}

.profile-positions-played__pitch {
  padding-left: 16px;
  padding-right: 16px;
  grid-column-start: 1;
  grid-column-end: 5;
}

/* Participation */

.profile-participation-stats {
  /* margin: 16px; */
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  margin-left: 0;
  margin-right: 0;
}

.profile-participation-stats__title {
  grid-column-start: 1;
  grid-column-end: 5;
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
}

.profile-participation-stats__table {
  grid-column-start: 1;
  grid-column-end: 5;
  display: flex;
  flex-direction: row;
}

.profile-participation-stats__table__column {
}

.profile-participation-stats__table__column__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: solid 1px var(--light-2);
  border-bottom: solid 1px var(--light-2);
  height: 24px;
  font-size: 10px;
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-4);
  padding-right: 12px;
}

.profile-participation-stats__table__column__header.with-image {
  justify-content: flex-start;
  padding-left: 16px;
}

.profile-participation-stats__table__column__rows {
}

.profile-participation-stats__table__td {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 44px;
  padding-right: 12px;
  color: var(--dark-1);
}

.profile-participation-stats__table__td.even {
  background: var(--light-2);
}

.profile-participation-stats__table__td.with-image {
  justify-content: flex-start;
  padding-left: 16px;
}

.profile-participation-stats__table__td__image {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-right: 4px;
  flex-shrink: 0;
}

.profile-participation-stats__table__td__meta {
  font-size: 10px;
  flex-grow: 1;
  overflow: hidden;
}

.profile-participation-stats__table__td__meta__primary {
  font-weight: 700;
  color: var(--dark-1);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-participation-stats__table__td__meta__secondary {
  font-weight: 400;
  color: var(--dark-3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Awards */

.profile-awards-stats {
  /* margin: 16px; */
  margin-top: 32px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.profile-awards-stats__title {
  grid-column-start: 1;
  grid-column-end: 5;
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
  padding-bottom: 0;
}

.profile-awards-stats__items {
  grid-column-start: 1;
  grid-column-end: 5;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 16px;
}

.profile-awards-stats__items__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px;
}

.profile-awards-stats__items__item__img {
  height: 40px;
  width: 40px;
  background-color: var(--blue-subtle);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-awards-stats__items__item__desc {
  padding-left: 16px;
}

.profile-awards-stats__items__item__desc__primary {
  font-weight: 700;
  margin-bottom: 4px;
  color: var(--dark-1);
}

.profile-awards-stats__items__item__desc__secondary {
  color: var(--dark-3);
}

.profile-stats-sync-container {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  color: var(--dark-4);
}

.profile-stats-sync-container__status {
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
