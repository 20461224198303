.profile-videos .upload-btn-container {
  grid-column-start: 3;
  grid-column-end: 11;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.profile-videos .profile-videos-gallery .video-list-item {
  width: 100%;
}
