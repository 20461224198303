.score-individual {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 24% 52% 24%;
  background: var(--light-2);
}

.score-individual .left-column {
  grid-column-start: 1;
  grid-column-end: 2;
  height: 100%;
  background: white;
}

.score-individual .middle-column {
  grid-column-start: 2;
  grid-column-end: 3;
  height: 100%;
}

.score-individual .right-column {
  grid-column-start: 3;
  grid-column-end: 4;
  height: 100%;
  background: white;
}
