.home-page-mobile__upcoming-events-card {
  background: var(--light-1);
  box-shadow: var(--elevation-mobile);
  margin-top: 12px;
  padding-bottom: 24px;
}

.home-page-mobile__upcoming-events-card__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
}

.home-page-mobile__upcoming-events-card__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.home-page-mobile__upcoming-events-card__month-selector {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home-page-mobile__upcoming-events-card__month-selector__option {
  stroke: var(--blue-main);
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page-mobile__upcoming-events-card__month-selector__current {
  font-family: var(--nhg-display);
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 2 */

  color: var(--dark-2);
  padding-left: 12px;
  padding-right: 12px;
}

.home-page-mobile__upcoming-events-card__days {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 24px;
  padding-top: 8px;
}

.home-page-mobile__upcoming-events-card__days__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--light-2);
  border-radius: 8px;
  width: calc((100% - 68px) / 4);
  margin-right: 12px;
  overflow: hidden;
  flex-shrink: 0;
}

.home-page-mobile__upcoming-events-card__days__item.active {
  border-color: var(--blue-main);
}

.home-page-mobile__upcoming-events-card__days__item__month {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 400;
  font-size: 8px;
  /* line-height: 7px; */
  /* display: flex;
align-items: center;
text-align: center; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
  margin-top: 6px;
  letter-spacing: 1px;
}

.home-page-mobile__upcoming-events-card__days__item.active
  .home-page-mobile__upcoming-events-card__days__item__month {
  color: var(--dark-3);
}

.home-page-mobile__upcoming-events-card__days__item__date {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 16px;
  /* line-height: 17px; */
  /* display: flex; */
  /* align-items: center; */
  /* text-align: center; */

  /* Light / Light 4 */

  color: var(--light-4);
}

.home-page-mobile__upcoming-events-card__days__item.active
  .home-page-mobile__upcoming-events-card__days__item__date {
  color: var(--dark-2);
}

.home-page-mobile__upcoming-events-card__days__item__day {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 8px;
  /* line-height: 7px; */
  /* display: flex; */
  /* align-items: center; */
  /* text-align: center; */

  /* Light / Light 4 */

  color: var(--light-4);
  margin-top: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: var(--light-2);
}

.home-page-mobile__upcoming-events-card__days__item.active
  .home-page-mobile__upcoming-events-card__days__item__day {
  background: var(--blue-main);
  color: var(--light-1);
}

.home-page-mobile__upcoming-events-card__fixtures__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: solid 1px var(--light-2);
}

.home-page-mobile__upcoming-events-card__fixtures__item__teams {
  flex-grow: 1;
  padding-top: 12px;
  padding-bottom: 12px;
  border-right: solid 1px var(--light-2);
}

.home-page-mobile__upcoming-events-card__fixtures__item__teams__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.home-page-mobile__upcoming-events-card__fixtures__item__teams__item:first-child {
  margin-bottom: 12px;
}

.home-page-mobile__upcoming-events-card__fixtures__item__teams__item__image {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background: var(--blue-subtle);
}

.home-page-mobile__upcoming-events-card__fixtures__item__teams__item__name {
  flex-grow: 1;
  padding-left: 16px;
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 15px; */
  /* display: flex; */
  /* align-items: center; */

  /* Dark / Dark 1 */

  color: var(--dark-1);
}

.home-page-mobile__upcoming-events-card__fixtures__item__teams__item__score {
  font-family: var(--nhg-display);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  /* line-height: 15px; */
  /* display: flex; */
  /* align-items: center; */

  /* Dark / Dark 3 */

  color: var(--dark-3);
}

.home-page-mobile__upcoming-events-card__fixtures__item__time {
  width: 22.22vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;

  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 700;
  font-size: 12px;
  /* line-height: 15px; */
  /* display: flex; */
  /* align-items: center; */
  /* text-align: center; */

  /* Light / Light 4 */

  color: var(--light-4);
}
