.events-page {
  display: grid;
  padding-left: 48px;
  padding-right: 48px;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 32px;
  padding-top: 32px;
}

.events-page .sticky-wrapper {
  position: -webkit-sticky;
  position: sticky;
  padding-bottom: 32px;
}

.events-page .sticky-wrapper.sticky-top {
  top: 32px;
}

.events-page .sticky-wrapper.sticky-bottom {
  bottom: 0;
}

.events-page .left-column {
  grid-column-start: 1;
  grid-column-end: 4;
}

.events-page .left-column.sticky-bottom {
  display: flex;
  flex-direction: column-reverse;
}

.events-page .middle-column {
  grid-column-start: 4;
  grid-column-end: 10;
}

.events-page .right-column {
  grid-column-start: 10;
  grid-column-end: 13;
  border-left: solid 1px var(--light-3);
  padding-left: 28px;
}

.events-page .right-column.sticky-bottom {
  display: flex;
  flex-direction: column-reverse;
}

.events-page .card-section {
  /* margin-bottom: 32px; */
}

.events-page .card-section .card-section-title {
  /* padding-left: 16px; */
  padding-right: 16px;
  margin-bottom: 12px;
  font-weight: 700;
  color: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  border-bottom: solid 1px var(--light-4);
  padding-bottom: 12px;
}

.events-page .card-section .card-section-title .reset {
  margin-left: auto;
  color: var(--red-lighter);
}

.event-card-small {
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 16px;
}

.event-card-small .head {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.event-card-small .head .date {
  height: 24px;
  /* width: 40px; */
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  background: var(--blue-subtlest);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  font-size: 10px;
}

.event-card-small .head .date .month {
  font-weight: 400;
  letter-spacing: 0.6px;
  font-size: 10px;
  line-height: 10px;
}

.event-card-small .head .date .day {
  font-size: 16px;
  font-weight: 600;
  color: var(--dark-1);
  line-height: 16px;
  margin-top: 2px;
  /* margin-bottom: 2px; */
}

.event-card-small .head .meta {
  padding-left: 16px;
}

.event-card-small .body .primary {
  font-size: 16px;
  font-weight: 600;
  color: var(--dark-1);
  line-height: 22px;
}

.event-card-small .head .options {
  margin-left: auto;
}

.event-card-small .body .secondary {
  font-size: 12px;
  font-weight: 400;
  color: var(--dark-4);
  /* line-height: 22px; */
}

.event-card-small .body {
}

.event-card-small .body .image {
  height: 160px;
  background: var(--blue-subtle);
  border-radius: 8px;
  margin-top: 16px;
}

.event-card-small .body .blurb {
  margin-top: 16px;
  font-family: "Inter", sans-serif;
}

.event-card-small .foot {
  margin-top: 16px;
  padding-top: 16px;
  border-top: solid 1px var(--light-3);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.event-card-small .foot .followers {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.event-card-small .foot .followers .follower {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-right: 8px;
}

.event-card-small .foot .stats {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: auto;
  height: 32px;
  -ms-align-items: center;
  align-items: center;
}

.event-card-small .foot .stats .stat-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.events-page .search-card {
  padding: 0;
  padding-left: 16px;
  padding-right: 16px;
  height: 40px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 24px;
}

.events-page .filter-group {
  margin-bottom: 24px;
}

.events-page .filter-group .filter-group-title {
  font-weight: 700;
  color: var(--dark-2);
  margin-bottom: 8px;
}

.events-page .filter-group .filter-group-input {
}

.events-page .filter-group .filter-group-input .app-input .input-wrapper {
  background: white;
}

.events-page .filter-group .filter-group-options {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 12px;
}

.events-page .filter-group .filter-group-option-item {
  flex: 1;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 8px;
  box-shadow: var(--elevation-1);
  color: var(--dark-4);
  cursor: pointer;
  margin-top: 8px;
}

.events-page .filter-group .filter-group-option-item.selected {
  background: var(--blue-main);
  color: white;
  font-weight: 700;
}

.events-page .filter-group .filter-group-applied {
  /* padding-top: 16px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.events-page .filter-group .filter-group-applied .app-chip {
  margin-right: 12px;
  margin-top: 12px;
  cursor: pointer;
  background: var(--blue-main);
  color: var(--light-1);
  padding-right: 4px;
}

.events-page .show-more-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  padding-top: 16px;
}

.events-page .show-more-container .show-more-button {
  width: 88px;
}

.events-page .location-input-wrapper .input-wrapper {
  height: 32px;
  box-shadow: var(--elevation-1);
  border: solid 1px var(--light-2);
  background: white;
}

.events-page .location-input-wrapper .input-wrapper input {
  padding-left: 16px;
}
