.video-card-mobile {
  background: white;
  margin-top: 12px;
  border-radius: 4px;
  padding: 12px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 0;
}

.video-card-mobile__source {
}

.video-card-mobile__description {
  padding-top: 12px;
  padding-bottom: 12px;
}

.video-card-mobile__description .ql-container {
  border: none !important;
  font-family: var(--nhg);
}

.video-card-mobile__description .ql-editor {
  padding: 0;
}

.video-card-mobile__description .truncate-button {
  cursor: pointer;
  color: var(--blue-main);
}

.video-card-mobile__video {
}

.video-card-mobile__video video {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.video-card-mobile-fixture-source {
}

.video-card-mobile-fixture-source .fixture-list-item-mobile__teams {
  border: none;
}

.video-card-mobile-fixture-source
  .fixture-list-item-mobile__teams
  .fixture-list-item-mobile__teams__item {
  padding-left: 0;
  padding-right: 0;
}
