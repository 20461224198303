.videos-summary-mobile {
  padding: 12px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.videos-summary-mobile__header {
  display: flex;
  flex-direction: row;
}

.videos-summary-mobile__header__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.videos-summary-mobile__header__link {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--blue-main);
  margin-left: auto;
}

.videos-summary-mobile__body {
  margin-top: 12px;
  background: var(--dark-1);

  position: relative;
}

.videos-summary-mobile__body .video {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videos-summary-mobile__body .video video {
  height: 100%;
}
