.ScoringApp-badminton__publish__confirm {
  overflow: hidden;
}

.ScoringApp-badminton__publish__confirm__form {
}

.ScoringApp-badminton__publish__confirm__form__header {
  height: 32px;
  background: var(--blue-darker);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  color: var(--light-2);
  font-family: var(--nhg-display);
  padding-left: 16px;
  padding-right: 16px;
}

.ScoringApp-badminton__publish__confirm__form__text {
  padding-left: 16px;
  padding-right: 16px;
}

.ScoringApp-badminton__publish__confirm__form__options {
  padding: 16px;
}
