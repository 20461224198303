.manage-event-fixtures-mobile {
}

.manage-event-fixtures-mobile__index {
}

.manage-event-fixtures-mobile__index__item {
  transition: all 0.2s ease-out;
}

.manage-event-fixtures-mobile__index__item.root {
  background: white;
  box-shadow: var(--elevation-mobile);
  border-radius: 8px;
  margin-top: 16px;
}

.manage-event-fixtures-mobile__index__item__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
}

.manage-event-fixtures-mobile__index__item__header__collapse {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-event-fixtures-mobile__index__item__header__collapse .icon {
  fill: var(--dark-4);
  height: 14px;
  transform: rotateZ(-90deg);
  transition: all 0.2s ease-out;
}

.manage-event-fixtures-mobile__index__item__header__collapse.open .icon {
  transform: rotateZ(0deg);
}

.manage-event-fixtures-mobile__index__item__header__title {
  font-weight: 700;
  font-family: var(--nhg-display);
  color: var(--dark-2);
}

.manage-event-fixtures-mobile__index__item__header__options {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.manage-event-fixtures-mobile__index__item__header__options__option {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-event-fixtures-mobile__index__item__header__options__option .view-icon {
  fill: var(--light-4);
  height: 12px;
}

.manage-event-fixtures-mobile__index__item__header__options__option .arrow-up {
  stroke: var(--light-4);
  height: 12px;
  transform: rotateZ(90deg);
}

.manage-event-fixtures-mobile__index__item__header__options__option
  .arrow-down {
  stroke: var(--light-4);
  height: 12px;
  transform: rotateZ(-90deg);
}

.manage-event-fixtures-mobile__index__item__header__options__option
  .icon.disabled {
  stroke: var(--light-2);
}

.manage-event-fixtures-mobile__index__item__subparts {
  padding-left: 16px;
}

.manage-event-fixtures-mobile__event-part {
  background: white;
  box-shadow: var(--elevation-mobile);
  border-radius: 8px;
  margin-top: 16px;
}

.manage-event-fixtures-mobile__event-part__title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-event-fixtures-mobile__event-part__title__back {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage-event-fixtures-mobile__event-part__title__back .icon {
  stroke: var(--dark-4);
  height: 14px;
  /* transform: rotateZ(-90deg); */
  transition: all 0.2s ease-out;
}

.manage-event-fixtures-mobile__event-part__title__text {
  font-weight: 700;
  font-family: var(--nhg-display);
  color: var(--dark-2);
}

.manage-event-fixtures-mobile__event-part__title__links {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-event-fixtures-mobile__event-part__title__links__link {
  font-weight: 700;
  font-family: var(--nhg-display);
  color: var(--blue-main);
  margin-right: 16px;
}
