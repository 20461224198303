.fixture-list-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 16px;
}

.fixture-list-item .participants {
  flex-grow: 1;
  padding: 8px;
}

.fixture-list-item .participants .participant {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding: 8px;
  padding-right: 16px;
}

.fixture-list-item .participants .participant .image {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.fixture-list-item .participants .participant .image img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.fixture-list-item .participants .participant .name {
  flex-grow: 1;
  color: var(--dark-1);
}

.fixture-list-item .score {
  /* font-family: var(--nhg-display); */
  font-weight: 600;
  color: var(--dark-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  padding-top: 0;
  padding-bottom: 0;
}

.fixture-list-item .score__box-score-period {
  /* padding: 8px;
  padding-right: 16px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fixture-list-item .score__box-score-period__item {
  flex-grow: 1;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}

.fixture-list-item .score__box-score-period__item.bold {
  font-weight: 600;
}

.fixture-list-item .datetime {
  width: 25%;
  flex-shrink: 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  border-left: solid 1px var(--light-2);
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-4);
  letter-spacing: 0.4px;
  /* font-size: 12px; */
}

.fixture-list-item .datetime .date {
  margin-bottom: 4px;
}
