.ScoringApp-football {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ScoringApp-football__header {
  height: 48px;
  width: 100%;
  background: var(--blue-darker);
  display: grid;
  grid-template-columns: 24% 52% 24%;
  grid-template-rows: 100%;
  font-family: var(--nhg-display);
  color: white;
  font-weight: 700;
  flex-shrink: 0;
  padding-left: 16px;
  padding-right: 16px;
}

.ScoringApp-football__header__nav-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ScoringApp-football__header__nav-left__back-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.ScoringApp-football__header__nav-left__back-btn__icon {
  stroke: var(--light-2);
  height: 16px;
  margin-top: 2px;
}

.ScoringApp-football__header__nav-left__back-btn__text {
  margin-left: 4px;
}

.ScoringApp-football__header__main {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.ScoringApp-football__header__nav-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.ScoringApp-football__header__nav-right__nav-btn {
  cursor: pointer;
}

.ScoringApp-football__header__nav-right__nav-btn.square {
  height: 32px;
  width: 32px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-darkest);
  border-radius: 8px;
}

.ScoringApp-football__header__nav-right__nav-btn__icon {
  stroke: var(--light-2);
  height: 16px;
}

.ScoringApp-football__body {
  background: var(--blue-darkest);
  flex-grow: 1;
  overflow: hidden;
}
