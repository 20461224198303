.filter-input-dropdown.disable-without-background .app-input .input-wrapper {
  background: white;
}

.filter-input-dropdown.disable-without-background
  .app-input
  .input-wrapper
  input {
  color: var(--dark-1);
}
