.home-page-mobile__profile-pages-card {
  background: var(--light-1);
  box-shadow: var(--elevation-mobile);
  margin-top: 12px;
}

.home-page-mobile__profile-pages-card__title {
  padding: 16px;
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */

  /* Dark / Dark 4 */

  color: var(--dark-4);
}

.home-page-mobile__profile-pages-card__list {
  display: flex;
  flex-direction: row;
  padding-left: 16px;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 24px;
}

.home-page-mobile__profile-pages-card__list__item {
  width: 72px;
  margin-right: 12px;
}

.home-page-mobile__profile-pages-card__list__item__image {
  height: 72px;
  width: 72px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--blue-subtle);

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.home-page-mobile__profile-pages-card__list__item__image img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.home-page-mobile__profile-pages-card__list__item__name {
  font-family: var(--nhg-display);
  font-size: 10px;
  color: var(--dark-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 8px;
  font-weight: 600;
}
