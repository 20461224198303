.app-post-card {
  /* font-family: "Inter", sans-serif; */
  /* padding: 16px; */
  position: relative;
  overflow: hidden;
}

.app-post-card__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 24, 39, 0.7);
  z-index: 1;
}

.app-post-card .post-menu {
}

.app-post-card .post-menu__item {
  border-bottom: solid 1px var(--light-3);
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.app-post-card .post-menu__item:hover {
  background: var(--light-2);
}

.app-post-card .head {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  /* margin-bottom: 16px; */
  border-bottom: solid 1px var(--light-2);
  /* padding-bottom: 16px; */
  padding: 8px;
  padding-left: 16px;
}

.app-post-card .head .avatar {
  height: 36px;
  width: 36px;
  background: var(--blue-subtle);
  margin-right: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.app-post-card .head .avatar img {
  width: 100%;
}

.app-post-card .head .meta {
  flex-grow: 1;
}

.app-post-card .head .meta .primary {
  font-weight: 700;
  color: var(--dark-1);
  /* font-family: var(--nhg); */
}

.app-post-card .head .meta .secondary {
  color: var(--dark-4);
  /* font-family: var(--nhg); */
}

.app-post-card .head .feed-item-options-button {
}

.app-post-card .text {
  line-height: 19px;
  padding: 16px;
  padding-bottom: 0;
}

.app-post-card .text .truncate-button {
  cursor: pointer;
  color: var(--blue-main);
}

.app-post-card .text .ql-container {
  border: none;
}

.app-post-card .text .ql-editor {
  padding: 0;
  font-family: var(--nhg);
  line-height: 20px;
  font-size: 12px;
  color: var(--dark-2);
}

.app-post-card__media {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 160px 160px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  padding-left: 16px;
  padding-right: 16px;
  /* margin-top: 16px; */
}

.app-post-card__media__item {
  background: var(--blue-subtle);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: black;
}

.app-post-card__media__item img {
  width: 100%;
}

.app-post-card__media__item video {
  width: 100%;
}

.app-post-card__media__item__overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #022e4b36;
}

.app-post-card .foot {
  border-top: solid 1px var(--light-2);
  margin-top: 16px;
  /* padding-top: 16px; */
  /* padding-bottom: 8px; */
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.app-post-card .foot .option {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.app-post-card .foot .option.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.app-post-card .foot .option .icon {
  /* stroke: var(--dark-2); */
}

.app-post-card .foot .option .value {
  margin-left: 0px;
  font-weight: 700;
  color: var(--dark-4);
}

.app-post-card .foot .options-right {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.app-post-card .foot .options-right .option {
  margin-left: 8px;
}

.app-post-card .media .image-placeholder {
  width: 100%;
  height: 400px;
  background: var(--blue-subtle);
  border-radius: 8px;
}

.app-post-card__options-menu {
}

.app-post-card__options-menu__item {
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.app-post-card__options-menu__item:hover {
  background: var(--light-2);
}

.delete-app-post {
}

.delete-app-post__text {
  font-size: 14px;
  color: white;
  font-weight: 700;
}

.delete-app-post__options {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.delete-app-post__options .app-button.primary {
  margin-left: auto;
  cursor: pointer;
}
