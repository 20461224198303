.manage-event-awards-mobile {
}

.manage-event-awards-mobile__section {
  background: white;
  margin-top: 16px;
  box-shadow: var(--elevation-mobile);
  padding: 16px;
}

.manage-event-awards-mobile__section__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */
  color: var(--dark-4);
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-event-awards-mobile__section__title__edit-btn {
  color: var(--blue-main);
  margin-left: auto;
}

.manage-event-awards-mobile__section__desc {
}

.manage-event-awards-mobile__section__items {
}

.manage-event-awards-mobile__section__items {
}

.manage-event-awards-mobile__section__items__item {
  margin-top: 16px;
}

.manage-event-awards-mobile__section__items__item__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */
  color: var(--dark-4);
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
}

.manage-event-awards-mobile__section__items__item__title__edit-btn {
  margin-left: auto;
  color: var(--blue-main);
}

.manage-event-awards-mobile .delete-event-placement {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.manage-event-awards-mobile .delete-event-placement__image {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: var(--blue-subtle);
  overflow: hidden;
}

.manage-event-awards-mobile .delete-event-placement__image img {
  width: 100%;
}

.manage-event-awards-mobile .delete-event-placement__text {
  padding-left: 16px;
}

.manage-event-awards-mobile .delete-event-placement__text__primary {
  font-weight: 700;
}

.manage-event-awards-mobile.delete-event-placement__text__secondary {
  color: var(--dark-3);
}

.manage-event-awards-mobile .delete-event-placement__option {
  margin-left: auto;
  height: 32px;
  /* width: 32px; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.manage-award-type-mobile {
  margin-top: 24px;
}

.manage-award-type-mobile__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */
  color: var(--dark-1);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-award-type-mobile__title__text {
}

.manage-award-type-mobile__title__delete-btn {
  margin-left: auto;
}

.manage-award-type-mobile__title__delete-btn__icon {
  stroke: var(--red-main);
  height: 14px;
}

.manage-award-type-mobile__section {
  margin-bottom: 24px;
}

.manage-award-type-mobile__section__title {
  font-size: 10px;
  color: var(--dark-4);
  margin-top: 8px;
  display: flex;
  flex-direction: row;
}

.manage-award-type-mobile__section__title__text {
}

.manage-award-type-mobile__section__title__link {
  margin-left: auto;
  color: var(--blue-main);
  font-weight: 600;
}

.manage-award-type-mobile__section__list {
}

.manage-award-type-mobile__section__list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 8px;
}

.manage-award-type-mobile__section__list__item__image {
  height: 36px;
  width: 36px;
  background: var(--blue-subtle);
  border-radius: 4px;
  flex-shrink: 0;
  overflow: hidden;
}

.manage-award-type-mobile__section__list__item__image img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.manage-award-type-mobile__section__list__item__desc {
  flex-grow: 1;
  padding-right: 8px;
  padding-left: 8px;
  font-size: 12px;
}

.manage-award-type-mobile__section__list__item__desc__primary {
  /* margin-bottom: 4px; */
}

.manage-award-type-mobile__section__list__item__desc__secondary {
  font-weight: 100;
  color: var(--dark-4);
}

.manage-award-type-mobile__section__list__item__options {
}

.manage-award-type-mobile__section__list__item__options__option__icon {
  stroke: var(--red-main);
  height: 14px;
}

.search-participants-wrapper {
  padding: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.search-participants-wrapper__suggestions {
  /* flex-grow: 1; */
  overflow: hidden;
}

.search-participants-wrapper__suggestions__list {
  height: 100%;
  padding-top: 8px;
  overflow-y: auto;
}

.search-participants-wrapper__suggestions__list__item {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-participants-wrapper__suggestions__list__item__image {
  height: 36px;
  width: 36px;
  background: var(--blue-subtle);
  border-radius: 4px;
  flex-shrink: 0;
  overflow: hidden;
}

.search-participants-wrapper__suggestions__list__item__image img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.search-participants-wrapper__suggestions__list__item__desc {
  padding-left: 12px;
}

.search-participants-wrapper__suggestions__list__item__desc__primary {
}

.search-participants-wrapper__suggestions__list__item__desc__secondary {
  font-weight: 100;
  color: var(--dark-4);
}
