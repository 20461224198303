.app-photo-browser {
  height: 100%;
  width: 100%;
  background: white;
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  grid-template-rows: 100%;
}

.app-photo-browser__image {
  grid-column-start: 1;
  grid-column-end: 18;
  background: black;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--elevation-6);
  overflow: hidden;
}

.app-photo-browser__image__close {
  position: absolute;
  top: 24px;
  left: 24px;
  stroke: white;
  cursor: pointer;
  background: var(--dark-1);
  height: 32px;
  width: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-photo-browser__image__close .icon {
  height: 14px;
}

.app-photo-browser__image__prev {
  position: absolute;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark-1);
  top: calc(50% - 16px);
  left: 24px;
  stroke: var(--dark-1);
  cursor: pointer;
}

.app-photo-browser__image__prev .icon {
  stroke: white;
  height: 16px;
}

.app-photo-browser__image__next {
  position: absolute;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark-1);
  top: calc(50% - 16px);
  right: 24px;
  stroke: var(--dark-1);
  cursor: pointer;
}

.app-photo-browser__image__next .icon {
  stroke: white;
  height: 16px;
}

.app-photo-browser__image img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.app-photo-browser__image video {
  width: 100%;
}

.app-photo-browser__info {
  grid-column-start: 18;
  grid-column-end: 25;
  display: flex;
  flex-direction: column;
  position: relative;
  /* padding: 16px; */
}

.app-photo-browser__info__source {
  padding-bottom: 16px;
  border-bottom: solid 1px var(--light-3);
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding-top: 16px;
}

.app-photo-browser__info__source img {
  width: 100%;
}

.app-photo-browser__info__source__image {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  margin-right: 16px;
  margin-left: 16px;
  overflow: hidden;
}

.app-photo-browser__info__source__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-photo-browser__info__source__text__primary {
  font-weight: 700;
}

.app-photo-browser__info__source__text__secondary {
  color: var(--dark-4);
}

.app-photo-browser__info__source__fixture {
  display: grid;
  grid-template-columns: 45% 10% 45%;

  width: 100%;
}

.app-photo-browser__info__source__fixture__team {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.app-photo-browser__info__source__fixture__team.away {
  justify-content: flex-end;
}

.app-photo-browser__info__source__fixture__team__image {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  margin-right: 16px;
  margin-left: 16px;
}

.app-photo-browser__info__source__fixture__team__title {
  font-weight: 700;
  flex-grow: 1;
}

.app-photo-browser__info__source__fixture__team.away
  .app-photo-browser__info__source__fixture__team__title {
  text-align: right;
}

.app-photo-browser__info__source__fixture__team__score {
  font-weight: 700;
}

.app-photo-browser__info__source__fixture__seperator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: 700;
  color: var(--dark-4);
  letter-spacing: 1px;
}

.app-photo-browser__info__description {
  padding: 16px;
  flex-grow: 1;
}

.app-photo-browser__info__tags {
  /* padding-top: 16px; */
  /* padding-bottom: 16px; */
  /* margin-left: 16px; */
  /* margin-right: 16px; */

  border-top: solid 1px var(--light-3);
  position: absolute;
  width: 100%;
  bottom: 48px;
  background: white;
  transition: all 0.2s ease-out;
  height: 32px;
}

.app-photo-browser__info__tags__summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  background: var(--light-2);
}

.app-photo-browser__info__tags__summary .no-data-placeholder {
  font-weight: 100;
  color: var(--dark-4);
}

/* .app-photo-browser__info__tags__summary:hover {
  background: var(--light-3);
} */

.app-photo-browser__info__tags.expanded {
  height: calc(100% - 48px);
}

.app-photo-browser__info__tags__heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding-left: 12px;
  padding-right: 16px;
  /* font-size: 10px; */
  /* border-bottom: solid 1px var(--light-2); */
}

.app-photo-browser__info__tags__heading__text {
  font-weight: 700;
  display: flex;
  align-items: center;
  color: var(--dark-4);
}

.app-photo-browser__info__tags__heading__option {
  margin-left: auto;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-photo-browser__info__tags__icon {
  /* margin-right: 8px; */
}

.app-photo-browser__info__tags__items {
  display: flex;
  flex-direction: row;
}

.app-photo-browser__info__tags__items__item {
  font-weight: 700;
  color: var(--blue-main);
  padding-left: 4px;
}

.app-photo-browser__info__tags__items__item:hover {
  text-decoration: underline;
}

.app-photo-browser__info__tags__tabs {
  display: flex;
  flex-direction: row;

  border-bottom: solid 1px var(--light-3);
}

.app-photo-browser__info__tags__tabs__tab {
  padding-left: 16px;
  padding-right: 16px;
  /* flex-grow: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-weight: 700;
  color: var(--dark-3);
}

.app-photo-browser__info__tags__list {
}

.app-photo-browser__info__tags__list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border-bottom: solid 1px var(--light-2);
}

.app-photo-browser__info__tags__list__item__image {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-right: 16px;
}

.app-photo-browser__info__tags__list__item__text {
}

.app-photo-browser__info__tags__list__item__text__primary {
  font-weight: 700;
  margin-bottom: 2px;
}

.app-photo-browser__info__tags__list__item__text__secondary {
  color: var(--dark-4);
  margin-bottom: 2px;
}

.app-photo-browser__info__tags__tabs__tab.selected {
  color: var(--blue-main);
  border-bottom: solid 2px var(--blue-main);
}

.app-photo-browser__info__timestamp {
  padding-left: 16px;
  padding-right: 16px;
  color: var(--dark-4);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.app-photo-browser__info__options {
  height: 48px;
  border-top: solid 1px var(--light-3);
  /* box-shadow: var(--elevation-4); */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  /* padding-right: 16px; */
}

.app-photo-browser__info__options__option.right {
  margin-left: auto;
  font-size: 10px;
}

.app-photo-browser__info__options__option {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.app-photo-browser__info__options__option .value {
  margin-left: 0px;
  font-weight: 700;
  color: var(--dark-4);
}

.app-photo-browser__info__options__option__text {
  text-align: right;
}

.app-photo-browser__info__options__option__text__primary {
  font-weight: 700;
}

.app-photo-browser__info__options__option__text__secondary {
  color: var(--dark-4);
}

.app-photo-browser__info__options__option__image {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: var(--blue-subtle);
  margin-left: 16px;
  overflow: hidden;
}

.app-photo-browser__info__options__option__image img {
  width: 100%;
}

.app-photo-browser__info__options__option__more {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  /* margin-left: 4px; */
}

.photo-browser-fixture-source {
  margin-top: 0;
}
