.fixture-list-item-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: solid 1px var(--light-2);
}

.fixture-list-item-mobile__teams {
  flex-grow: 1;
  padding-top: 16px;
  padding-bottom: 16px;
  border-right: solid 1px var(--light-2);
}

.fixture-list-item-mobile__teams__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.fixture-list-item-mobile__teams__item:first-child {
  margin-bottom: 12px;
}

.fixture-list-item-mobile__teams__item__image {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background: var(--blue-subtle);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixture-list-item-mobile__teams__item__image img {
  max-width: 100%;
  max-height: 100%;
}

.fixture-list-item-mobile__teams__item__name {
  flex-grow: 1;
  padding-left: 16px;
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 15px; */
  /* display: flex; */
  /* align-items: center; */

  /* Dark / Dark 1 */

  color: var(--dark-1);
}

.fixture-list-item-mobile__teams__item__score {
  font-family: var(--nhg-display);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  /* line-height: 15px; */
  /* display: flex; */
  /* align-items: center; */

  /* Dark / Dark 3 */

  color: var(--dark-3);
}

.fixture-list-item-mobile__time {
  width: 22.22vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;

  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 700;
  font-size: 12px;
  /* line-height: 15px; */
  /* display: flex; */
  /* align-items: center; */
  /* text-align: center; */

  /* Light / Light 4 */

  color: var(--light-4);
}
