.create-page-mobile {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 48px;
  background: white;
}

.create-page-mobile__nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 48px;
  background: white;
  /* box-shadow: 0px 0px 3px rgba(40, 41, 61, 0.08); */
  box-shadow: 0px 0px 4px 1px rgba(40, 41, 61, 0.08);
  /* padding-left: 16px; */
  padding-right: 16px;
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.create-page-mobile__nav__back-btn {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.onboarding-form-mobile__wrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.onboarding-form-mobile__title {
  color: var(--dark-1);
  margin-bottom: 24px;
}

.onboarding-form-mobile__title__primary {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 700;
  font-size: 5.55vw;
  margin-top: 32px;

  /* line-height: 24px; */
  /* display: flex; */
  /* align-items: center; */

  /* Dark / Dark 1 */

  color: var(--dark-1);
}

.onboarding-form-mobile__title__secondary {
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-4);
  margin-top: 8px;
}

.onboarding-form-mobile {
}

.onboarding-form-mobile__group {
  margin-bottom: 24px;
  position: relative;
}

.onboarding-form-mobile__group:last-child {
  /* margin-bottom: 0; */
}

.onboarding-form-mobile__group__label {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 10px;
  /* line-height: 14px; */
  /* identical to box height, or 140% */

  letter-spacing: 0.2px;

  /* Dark / Dark 4 */

  color: var(--dark-4);
  margin-bottom: 8px;
}

.onboarding-form-mobile__dropdown-input-wrapper {
  width: 100%;
  position: relative;
}

.onboarding-form-mobile__group__field {
  height: 36px;
  width: 100%;
  border-radius: 8px;
  border: solid 1px var(--light-3);
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.onboarding-form-mobile__group__field.multi {
  height: auto;
  min-height: 36px;
}

.onboarding-form-mobile__group__field:focus-within {
  border-color: var(--blue-main);
}

.onboarding-form-mobile__group__field.error {
  border-color: var(--red-main);
}

.onboarding-form-mobile__group__field__icon {
  margin-left: 4px;
  margin-right: 4px;
}

.onboarding-form-mobile__group__field__multi-select-wrapper {
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.onboarding-form-mobile__group__field__multi-select-wrapper .app-chip {
  background: var(--blue-main);
  color: var(--light-1);
  padding-right: 0;
  padding-left: 8px;
  margin-right: 8px;
}

.onboarding-form-mobile__group__field__dropdown-menu {
  width: 100%;
}

.onboarding-form-mobile__group__field__dropdown-menu__item {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.onboarding-form-mobile__group__field__input {
  height: 100%;
  flex-grow: 1;
  font-family: var(--nhg);
  font-weight: 500;
  font-size: 12px;
  color: var(--dark-2);
  border: none;
  outline: none;
}

.onboarding-form-mobile__group__field__input.select:disabled {
  background: var(--light-1);
  pointer-events: none;
}

.onboarding-form-mobile__error {
  border-radius: 8px;
  padding: 8px;
  padding-left: 12px;
  color: var(--red-main);
  font-weight: 700;
  font-family: var(--nhg);
  background: var(--red-subtle);
  /* margin-bottom: 8px; */
}

.onboarding-form-mobile__submit {
  margin-top: 32px;
  margin-bottom: 24px;
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-main);
  border-radius: 8px;
  outline: none;
  border: none;

  font-family: var(--nhg-display);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  /* line-height: 19px; */
  /* or 158% */

  color: var(--light-1);
  box-shadow: var(--elevation-primary);
}

.onboarding-form-mobile__graphic {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
}

.onboarding-form-mobile__graphic img {
  width: 100%;
}

.onboarding-form-mobile__logo-bottom {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.onboarding-form-mobile__logo-bottom img {
}

.onboarding-form-mobile__radio {
  display: flex;
  flex-direction: row;
}

.onboarding-form-mobile__radio__btn {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-4);
  background: var(--light-2);
  margin-right: 16px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 12px;
  border-radius: 8px;
}

.onboarding-form-mobile__radio__btn.selected {
  background: var(--blue-main);
  color: white;
}
