.fixture-photos {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.fixture-photos .upload-btn-container {
  grid-column-start: 3;
  grid-column-end: 11;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.fixture-photos .fixture-photos__gallery {
  grid-column-start: 3;
  grid-column-end: 11;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 8px;
  margin-top: 32px;
  padding: 24px;
}

.fixture-photos .fixture-photos__gallery .fixture-photos__gallery__column {
}

.fixture-photos
  .fixture-photos__gallery
  .fixture-photos__gallery__column
  .fixture-photos__gallery__column__item {
  background: var(--light-1);
  margin-top: 8px;
  /* height: 200px; */
}

.fixture-photos
  .fixture-photos__gallery
  .fixture-photos__gallery__column
  .fixture-photos__gallery__column__item
  img {
  width: 100%;
}
