.football-penalty-shootout {
  margin-bottom: 32px;
}

.football-penalty-shootout__title {
  font-weight: 700;
  color: var(--dark-4);
  /* margin-bottom: 16px; */
  text-align: center;
  padding: 16px;
  border-bottom: solid 1px var(--light-2);
}

.football-penalty-shootout__header {
  display: flex;
  flex-direction: row;
  height: 40px;
  border-bottom: solid 1px var(--light-2);
}

.football-penalty-shootout__header__section {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.football-penalty-shootout__header__team {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
}

.football-penalty-shootout__header__team__image {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  overflow: hidden;
  margin-right: 16px;
  margin-left: 16px;
}

.football-penalty-shootout__header__team__image img {
  width: 100%;
}

.football-penalty-shootout__header__team__name {
  font-weight: 700;
  color: var(--dark-3);
}

.football-penalty-shootout__header__team.away {
  flex-direction: row-reverse;
  /* justify-content: flex-end; */
}

.football-penalty-shootout__header__score {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-weight: 700;
  color: var(--dark-3);
}

.football-penalty-shootout__rows__row {
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px var(--light-2);
  height: 40px;
}

.football-penalty-shootout__rows__row__section {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
}

.football-penalty-shootout__rows__row__section.away {
  flex-direction: row-reverse;
}

.football-penalty-shootout__rows__row__section__img {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  border-radius: 4px;
  overflow: hidden;
  margin-left: 16px;
  margin-right: 16px;
  flex-shrink: 0;
}

.football-penalty-shootout__rows__row__section__img img {
  width: 100%;
}

.football-penalty-shootout__rows__row__section__name {
  flex-grow: 1;
}

.football-penalty-shootout__rows__row__section.away
  .football-penalty-shootout__rows__row__section__name {
  text-align: right;
}

.football-penalty-shootout__rows__row__section__indicator {
  border-left: solid 1px var(--light-2);
  border-right: solid 1px var(--light-2);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.football-penalty-shootout__rows__row__section__indicator__icon {
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background: var(--light-2);
}

.football-penalty-shootout__rows__row__section__indicator__icon.red {
  background: var(--red-lighter);
}

.football-penalty-shootout__rows__row__section__indicator__icon.green {
  background: var(--green-lighter);
}
