.events-page .create-prompt {
  background: var(--blue-darker);
  padding: 24px;
  color: var(--light-1);
  border-radius: 8px;
  /* margin-top: 8px; */
  margin-bottom: 32px;
}

.events-page .create-prompt .illustration {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  margin-bottom: 16px;
  height: 184px;
}

.events-page .create-prompt .blurb {
  margin-bottom: 16px;
  font-weight: 700;
  text-align: center;
}
