.starters__body__list__item__batting-order {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
}

.starters__body__list__item__batting-order__value {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
}

.starters__body__list__item__batting-order__option {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* margin-left: 4px; */
  /* margin-right: 4px; */
}

.starters__body__list__item__batting-order__option__icon {
  fill: var(--light-4);
  height: 12px;
}

.starters__body__list__item__batting-order__form {
  width: 48px;
  margin-right: 16px;
}

.starters__body__list__item__batting-order__form__input {
  width: 100%;
}
