.score-basketball {
  height: 100%;
  width: 100%;
  position: relative;
}

/* .score-basketball .header {
  height: 48px;
  position: absolute;
  top: 0;
  width: 100%;
  background: var(--blue-darker);
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: var(--elevation-4);
  z-index: 3;
}

.score-basketball .header.expanded {
  height: 100%;
} */

.score-basketball .body {
  display: grid;
  grid-template-columns: 24% 52% 24%;
  grid-template-rows: 100%;
  background: var(--light-2);
  height: 100%;
  padding-top: 48px;
}

.score-basketball .left-column {
  grid-column-start: 1;
  grid-column-end: 2;
  height: 100%;
  overflow: auto;
}

.score-basketball .middle-column {
  grid-column-start: 2;
  grid-column-end: 3;
  height: 100%;
  background: white;
  box-shadow: var(--elevation-3);
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
}

.score-basketball .middle-column .match-participants {
  display: flex;
  flex-direction: row;
  height: 128px;
  border-bottom: solid 1px var(--light-2);
  flex-shrink: 0;
}

.score-basketball .middle-column .match-participants .home-participant {
  display: flex;
  flex-direction: row;
  width: 33%;
  align-items: center;
}

.score-basketball .middle-column .match-participants .away-participant {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 33%;
}

.score-basketball .middle-column .match-participants .logo {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  margin-right: 16px;
  margin-left: 16px;
}

.score-basketball .middle-column .match-participants .name {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  /* margin-bottom: 24px; */
  color: var(--dark-2);
}

.score-basketball .middle-column .match-participants .score {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  /* margin-bottom: 24px; */
  color: var(--dark-2);
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-basketball .right-column {
  grid-column-start: 3;
  grid-column-end: 4;
  height: 100%;
  overflow: auto;
}

.score-basketball .stat-type-group {
  padding: 16px;
  /* padding-bottom: 0; */
  border-bottom: solid 1px var(--light-3);
}

.score-basketball .stat-type-group .title {
  font-weight: 600;
  color: var(--dark-3);
  /* text-align: center; */
}

.score-basketball .stat-type-group .items {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 16px;
}

.score-basketball .stat-type-group .stat-type-item {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  height: 40px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease-out;
  /* height: 64px; */
}

.score-basketball .stat-type-group .stat-type-item:hover {
  box-shadow: var(--elevation-3);
}

.score-basketball .stat-overlay {
  height: 100%;
  width: 100%;
  z-index: 6;
  background: rgba(46, 52, 56, 0.84);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-basketball .stat-input-form {
  background: white;
  width: 32%;
  height: 80%;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.score-basketball .stat-input-form__title {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--blue-darker);
  color: white;
  font-weight: 600;
  padding-left: 16px;
  padding-right: 16px;
  flex-shrink: 0;
}

.score-basketball .stat-input-form__title .dismiss-btn {
  margin-left: auto;
  cursor: pointer;
}

.score-basketball .stat-input-form .timestamp-editor {
  height: 40px;
  border-bottom: solid 1px var(--light-2);
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
  flex-shrink: 0;
}

.score-basketball .stat-input-form .timestamp-editor .label {
  font-weight: 600;
  color: var(--dark-4);
}

.score-basketball .stat-input-form .timestamp-editor .input {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--light-4);
  border-radius: 4px;
  margin-left: auto;
  overflow: hidden;
}

.score-basketball .stat-input-form .timestamp-editor .input .icon {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-2);
  height: 100%;
  cursor: pointer;
}

.score-basketball .stat-input-form .timestamp-editor .input .value {
  width: 32px;
  border-left: solid 1px var(--light-4);
  border-right: solid 1px var(--light-4);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-basketball .stat-input-form .select-player {
  border-top: solid 1px var(--light-2);
}

.score-basketball .stat-input-form .select-player .select-player__title {
  padding: 16px;
  padding-bottom: 8px;
  font-weight: 600;
  color: var(--dark-4);
}

.score-basketball .stat-input-form .player-list .select-player__item {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--light-2);
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.score-basketball .stat-input-form .player-list {
  flex-grow: 1;
  overflow: auto;
}

.score-basketball .stat-input-form .player-list .select-player__item:hover {
  background: var(--blue-subtlest);
}

.score-basketball .stat-input-form .player-list .select-player__item .img {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  border-radius: 4px;
  margin-right: 16px;
}

.score-basketball .stat-input-form .player-list .select-player__item .img img {
  width: 100%;
}

.score-basketball
  .stat-input-form
  .player-list
  .select-player__item
  .name__primary {
  font-weight: 700;
}

.score-basketball
  .stat-input-form
  .player-list
  .select-player__item
  .name__secondary {
  color: var(--dark-4);
}

.score-basketball
  .stat-input-form
  .player-list
  .select-player__item
  .num-container {
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
}

.score-basketball
  .stat-input-form
  .player-list
  .select-player__item
  .num-container
  .num-val {
  font-size: 12px;
  color: var(--dark-4);
}

.score-basketball .stat-input-form .select-player .team-selector {
  display: flex;
  flex-direction: row;
  background: var(--light-2);
}

.score-basketball
  .stat-input-form
  .select-player
  .team-selector
  .team-selector__item {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
}

.score-basketball
  .stat-input-form
  .select-player
  .team-selector
  .team-selector__item.active {
  border-bottom: solid 2px var(--blue-main);
}

.score-basketball .stat-input-form .saved-stats {
  padding-left: 16px;
  padding-right: 16px;
}

.score-basketball .stat-input-form .saved-stats .saved-stat-item {
  border: dashed 1px var(--blue-lighter);
  border-radius: 4px;
  margin-top: 16px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-basketball .stat-input-form .saved-stats .saved-stat-item .timestamp {
  margin-right: 8px;
}

.score-basketball .stat-input-form .saved-stats .saved-stat-item .edit-btn {
  margin-left: auto;
  font-weight: 600;
  color: var(--orange-lighter);
  cursor: pointer;
}

.score-basketball .stat-input-form .associated-stat-title {
  background: var(--light-2);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 16px;
  color: var(--dark-4);
  font-weight: 600;
}

.score-basketball .stats-list {
  padding-left: 24px;
  padding-right: 24px;
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 48px;
  padding-top: 16px;
}

.score-basketball .stats-list .stat-item-container {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}

.score-basketball .stats-list .stat-item-container.away {
  justify-content: flex-end;
}

.score-basketball .stats-list .stat-item-container .stat-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 60%; */
  border: solid 1px var(--light-2);
  border-radius: 4px;
  overflow: hidden;
}

.score-basketball .stats-list .stat-item-container .stat-item.away {
  justify-content: flex-end;
}

.score-basketball .stats-list .stat-item-container .stat-item .img {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
}

.score-basketball .stats-list .stat-item-container .stat-item .timestamp {
  height: 32px;
  width: 48px;
  border: solid 1px var(--light-2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-basketball .stats-list .stat-item-container .stat-item .statement {
  margin-left: 16px;
  margin-right: 16px;
}

.score-basketball .stats-list .stat-item-container .stat-item.home .statement {
  margin-right: 32px;
}

.score-basketball .stats-list .stat-item-container .stat-item.away .statement {
  margin-left: 32px;
}

.score-basketball .stats-list .stat-item-container .stat-item .options {
  display: flex;
  flex-direction: row;
}

.score-basketball .stats-list .stat-item-container .stat-item .options .option {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--light-2);
}
