.profile-stats-mobile__filters {
  height: 40px;
  background: white;
  box-shadow: var(--elevation-mobile);
  display: flex;
  flex-direction: row;
}

.profile-stats-mobile__filters__sport {
  flex-grow: 1;
  border-right: solid 1px var(--light-3);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
}

.profile-stats-mobile__filters__sport__name {
  font-family: var(--nhg-display);
  /* font-weight: 600; */
  color: var(--dark-3);
  flex-grow: 1;
}

.profile-stats-mobile__filters__sport__icon {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* fill: var(--light-2); */
  /* margin-left: auto; */
  /* margin-right: 12px; */
}

.profile-stats-mobile__filters__sport__icon .sports-icon-svg {
  height: 14px;
  fill: var(--blue-main);
}

.profile-stats-mobile__filters__sport__icon__svg {
  height: 12px;
  fill: var(--light-3);
}

.profile-stats-mobile__filters__filters-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  height: 100%;
  font-family: var(--nhg-display);
  /* font-weight: 600; */
  color: var(--dark-3);
  font-size: 12px;
  /* letter-spacing: 0.4px; */
}

.profile-stats-mobile__filters__filters-btn__icon {
  margin-right: 12px;
}
