.profile-wickets-taken-mobile {
  margin: 16px;
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.profile-wickets-taken-mobile__highlight {
  padding: 16px;
}

.profile-wickets-taken-mobile__highlight__value {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-1);
}

.profile-wickets-taken-mobile__highlight__label {
  font-family: var(--nhg-display);
  font-weight: 600;
  font-size: 12px;
  color: var(--dark-4);
  margin-top: 8px;
  /* text-align: center; */
}

.profile-wickets-taken-mobile__highlight__graph {
  padding: 12px;
}

.profile-fielding-stats-mobile {
  margin: 16px;
  /* display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column-gap: 12px; */
  background: var(--light-1);
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
}

.profile-fielding-stats-mobile__title {
  font-family: var(--nhg-display);
  font-weight: 400;
  letter-spacing: 3px;
  color: var(--dark-4);
  font-size: 10px;
  padding: 16px;
}

.profile-fielding-stats-mobile__items {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
}

.profile-fielding-stats-mobile__items__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: solid 1px var(--light-2);
}

.profile-fielding-stats-mobile__items__item:last-child {
  border: none;
}

.profile-fielding-stats-mobile__items__item__value {
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 24px;
  color: var(--dark-1);
}

.profile-fielding-stats-mobile__items__item__key {
  font-family: var(--nhg-display);
  font-weight: 600;
  font-size: 12px;
  color: var(--dark-4);
  margin-top: 8px;
  text-align: center;
}

.profile-fielding-stats-mobile__graphic {
  position: relative;
  height: 40px;
}

.profile-fielding-stats-mobile__graphic__text {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 56px;
  letter-spacing: -6px;
  font-weight: 700;
  font-style: italic;
  line-height: 40px;
}

.profile-fielding-stats-mobile__graphic__text.green {
  color: rgba(99, 234, 71, 0.24);
}

.profile-fielding-stats-mobile__graphic__text.blue {
  color: rgba(19, 160, 250, 0.48);
  margin-bottom: 4px;
  margin-right: 8px;
}
