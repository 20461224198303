.match-report-form {
  border-radius: 4px;
  box-shadow: var(--elevation-3);
  background: white;
  overflow: hidden;
}

.match-report-form__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 10px;
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-2);
}

.match-report-form__header__title {
}

.match-report-form__header__dismiss {
  margin-left: auto;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.match-report-form__header__dismiss__icon {
  height: 16px;
  stroke: var(--dark-3);
}

.match-report-form__image-uploader {
  background: var(--blue-subtle);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.match-report-form__image-uploader__preview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.match-report-form__image-uploader__preview__dismiss {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  background: var(--light-2);
  border-radius: 100%;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.match-report-form__image-uploader__preview__dismiss__icon {
  stroke: var(--dark-4);
  height: 14px;
}

.match-report-form__image-uploader__preview img {
  width: 100%;
  height: auto;
}

.match-report-form__headline-editor {
  padding: 16px;
}

.match-report-form__headline-editor__label {
  font-size: 10px;
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-4);
}

.match-report-form__headline-editor__label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.match-report-form__headline-editor__label__character-count {
  margin-left: auto;
}

.match-report-form__headline-editor__input {
  margin-top: 8px;
}

.match-report-form__headline-editor__input textarea {
  border: solid 1px var(--light-3);
  width: 100%;
  resize: none;
  border-radius: 4px;
  padding: 8px;
  font-family: var(--nhg-display);
  font-weight: 700;
  font-size: 20px;
  height: 64px;
}

.match-report-form__content-editor {
  padding-left: 16px;
  padding-right: 16px;
}

.match-report-form__content-editor__label {
  font-size: 10px;
  font-family: var(--nhg-display);
  font-weight: 600;
  color: var(--dark-4);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.match-report-form__content-editor__label__character-count {
  margin-left: auto;
}

.match-report-form__content-editor__input #match-report-editor {
  /* height: 100px; */
  border: solid 1px var(--light-3);
  border-radius: 4px;
  /* padding: 8px; */
  display: flex;
  flex-direction: column-reverse;
  margin-top: 8px;
  /* border: none; */
}

.match-report-form__content-editor #custom-toolbar {
  border: none;
  position: relative;
  z-index: 0;
}

.match-report-form__content-editor #custom-toolbar .ql-formats {
  margin-left: -14px;
}

.match-report-form__content-editor #match-report-editor .ql-container {
  border: none;
}

.match-report-form__content-editor
  .quill
  > .ql-container
  > .ql-editor.ql-blank::before {
  font-size: 12px;
  font-style: normal;
  font-family: var(--nhg);
  padding-left: 8px;
  left: 0;
  /* color: red; */
}

.match-report-form__content-editor #match-report-editor .ql-editor {
  min-height: 80px;
  /* border-bottom: solid 1px var(--light-3); */
  /* border-radius: 8px; */
  padding: 8px;
  padding-top: 8px;
  /* padding-left: 0; */
  /* padding: 8px; */
  /* background: var(--light-2); */
}

.match-report-form__content-editor #match-report-editor .ql-toolbar {
  border: none;
  padding-left: 0;
  padding-bottom: 0;
}

.match-report-form__content-editor #custom-toolbar svg .ql-stroke {
  stroke: var(--dark-4);
  /* fill: var(--light-4); */
}

.match-report-form__content-editor #custom-toolbar .ql-active svg .ql-stroke {
  stroke: var(--blue-main);
  /* fill: var(--light-4); */
}

.match-report-form__content-editor #custom-toolbar svg .ql-fill {
  /* stroke: var(--light-4); */
  fill: var(--dark-4);
}

.match-report-form__content-editor #custom-toolbar .ql-active svg .ql-fill {
  /* stroke: var(--blue-main); */
  fill: var(--blue-main);
}

.match-report-form .ql-mention-list-container {
  background: white;
  border-radius: 8px;
  box-shadow: var(--elevation-4);
  z-index: 1;
}

.match-report-form .ql-mention-list-container .ql-mention-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.match-report-form .mention-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.match-report-form .mention-item:hover {
  background: var(--light-2);
}

.match-report-form .mention-item__image {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.match-report-form .mention-item__image img {
  width: 100%;
}

.match-report-form .mention-item__text {
  font-size: 10px;
  font-family: var(--nhg);
}

/* .rdw-inline-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
  }
  
  .rdw-block-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
  } */

.match-report-form .mention-item__text__primary {
  font-weight: 600;
  color: var(--dark-1);
}

.match-report-form .mention-item__text__secondary {
  font-size: 10px;
  color: var(--dark-3);
}

.match-report-form__content-editor__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.match-report-form__content-editor__footer__submit {
  margin-left: auto;
}
