.edit-media-description {
  /* position: relative; */
}

.edit-media-description .character-count {
  position: absolute;
  right: 6px;
  top: -6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: 0.4px;
  font-weight: 700;
  color: var(--dark-3);
  font-size: 10px;
  background: white;
  padding-left: 4px;
  padding-right: 4px;
}

.edit-media-description .character-count.red {
  color: var(--red-lighter);
}

.edit-media-description .custom-toolbar-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
}

.edit-media-description #custom-toolbar {
  border: none;
  /* position: relative; */
  z-index: 0;
}

.edit-media-description #custom-toolbar .ql-formats {
  margin-left: -14px;
}

.edit-media-description #edit-media-description-editor .ql-container {
  border: none;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-size: 12px;
  font-style: normal;
  font-family: var(--nhg);
  padding-left: 0;
  left: 0;
  /* color: red; */
}

.quill > .ql-container > .ql-editor.ql-blank br {
  display: none;
}
.edit-media-description .input-wrapper {
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;
  border: solid 1px var(--light-3);
  border-radius: 8px;
}

.edit-media-description #edit-media-description-editor .ql-editor {
  min-height: 120px;
  padding: 0;
  /* border: solid 1px var(--light-3); */
  /* border-radius: 8px; */
  /* padding: 0;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px; */
  /* padding-left: 0; */
  /* padding: 8px; */
  /* background: var(--light-2); */
}

.edit-media-description #edit-media-description-editor .ql-toolbar {
  border: none;
  padding-left: 0;
  padding-bottom: 0;
}

.edit-media-description #custom-toolbar svg .ql-stroke {
  stroke: var(--dark-4);
  /* fill: var(--light-4); */
}

.edit-media-description #custom-toolbar .ql-active svg .ql-stroke {
  stroke: var(--blue-main);
  /* fill: var(--light-4); */
}

.edit-media-description #custom-toolbar svg .ql-fill {
  /* stroke: var(--light-4); */
  fill: var(--dark-4);
}

.edit-media-description #custom-toolbar .ql-active svg .ql-fill {
  /* stroke: var(--blue-main); */
  fill: var(--blue-main);
}

.edit-media-description .ql-mention-list-container {
  background: white;
  border-radius: 8px;
  box-shadow: var(--elevation-4);
  z-index: 1;
}

.edit-media-description .ql-mention-list-container .ql-mention-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.edit-media-description .mention-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.edit-media-description .mention-item:hover {
  background: var(--light-2);
}

.edit-media-description .mention-item__image {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.edit-media-description .mention-item__image img {
  width: 100%;
}

.edit-media-description .mention-item__text {
  font-size: 12px;
  font-family: var(--nhg);
}

.edit-media-description .mention-item__text__primary {
  font-weight: 600;
  color: var(--dark-1);
}

.edit-media-description .mention-item__text__secondary {
  font-size: 10px;
  color: var(--dark-3);
}

.edit-media-description .custom-toolbar-wrapper__options-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.edit-media-description .custom-toolbar-wrapper__options-right__option {
  cursor: pointer;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 700;
  color: var(--dark-4);
  margin-left: 16px;
}

.edit-media-description .custom-toolbar-wrapper__options-right__option.save {
  background: var(--green-main);
  color: white;
  padding-left: 8px;
  padding-right: 8px;
}
