.create-page {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 100%;
  /* grid-column-gap: 32px; */
  height: 100%;
  overflow: hidden;
  /* margin-top: 40px; */
}

.create-page .auth-alert {
  margin-top: 40px;
}

.create-page .auth-alert .auth-alert-link {
  text-decoration: underline;
  cursor: pointer;
  color: var(--dark-3);
}

.create-page .content-wrapper {
  /* padding: 32px; */
  padding: 48px;
  padding-left: 16.66%;
  padding-right: 16.66%;
  background: white;
  overflow: auto;
}

.create-page .content-wrapper .page-title {
  font-size: 32px;
  font-family: var(--nhg-display);
  font-weight: 700;
  margin-bottom: 8px;
}

.create-page .content-wrapper .page-subtitle {
  font-size: 16px;
  color: var(--dark-4);
}

.create-page .graphic-wrapper {
  background: var(--blue-lighter);
  /* width: 40%; */
}

.create-page .create-page-form {
}

.create-page .create-page-form .form-group {
  margin-top: 32px;
  position: relative;
}

.create-page .form-group .input-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.create-page .form-group .input-tags .category {
  padding: 12px;
  margin-right: 16px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 10px;
  border-radius: 24px;
  color: var(--dark-4);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  /* font-weight: 400; */
  margin-bottom: 16px;
}

.create-page .form-group .input-tags .category.selected {
  background: var(--blue-darker);
  color: var(--light-2);
  font-weight: 700;
}

.create-page .create-page-form .form-group .static-label {
  font-size: 14px;
  font-weight: 600;
  color: var(--dark-2);
  margin-bottom: 16px;
}

/* .create-page .create-page-form .form-group .input {
  height: 40px;
  width: 100%;
  border: solid 1px var(--light-4);
  border-radius: 4px;
  outline: none;
  padding-left: 8px;
} */

.create-page .create-page-form .options-group {
  margin-top: 32px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: flex-end;
}

.create-page .form-group .input {
  height: 40px;
  font-size: 16px;
  border: none;
  border-bottom: solid 2px var(--light-4);
  width: 100%;
  outline: none;
  font-family: var(--nhg);
  padding-left: 32px;
}

input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: transparent;
}

input[type="date"]:focus:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:focus:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:focus:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:focus:in-range::-webkit-datetime-edit-text {
  color: var(--light-4);
}

.create-page .form-group .input:focus {
  border-bottom-color: var(--blue-main);
}

.create-page .form-group .input.invalid {
  border-bottom-color: var(--red-main);
}

.create-page
  .form-group
  .input[type="date"]::-webkit-calendar-picker-indicator {
  /* color: rgba(0, 0, 0, 0); */
  /* opacity: 1; */
  display: block;
  /* background: url("../../icons/calendar.svg") no-repeat; */
  width: 14px;
  height: 14px;
  /* color: var(--light-3); */
  filter: brightness(50%);
  /* border-width: thin; */
}

.create-page .form-group label {
  position: absolute;
  left: 32px;
  bottom: 12px;
  font-size: 16px;
  color: var(--dark-4);
  transition: all 0.2s ease-out;
  pointer-events: none;
}

.create-page .form-group .static-icon {
  position: absolute;
  left: 0;
  bottom: 11px;
  color: var(--dark-4);
  transform: all 0.4s ease-out;
  pointer-events: none;
}

.create-page .form-group .input:focus + label {
  font-size: 10px;
  bottom: 38px;
  left: 2px;
}

.create-page .form-group .input.hide-label-on-active + label {
  font-size: 12px;
}

.create-page .form-group .input.hide-label-on-active:focus + label {
  display: none;
}

.create-page .form-group .input:active + label,
.create-page .form-group .input.hasValue + label {
  font-size: 10px;
  bottom: 38px;
  left: 2px;
}
