.event-registration-form-wrapper {
  height: 80%;
  width: 40%;
}

.event-registration-form {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}

.event-registration-form .dismiss-icon {
  position: absolute;
  top: 10px;
  right: 4px;
  cursor: pointer;
}

.event-registration-form__form-type {
  background: var(--blue-darkest);
  color: white;
  font-weight: 700;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  letter-spacing: 0.4px;
  box-shadow: var(--elevation-3);
}

.event-registration-form__cover {
  height: 80px;
  background: var(--blue-subtle);
  box-shadow: var(--elevation-2);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.event-registration-form__cover .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #022e4b;
  z-index: 0;
  opacity: 0.4;
}

.event-registration-form__cover img {
  width: 100%;
}

.event-registration-form__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: var(--elevation-1);
  padding: 16px;
  background: var(--blue-darker);
  color: white;
}

.event-registration-form__header__image {
  height: 40px;
  width: 40px;
  background: var(--blue-subtle);
  margin-right: 16px;
  border-radius: 8px;
}

.event-registration-form__header__title {
}

.event-registration-form__header__title__primary {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
}

.event-registration-form__header__title__secondary {
  color: var(--light-2);
  font-size: 10px;
}

.event-registration-form__header__amount {
  margin-left: auto;
  font-size: 28px;
  font-weight: 700;
  color: var(--light-1);
}

.event-registration-form__body {
  /* display: grid; */
  /* grid-template-columns: repeat(2, minmax(0, 1fr)); */
  padding: 16px;
  background: var(--light-2);
  flex-grow: 1;
  overflow: auto;
}

.event-registration-form__body .app-alert {
}

.event-registration-form__body__label {
  font-weight: 700;
  color: var(--red-lighter);
  margin-bottom: 16px;
  line-height: 20px;
}

.event-registration-form__body__select-team {
  /* display: flex;
  flex-direction: row; */
  height: 32px;
  background: white;
  border-radius: 8px;
  box-shadow: var(--elevation-1);
}

.event-registration-form__body__form-group.profile .image {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
}

.event-registration-form__body__form-group.profile .text {
}

.event-registration-form__footer {
  padding: 16px;
  background: white;
}

.event-registration-form__body__team-selector {
  /* display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 16px; */
}

.event-registration-form__body__team-selector__description {
  margin-bottom: 16px;
}

.event-registration-form__body__team-selector__list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 16px;
}

.event-registration-form__body__team-selector__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  margin-bottom: 16px;
  cursor: pointer;
}

.event-registration-form__body__team-selector__item.selected {
  background: var(--blue-darkest);
  color: white;
  font-weight: 700;
}

.event-registration-form__body__team-selector__item__image {
  height: 32px;
  width: 32px;
  background: var(--blue-subtle);
  margin-right: 16px;
  border-radius: 4px;
  overflow: hidden;
}

.event-registration-form__body__team-selector__item__image img {
  width: 100%;
}

.event-registration-form__body .app-alert {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.event-registration-complete {
  background: white;
  height: 40%;
  width: 40%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
}

.event-registration-complete .dismiss-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.event-registration-complete .icon {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-registration-complete .description {
  font-weight: 100;
  font-size: 16px;
  text-align: center;
}

.event-registration-complete .description .blue-txt {
  color: var(--blue-main);
  margin-bottom: 8px;
  font-weight: 700;
}
