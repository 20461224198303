.directory-page-card {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 16px;
  margin-bottom: 24px;
}

.directory-page-card .image {
  height: 80px;
  width: 80px;
  border-radius: 8px;
  background: var(--blue-subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.directory-page-card .image.black {
  background: black;
}

.directory-page-card .image img {
  width: 100%;
}

.directory-page-card .meta {
  padding-left: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-grow: 1;
}

.directory-page-card .meta .head {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.directory-page-card .meta .head .page-follow-button {
  margin-left: auto;
}

.directory-page-card .meta .head .page-follow-button.submitting {
  padding-left: 20px;
  padding-right: 20px;
}

.directory-page-card .meta .head .page-follow-button.neutral {
  color: var(--dark-4);
}

.directory-page-card .meta .head .page-follow-button .show-on-hover {
  display: none;
}

.directory-page-card .meta .head .page-follow-button:hover .hide-on-hover {
  display: none;
}

.directory-page-card .meta .head .page-follow-button:hover .show-on-hover {
  display: inline-block;
}

.directory-page-card .meta .head .page-follow-button.neutral:hover {
  color: var(--red-lighter);
  border-color: var(--red-lighter);
}

.directory-page-card .meta .head .follow-icon {
  height: 36px;
  width: 36px;
  border-radius: 100%;
  border: solid 2px var(--blue-lighter);
  margin-left: auto;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.directory-page-card .meta .h6 {
  font-size: 16px;
  font-weight: 700;
  font-family: var(--nhg-display);
  color: var(--dark-2);
  line-height: 28px;
  /* margin-bottom: 8px; */
  /* margin-top: 16px; */
  display: block;
  margin-bottom: 4px;
}

.directory-page-card .meta .secondary-text {
  color: var(--dark-4);
  /* font-family: "Inter", sans-serif; */
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.directory-page-card .meta .meta-icon {
  stroke: var(--light-4);
  height: 12px;
  width: 12px;
  margin-right: 8px;
}

.directory-page-card .meta .description {
  /* font-family: "Inter", sans-serif; */
  flex-grow: 1;
}
