.app-album-container-mobile {
}

.app-album-container-mobile__head {
  margin-top: 16px;
  padding: 16px;
  border-bottom: solid 1px var(--light-3);
}

.app-album-container-mobile__head__link {
  margin-bottom: 12px;
  /* text-decoration: underline; */
  color: var(--dark-4);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -8px;
  font-size: 10px;
}

.app-album-container-mobile__head__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* font-family: var(--nhg-display); */
  font-weight: 700;
  font-size: 14px;
  color: var(--dark-2);
}

.app-album-container-mobile__head__title__icon {
  stroke: var(--dark-4);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-album-container-mobile__head__title__icon .icon {
  /* height: 4px; */
}

.app-album-container-mobile__head__title__text {
  /* margin-left: 16px; */
  font-weight: 700;

  margin-bottom: 12px;
}

.app-album-container-mobile__head__meta {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.app-album-container-mobile__head__timestamp {
  /* margin-left: 48px; */
  /* font-size: 14px; */
  color: var(--dark-4);
  font-size: 10px;
}

.app-album-container-mobile__head__add-photos-btn {
  /* margin-left: 48px; */
  /* font-size: 14px; */
  color: var(--dark-4);
  margin-left: auto;
  cursor: pointer;
  color: var(--blue-main);
  font-weight: 700;
}

.app-album-container-mobile__body {
  /* margin-top: 24px; */
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 8px;
}

.app-album-container-mobile__body__item {
  margin-bottom: 8px;
  position: relative;
}

.app-album-container-mobile__body__item__image {
  height: 160px;
  background: black;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.app-album-container-mobile__body__item__image img {
  width: 100%;
}

.app-album-container-mobile__body__item__image .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #022e4b;
  z-index: 0;
  opacity: 0.1;
}
