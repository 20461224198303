.profile-stats {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 32px;
}

.profile-stats .card-section {
  margin-top: 32px;
}

.profile-stats .card-section .card-section-title {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 12px;
  font-weight: 700;
  color: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.profile-stats .left-column {
  grid-column-start: 1;
  grid-column-end: 9;
}

.profile-stats .right-column {
  grid-column-start: 9;
  grid-column-end: 13;
}

.profile-stats .right-column .app-card {
  margin-top: 32px;
  padding: 16px;
}

.profile-stats .filter-item {
  margin-top: 32px;
  padding-left: 16px;
}

.profile-stats .filter-item__input__label {
  font-weight: 700;
  color: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 12px;
}

.profile-stats .filter-item__input {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.profile-stats .filter-item__input__button-filter {
  height: 30px;
  /* width: 32px; */
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  border-radius: 8px;
  box-shadow: var(--elevation-2);
  cursor: pointer;
  transition: all 0.2s ease-out;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 16px;
  font-size: 10px;
  font-weight: 600;
  color: var(--dark-3);
}

.profile-stats .filter-item__input__button-filter.selected {
  background: var(--blue-main);
  color: var(--light-2);
}

.profile-stats .filter-item__input__button-filter:hover {
  box-shadow: var(--elevation-4);
}

.profile-stats .filter-item__input .filter-input-dropdown {
  width: 100%;
  background: white;
  margin-right: 16px;
}

.profile-stats .filter-item__applied {
  /* padding-top: 16px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.profile-stats .filter-item__applied .app-chip {
  margin-right: 12px;
  margin-top: 12px;
  cursor: pointer;
  background: var(--blue-main);
  color: var(--light-1);
  padding-right: 4px;
}
