.events-card {
  padding: 0;
  overflow: hidden;
}

.events-card .header {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  /* margin-bottom: 16px; */
  padding: 16px;
  padding-bottom: 12px;
  padding-left: 24px;
  /* padding-top: 20px; */
  /* padding-right: 24px; */
}

.events-card .header .text {
  font-size: 20px;
  font-weight: 700;
  font-family: var(--nhg-display);
  color: var(--dark-2);
  margin-left: 16px;
}

.events-card .info {
  margin-left: 24px;
  margin-right: 40px;
  /* font-size: 12px; */
  color: var(--dark-3);
  /* padding-bottom: 8px; */
}

.events-card .info p {
  margin: 0;
  margin-bottom: 16px;
  line-height: 19px;
}

.events-card .user-events {
  padding-top: 16px;
  padding-bottom: 24px;
  border-top: solid 1px var(--light-2);
  /* padding: 24px; */
}

.events-card .user-events .app-events-calendar-header {
  padding-left: 24px;
  padding-right: 24px;
}

.events-card .user-events .app-events-calendar-calendar {
  padding-left: 12px;
  padding-right: 12px;
}

.events-card .calendar-fixture-list {
  padding-left: 16px;
  padding-right: 16px;
}

.events-card .calendar-fixture-list .calendar-fixture-list-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 16px;
}

.events-card .calendar-fixture-list .calendar-fixture-list-item .participants {
  flex-grow: 1;
}

.events-card
  .calendar-fixture-list
  .calendar-fixture-list-item
  .participants
  .participant {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding: 8px;
  padding-right: 16px;
}

.events-card
  .calendar-fixture-list
  .calendar-fixture-list-item
  .participants
  .participant
  .image {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: var(--blue-subtle);
  flex-shrink: 0;
  margin-right: 16px;
  overflow: hidden;
}

.events-card
  .calendar-fixture-list
  .calendar-fixture-list-item
  .participants
  .participant
  .name {
  flex-grow: 1;
  color: var(--dark-1);
}

.events-card
  .calendar-fixture-list
  .calendar-fixture-list-item
  .participants
  .participant
  .score {
  /* font-family: var(--nhg-display); */
  font-weight: 600;
  color: var(--dark-2);
}

.events-card .calendar-fixture-list .calendar-fixture-list-item .datetime {
  width: 25%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  border-left: solid 1px var(--light-2);
  font-family: var(--nhg-display);
  font-weight: 700;
  color: var(--dark-4);
  letter-spacing: 0.4px;
  /* font-size: 12px; */
}

.events-card
  .calendar-fixture-list
  .calendar-fixture-list-item
  .datetime
  .date {
  margin-bottom: 4px;
}
