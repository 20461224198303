.fixture-page {
}

.fixture-page .event-head {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  background: white;
  border-bottom: solid 1px var(--light-2);
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 600;
  color: var(--dark-4);
  cursor: pointer;
}

.fixture-page .event-head:hover {
  color: var(--blue-main);
}

.fixture-page .head {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  /* -ms-align-items: center; */
  /* align-items: center; */
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 32px;
  padding-bottom: 32px;
  background: white;
}

.fixture-page .head .fixture-org {
  width: 42.5%;
}

.fixture-page .head .fixture-org .meta .logo-large {
  height: 64px;
  width: 64px;
  background: var(--blue-subtle);
  border-radius: 8px;
  overflow: hidden;
}

.fixture-page .head .fixture-org .meta .logo-large img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.fixture-page .head .fixture-org .meta .logo-small {
  height: 24px;
  width: 24px;
  background: var(--blue-subtle);
  border-radius: 8px;
  overflow: hidden;
}

.fixture-page .head .fixture-org .meta .logo-small img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
}

.fixture-page .head .fixture-org .meta .org-name {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  /* margin-bottom: 24px; */
  color: var(--dark-2);
}

.fixture-page .head .fixture-org .meta .result {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  /* margin-bottom: 24px; */
  color: var(--dark-2);
}

.fixture-page .head .home-org .meta .result {
  margin-left: 64px;
}

.fixture-page .head .away-org .meta .result {
  margin-right: 64px;
}

.fixture-page .head .home-org .meta {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.fixture-page .head .home-org .meta .logo-large {
  margin-right: auto;
}

.fixture-page .head .home-org .meta .logo-small {
  margin-right: 64px;
  margin-left: 24px;
}

.fixture-page .head .home-org .meta .org-name {
}

.fixture-page .head .away-org .meta {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row-reverse;
  -ms-align-items: center;
  align-items: center;
}

.fixture-page .head .away-org .meta .logo-large {
  margin-left: auto;
}

.fixture-page .head .away-org .meta .logo-small {
  margin-left: 64px;
  margin-right: 24px;
}

.fixture-page .head .away-org .meta .org-name {
}

.fixture-page .head .seperator {
  width: 15%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  /* -ms-align-items: center; */
  /* align-items: center; */
  /* justify-content: center; */
  align-items: center;
  color: var(--dark-4);
  font-weight: 600;
  padding-top: 24px;
  /* height: 32px; */
}

.fixture-page .head .seperator .badminton-score {
  /* margin-top: 8px; */
}

.fixture-page .head .seperator .live-indicator {
  font-size: 8px;
  border: solid 1px var(--red-lighter);
  color: var(--red-lighter);
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
}

.fixture-page .tabs {
  height: 40px;
  background: white;
  padding-left: 48px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: center;
  border-top: solid 1px var(--light-2);
  border-bottom: solid 1px var(--light-2);
  position: relative;
}

.fixture-page .tabs .tab {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-right: 32px;
  color: var(--dark-4);
  font-weight: 500;
  border-bottom: solid 2px transparent;
  padding-top: 2px;
}

.fixture-page .tabs .score-fixture-button {
  position: absolute;
  right: 48px;
  top: 10px;
  color: var(--orange-main);
  font-weight: 600;
  cursor: pointer;
}

.fixture-page .tabs .tab.active {
  color: var(--dark-2);
  /* font-weight: 600; */
  border-bottom: solid 2px var(--blue-main);
}

.fixture-page .can-manage-fixture {
  height: 24px;
  background: var(--orange-darker);
  background: var(--red-main);
  width: 100%;
  color: var(--light-1);
  font-weight: 600;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  /* text-decoration: underline; */
  cursor: pointer;
}

.fixture-page .body {
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 48px;
}

.fixture-page .head .highlights {
  font-size: 10px;
  display: flex;
  flex-direction: column;
}

.fixture-page .head .home-org .highlights {
  align-items: flex-end;
}

.fixture-page .head .away-org .highlights {
  align-items: flex-start;
}

.fixture-page .head .highlights__item {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  color: var(--dark-3);
  margin-top: 8px;
  position: relative;
  padding-left: 32px;
  padding-right: 32px;
  flex-wrap: wrap;
}

.fixture-page .head .home-org .highlights__item {
  justify-content: flex-end;
}

.fixture-page .head .highlights__item__icon {
  fill: var(--dark-4);
  height: 14px;
  position: absolute;
}

.fixture-page .head .highlights__item__icon.home {
  margin-right: -4px;
  margin-left: 8px;
  right: 0;
}

.fixture-page .head .highlights__item__icon.away {
  margin-left: -4px;
  margin-right: 8px;
  left: 0;
}
