.home-page {
  display: grid;
  padding-left: 48px;
  padding-right: 48px;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 32px;
  padding-top: 32px;
}

.home-page .sticky-wrapper {
  position: -webkit-sticky;
  position: sticky;
}

.home-page .sticky-wrapper.sticky-top {
  top: 32px;
}

.home-page .sticky-wrapper.sticky-bottom {
  bottom: 32px;
}

/* .home-page .sticky-fill {
  flex-grow: 1;
} */

.home-page .left-column {
  grid-column-start: 1;
  grid-column-end: 4;
}

.home-page .left-column.sticky-bottom {
  display: flex;
  flex-direction: column-reverse;
}

.home-page .middle-column {
  grid-column-start: 4;
  grid-column-end: 10;
}

.home-page .right-column {
  grid-column-start: 10;
  grid-column-end: 13;
  /* display: flex;
  flex-direction: column; */
}

.home-page .right-column.sticky-bottom {
  display: flex;
  flex-direction: column-reverse;
}

.home-page .card-section {
  margin-bottom: 32px;
}

.home-page .card-section .card-section-title {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  font-weight: 700;
  color: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  font-family: var(--nhg-display);
}

.home-page .card-section .card-section-title .app-notification {
  margin-left: auto;
}

/* .home-page .app-card {
  margin-bottom: 32px;
  padding: 16px;
} */

.home-page .card-label {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 12px;
  font-weight: 700;
  color: var(--dark-4);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
}

.home-page .card-label .app-notification {
  margin-left: auto;
}

.home-page .create-post-card {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

.home-page .create-post-card .avatar {
  height: 36px;
  width: 36px;
  background: var(--blue-subtle);
  border-radius: 8px;
  margin-right: 16px;
}

.home-page .create-post-card .input {
  flex-grow: 1;
  color: var(--dark-4);
}

.home-page .page-card {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 16px;
}

.home-page .page-card .image {
  height: 48px;
  width: 48px;
  border-radius: 8px;
  background: var(--blue-subtle);
  margin-right: 16px;
}

.home-page .page-card .meta {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  font-family: "Inter", sans-serif;
}

.home-page .page-card .meta .primary {
  font-weight: 700;
  margin-bottom: 4px;
}

.home-page .page-card .meta .secondary {
  color: var(--dark-2);
}

.home-page .summary-calendar {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  width: 100%;
  /* padding-top: 24px; */
}

.home-page .summary-calendar .week {
  font-size: 10px;
  margin-bottom: 16px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  -ms-align-items: center;
  align-items: center;
}

.home-page .summary-calendar .day-pager {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-align-items: center;
  align-items: center;
  /* padding-left: 16px; */
  /* padding-right: 16px; */
}

.home-page .summary-calendar .week .prev {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border-radius: 8px;
  stroke: var(--blue-lighter);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  /* background: var(--blue-subtlest); */
}

.home-page .summary-calendar .week .next {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  margin-left: 16px;
  stroke: var(--blue-lighter);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  /* background: var(--blue-subtlest); */
}

.home-page .summary-calendar .week .label {
  flex-grow: 1;
  text-align: center;
}

.home-page .summary-calendar .days {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  flex-grow: 1;
}
.home-page .summary-calendar .days .day {
  width: 20%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
}

.home-page .summary-calendar .days .day.selected {
  background: var(--blue-subtle);
}

.home-page .summary-calendar .days .day .value {
  font-weight: 600;
}
.home-page .summary-calendar .days .day .label {
  font-size: 10px;
  color: var(--dark-4);
}

.home-page .summary-calendar .user-events {
  width: 100%;
}

.home-page .summary-calendar .user-events .event-card-small {
  padding-left: 0;
  padding-right: 0;
}
