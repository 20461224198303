.manage-event-details-mobile {
  padding: 16px;
}

.manage-event-details-mobile__info {
  background: white;
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  padding: 12px;
  margin-bottom: 16px;
}

.manage-event-details-mobile__info__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */
  color: var(--dark-4);
}

.manage-event-details-mobile__info__fields {
}

.manage-event-details-mobile__info__fields__group {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-event-details-mobile__info__fields__group.column {
  flex-direction: column;
  align-items: flex-start;
}

.manage-event-details-mobile__info__fields__group__key {
  font-weight: 600;
  color: var(--dark-2);
  font-family: var(--nhg-display);
  display: flex;
  flex-direction: row;
}

.manage-event-details-mobile__info__fields__group.column
  .manage-event-details-mobile__info__fields__group__key {
  width: 100%;
}

.manage-event-details-mobile__info__fields__group__value {
  margin-left: auto;
}

.manage-event-details-mobile__info__fields__group.column
  .manage-event-details-mobile__info__fields__group__value {
  margin-top: 8px;
}

.manage-event-details-mobile__info__fields__group__edit-btn {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--orange-main);
  margin-left: 16px;
  background: var(--orange-subtle);
  border-radius: 4px;
}

.manage-event-details-mobile__location {
  background: white;
  border-radius: 8px;
  box-shadow: var(--elevation-mobile);
  padding: 12px;
}

.manage-event-details-mobile__location__title {
  font-family: var(--nhg-display);
  /* font-style: normal; */
  font-weight: 600;
  font-size: 12px;
  /* line-height: 14px; */
  color: var(--dark-4);
}

.manage-event-details-mobile__location__map {
  height: 150px;
  margin-top: 12px;
}

.manage-event-details-mobile__location__value {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
}

.manage-event-details-mobile__location__value__text {
  padding-right: 16px;
}

.manage-event-details-mobile__location__value__text__primary {
  font-weight: 700;
}

.manage-event-details-mobile__location__value__text__secondary {
}

.manage-event-details-mobile__location__value__edit-btn {
  margin-left: auto;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--orange-main);
  /* margin-left: 16px; */
  background: var(--orange-subtle);
  border-radius: 4px;
}

.manage-event-details-mobile__location__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.manage-event-details-mobile__location__options__option {
  margin-right: 32px;
  color: var(--orange-main);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manage-event-details-mobile__location__options__option__icon {
  /* margin-right: 4px; */
  height: 12px;
}

.manage-event-details-mobile__location__options__option.delete {
  color: var(--red-main);
}

.manage-event-details-mobile__location__options__option.edit
  .manage-event-details-mobile__location__options__option__icon {
  fill: var(--orange-main);
}

.manage-event-details-mobile__location__options__option.delete
  .manage-event-details-mobile__location__options__option__icon {
  stroke: var(--red-main);
}

.manage-event-details-mobile__location__options__option.delete.disabled {
  opacity: 0.6;
  pointer-events: none;
}
